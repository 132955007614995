import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
  AccountingBudgetBeforeYearTableForm,
  AccountingBudgetHookMethodsType,
  ReflectActivatedManagementType,
  ReflectBudgetYearType,
} from '@front/src/features/accounting/features/budget/type';
import { getMaxCol } from '@front/src/features/accounting/features/budget/util/convert';

type HookType = () => {
  methods: AccountingBudgetHookMethodsType<AccountingBudgetBeforeYearTableForm>,
  reflectBudgetYear: ReflectBudgetYearType,
  reflectActivatedManagement: ReflectActivatedManagementType,
}

export const accountingBudgetBeforeYearTableHookDefaultValues: AccountingBudgetBeforeYearTableForm = {
  cellList: [{
    row: 0,
    col: 0,
    value: '과년도 실적(선택미분류)',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 2,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 3,
    value: '비율',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 4,
    value: '합계(년)',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 5,
    value: '평균(월)',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 0,
    value: null, // 선택 미분류
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 2,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 3,
    value: null, // 계층조정 - 비율
    style: 'percent',
    clickable: false,
  }, {
    row: 1,
    col: 4,
    value: null, // 계층조정 - 합계(년)
    style: 'amount',
    clickable: false,
  }, {
    row: 1,
    col: 5,
    value: null, // 계층조정 - 평균(월)
    style: 'amount',
    clickable: false,
  }],
};

const useAccountingBudgetBeforeYearTableHook: HookType = () => {

  const methods = useForm({
    defaultValues: accountingBudgetBeforeYearTableHookDefaultValues,
  });

  const {
    getValues,
    setValue,
  } = methods;

  const reflectBudgetYear: ReflectBudgetYearType = (budgetYear) => {
    const cellList = [...accountingBudgetBeforeYearTableHookDefaultValues.cellList];
    const defaultHeaderMaxCol = getMaxCol(accountingBudgetBeforeYearTableHookDefaultValues.cellList);

    for (let i = 0; i < 12; i++) {
      const value = dayjs().year(budgetYear - 1).month(i).date(1).format('YYMM');
      const col = defaultHeaderMaxCol + i + 1;
      cellList.push({
        col,
        row: 0,
        value,
        isHead: true,
        clickable: false,
      });

      cellList.push({
        col,
        row: 1,
        value: null,
        style: 'amount',
        clickable: false,
      });
    }
    setValue('cellList', cellList);
  };

  const reflectActivatedManagement: ReflectActivatedManagementType = (activatedManagement) => {
    if (activatedManagement && activatedManagement.depth === 4) {
      const cellList = [...getValues('cellList')];
      for (let i = 0; i < cellList.length; i++) {
        const cell = cellList[i];
        if (cell.col === 0 && cell.row === 1) {
          cellList[i].value = activatedManagement.name;
        }
      }
      setValue('cellList', cellList);
    }
  };

  return {
    methods,
    reflectBudgetYear,
    reflectActivatedManagement,
  };
};

export default useAccountingBudgetBeforeYearTableHook;
