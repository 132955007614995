import React, { useCallback } from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import useFileItemDownload from '@front/src/features/file-item/hooks/useDownload';
import { hasImageExtension } from '@front/src/utils';
import Box from '@mui/material/Box';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useImageDraw from '@front/src/features/image-draw/hooks/useImageDraw';
import { useFormContext } from 'react-hook-form';
import HookFormWithFile from '@front/src/components/hook-form/File';

interface Props extends Omit<CellComponentProps, 'item'> {
  name: string;
  disabled?: boolean;
  onAfterChange?: () => void;
}

export default function UIBuilderTableCellFileAttachment(props: Props) {
  const {
    isEditMode,
    onClick,
    sx = {},
    readOnly,
    onSubmit,
    name,
    disabled = false,
    onAfterChange,
  } = props;

  const formContext = useFormContext();
  const { getValues } = formContext;

  const fileItem = getValues(name);

  const { open: openImageDraw } = useImageDraw();
  const onOpenImageModal = useCallback(() => {
    if (fileItem) {
      openImageDraw(fileItem);
    }
  }, [fileItem]);

  const { onDownload } = useFileItemDownload({ fileKey: fileItem?.fileKey });

  const renderContent = () => {
    if (isEditMode && !readOnly) {
      return (
        <HookFormWithFile
          name={name}
          onSubmit={onSubmit}
          formContext={formContext}
          disabled={disabled}
          onAfterChange={onAfterChange}
        />
      );
    }
    if (!fileItem) return <>-</>;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <FileDisplayText
          fileName={fileItem?.filename}
          onClick={onDownload}
          align="left"
        />

        {hasImageExtension(fileItem?.ext) && (
          <ButtonIconUI
            shape="tertiary"
            size="medium"
            onClick={onOpenImageModal}
          >
            <FontAwesomeIcon icon="magnifying-glass" />
          </ButtonIconUI>
        )}
      </Box>
    );
  };
  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
}
