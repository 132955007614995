import { useQuery } from 'react-query';
import AccountingBudgetApi from '@front/src/features/accounting/features/budget/api';
import AccountingBudgetConstant from '@front/src/features/accounting/features/budget/constant';
import {
  AccountingBudgetApiView,
  AccountingManagementView,
  CommonParameter,
} from '@front/src/features/accounting/features/budget/type';

type QueryType = {
  useGetAccountManagementList: (params: CommonParameter) => {
    data: AccountingManagementView[] | undefined,
    isLoading: boolean,
  }
  useGetBudgetList: (params: CommonParameter) => {
    data: AccountingBudgetApiView[] | undefined,
    isLoading: boolean
  }
}

const AccountingBudgetQuery: QueryType = {
  useGetAccountManagementList: (params) => {
    const { data, isLoading } = useQuery<AccountingManagementView[]>({
      queryFn: () => AccountingBudgetApi.getAccountingManagementList(params),
      queryKey: AccountingBudgetConstant.queryKey.accountingManagementList,
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
  useGetBudgetList: (params) => {
    const { data, isLoading } = useQuery<AccountingBudgetApiView[]>({
      queryFn: () => AccountingBudgetApi.getBudgetList(params),
      queryKey: AccountingBudgetConstant.queryKey.budgetList,
      suspense: true,
    });
    return {
      data,
      isLoading,
    };
  },
};

export default AccountingBudgetQuery;
