import React from 'react';
import { useOutletContext, useParams } from 'react-router';
import type { ProjectSalesCommonFeatureProps } from '@front/src/features/project-sales';
import { ContractFinalSection } from '@front/src/features/project-sales/features/tabs/contract/sections/final';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import CommonSectionList from '@front/src/components/ui-builder/CommonSectionList';
import { ContractHistorySection } from '@front/src/features/project-sales/features/tabs/contract/sections/history';
import { useSectionComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useSectionComponentLifeCycleManager';
import useActiveTab from '@front/src/features/project-sales/hooks/useActiveTab';
import { ContractCmSection } from '@front/src/features/project-sales/features/tabs/contract/sections/cm';
import { ContractPaymentTermSection } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term';

export { Tab as ContractTab };

// 객체 key는 mn_section 엔티티의 id 값
const sectionComponents: SectionComponent = {
  102: ContractFinalSection,
  103: ContractHistorySection,
  104: ContractPaymentTermSection,
  105: ContractCmSection,
};

/**
 * [메뉴]프로젝트-영업부-영업정보 / [탭]계약
 * @constructor
 */
function Tab() {
  useSectionComponentLifeCycleManager(sectionComponents);
  const { id } = useParams<{ id: string }>();
  const { tabList } = useOutletContext<ProjectSalesCommonFeatureProps>();
  const activeTab = useActiveTab(tabList);

  return (
    <>
      {activeTab && id && (
        <CommonSectionList
          menuId={activeTab.id}
          dataId={+id}
        />
      )}
    </>
  );
}
