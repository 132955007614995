import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import type {
  EstimationClientQueryDto,
  EstimationHistoryView,
} from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';

import dayjs from 'dayjs';
import { YY_MM_DD } from '@front/src/utils';
import type { FileItemFormValues } from '@front/src/components/hook-form/File';

const useEstimationHistoryTableRowForm = (item: EstimationHistoryView) => {
  const methods = useForm<EstimationHistoryFormValues>({
    defaultValues: getFormValues(item),
  });

  const {
    formState: { isDirty },
    setValue,
    reset,
    getValues,
  } = methods;

  useEffect(() => {
    if (!isDirty) {
      (Object.keys(getFormValues(item)) as (keyof EstimationHistoryFormValues)[]).forEach(
        (field) => {
          setValue(field, getFormValues(item)[field]);
        }
      );
    }
  }, [item, setValue, isDirty]);

  useEffect(() => {
    reset({
      ...getValues(),
      estimationClient: mapEstimationClient(item.estimationClient),
    });
  }, [item.estimationClient]);

  return {
    methods,
  };
};

export default useEstimationHistoryTableRowForm;

const mapEstimationClient = (estimationClient: EstimationClientQueryDto[]) =>
  estimationClient.map((client) => ({
    ...client,
    orderType: client.orderType ?? '',
    createdAt: client.createdAt ? dayjs(client.createdAt).format(YY_MM_DD) : null,
  }));

const getFormValues = (item: EstimationHistoryView) => ({
  ...item,
  ...item.estimation,
  isConfirmed: item.estimation.isConfirmed === null ? '' : item.estimation.isConfirmed ? 'Y' : 'N',
  type: item.estimation.type ?? '',
  status: item.estimation.status ?? '',
  possibilityOfOrder: item.estimation.possibilityOfOrder ?? '',
  projectDifficulty: item.estimation.projectDifficulty ?? '',
  modelDifficulty: item.estimation.modelDifficulty ?? '',
  estimationClient: mapEstimationClient(item.estimationClient),
});

export interface EstimationHistoryFormValues
  extends Omit<
    EstimationHistoryView,
    | 'isConfirmed'
    | 'type'
    | 'status'
    | 'possibilityOfOrder'
    | 'projectDifficulty'
    | 'modelDifficulty'
    | 'estimationClient'
  > {
  isConfirmed: string;
  type: string;
  status: string;
  possibilityOfOrder: string;
  projectDifficulty: string;
  modelDifficulty: string;
  estimationClient: EstimationHistoryFormListValues[];
}

export interface EstimationHistoryFormListValues
  extends Omit<EstimationClientQueryDto, 'orderType' | 'excelFile' | 'pdfFile'> {
  orderType: string;
  excelFile: FileItemFormValues | null;
  pdfFile: FileItemFormValues | null;
}
