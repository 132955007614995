import type React from 'react';
import workCellComponent from '@front/src/features/work/features/work/components/cellComponent';
import type { SxProps } from '@mui/system';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import AccountStatusCellComponents from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/components/cell';

export interface CellComponentProps<T = any>
  extends Omit<
    UIBuilderTableRowProps<T>,
    'rowSpan' | 'rowSpanIndex' | 'rowSpanSum' | 'groupAttr' | 'groupSumAttrs'
  > {
  isEditMode?: boolean;
  onClick?: (innerCellIndex?: number) => void;
  isForm?: boolean;
  sx?: SxProps;
  innerCellIndex?: number; // 행 병합 효과 제어용 속성
}

/**
 * 섹션 공통 테이블 셀 랜더링 컴포넌트 묶음
 * @remarks 객체의 key 는 mn_header_setting 메타의 id 속성 값을 의미한다.
 * @remarks 객체의 value 는 셀 랜더링 컴포넌트이며 반복적으로 사용하는 기능은 공통 셀 컴포넌트를 정의하고 사용한다.
 */
export interface CellComponent<T = any> {
  [key: number]: React.FunctionComponent<CellComponentProps<T>>;
}

const cellComponent: CellComponent = {
  ...workCellComponent,
  ...AccountStatusCellComponents,
};

export default cellComponent;

/**
 * 셀 컴포넌트 등록
 * @param headerId
 * @param component
 */
export const register = (
  headerId: number,
  component: React.FunctionComponent<CellComponentProps>
) => {
  cellComponent[headerId] = component;
};

/**
 * 셀 컴포넌트 삭제
 * @param headerId
 */
export const unregister = (headerId: number) => {
  delete cellComponent[headerId];
};
