import { useForm } from 'react-hook-form';
import {
  AccountingBudgetHookMethodsType,
  AccountingBudgetRecordTableForm,
  ReflectBudgetYearType,
} from '@front/src/features/accounting/features/budget/type';
import { accountingBudgetHookDefaultValues } from '@front/src/features/accounting/features/budget/hook/index';

type HookType = () => {
  methods: AccountingBudgetHookMethodsType<AccountingBudgetRecordTableForm>,
  reflectBudgetYear: ReflectBudgetYearType,
}

export const accountingBudgetRecordTableHookDefaultValues: AccountingBudgetRecordTableForm = {
  cellList: [{
    row: 0,
    col: 0,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
    cellGroupId: 0,
  }, {
    id: 0,
    row: 0,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
    cellGroupId: 0,
  }, {
    id: 0,
    row: 0,
    col: 2,
    value: '과년도 실적',
    isHead: true,
    clickable: false,
    cellGroupId: 0,
  }, {
    id: 0,
    row: 0,
    col: 3,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
    cellGroupId: 0,
  }, {
    id: 0,
    row: 0,
    col: 4,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
    cellGroupId: 0,
  }, {
    row: 1,
    col: 0,
    value: '구분',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 1,
    value: `${accountingBudgetHookDefaultValues.budgetYear - 3}`,
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 2,
    value: `${accountingBudgetHookDefaultValues.budgetYear - 2}`,
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 3,
    value: `${accountingBudgetHookDefaultValues.budgetYear - 1}`,
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 4,
    value: '실적 평균',
    isHead: true,
    clickable: false,
  }, {
    row: 2,
    col: 0,
    isHead: true,
    value: '수금',
    clickable: false,
  }, {
    row: 2,
    col: 1,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 2,
    col: 2,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 2,
    col: 3,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 2,
    col: 4,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 3,
    col: 0,
    isHead: true,
    value: '지출',
    clickable: false,
  }, {
    row: 3,
    col: 1,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 3,
    col: 2,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 3,
    col: 3,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 3,
    col: 4,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 4,
    col: 0,
    isHead: true,
    value: '순이익',
    clickable: false,
  }, {
    row: 4,
    col: 1,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 4,
    col: 2,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 4,
    col: 3,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 4,
    col: 4,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 5,
    col: 0,
    isHead: true,
    value: '이익율',
    clickable: false,
  }, {
    row: 5,
    col: 1,
    value: null,
    style: 'percent',
    clickable: false,
  }, {
    row: 5,
    col: 2,
    value: null,
    style: 'percent',
    clickable: false,
  }, {
    row: 5,
    col: 3,
    value: null,
    style: 'percent',
    clickable: false,
  }, {
    row: 5,
    col: 4,
    value: null,
    style: 'percent',
    clickable: false,
  }],
};

const useAccountingBudgetRecordTableHook: HookType = () => {

  const methods = useForm({
    defaultValues: accountingBudgetRecordTableHookDefaultValues,
  });

  const {
    getValues,
    reset,
  } = methods;


  const reflectBudgetYear: ReflectBudgetYearType = (budgetYear) => {
    const currentValues = getValues();
    const prevCellist = getValues('cellList');
    const cellList: typeof prevCellist = [];

    for (let i = 0; i < prevCellist.length; i++) {
      const cell = prevCellist[i];
      if (cell.col === 1 && cell.row === 1) {
        cellList.push({
          ...cell,
          value: `${budgetYear - 3}`,
        });
      } else if (cell.col === 2 && cell.row === 1) {
        cellList.push({
          ...cell,
          value: `${budgetYear - 2}`,
        });
      } else if (cell.col === 3 && cell.row === 1) {
        cellList.push({
          ...cell,
          value: `${budgetYear - 1}`,
        });
      } else {
        cellList.push(cell);
      }
    }

    reset({
      ...currentValues,
      cellList,
    });
  };

  return {
    methods,
    reflectBudgetYear,
  };
};

export default useAccountingBudgetRecordTableHook;
