import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React, { useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import type { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';
import useUIBuilderTableRowMutation from '@front/src/hooks/ui-builder-table/useTableRowMutation';
import useUIBuilderTableRowLogic from '@front/src/hooks/ui-builder-table/useTableRowLogic';
import useContractHistoryTableRowForm from '@front/src/features/project-sales/features/tabs/contract/sections/history/hooks/useForm';
import type { ContractHistoryUpdateParameter } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/parameter';
import useContractHistoryTableRowUpdate from '@front/src/features/project-sales/features/tabs/contract/sections/history/hooks/useUpdate';
import contractHistoryMutation from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/mutation';

import { useSnackbar } from 'notistack';
import useContractHistoryClientUpdate from '@front/src/features/project-sales/features/tabs/contract/sections/history/hooks/useClientUpdate';

export { TableBody as ContractHistoryTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<ContractHistoryView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<ContractHistoryView>) {
  const { item, formContext, dataId, menuId, sectionId } = props;

  const methods = useContractHistoryTableRowForm(item);
  const { handleSubmit } = methods;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const { onAdd, onUpdateSeq, onDelete, onUpdate } =
    useUIBuilderTableRowMutation<ContractHistoryUpdateParameter>({
      onAdd: contractHistoryMutation.useAdd(commonParams),
      onUpdateSeq: contractHistoryMutation.useUpdateSeq(commonParams),
      onDelete: contractHistoryMutation.useDelete(commonParams),
      onUpdate: contractHistoryMutation.useUpdate(commonParams),
      formContext,
      queryKey: ['project', 'sales', dataId, 'contract', 'history'],
    });

  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = useCallback(() => {
    if (item.contractClient.some((s) => !s.canDelete)) {
      enqueueSnackbar('수금액이 있어 삭제할 수 없습니다.', {
        variant: 'warning',
      });
      return;
    }

    onDelete(item.id);
  }, [onDelete, item, enqueueSnackbar]);

  const { sx, isEditOpen, contextMenuItems } = useUIBuilderTableRowLogic({
    formContext,
    item,
    onAdd,
    onUpdateSeq,
    onDelete: handleDelete,
  });

  const onSubmit = useContractHistoryTableRowUpdate({ onUpdate, item });

  const onInnerCellSubmit = useContractHistoryClientUpdate({
    dataId,
    menuId,
    sectionId,
    item,
    formUpdateFormContext: methods,
  });

  const disabled = useMemo(() => item.contractClient.some((s) => !s.canDelete), [item]);

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={handleSubmit(onSubmit)}
        itemList={contextMenuItems}
        isEditMode={isEditOpen}
        sx={sx}
        onInnerCellSubmit={onInnerCellSubmit}
        disabled={disabled}
      />
    </FormProvider>
  );
}
