import type { AuthorizationPersonalSettingsUserView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import type { AdminRoleNameView } from '@front/user/domain';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';

export const getTab = () => {
  const menuId = useGlobalNavBarState((state) => state.menuId);
  const tabList = useGlobalNavBarState((state) => state.tabList);
  const tab = tabList?.find((item) => item.id === (menuId ? +menuId : 0));
  return tab?.title;
};

export const sortKor = (list: AuthorizationPersonalSettingsUserView[]) => {
  const newList = [...list];
  newList.sort((curr, prev) => curr.name?.localeCompare(prev.name));
  return newList;
};

export const getRefinedTab = (list: string[], role?: AdminRoleNameView) => {
  if (!role) return list;
  if (role.canAccessPageAuthorization && !role.canAccessPageView) return [list[0]];
  if (role.canAccessPageView && !role.canAccessPageAuthorization) return [list[1]];
  return list;
};
