import { v4 as uuid } from 'uuid';
import type { CollectionClaimDocumentFormListValues } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/hooks/useForm';

export const collectionClaimDocumentFormDefaultRow = (): CollectionClaimDocumentFormListValues => ({
  id: uuid(),
  rowId: null,
  claimDate: null,
  writer: null,
  fileItem: null,
  noteCount: null,

  numberAttr1: null,
  numberAttr2: null,
  numberAttr3: null,
  textAttr1: null,
  textAttr2: null,
  textAttr3: null,
  dateAttr1: null,
  dateAttr2: null,
  dateAttr3: null,
});
