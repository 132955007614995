import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';

import { ContractPaymentTermTableBody } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/table-body';
import { ContractPaymentTermTableHead } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/table-head';
import { useFieldArray, useFormContext } from 'react-hook-form';
import type { ContractPaymentTermFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';
import type { ContractPaymentTermModalProps } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/Modal';

export { Table as ContractPaymentTermTable };

function Table(props: ContractPaymentTermModalProps) {
  const formContext = useFormContext<ContractPaymentTermFormValues>();

  const { control, watch } = formContext;

  const fieldArray = useFieldArray<ContractPaymentTermFormValues, 'list', string>({
    name: 'list',
    control,
    keyName: 'fieldId',
  });

  const list = watch('list');

  return (
    <UiBuilderTable
      {...props}
      useFormUpdate
      useSummary
      formUpdateFormContext={formContext}
      HeadComponent={(props) => (
        <ContractPaymentTermTableHead
          {...props}
          formUpdateFormContext={formContext}
          fieldArray={fieldArray}
        />
      )}
      BodyComponent={(props) => (
        <ContractPaymentTermTableBody
          {...props}
          formUpdateFormContext={formContext}
          fieldArray={fieldArray}
        />
      )}
      list={list}
    />
  );
}
