import React, { useMemo } from 'react';
import useCollectionClaimDocumentFormSubmit from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/hooks/useSubmit';
import collectionClaimDocumentMutation from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/query/mutation';
import collectionClaimDocumentQuery from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/query/query';
import type { CollectionClaimDocumentModalProps } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/components/Modal';
import CollectionClaimDocumentModalBody from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/components/ModalBody';
import useCollectionClaimDocumentForm from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/hooks/useForm';
import { FormProvider } from 'react-hook-form';

export default function CollectionClaimDocumentForm(props: CollectionClaimDocumentModalProps) {
  const { menuId, sectionId, dataId, collectionId } = props;
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);
  const list = collectionClaimDocumentQuery.useGet(collectionId, commonParams);
  const onUpdate = collectionClaimDocumentMutation.useUpdate(collectionId, commonParams);

  const methods = useCollectionClaimDocumentForm(list!);
  const { handleSubmit } = methods;
  const onSubmit = useCollectionClaimDocumentFormSubmit(onUpdate, commonParams);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <CollectionClaimDocumentModalBody {...props} />
      </FormProvider>
    </form>
  );
}
