import React, { useCallback, useEffect, useRef } from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import TextFieldBasic from '@front/src/components/components-with-design/component/TextFieldBasic';

interface Props extends CellComponentProps {
  name: string;
  type?: 'default' | 'percent' | 'comma';
  displayValue?: string;
  disabled?: boolean;
  onAfterChange?: () => void;
}

export default function UIBuilderTableCellInputNumber(props: Props) {
  const {
    isEditMode,
    isForm,
    onSubmit,
    readOnly,
    onClick,
    sx,
    name,
    type = 'comma',
    displayValue,
    disabled = false,
    onAfterChange,
  } = props;

  const { getValues, setValue } = useFormContext();
  const value = getValues(name);

  const renderReadOnlyContent = useCallback(() => {
    if (readOnly || !isEditMode) {
      if (displayValue) return displayValue;
      if (type === 'default') return value || '-';
      if (type === 'percent') return value ? `${value}%` : '-';
      if (type === 'comma') return value?.toLocaleString() || '-';
    }
  }, [readOnly, isEditMode, displayValue, type, value]);

  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (e) => {
    if (ref.current) {
      ref.current.value = e.target.value;
    }
  };

  const handleBlur = useCallback(() => {
    if (ref.current) {
      setValue(name, +ref.current.value);
    }

    if (isForm) return;
    onAfterChange?.();
    onSubmit?.();
  }, [setValue, isForm, onAfterChange, onSubmit]);

  const renderContent = useCallback(
    () => (
      <TextFieldBasic
        ref={ref}
        defaultValue={getValues(name) ?? 0}
        type="number"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={readOnly || !isEditMode}
        disabled={disabled}
      />
    ),
    [ref, name, handleBlur, readOnly, isEditMode, disabled]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.value = getValues(name) ?? 0;
    }
  }, [getValues]);

  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {readOnly || !isEditMode ? renderReadOnlyContent() : renderContent()}
    </Box>
  );
}
