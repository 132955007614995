import React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { useInput } from '@front/src/components/input-with-hook-form/useInput';
import { useExtraInput } from '@front/src/components/input-with-hook-form/useExtraInput';
import { onChangeValidator } from '@front/src/components/input-with-hook-form/onChangeValidator';
import type { InputType } from '@front/src/features/affiliated-company/types/domain';
import type { SxProps } from '@mui/system';
import type { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextFieldBasicUI from '@front/src/components/components-with-design/component/TextFieldBasicUI';

interface Props extends Omit<OutlinedInputProps, 'required' | 'value' | 'onChange'> {
  name: string;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  placeholder?: string;
  type?: string;
  inputType?: InputType;
  required?: string;
  sx?: SxProps;
  onPressChange?: () => void;
  disabled?: boolean;
  formContext?: UseFormReturn<any, any, undefined>;
}

export default function InputWithHookForm(props: Readonly<Props>) {
  const {
    sx = {},
    readOnly = false,
    inputType,
    onPressChange,
    type = 'text',
    name,
    onBlur,
  } = props;
  const { required, onSubmit, formContext, ...rest } = props;
  const { control, validateString, validateNumber } = useInput({
    type,
    name,
    formContext,
  });
  const validateInput = useExtraInput(inputType);
  const {
    field: { value, onChange },
    formState: { defaultValues },
  } = useController({ name, control, rules: { required } });
  const currentVal = defaultValues?.[name];
  function handleChange(e) {
    const newValue = onChangeValidator(e, value, inputType);
    onChange({
      ...e,
      target: { ...e.target, value: newValue },
    });
    validateString(e, currentVal);
    onPressChange?.();
  }
  async function handleBlur(e) {
    onBlur?.(value);
    const isPositive = validateNumber(e, value, currentVal);
    const isValid = validateInput(value);
    if (value === currentVal) return;
    if (onSubmit && isPositive && isValid) {
      await onSubmit();
    }
  }
  if (readOnly)
    return (
      <div
        style={{
          textAlign: 'left',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        {value}
      </div>
    );
  return (
    <TextFieldBasicUI
      {...rest}
      type={type}
      value={value ?? ''}
      onChange={(e) => {
        handleChange(e);
      }}
      onBlur={handleBlur}
      sx={{
        ...sx,
      }}
    />
  );
}
