import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { EstimationComparisonView } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/types/view';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React, { useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import estimationComparisonMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/query/mutation';
import useEstimationComparisonTableRowForm from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/hooks/useTableRowForm';
import useUIBuilderTableRowMutation from '@front/src/hooks/ui-builder-table/useTableRowMutation';
import useUIBuilderTableRowLogic from '@front/src/hooks/ui-builder-table/useTableRowLogic';
import type { UpdateByFormDataParameter } from '@front/src/types/parameter';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';

export { TableBody as EstimationComparisonTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<EstimationComparisonView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<EstimationComparisonView>) {
  const { item, formContext, dataId, menuId, sectionId } = props;

  const { methods } = useEstimationComparisonTableRowForm(item);
  const { handleSubmit } = methods;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const { onAdd, onUpdateSeq, onDelete, onUpdate } =
    useUIBuilderTableRowMutation<UpdateByFormDataParameter>({
      onAdd: estimationComparisonMutation.useAdd(commonParams),
      onUpdateSeq: estimationComparisonMutation.useUpdateSeq(commonParams),
      onDelete: estimationComparisonMutation.useDelete(commonParams),
      onUpdate: estimationComparisonMutation.useUpdate(commonParams),
      formContext,
      queryKey: ['project', 'sales', dataId, 'estimation', 'comparison'],
    });

  const { sx, isEditOpen, contextMenuItems } = useUIBuilderTableRowLogic({
    formContext,
    item,
    onAdd,
    onUpdateSeq,
    onDelete: () => {
      onDelete(item.id);
    },
  });

  const onSubmit = useCallback(
    (data) => {
      const formData = new FormData();

      if (
        data.estimationDocument &&
        data.estimationDocument.isNew &&
        data.estimationDocument.multipartFile
      ) {
        formData.append('pdf', data.estimationDocument.multipartFile);
      }

      const request = {
        seq: data.seq,
        estimationDate: data.estimationDate ? dayjs(data.estimationDate).format(YYYY_MM_DD) : null,
        affiliatedCompanyId: data.affiliatedCompany?.id ?? null,
        affiliatedCompanyPersonId: data.affiliatedCompanyPerson?.id ?? null,
        estimationRequestCompanyId: data.estimationRequestCompany?.id ?? null,
        testPlan: data.testPlan ?? null,
        totalPriceDiff: data.totalPriceDiff ?? null,
        comparisonTotalPrice: data.comparisonTotalPrice ?? null,
        windTunnelPrice: data.windTunnelPrice ?? null,
        structuralReviewPrice: data.structuralReviewPrice ?? null,
        pureUnitPrice: data.pureUnitPrice ?? null,
        requiredWeeksStart: data.requiredWeeksStart ?? null,
        requiredWeeksEnd: data.requiredWeeksEnd ?? null,
        isNew: data.estimationDocument?.isNew ?? false,
        managerId: data.createdBy?.id ?? null,

        ...commonParams,

        numberAttr1: data.numberAttr1 ?? null,
        numberAttr2: data.numberAttr2 ?? null,
        numberAttr3: data.numberAttr3 ?? null,
        textAttr1: data.textAttr1 ?? null,
        textAttr2: data.textAttr2 ?? null,
        textAttr3: data.textAttr3 ?? null,
        dateAttr1: data.dateAttr1 ? dayjs(data.dateAttr1).format(YYYY_MM_DD) : null,
        dateAttr2: data.dateAttr2 ? dayjs(data.dateAttr2).format(YYYY_MM_DD) : null,
        dateAttr3: data.dateAttr3 ? dayjs(data.dateAttr3).format(YYYY_MM_DD) : null,
      };

      formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

      onUpdate({
        id: item.id,
        formData,
      });
    },
    [onUpdate, commonParams, item]
  );

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={handleSubmit(onSubmit)}
        itemList={contextMenuItems}
        isEditMode={isEditOpen}
        sx={sx}
      />
    </FormProvider>
  );
}
