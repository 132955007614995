import React, { useMemo } from 'react';
import { EstimationComparisonTableHead } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/components/table-head';
import { EstimationComparisonTableBody } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/components/table-body';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import estimationComparisonQuery from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/query/query';

import useGetUIBuilderTableLoading from '@front/src/hooks/ui-builder-table/useGetLoading';

export { Table as EstimationComparisonTable };

function Table(props: SectionComponentProps) {
  const { dataId, sectionId, menuId } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);
  const list = estimationComparisonQuery.useList(commonParams);

  const isLoading = useGetUIBuilderTableLoading(dataId, (dataId) => [
    'project',
    'sales',
    dataId,
    'estimation',
    'comparison',
  ]);

  return (
    <UiBuilderTable
      {...props}
      HeadComponent={EstimationComparisonTableHead}
      BodyComponent={EstimationComparisonTableBody}
      list={list}
      isLoading={isLoading}
    />
  );
}
