import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import type {
  ContractClientQueryDto,
  ContractHistoryView,
} from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';
import dayjs from 'dayjs';
import { YY_MM_DD } from '@front/src/utils';
import type { FileItemFormValues } from '@front/src/components/hook-form/File';

const useContractHistoryTableRowForm = (item: ContractHistoryView) => {
  const methods = useForm<ContractHistoryFormValues>({
    defaultValues: getFormValues(item),
  });

  const {
    formState: { isDirty },
    setValue,
    reset,
    getValues,
  } = methods;

  useEffect(() => {
    if (!isDirty) {
      (Object.keys(getFormValues(item)) as (keyof ContractHistoryFormValues)[]).forEach((field) => {
        setValue(field, getFormValues(item)[field]);
      });
    }
  }, [item, setValue, isDirty]);

  useEffect(() => {
    reset({
      ...getValues(),
      contractClient: mapContractClient(item.contractClient),
    });
  }, [item.contractClient]);

  return methods;
};

export default useContractHistoryTableRowForm;

const mapContractClient = (contractClient: ContractClientQueryDto[]) =>
  contractClient.map((client) => ({
    ...client,
    contractType: client.contractType ?? '',
    createdAt: client.createdAt ? dayjs(client.createdAt).format(YY_MM_DD) : null,
  }));

const getFormValues = (item: ContractHistoryView) => ({
  ...item,
  ...item.contract,
  isConfirmed: item.contract.isConfirmed === null ? '' : item.contract.isConfirmed ? 'Y' : 'N',
  orderType: item.contract.orderType ?? '',
  estimationId: item.contract.estimationId ? item.contract.estimationId.toString() : '',
  contractClient: item.contractClient.map((client) => ({
    ...client,
    contractType: client.contractType ?? '',
    createdAt: client.createdAt ? dayjs(client.createdAt).format(YY_MM_DD) : null,
  })),
});

export interface ContractHistoryFormValues
  extends Omit<
    ContractHistoryView,
    'isConfirmed' | 'orderType' | 'estimationId' | 'contractClient'
  > {
  isConfirmed: string;
  orderType: string;
  estimationId: string;
  contractClient: ContractHistoryClientFormValues[];
}

interface ContractHistoryClientFormValues
  extends Omit<ContractClientQueryDto, 'contractType' | 'pdfFile' | 'excelFile'> {
  contractType: string;
  pdfFile: FileItemFormValues | null;
  excelFile: FileItemFormValues | null;
}
