import { useForm } from 'react-hook-form';
import {
  AccountingBudgetDiagramTableForm,
  AccountingBudgetHookMethodsType,
  ReflectActivatedManagementType,
  ReflectBudgetYearType, UpdateActivatedManagementType,
  UpdateManagementType,
} from '@front/src/features/accounting/features/budget/type';
import accountingBudgetDiagramTableHookDefaultValues
  from '@front/src/features/accounting/features/budget/hook/diagram-table/default-values';
import {
  generateDefaultCellListValues,
  generateVisibleCellList,
  getVisibleManagementList,
} from '@front/src/features/accounting/features/budget/hook/diagram-table/util';

type HookType = () => {
  methods: AccountingBudgetHookMethodsType<AccountingBudgetDiagramTableForm>,
  updateManagementList: UpdateManagementType,
  reflectActivatedManagement: ReflectActivatedManagementType,
  reflectBudgetYear: ReflectBudgetYearType,
}

const useAccountingBudgetDiagramTableHook: HookType = (updateActivateManagement: UpdateActivatedManagementType) => {

  const methods = useForm({
    defaultValues: accountingBudgetDiagramTableHookDefaultValues,
  });

  const {
    getValues,
    setValue,
  } = methods;

  const updateManagementList: UpdateManagementType = (managementList) => {
    setValue('managementList', managementList);
  };

  const reflectActivatedManagement: ReflectActivatedManagementType = (activatedManagement) => {
    const managementList = getValues('managementList');
    const visibleList = getVisibleManagementList(managementList, activatedManagement);
    const cellList = generateVisibleCellList(visibleList, getValues('cellList'));

    const afterCellList = cellList.map(cell => {
      if (!cell.onClick && cell.cellGroupId && cell.cellGroupId > 0) {
        cell.onClick = () => {
          const management = managementList.find(m => m.id === cell.cellGroupId);
          updateActivateManagement(management);
        };
      }
      return cell;
    });

    setValue('cellList', afterCellList);
  };

  const reflectBudgetYear: ReflectBudgetYearType = (budgetYear) => {
    const cellList = generateDefaultCellListValues(budgetYear);
    setValue('cellList', cellList);
  };

  return {
    methods,
    updateManagementList,
    reflectActivatedManagement,
    reflectBudgetYear,
  };
};

export default useAccountingBudgetDiagramTableHook;