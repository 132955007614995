import { useQuery } from 'react-query';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';

import { adminManageableVariableApi } from '@front/src/features/manageable-variable/query/api';
import { useMemo } from 'react';

const useManagedVariable = (code: ManagedVariable) => {
  const queryKey = useMemo(() => ['managed-variable', code], [code]);

  const { data } = useQuery<VariableInformationView[], Error, VariableInformationView[]>({
    queryFn: () => adminManageableVariableApi.getList(code),
    queryKey,
    enabled: !!code,
    staleTime: 1000,
  });

  return data;
};

export default useManagedVariable;
