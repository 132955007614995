import { useCallback, useState } from 'react';

const useCollectionAddModalState = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [collectionId, setCollectionId] = useState<number | null>(null);
  const onOpen = useCallback(
    (id: number) => {
      setCollectionId(id);
      setOpen(true);
    },
    [setCollectionId, setOpen]
  );

  const onClose = useCallback(() => {
    setCollectionId(null);
    setOpen(false);
  }, [setCollectionId, setOpen]);

  return {
    onOpen,
    onClose,
    open,
    collectionId,
  };
};

export default useCollectionAddModalState;
