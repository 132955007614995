import { AddBudgetParameter, CommonParameter } from '@front/src/features/accounting/features/budget/type';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { useCustomDialog } from '@front/src/features/dialog';
import AccountingBudgetApi from '@front/src/features/accounting/features/budget/api';
import AccountingBudgetConstant from '@front/src/features/accounting/features/budget/constant';
import { handleError } from '@front/src/utils';

type MutationType = {
  addBudget: (params: Pick<CommonParameter, 'menuId'>) => {
    mutate: UseMutateFunction<unknown, unknown, AddBudgetParameter>,
    isLoading: boolean
  }
}

const AccountingBudgetMutation: MutationType = {

  addBudget: (commonParams) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation({
      mutationFn: (params: AddBudgetParameter) => AccountingBudgetApi.addBudget({
        ...commonParams, ...params,
      }),
      onSuccess: async () => await queryClient.invalidateQueries({
        queryKey: AccountingBudgetConstant.queryKey.budgetList,
      }),
      onError: (error) => handleError(error, alert),
      onSettled: async () => await queryClient.invalidateQueries({
        queryKey: AccountingBudgetConstant.queryKey.budgetList,
      }),
    });
    return {
      mutate,
      isLoading,
    };
  },
};

export default AccountingBudgetMutation;
