import React from 'react';
import { FormProvider } from 'react-hook-form';
import { DefaultSectionProps } from '@front/type/Function';
import AccountingBudgetBudgetYearFilter
  from '@front/src/features/accounting/features/budget/component/budget-year-filter';
import AccountingBudgetDetailTable from '@front/src/features/accounting/features/budget/component/detail-table';
import AccountingBudgetDiagramTable from '@front/src/features/accounting/features/budget/component/diagram-table';
import AccountingBudgetSummaryTable from '@front/src/features/accounting/features/budget/component/summary-table';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import useAccountingBudgetHook from '@front/src/features/accounting/features/budget/hook';
import AccountingBudgetRecordTable from '@front/src/features/accounting/features/budget/component/record-table';
import AccountingBudgetTargetTable from '@front/src/features/accounting/features/budget/component/target-table';
import AccountingBudgetTopDownTable from '@front/src/features/accounting/features/budget/component/top-down-table';
import AccountingBudgetBottomUpTable from '@front/src/features/accounting/features/budget/component/bottom-up-table';
import AccountingBudgetConfirmTable from '@front/src/features/accounting/features/budget/component/confirm-table';
import AccountingBudgetBeforeYearTable
  from '@front/src/features/accounting/features/budget/component/before-year-table';
import SectionBox from '@front/src/features/accounting/features/budget/component/common/section-box';
import AccountingBudgetButtonGroup from '@front/src/features/accounting/features/budget/component/button-group';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

const AccountingBudgetFeature = (props: Readonly<SectionComponentProps>) => {

  if (!props.menuId || !props.dataId) {
    return (<LoadingSpinnerUI />);
  }
  const defaultProps: DefaultSectionProps = props as DefaultSectionProps;

  const { methods } = useAccountingBudgetHook();

  return (
    <FormProvider {...methods}>
      <SectionBox>
        <AccountingBudgetBudgetYearFilter
          {...defaultProps}
        />
      </SectionBox>
      <SectionBox>
        <AccountingBudgetRecordTable
          {...defaultProps}
        />
        <AccountingBudgetTargetTable
          {...defaultProps}
        />
        <AccountingBudgetTopDownTable
          {...defaultProps}
        />
        <AccountingBudgetBottomUpTable
          {...defaultProps}
        />
        <AccountingBudgetConfirmTable
          {...defaultProps}
        />
        <AccountingBudgetButtonGroup
          {...defaultProps}
        />
      </SectionBox>
      <SectionBox>
        <AccountingBudgetDiagramTable
          {...defaultProps}
        />
      </SectionBox>
      <SectionBox>
        <AccountingBudgetBeforeYearTable
          {...defaultProps}
        />
      </SectionBox>
      <SectionBox>
        <AccountingBudgetSummaryTable
          {...defaultProps}
        />
      </SectionBox>
      <SectionBox>
        <AccountingBudgetDetailTable
          {...defaultProps}
        />
      </SectionBox>
    </FormProvider>
  );
};

export default AccountingBudgetFeature;
