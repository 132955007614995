import React, { useMemo } from 'react';
import type { CollectionHistoryView } from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import useCollectionHistoryTableRowForm from '@front/src/features/project-sales/features/tabs/collection/sections/history/hooks/useForm';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import { FormProvider } from 'react-hook-form';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import type { ControlPanelItem } from '@front/src/types/domain';
import { ColorPalette } from '@front/assets/theme';
import EditIcon from '@mui/icons-material/Edit';
import useCollectionUpdate from '@front/src/features/project-sales/features/tabs/collection/sections/history/hooks/useUpdate';

export { TableBody as CollectionHistoryTableBody };

function TableBody(props: UIBuilderTableBodyProps) {
  return (
    <UIBuilderTableBody<CollectionHistoryView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<CollectionHistoryView>) {
  const { item, formContext, dataId, sectionId, menuId } = props;

  const methods = useCollectionHistoryTableRowForm(item);
  const { watch } = formContext;
  const { toggleEditOpenId } = useGetUIBuilderTableEditState(formContext);

  const editOpenIdList = watch('editOpenIdList');
  const recentUpdatedId = watch('recentUpdatedId');
  const isEditOpen = useMemo(() => editOpenIdList.includes(item.id), [editOpenIdList, item]);

  const contextMenuItems: ControlPanelItem[] = useMemo(
    () => [
      {
        text: isEditOpen ? '수정 닫기' : '수정',
        icon: EditIcon,
        action: () => {
          toggleEditOpenId(item.id);
        },
        seq: 2,
      },
    ],
    [isEditOpen, toggleEditOpenId, item]
  );

  const sx = useMemo(
    () => ({
      backgroundColor:
        recentUpdatedId === item.id
          ? `${ColorPalette._d2e7fa}`
          : item.isActive
          ? 'inherit'
          : ColorPalette.greyscale.disabled,
    }),
    [recentUpdatedId, item]
  );

  const onInnerCellSubmit = useCollectionUpdate({
    dataId,
    sectionId,
    menuId,
    formUpdateFormContext: methods,
  });

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        itemList={contextMenuItems}
        isEditMode={isEditOpen}
        sx={sx}
        onInnerCellSubmit={onInnerCellSubmit}
      />
    </FormProvider>
  );
}
