import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';
import type { AxiosError } from 'axios';
import type { CollectionHistoryView } from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import dayjs from 'dayjs';
import { YY_MM_DD } from '@front/src/utils';

const collectionHistoryQuery = {
  useGet: (commonParams: UIBuilderParameter) => {
    const url = `/api/project/sales/sales-information/${commonParams.dataId}/collection-history`;
    const queryKey = ['project', 'sales', commonParams.dataId, 'collection', 'history'];
    const enabled = !!commonParams.dataId;

    const { data } = useQuery<CollectionHistoryView[], AxiosError, CollectionHistoryView[]>({
      queryFn: () => getHistory(url, commonParams),
      queryKey,
      enabled,
    });

    return data;
  },
};

export default collectionHistoryQuery;

const getHistory = async (url, commonParams) => {
  const data = await commonApi.getListByParams(url, commonParams);

  return data.map((m) => ({
    ...m,
    id: m.paymentTerm.id,
    paymentStage: m.paymentTerm.paymentStage,
    paymentSequence: m.paymentTerm.paymentSequence,
    clientCompany: m.paymentTerm.clientCompany,
    isActive: m.paymentTerm.isActive,
    collectionList: m.collectionList.map((c) => ({
      ...c,
      collectionDueDateChangeDate: convertToString(
        c.collectionDueDateHistoryList,
        'changedCollectionDueDate'
      ),
      claimDate: convertToString(c.claimDocumentList, 'claimDate'),
      invoiceDate: convertToString(c.taxInvoiceList, 'invoiceDate'),
    })),

    textAttr1: m.paymentTerm.textAttr1,
    textAttr2: m.paymentTerm.textAttr2,
    textAttr3: m.paymentTerm.textAttr3,
    numberAttr1: m.paymentTerm.numberAttr1,
    numberAttr2: m.paymentTerm.numberAttr2,
    numberAttr3: m.paymentTerm.numberAttr3,
    dateAttr1: m.paymentTerm.dateAttr1,
    dateAttr2: m.paymentTerm.dateAttr2,
    dateAttr3: m.paymentTerm.dateAttr3,
  }));
};

const convertToString = (list: any[] | null, attribute: string) => {
  if (!list || list.length === 0) return null;

  const result = dayjs(list[0][attribute]).format(YY_MM_DD);

  if (list.length === 1) {
    return `${result}`;
  }

  return `${result} 외 ${list.length - 1}건`;
};
