import React, { useCallback } from 'react';
import {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { useCustomDialog } from '@front/src/features/dialog';
import type { CollectionTaxInvoiceModalProps } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/Modal';
import CollectionTaxInvoiceTable from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/Table';
import { useFormContext } from 'react-hook-form';
import type { CollectionTaxInvoiceFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/hooks/useForm';

export default function CollectionTaxInvoiceModalBody(props: CollectionTaxInvoiceModalProps) {
  const { onClose } = props;

  const {
    formState: { isDirty },
  } = useFormContext<CollectionTaxInvoiceFormValues>();

  const { confirm } = useCustomDialog();
  const handleCancel = useCallback(() => {
    if (isDirty) {
      confirm({
        lineBreakChildren: [
          { value: '저장되지 않은 변경사항은 반영되지 않습니다.' },
          { value: '탭을 이동하시겠습니까?' },
        ],
        confirmText: '확인',
        closeText: '취소',
        afterConfirm: () => {
          onClose();
        },
      });
      return;
    }

    onClose();
  }, [isDirty, confirm, onClose]);

  return (
    <ModalBodyUI sx={modalBodySx}>
      <ModalHeaderUI>
        저장하지 않은 내용은 반영되지 않습니다.
        <ButtonBasicUI
          shape="tertiary"
          size="medium"
          onClick={handleCancel}
          sx={buttonSx}
        >
          취소
        </ButtonBasicUI>
        <ButtonBasicUI
          type="submit"
          shape="primary2"
          size="medium"
          sx={buttonSx}
        >
          저장
        </ButtonBasicUI>
      </ModalHeaderUI>
      <ModalContentUI sx={modalContentSx}>
        <CollectionTaxInvoiceTable {...props} />
      </ModalContentUI>
    </ModalBodyUI>
  );
}

const modalBodySx = {
  minWidth: '40dvw',
  minHeight: '50dvh',
};

const buttonSx = {
  width: '64px',
};

const modalContentSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  maxWidth: '96dvw',
};
