import React from 'react';
import UIBuilderTableBody, {
  type UIBuilderTableBodyProps,
} from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import type {
  CollectionClaimDocumentFormListValues,
  CollectionClaimDocumentFormValues,
} from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/hooks/useForm';
import { FormProvider, type UseFieldArrayReturn } from 'react-hook-form';
import useCollectionModalTableRowLogic from '@front/src/features/project-sales/features/tabs/collection/hooks/useModalTableRowLogic';

interface TableBodyProps extends UIBuilderTableBodyProps {
  fieldArray: UseFieldArrayReturn<CollectionClaimDocumentFormValues, 'list', string>;
}

export default function CollectionClaimDocumentTableBody(props: TableBodyProps) {
  return (
    <UIBuilderTableBody<CollectionClaimDocumentFormListValues>
      {...props}
      RowComponent={(rowProps) => (
        <TableRow
          {...rowProps}
          fieldArray={props.fieldArray}
        />
      )}
    />
  );
}

interface TableRowProps extends UIBuilderTableRowProps {
  fieldArray: UseFieldArrayReturn<CollectionClaimDocumentFormValues, 'list', string>;
}

function TableRow(props: TableRowProps) {
  const { fieldArray, rowIndex, formUpdateFormContext } = props;

  const { contextMenuItems, isEditMode, sx } = useCollectionModalTableRowLogic(
    formUpdateFormContext,
    fieldArray,
    rowIndex
  );

  return (
    <FormProvider {...formUpdateFormContext!}>
      <UiBuilderTableRow
        {...props}
        itemList={contextMenuItems}
        isEditMode={isEditMode}
        sx={sx}
      />
    </FormProvider>
  );
}
