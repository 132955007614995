import React, { useMemo } from 'react';
import ContractPaymentTermModalBody from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/ModaBody';
import useContractPaymentTermTabForm from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';
import contractPaymentTermQuery from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/query/query';
import contractPaymentTermMutation from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/query/mutation';
import useGetContractPaymentTermSubmit from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useGetSubmit';
import contractHistoryQuery from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/query';
import { FormProvider } from 'react-hook-form';
import type { ContractPaymentTermModalProps } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/Modal';

export default function ContractPaymentTermModalForm(props: ContractPaymentTermModalProps) {
  const { dataId, menuId, sectionId, defaultClientId } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const dataObject = contractPaymentTermQuery.useGetObject(defaultClientId, commonParams);
  const onUpdate = contractPaymentTermMutation.useUpdate(defaultClientId, commonParams);
  const historyList = contractHistoryQuery.useGetList(commonParams);

  const methods = useContractPaymentTermTabForm(defaultClientId, dataObject, historyList);
  const { handleSubmit } = methods;
  const onSubmit = useGetContractPaymentTermSubmit(methods, onUpdate, commonParams);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <ContractPaymentTermModalBody {...props} />
      </FormProvider>
    </form>
  );
}
