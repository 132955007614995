import { ColorPalette } from '@front/assets/theme';
import { bondStatus } from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import { useFormContext } from 'react-hook-form';
import type { CollectionHistoryFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/history/hooks/useForm';
import { useCallback } from 'react';

const useGetCollectionCellSx = () => {
  const { getValues } = useFormContext<CollectionHistoryFormValues>();
  const getSx = useCallback(
    (index: number) => {
      const value = getValues(`collectionList.${index}`);

      const convertBackgroundColor = () => {
        if (!value.isActive) return ColorPalette.greyscale.disabled;

        if (!(value.bondStatus === bondStatus.NORMAL || value.bondStatus === bondStatus.INACTIVE))
          return ColorPalette.sub.sub_primary;

        if (value.bondStatus === bondStatus.NORMAL && value.unpaidAmount === 0)
          return ColorPalette.main.main_tertiary;

        return 'transparent';
      };

      const convertColor = () => {
        if (!value.isActive) return ColorPalette.greyscale.text_secondary;

        return 'default';
      };

      return {
        backgroundColor: convertBackgroundColor(),
        color: convertColor(),
      };
    },
    [getValues]
  );

  return { getSx };
};

export default useGetCollectionCellSx;
