import { useFormContext } from 'react-hook-form';
import type { EstimationHistoryFormValues } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useTableRowForm';

import estimationHistoryClientMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/clientMutation';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';

interface Props
  extends Pick<
    CellComponentProps<EstimationHistoryView>,
    'dataId' | 'sectionId' | 'menuId' | 'item'
  > {}

const useEstimationHistoryClientUpdate = ({ dataId, sectionId, menuId, item }: Props) => {
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);
  const { getValues } = useFormContext<EstimationHistoryFormValues>();
  const onUpdate = estimationHistoryClientMutation.useUpdateByFormData(commonParams);

  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = useCallback(
    (index) => {
      if (!item.estimation.canDelete) {
        enqueueSnackbar('계약과 연결되어 있어 수정할 수 없습니다.', {
          variant: 'warning',
        });

        return;
      }

      const value = getValues(`estimationClient.${index}`);

      if (!value.id) return;

      const formData = new FormData();

      if (value.excelFile && value.excelFile.isNew && value.excelFile.multipartFile) {
        formData.append('excel', value.excelFile.multipartFile);
      }

      if (value.pdfFile && value.pdfFile.isNew && value.pdfFile.multipartFile) {
        formData.append('pdf', value.pdfFile.multipartFile);
      }

      const request = {
        seq: value.seq,
        clientCompanyId: value.clientCompany?.id ?? null,
        clientCompanyPersonId: value.clientCompanyPerson?.id ?? null,
        orderType: value.orderType || null,
        nextContactDateClient: value.nextContactDateClient
          ? dayjs(value.nextContactDateClient).format(YYYY_MM_DD)
          : null,
        blockName: value.blockName ?? null,
        siteModel: value.siteModel ?? null,
        testPlan: value.testPlan ?? null,
        requiredWeeksStart: value.requiredWeeksStart ?? null,
        requiredWeeksEnd: value.requiredWeeksEnd ?? null,
        estimatedStartDate: value.estimatedStartDate
          ? dayjs(value.estimatedStartDate).format(YYYY_MM_DD)
          : null,
        totalEstimationPrice: value.totalEstimationPrice ?? null,
        estimatedWindTunnelPrice: value.estimatedWindTunnelPrice ?? null,
        actualWindTunnelPrice: value.actualWindTunnelPrice ?? null,
        structuralReviewPrice: value.structuralReviewPrice ?? null,
        structuralReviewRatio: value.structuralReviewRatio ?? null,
        estimationCm: value.estimationCm ?? null,
        estimationCmPostDeduction: value.estimationCmPostDeduction ?? null,
        cmDeductionRatio: value.cmDeductionRatio ?? null,
        cmRatio: value.cmRatio ?? null,
        actualUnitPrice: value.actualUnitPrice ?? null,
        targetUnitPrice: value.targetUnitPrice ?? null,
        minimumUnitPrice: value.minimumUnitPrice ?? null,
        actualCostPrice: value.actualCostPrice ?? null,
        isExcelNew: value.excelFile?.isNew ?? false,
        isPdfNew: value.pdfFile?.isNew ?? false,
        managerId: value.createdBy?.id ?? null,

        ...commonParams,
      };

      formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

      onUpdate(
        {
          id: value.id,
          formData,
        },
        {
          onSuccess: () => {
            enqueueSnackbar('저장했습니다.', {
              variant: 'success',
            });
          },
        }
      );
    },
    [onUpdate, enqueueSnackbar, commonParams, item]
  );

  return onSubmit;
};

export default useEstimationHistoryClientUpdate;
