import type { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DeleteSweep } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import type { ContractPaymentTermFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';

const useContractPaymentTermTableHeadLogic = (
  formUpdateFormContext: UseFormReturn<ContractPaymentTermFormValues, any, undefined>,
  fieldArray: UseFieldArrayReturn<ContractPaymentTermFormValues, 'list', string>,
  defaultRow: any
) => {
  const { setValue, getValues } = formUpdateFormContext;
  const { prepend, replace, append } = fieldArray;

  const handleAddTop = useCallback(() => {
    prepend(defaultRow);
    setValue('lastUpdatedId', defaultRow.id);
    setValue('editOpenIdList', [...getValues('editOpenIdList'), defaultRow.id]);
  }, [prepend, setValue, getValues, defaultRow]);

  const handleAddBottom = useCallback(() => {
    append(defaultRow);
    setValue('lastUpdatedId', defaultRow.id);
    setValue('editOpenIdList', [...getValues('editOpenIdList'), defaultRow.id]);
  }, [append, setValue, getValues, defaultRow]);

  const handleCloseAllEditOpen = useCallback(() => {
    setValue('editOpenIdList', []);
  }, [setValue]);

  const { enqueueSnackbar } = useSnackbar();
  const handleRemoveAll = useCallback(() => {
    const list = getValues('list');
    if (list.some((s) => !s.canDelete)) {
      enqueueSnackbar('수금액이 있는 행이 있어 전체 삭제할 수 없습니다.', {
        variant: 'warning',
      });

      return;
    }

    setValue('deleteList', [...list]);
    replace([]);
    setValue('lastUpdatedId', null);
    setValue('editOpenIdList', []);
  }, [getValues, enqueueSnackbar, setValue, replace]);

  const itemList = useMemo(
    () => [
      {
        text: '최상단에 추가',
        icon: AddCircleOutlineIcon,
        action: handleAddTop,
        seq: 4,
      },
      {
        text: '최하단에 추가',
        icon: AddCircleOutlineIcon,
        action: handleAddBottom,
        seq: 5,
      },
      {
        text: '전체 수정 닫기',
        icon: HighlightOffIcon,
        action: handleCloseAllEditOpen,
        seq: 98,
      },
      {
        text: '전체 삭제',
        icon: DeleteSweep,
        action: handleRemoveAll,
        iconColor: '#e43333',
        seq: 100,
      },
    ],
    [handleAddBottom, handleAddTop, handleCloseAllEditOpen, handleRemoveAll]
  );

  return {
    itemList,
  };
};

export default useContractPaymentTermTableHeadLogic;
