import React, { useMemo } from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { EstimationHistoryTableBody } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/components/table-body';
import { EstimationHistoryTableHead } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/components/table-head';
import estimationHistoryQuery from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/query';

import useGetUIBuilderTableLoading from '@front/src/hooks/ui-builder-table/useGetLoading';

export { Table as EstimationHistoryTable };

function Table(props: SectionComponentProps) {
  const { dataId, menuId, sectionId } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const list = estimationHistoryQuery.useGetList(commonParams);

  const isLoading = useGetUIBuilderTableLoading(dataId, (dataId) => [
    'project',
    'sales',
    dataId,
    'estimation',
    'history',
  ]);

  const isClientLoading = useGetUIBuilderTableLoading(dataId, (dataId: number | undefined) => [
    'project',
    'sales',
    dataId,
    'estimation',
    'history',
    'client',
  ]);

  return (
    <UiBuilderTable
      {...props}
      HeadComponent={EstimationHistoryTableHead}
      BodyComponent={EstimationHistoryTableBody}
      list={list}
      isLoading={isLoading || isClientLoading}
    />
  );
}
