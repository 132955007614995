import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import type { CollectionTaxInvoiceView } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/types/views';
import { useCallback, useEffect } from 'react';

const useCollectionTaxInvoiceForm = (list: CollectionTaxInvoiceView[]) => {
  const methods = useForm<CollectionTaxInvoiceFormValues>({
    defaultValues: getDefaultValues(list),
  });

  const { reset } = methods;

  const initializeForm = useCallback(() => {
    reset(getDefaultValues(list));
  }, [reset, list]);

  useEffect(() => {
    initializeForm();
  }, [list]);

  return methods;
};

export default useCollectionTaxInvoiceForm;

const getDefaultValues = (list: CollectionTaxInvoiceView[]) => ({
  list: convertList(list),
  lastUpdatedId: null,
  editOpenIdList: [],
  deleteList: [],
});

const convertList = (list: CollectionTaxInvoiceView[]) =>
  list.map((l) => ({
    ...l,
    id: uuid(),
    rowId: l.id,
  }));

export interface CollectionTaxInvoiceFormValues {
  lastUpdatedId: string | null;
  editOpenIdList: string[];
  list: CollectionTaxInvoiceFormListValues[];
  deleteList: CollectionTaxInvoiceFormListValues[];
}

export interface CollectionTaxInvoiceFormListValues extends Omit<CollectionTaxInvoiceView, 'id'> {
  id: string;
  rowId: number | null;
  fileItem: any | null;
}
