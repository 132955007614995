import React from 'react';
import { Box } from '@mui/material';
import { DefaultSectionComponent } from '@front/type/Function';
import AccountingBudgetCopyButton
  from '@front/src/features/accounting/features/budget/component/button-group/copy-button';
import AccountingBudgetSaveButton
  from '@front/src/features/accounting/features/budget/component/button-group/save-button';
import AccountingBudgetConfirmButton
  from '@front/src/features/accounting/features/budget/component/button-group/confirm-button';

const AccountingBudgetButtonGroup: DefaultSectionComponent = (props) => {

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'min-content',
        justifyContent: 'space-between',
        '& > button': {
          width: '2dvw',
          marginRight: '4px'
        },
      }}
    >
      <AccountingBudgetCopyButton />
      <AccountingBudgetSaveButton />
      <AccountingBudgetConfirmButton />
    </Box>
  );
};

export default AccountingBudgetButtonGroup;
