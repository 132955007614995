import { useCustomDialog } from '@front/src/features/dialog';
import { useMutation, useQueryClient } from 'react-query';
import { handleError } from '@front/src/utils';
import { projectSalesDbAnalysisPresetApi } from '@front/src/features/project-sales-db/features/preset/api/preset-api';
import { PresetParameter } from '@front/src/features/project-sales-db/features/preset/type';

export const projectSalesDbAnalysisPresetMutation = {
  useCreate: (menuId: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
      mutationFn: (params: PresetParameter) =>
        projectSalesDbAnalysisPresetApi.create(params, menuId),
      onSuccess: async () => {
        await queryClient.invalidateQueries(['project', 'sales-db-analysis', 'preset', 'list']);
      },
      onError: async (error) => handleError(error, alert),
    });
    return {
      mutate,
    };
  },
  useUpdate: (menuId: number, presetId: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (params: PresetParameter) => projectSalesDbAnalysisPresetApi.modify(params, presetId, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['project', 'sales-db-analysis', 'preset', 'list']);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
  useDelete: (menuId: number) => {
    const { alert } = useCustomDialog();
    const queryClient = useQueryClient();
    const { mutate } = useMutation(
      (presetId: number) => projectSalesDbAnalysisPresetApi.remove(presetId, menuId),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['project', 'sales-db-analysis', 'preset', 'list']);
        },
        onError: async (error) => handleError(error, alert),
      }
    );
    return {
      mutate,
    };
  },
};
