import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import React, { useCallback, useMemo } from 'react';
import useUIBuilderTableHeadMutation from '@front/src/hooks/ui-builder-table/useTableHeadMutation';
import useGetUIBuilderTableHeadContextMenu from '@front/src/hooks/ui-builder-table/useTableHeadLogic';
import contractHistoryMutation from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/mutation';
import { useSnackbar } from 'notistack';

import contractHistoryQuery from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/query';

export { TableHead as ContractHistoryTableHead };

function TableHead(props: UIBuilderTableHeadProps) {
  const { dataId, menuId, sectionId } = props;
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const { onAdd, onDeleteAll } = useUIBuilderTableHeadMutation({
    onAdd: contractHistoryMutation.useAdd(commonParams),
    onDelete: contractHistoryMutation.useDelete(commonParams),
    commonParams,
    queryKey: ['project', 'sales', commonParams.dataId, 'contract', 'history'],
  });

  const list = contractHistoryQuery.useGetList(commonParams);

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteAll = useCallback(() => {
    const some = list?.flatMap((f) => f.contractClient)?.some((f) => !f.canDelete);
    if (some) {
      enqueueSnackbar('수금액이 있는 계약 행이 있어 전체 삭제할 수 없습니다.', {
        variant: 'warning',
      });
      return;
    }

    onDeleteAll();
  }, [onDeleteAll, enqueueSnackbar, list]);

  const { itemList } = useGetUIBuilderTableHeadContextMenu({
    onAdd,
    onDeleteAll: handleDeleteAll,
  });

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={itemList}
    />
  );
}
