import {
  apiAminSectionRequestUrl,
  getMenuIdHeaders,
  menuRequestUrl,
  userListUrl,
} from '@front/src/utils';
import type { HeaderSettingView, SectionView } from '@front/src/types';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type {
  AddParameter,
  HeaderGetParams,
  IdsDataParameter,
  SectionGetParams,
  SeqUpdateParameter,
  UIBuilderParameter,
  UserListGetParams,
} from '@front/src/types/parameter';
import type { UserShortView } from '@front/src/features/mobile-notification/types/view';

const url = {
  getSectionList: (id?: number) => `${menuRequestUrl}/${id}/section`,
  getHeaderList: (id?: number) => `${apiAminSectionRequestUrl}/${id}/header-setting`,
  getUserList: () => `${userListUrl}`,
  createImage: () => `/api/file-item/image`,
};

export const commonApi = {
  getSectionList: async <T = SectionView[]>(authMenuId?: number, params?: SectionGetParams) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getSectionList(authMenuId), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getHeaderList: async <T = HeaderSettingView[]>(
    id?: number,
    params?: HeaderGetParams,
    authMenuId?: number
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getHeaderList(id), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  getUserList: async <T = UserShortView[]>(params: UserListGetParams, authMenuId) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getUserList(), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
  createImage: async <T = string>(formData, authMenuId) =>
    await axios.post<T, AxiosResponse<T>>(url.createImage(), formData, {
      headers: getMenuIdHeaders(authMenuId),
    }),

  add: async (url: string, params: AddParameter, commonParams: UIBuilderParameter) => {
    const { data } = await axios.post(url, null, {
      params,
      headers: getMenuIdHeaders(commonParams.menuId),
    });

    return data;
  },
  getList: async (url: string, commonParams: UIBuilderParameter) => {
    const { data } = await axios.get(url, {
      headers: getMenuIdHeaders(commonParams.menuId),
    });

    return data;
  },
  getListByParams: async (url: string, commonParams: UIBuilderParameter) => {
    const { data } = await axios.get(url, {
      params: {
        menuId: commonParams.menuId,
        dataId: commonParams.dataId,
        sectionId: commonParams.sectionId,
      },
      headers: getMenuIdHeaders(commonParams.menuId),
    });

    return data;
  },
  update: async (url: string, params: any, commonParams: UIBuilderParameter) => {
    const { data } = await axios.put(url, params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    });

    return data;
  },
  updateByFormData: async (url: string, formData: FormData, commonParams: UIBuilderParameter) => {
    const { data } = await axios.put(url, formData, {
      headers: getMenuIdHeaders(commonParams.menuId),
    });

    return data;
  },
  updateSeq: async (url: string, params: SeqUpdateParameter, commonParams: UIBuilderParameter) => {
    const { data } = await axios.patch(url, params, {
      headers: getMenuIdHeaders(commonParams.menuId),
    });

    return data;
  },
  delete: async (url: string, params: IdsDataParameter, commonParams: UIBuilderParameter) => {
    const { data } = await axios.delete(url, {
      data: params,
      headers: getMenuIdHeaders(commonParams.menuId),
    });

    return data;
  },
};
