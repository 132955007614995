import React, { forwardRef } from 'react';
import type { TableHeadProps } from '@mui/material/TableHead';
import TableHead from '@mui/material/TableHead';
import { ColorPalette } from '@front/assets/theme';

const TableHeadUI = forwardRef<HTMLTableSectionElement, TableHeadProps>(
  ({ sx = {}, ...rest }, ref) => (
    <TableHead
      ref={ref}
      sx={{
        background: ColorPalette.background.bg03,
        '& th': {
          fontWeight: '500',
        },
        ...sx,
      }}
      {...rest}
    />
  )
);

export default TableHeadUI;

TableHeadUI.displayName = 'UIBuilderTableHeadUI';
