import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ListDrawer } from '@front/src/components/list-drawer/components/list-drawer';
import { Box } from '@mui/material';
import theme, { ColorPalette } from '@front/assets/theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useListDrawerState } from '@front/src/components/list-drawer/useState';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  anchor?: 'left' | 'right';
  overlay?: boolean;
}

const ResizableListDrawer = ({ isOpen, children, anchor = 'left', overlay = false }: Props) => {
  const [drawerWidth, setDrawerWidth] = useState(560);
  const isResizing = useRef(false);
  const { setIsResizeActive } = useListDrawerState(
    useShallow((state) => ({
      setIsResizeActive: state.setIsResizeActive,
      setCurrentWidth: state.setCurrentWidth,
      currentWidth: state.currentWidth,
    }))
  );

  const handleMouseDown = useCallback(() => {
    isResizing.current = true;
    setIsResizeActive(true);
  }, [isResizing]);

  const handleMouseUp = useCallback(() => {
    isResizing.current = false;
    setIsResizeActive(false);
  }, [isResizing]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isResizing.current) {
        const minWidth = 230;
        const maxWidth = 560;
        const clientWidth = document.documentElement.clientWidth;
        const currentWidth = anchor === 'left' ? e.clientX : clientWidth - e.clientX;

        const isValidWidth = (width: number) => width >= minWidth && width <= maxWidth;

        if (isValidWidth(currentWidth)) {
          setDrawerWidth(currentWidth);
        }
      }
    },
    [isResizing, anchor]
  );

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <ListDrawer
      open={isOpen}
      openedWidth={drawerWidth}
      anchor={anchor}
      overlay={overlay}
      closedWidth={1}
      sx={{
        zIndex: theme.zIndex.drawer + 1010,
        overflow: 'visible',
        position: 'absolute',
        backgroundColor: ColorPalette._2d3a54,
        [anchor]: 0,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            width: '8px',
            height: '100%',
            position: 'absolute',
            top: 0,
            [anchor === 'left' ? 'right' : 'left']: '-8px',
            cursor: 'ew-resize',
            zIndex: theme.zIndex.drawer + 1001,
            backgroundColor: ColorPalette._b2b4b7,
            display: 'flex',
            alignItems: 'center',
          }}
          onMouseDown={handleMouseDown}
        >
          <MoreVertIcon
            sx={{
              width: '100%',
              transform: 'scale(4)',
            }}
          />
        </Box>
        {children}
      </Box>
    </ListDrawer>
  );
};

export default ResizableListDrawer;
