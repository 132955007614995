import {
  AccountingBudgetManagement,
  VerticalTableCellProps,
} from '@front/src/features/accounting/features/budget/type';
import { getMaxCol, getMaxRow } from '@front/src/features/accounting/features/budget/util/convert';


export function generateCellValue(props: {
  startRow: number,
  startCol: number,
  value?: string,
  isHead?: boolean,
  cellGroupId?: number,
}): VerticalTableCellProps {

  const {
    startRow,
    startCol,
    value,
    isHead,
    cellGroupId,
  } = props;
  if (typeof value === 'undefined' || typeof cellGroupId === 'undefined' || typeof isHead === 'undefined') {
    return [{
      row: startRow,
      col: startCol,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }, {
      row: startRow,
      col: startCol + 1,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }, {
      row: startRow + 1,
      col: startCol,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }, {
      row: startRow + 1,
      col: startCol + 1,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }, {
      row: startRow,
      col: startCol + 2,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }, {
      row: startRow + 1,
      col: startCol + 2,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }, {
      row: startRow,
      col: startCol + 3,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }, {
      row: startRow + 1,
      col: startCol + 3,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }, {
      row: startRow,
      col: startCol + 4,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }, {
      row: startRow + 1,
      col: startCol + 4,
      value: '',
      style: 'span',
      isHead: false,
      clickable: false,
    }];
  }

  const clickable = !isHead && cellGroupId > 0;

  return [{
    row: startRow,
    col: startCol,
    value,
    isHead,
    clickable,
    cellGroupId,
    justifyContent: isHead ? 'center' : 'flex-start',
  }, {
    row: startRow,
    col: startCol + 1,
    value: '',
    style: 'span',
    isHead,
    clickable,
    cellGroupId,
  }, {
    row: startRow + 1,
    col: startCol,
    value: '',
    style: 'span',
    isHead,
    clickable,
    cellGroupId,
  }, {
    row: startRow + 1,
    col: startCol + 1,
    value: '',
    style: 'span',
    isHead,
    clickable,
    cellGroupId,
  }, {
    row: startRow,
    col: startCol + 2,
    value: '계층조정',
    isHead,
    justifyContent: 'flex-start',
    clickable: false,
    cellGroupId,
  }, {
    row: startRow + 1,
    col: startCol + 2,
    value: '미분류조정',
    isHead,
    justifyContent: 'flex-start',
    clickable,
    cellGroupId,
  }, {
    row: startRow,
    col: startCol + 3,
    value: null, // 계층조정 금액 값
    style: 'amount',
    isHead,
    clickable,
    cellGroupId,
    onClick: () => {
    },
  }, {
    row: startRow + 1,
    col: startCol + 3,
    value: null,
    style: 'amount',
    isHead,
    clickable,
    cellGroupId,
  }, {
    row: startRow,
    col: startCol + 4,
    value: null, // 계층조정 비율 값
    style: 'percent',
    isHead,
    clickable,
    cellGroupId,
    onClick: () => {
    },
  }, {
    row: startRow + 1,
    col: startCol + 4,
    value: null,
    style: 'percent',
    isHead,
    clickable,
    cellGroupId,
  }];
}

function getMaxMinusCellGroupId(cellList: VerticalTableCellProps[]) {
  return cellList
    .filter(cell => cell.cellGroupId && cell.cellGroupId < 0)
    .map(cell => cell.cellGroupId as number)
    .reduce((a, b) => a < b ? a : b, 0);
}

export function generateDefaultCellListValues(budgetYear: number): VerticalTableCellProps[] {

  const cellList: VerticalTableCellProps[] = [];
  cellList.push({
    row: 0,
    col: 0,
    value: '비율',
    isHead: true,
    clickable: false,
    cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
  });
  cellList.push({
    row: 0,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
    cellGroupId: getMaxMinusCellGroupId(cellList),
  });
  cellList.push({
    row: 0,
    col: 2,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
    cellGroupId: getMaxMinusCellGroupId(cellList),
  });
  cellList.push({
    row: 0,
    col: 3,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
    cellGroupId: getMaxMinusCellGroupId(cellList),
  });
  cellList.push({
    row: 1,
    col: 0,
    value: `${budgetYear - 1}`, // 전년도 값
    isHead: true,
    clickable: false,
    cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
  });
  cellList.push({
    row: 1,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
    cellGroupId: getMaxMinusCellGroupId(cellList),
  });
  cellList.push({
    row: 1,
    col: 2,
    value: '계층조정',
    isHead: true,
    clickable: false,
    cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
  });
  cellList.push({
    row: 1,
    col: 3,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
    cellGroupId: getMaxMinusCellGroupId(cellList),
  });

  cellList.push(...generateCellValue({
    startRow: 0,
    startCol: getMaxCol(cellList) + 1,
    value: '대분류',
    isHead: true,
    cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
  }));

  cellList.push(...generateCellValue({
    startRow: 0,
    startCol: getMaxCol(cellList) + 1,
    value: '계좌용도',
    isHead: true,
    cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
  }));
  cellList.push(...generateCellValue({
    startRow: 0,
    startCol: getMaxCol(cellList) + 1,
    value: '중분류',
    isHead: true,
    cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
  }));
  cellList.push(...generateCellValue({
    startRow: 0,
    startCol: getMaxCol(cellList) + 1,
    value: '소분류',
    isHead: true,
    cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
  }));
  cellList.push(...generateCellValue({
    startRow: 0,
    startCol: getMaxCol(cellList) + 1,
    value: '미분류',
    isHead: true,
    cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
  }));
  return cellList;
}

export function getAllParentIdList(managementList: AccountingBudgetManagement[], targetId: number, parentIdList: number[] = []): number[] {
  for (let i = 0; i < managementList.length; i++) {
    const management = managementList[i];
    if (management.id === targetId) {
      if (!management.parent) {
        return parentIdList.length === 0 ? [management.id] : parentIdList;
      }

      const newParentIdList = [...parentIdList, management.id, management.parent];

      return getAllParentIdList(managementList, management.parent, newParentIdList);
    }
  }
  return parentIdList;
}

export function getVisibleManagementList(managementList: AccountingBudgetManagement[], activatedManagement: AccountingBudgetManagement | undefined) {
  if (!activatedManagement) {
    return managementList.filter(management => management.col === 0);
  }
  const allParentIdList = getAllParentIdList(managementList, activatedManagement.id);
  const childrenIdList = managementList
    .filter(management => allParentIdList.includes(management.id))
    .filter(management => Array.isArray(management.children))
    .map(management => management.children as number[])
    .flatMap(l => l);
  return managementList.filter(management => management.col === 0 || childrenIdList.includes(management.id));
}

export function getSelectedManagementList(managementList: AccountingBudgetManagement[], activatedManagement: AccountingBudgetManagement | undefined) {
  if (!activatedManagement) {
    return [];
  }
  const allParentIdList = getAllParentIdList(managementList, activatedManagement.id);
  return managementList.filter(management => allParentIdList.includes(management.id));
}

export function generateVisibleCellList(visibleList: AccountingBudgetManagement[], prevCellList: VerticalTableCellProps[]) {


  const cellList = prevCellList.filter(cell => cell.row <= 1);

  const maxCol = getMaxCol(visibleList);
  const maxRow = getMaxRow(visibleList);

  for (let row = 0; row <= maxRow; row++) {
    cellList.push({
      row: row * 2 + 2,
      col: 0,
      value: null,
      style: 'amount',
      isHead: true,
      clickable: false,
      cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
    });
    cellList.push({
      row: row * 2 + 2 + 1,
      col: 0,
      value: '',
      style: 'span',
      isHead: true,
      clickable: false,
      cellGroupId: getMaxMinusCellGroupId(cellList),
    });
    cellList.push({
      row: row * 2 + 2,
      col: 1,
      value: null,
      style: 'percent',
      isHead: true,
      clickable: false,
      cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
    });
    cellList.push({
      row: row * 2 + 2 + 1,
      col: 1,
      value: '',
      style: 'span',
      isHead: true,
      clickable: false,
      cellGroupId: getMaxMinusCellGroupId(cellList),
    });
    cellList.push({
      row: row * 2 + 2,
      col: 2,
      value: null,
      style: 'amount',
      isHead: true,
      clickable: false,
      cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
    });
    cellList.push({
      row: row * 2 + 2 + 1,
      col: 2,
      value: '',
      style: 'span',
      isHead: true,
      clickable: false,
      cellGroupId: getMaxMinusCellGroupId(cellList),
    });
    cellList.push({
      row: row * 2 + 2,
      col: 3,
      value: null,
      style: 'percent',
      isHead: true,
      clickable: false,
      cellGroupId: getMaxMinusCellGroupId(cellList) - 1,
    });
    cellList.push({
      row: row * 2 + 2 + 1,
      col: 3,
      value: '',
      style: 'span',
      isHead: true,
      clickable: false,
      cellGroupId: getMaxMinusCellGroupId(cellList),
    });

    for (let col = 0; col <= maxCol; col++) {
      const management = visibleList.find(item => item.col === col && item.row === row);
      if (management) {
        cellList.push(...generateCellValue({
          startRow: row * 2 + 2,
          startCol: col * 5 + 4,
          value: management.name,
          isHead: false,
          cellGroupId: management.id,
        }));
      } else {
        cellList.push(...generateCellValue({
          startRow: row * 2 + 2,
          startCol: col * 5 + 4,
        }));
      }
    }
  }
  return cellList;
}
