import { useQuery } from 'react-query';
import { commonApi } from '@front/src/api/api';
import type { UserListGetParams } from '@front/src/types/parameter';
import type { SectionView } from '@front/src/types';
import type { AxiosError } from 'axios';

const flatten = (list?: SectionView[]) =>
  list?.flatMap((item) => [item, ...flatten(item.children)]);

export const commonQuery = {
  useGetSectionList: (authMenuId) => {
    const { data } = useQuery<SectionView[], AxiosError, SectionView[]>({
      queryKey: ['menu', authMenuId, 'section-list'],
      queryFn: () => commonApi.getSectionList(authMenuId),
      enabled: !!authMenuId,
      select: (data) => flatten(data),
    });

    return data;
  },
  useGetHeaderList: (sectionId, authMenuId) => {
    const { data } = useQuery({
      queryKey: ['section', sectionId, 'header-list'],
      queryFn: () =>
        commonApi.getHeaderList(
          sectionId,
          {
            position: 'table',
          },
          authMenuId
        ),
      enabled: !!authMenuId,
    });
    return {
      data,
    };
  },
  useGetUserList: (params: UserListGetParams, isMentionOpen: boolean, authMenuId) => {
    const { data } = useQuery({
      queryKey: ['user', params],
      queryFn: () => commonApi.getUserList(params, authMenuId),
      enabled: isMentionOpen && !!authMenuId,
    });
    return { data };
  },
};
