import headerMeta, { arraySupportKeyword } from '@front/src/components/ui-builder/headerMeta';
import { getNestedAttribute } from '@front/src/utils';
import React, { useCallback } from 'react';
import type { HeaderSettingView } from '@front/src/types';
import type { ListChildComponentProps } from 'react-window';
import type { CellAlignment } from '@front/src/features/project-sales-db/features/data-list/types';

interface BodyRowProps {
  cellHeight: number;
  cellAlignment: CellAlignment[];
  handleCellValue: (any) => string;
  getRowHeight: (number) => number;
  headerList: HeaderSettingView[];
}

export default function useTableBodyRowRenderer(props: BodyRowProps) {
  const { getRowHeight, handleCellValue, headerList, cellHeight, cellAlignment } = props;

  const Row = useCallback(
    (props: ListChildComponentProps) => {
      const { index, style, data } = props;
      const item = data[index];
      const height = getRowHeight(index);
      return (
        <tr
          key={item.id}
          style={style}
        >
          {headerList.map((cell, cellIndex) => {
            const meta = headerMeta[cell.id];
            let value: any;
            if (meta.attributeName) {
              const attrName = meta.attributeName.join('.');
              if (meta.group) {
                const groupItem = getNestedAttribute(item, meta.group);
                if (Array.isArray(groupItem)) {
                  if (groupItem && groupItem.length > 0) {
                    value = groupItem.map((innerItem, index1) => {
                      if (meta.attributeName?.includes(arraySupportKeyword)) {
                        const arrayIndexPosition = meta.attributeName?.findIndex(
                          (value1) => value1 === arraySupportKeyword
                        )!;
                        const temp2 = getNestedAttribute(
                          innerItem,
                          meta.attributeName[arrayIndexPosition - 1]
                        );
                        return temp2.map((innerItem2, index2) => {
                          const attrNameWithoutIndex = meta.attributeName
                            ?.slice(arrayIndexPosition + 1)
                            .join('.');

                          return (
                            <div
                              className="nested-cell"
                              style={{ width: `${meta.width}px`, minHeight: `${cellHeight}px` }}
                              key={`${index}_${cell.id}_${index1}_${index2}`}
                            >
                              {handleCellValue(
                                getNestedAttribute(innerItem2, attrNameWithoutIndex!)
                              )}
                            </div>
                          );
                        });
                      } else {
                        // innerItem 형제들 중에 배열이 있을지 탐색해야 함
                        let innerItemSiblingMaxArraySize = 0;
                        Object.keys(innerItem).forEach((innerItemKey) => {
                          const siblingOfInnerItem = innerItem[innerItemKey];
                          if (Array.isArray(siblingOfInnerItem) && siblingOfInnerItem.length > 0) {
                            innerItemSiblingMaxArraySize = Math.max(
                              siblingOfInnerItem.length,
                              innerItemSiblingMaxArraySize
                            );
                          }
                        });

                        return (
                          <div
                            className="nested-cell"
                            style={{
                              width: `${meta.width}px`,
                              minHeight: `${
                                (innerItemSiblingMaxArraySize === 0
                                  ? 1
                                  : innerItemSiblingMaxArraySize) * cellHeight
                              }px`,
                            }}
                            key={`${index}_${cell.id}_${index1}`}
                          >
                            {handleCellValue(getNestedAttribute(innerItem, attrName))}
                          </div>
                        );
                      }
                    });
                  } else {
                    value = (
                      <div
                        className="nested-cell"
                        style={{ width: `${meta.width}px`, minHeight: `${cellHeight}px` }}
                        key={`${index}_${cell.id}`}
                      >
                        -
                      </div>
                    );
                  }
                } else {
                  value = (
                    <div
                      className="nested-cell"
                      style={{ width: `${meta.width}px`, minHeight: `${cellHeight}px` }}
                    >
                      {handleCellValue(getNestedAttribute(groupItem, attrName))}
                    </div>
                  );
                }
              } else {
                value = (
                  <div
                    className="nested-cell"
                    style={{ width: `${meta.width}px`, minHeight: `${cellHeight}px` }}
                  >
                    {handleCellValue(getNestedAttribute(item, attrName))}
                  </div>
                );
              }
            }

            return (
              <td
                className={`cell ${cellAlignment[cellIndex]}`}
                key={cell.id}
                style={{
                  height: `${height}px`,
                  width: `${meta.width}px`,
                  maxWidth: `${meta.width}px`,
                }}
              >
                {value}
              </td>
            );
          })}
        </tr>
      );
    },
    [getRowHeight, handleCellValue, headerList, cellHeight, cellAlignment]
  );

  return { Row };
}
