import React, { useCallback, useMemo } from 'react';
import {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import { ContractPaymentTermTable } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/table';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { useShallow } from 'zustand/react/shallow';
import contractPaymentTermQuery from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/query/query';
import usePaymentTermModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/usePaymentTermsModalStore';
import ContractClientTabs from '@front/src/features/project-sales/features/tabs/contract/components/client-tabs';
import { useCustomDialog } from '@front/src/features/dialog';
import { getContractPaymentTermTabFormUpdateValues } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/utils';
import type { ContractPaymentTermModalProps } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/Modal';
import { useFormContext } from 'react-hook-form';
import type { ContractPaymentTermFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';

export default function ContractPaymentTermModalBody(props: ContractPaymentTermModalProps) {
  const { dataId, menuId, sectionId, defaultClientId } = props;

  const { onClose } = usePaymentTermModalStore(
    useShallow((state) => ({
      onClose: state.onClose,
    }))
  );

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const {
    getValues,
    watch,
    reset,
    formState: { isDirty },
  } = useFormContext<ContractPaymentTermFormValues>();
  const clientId = watch('clientId');

  const dataObject = contractPaymentTermQuery.useGetObject(defaultClientId, commonParams);

  const handleAfterConfirm = useCallback(
    (value) => {
      if (dataObject) {
        const { clientId, list, deleteList, contractClientCompany, remainsList } =
          getContractPaymentTermTabFormUpdateValues(value, dataObject);
        reset({
          parent: getValues('parent'),
          lastUpdatedId: null,
          editOpenIdList: [],
          clientId,
          list,
          deleteList,
          contractClientCompany,
          remainsList,
        });
      }
    },
    [reset, getValues, dataObject]
  );

  const { confirm } = useCustomDialog();

  const handleChange = useCallback(
    (e, value) => {
      if (value === getValues('clientId')) return;

      if (isDirty) {
        confirm({
          lineBreakChildren: [
            { value: '저장되지 않은 변경사항은 반영되지 않습니다.' },
            { value: '탭을 이동하시겠습니까?' },
          ],
          confirmText: '확인',
          closeText: '취소',
          afterConfirm: () => {
            handleAfterConfirm(value);
          },
        });
        return;
      }

      handleAfterConfirm(value);
    },
    [getValues, isDirty, confirm, handleAfterConfirm]
  );

  const handleCancel = useCallback(() => {
    if (isDirty) {
      confirm({
        lineBreakChildren: [
          { value: '저장되지 않은 변경사항은 반영되지 않습니다.' },
          { value: '탭을 이동하시겠습니까?' },
        ],
        confirmText: '확인',
        closeText: '취소',
        afterConfirm: onClose,
      });
      return;
    }

    onClose();
  }, [isDirty, confirm, onClose]);

  return (
    <ModalBodyUI sx={modalBodySx}>
      {dataObject && (
        <ContractClientTabs
          {...props}
          value={clientId}
          dataObject={dataObject}
          onChange={handleChange}
        />
      )}
      <ModalHeaderUI>
        저장하지 않은 내용은 반영되지 않습니다.
        <ButtonBasicUI
          shape="tertiary"
          size="medium"
          onClick={handleCancel}
          sx={buttonSx}
        >
          취소
        </ButtonBasicUI>
        <ButtonBasicUI
          type="submit"
          shape="primary2"
          size="medium"
          sx={buttonSx}
        >
          저장
        </ButtonBasicUI>
      </ModalHeaderUI>
      <ModalContentUI sx={modalContentSx}>
        <ContractPaymentTermTable {...props} />
      </ModalContentUI>
    </ModalBodyUI>
  );
}

const modalBodySx = {
  minWidth: '70dvw',
  minHeight: '50dvh',
  // backfaceVisibility: 'hidden',
};

const buttonSx = {
  width: '64px',
};

const modalContentSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  maxWidth: '96dvw',
};
