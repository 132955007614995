import React, { useMemo } from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { CollectionHistoryTableBody } from '@front/src/features/project-sales/features/tabs/collection/sections/history/components/table-body';
import collectionHistoryQuery from '@front/src/features/project-sales/features/tabs/collection/sections/history/query/query';
import useGetContractHistoryLoading from '@front/src/features/project-sales/features/tabs/collection/sections/history/hooks/useGetLoading';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';

export { Table as CollectionHistoryTable };

function Table(props: SectionComponentProps) {
  const { dataId, sectionId, menuId } = props;

  const commonParams = useMemo(() => ({ dataId, sectionId, menuId }), [dataId, sectionId, menuId]);
  const list = collectionHistoryQuery.useGet(commonParams);

  const isLoading = useGetContractHistoryLoading(dataId);

  const getSumExpectedCollectionAmount = () => {
    let sumExpectedCollectionAmount = 0;

    list?.forEach((history) => {
      history.collectionList.forEach((collection) => {
        if (
          !history.collectionList.some(
            (s) => s.collectionParentSequence === collection.collectionSequence
          ) &&
          collection.isActive
        ) {
          sumExpectedCollectionAmount += collection.expectedCollectionAmount ?? 0;
        }
      });
    });

    return sumExpectedCollectionAmount;
  };

  const getSumUnpaid = () => {
    let sumUnpaid = 0;

    list?.forEach((history) => {
      history.collectionList.forEach((collection) => {
        if (
          !history.collectionList.some(
            (s) => s.collectionParentSequence === collection.collectionSequence
          ) &&
          collection.isActive
        ) {
          sumUnpaid += collection.unpaidAmount ?? 0;
        }
      });
    });

    return sumUnpaid;
  };

  const summaryRenderer = {
    'collectionList.{:index}.expectedCollectionAmount': () =>
      getSumExpectedCollectionAmount().toLocaleString(),

    'collectionList.{:index}.unpaidAmount': () => getSumUnpaid().toLocaleString(),
  };

  return (
    <UiBuilderTable
      {...props}
      useSummary
      summaryRenderer={summaryRenderer}
      HeadComponent={UIBuilderTableHeader}
      BodyComponent={CollectionHistoryTableBody}
      list={list}
      isLoading={isLoading}
    />
  );
}
