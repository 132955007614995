import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import collectionHistoryQuery from '@front/src/features/project-sales/features/tabs/collection/sections/history/query/query';
import collectionFinalQuery from '@front/src/features/project-sales/features/tabs/collection/sections/final/query/query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import Box from '@mui/material/Box';
import { ColorPalette } from '@front/assets/theme';

export default function CollectionNoticeMessage({ sectionId, menuId, dataId }: UIBuilderParameter) {
  const commonParams = useMemo(() => ({ dataId, sectionId, menuId }), [dataId, sectionId, menuId]);

  const finalList = collectionFinalQuery.useGet(commonParams);

  const sumTotalContractPrice = finalList?.reduce(
    (acc, cur) => acc + (cur.totalContractPrice ?? 0),
    0
  );

  const historyList = collectionHistoryQuery.useGet(commonParams);

  const collectionList = historyList?.flatMap((f) => f.collectionList);

  const getSumExpectedCollectionAmount = () => {
    let sumExpectedCollectionAmount = 0;

    historyList?.forEach((history) => {
      history.collectionList.forEach((collection) => {
        if (
          !history.collectionList.some(
            (s) => s.collectionParentSequence === collection.collectionSequence
          ) &&
          collection.isActive
        ) {
          sumExpectedCollectionAmount += collection.expectedCollectionAmount ?? 0;
        }
      });
    });

    return sumExpectedCollectionAmount;
  };

  const sumExpectedCollectionAmount = getSumExpectedCollectionAmount();

  const getSumCollectionAmountPlusUnpaidAmount = () => {
    let sumExpectedCollectionAmount = 0;

    historyList?.forEach((history) => {
      history.collectionList.forEach((collection) => {
        if (
          !history.collectionList.some(
            (s) => s.collectionParentSequence === collection.collectionSequence
          ) &&
          collection.isActive
        ) {
          sumExpectedCollectionAmount +=
            (collection.collectionAmount ?? 0) + (collection.unpaidAmount ?? 0);
        }
      });
    });

    return sumExpectedCollectionAmount;
  };

  const sumCollectionAmountPlusUnpaidAmount = getSumCollectionAmountPlusUnpaidAmount();

  if (
    sumTotalContractPrice &&
    sumExpectedCollectionAmount &&
    sumTotalContractPrice !== sumExpectedCollectionAmount &&
    collectionList?.every((s) => !s.collectionAmount)
  ) {
    return <RedLabel>수금 예정액의 합계와 계약 총액의 합계가 동일하지 않습니다.</RedLabel>;
  }

  if (
    sumTotalContractPrice &&
    sumCollectionAmountPlusUnpaidAmount &&
    sumTotalContractPrice !== sumCollectionAmountPlusUnpaidAmount &&
    collectionList?.some((s) => !!s.collectionAmount)
  ) {
    return <RedLabel>수금액 + 미수금의 합계와 계약 총액의 합계가 동일하지 않습니다.</RedLabel>;
  }

  return <></>;
}

interface Props {
  children: ReactNode;
}

const RedLabel = ({ children }: Props) => (
  <Box
    sx={{
      width: '100%',
      padding: '6px',
      textAlign: 'center',
      verticalAlign: 'center',
      backgroundColor: ColorPalette.sub2.sub2_primary,
      color: ColorPalette.greyscale.white,
      fontWeight: '900',
      fontSize: '20px',
    }}
  >
    {children}
  </Box>
);
