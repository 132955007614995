import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';

import type { CollectionTaxInvoiceView } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/types/views';
import { commonApi } from '@front/src/api/api';

const collectionTaxInvoiceQuery = {
  useGet: (collectionId: number, commonParams: UIBuilderParameter) => {
    const url = `/api/project/sales/sales-information/collection-history/collection/${collectionId}/tax-invoice`;
    const queryKey = [
      'project',
      'sales',
      commonParams.dataId,
      'collection',
      collectionId,
      'tax-invoice',
    ];
    const enabled = !!commonParams.dataId && !!collectionId;

    const { data } = useQuery<CollectionTaxInvoiceView[], Error, CollectionTaxInvoiceView[]>({
      queryFn: () => commonApi.getListByParams(url, commonParams),
      queryKey,
      suspense: true,
      enabled,
    });

    return data;
  },
};

export default collectionTaxInvoiceQuery;
