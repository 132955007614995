import { create } from 'zustand';

interface State {
  refetchOnWindowFocus: boolean;
}

interface Actions {
  setRefetchOnWindowFocus: (refetchOnWindowFocus: boolean) => void;
}

const useQueryClientState = create<State & Actions>((set) => ({
  refetchOnWindowFocus: true,
  setRefetchOnWindowFocus: (refetchOnWindowFocus) =>
    set(() => ({
      refetchOnWindowFocus,
    })),
}));

export default useQueryClientState;
