import type { AdminDirectorySettingsUpdateParams } from '@front/src/features/admin/features/menu-settings/features/menu-tab-section-settings/types/parameter';

export const adminMenuApi = '/api/admin/menu';
export const adminMenuHistoryApi = '/api/admin/menu';

export const initialUpdateParams: AdminDirectorySettingsUpdateParams = {
  directory: {
    seqList: [],
    nameList: [],
  },
  section: {
    seqList: [],
    nameList: [],
  },
  tab: {
    seqList: [],
    nameList: [],
  },
};
