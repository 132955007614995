import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { AxiosError } from 'axios';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import { commonApi } from '@front/src/api/api';

const estimationHistoryQuery = {
  useGetList: (commonParams: UIBuilderParameter) => {
    const url = `/api/project/sales/sales-information/${commonParams.dataId}/estimation`;
    const queryKey = ['project', 'sales', commonParams.dataId, 'estimation', 'history'];
    const enabled = !!commonParams.dataId && !!commonParams.menuId;

    const { data } = useQuery<EstimationHistoryView[], AxiosError, EstimationHistoryView[]>({
      queryFn: () => getHistory(url, commonParams),
      queryKey,
      suspense: true,
      enabled,
    });
    return data;
  },
};

export default estimationHistoryQuery;

const getHistory = async (url, commonParams) => {
  const data = await commonApi.getListByParams(url, commonParams);

  const mapped = [...data].map((d) => ({
    ...d,
    ...d.estimation,
  }));

  return mapped.sort((a, b) => a.estimation.seq - b.estimation.seq);
};
