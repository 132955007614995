import headerMeta from '@front/src/components/ui-builder/headerMeta';
import React from 'react';
import { HeaderSettingView } from '@front/src/types';
import {
  ActiveFilterKeywords,
  CellAlignment,
  FilterKeywords,
  SummaryRowData,
} from '@front/src/features/project-sales-db/features/data-list/types';
import { CheckedAttributes } from '@front/src/features/project-sales-db/types';
import FilterPopOver from '@front/src/features/project-sales-db/features/data-list/components/filter-popup-over';

interface DataListHeaderProps {
  headerList: HeaderSettingView[];
  list: any[];
  checkedAttributes: CheckedAttributes;
  summary: SummaryRowData;
  cellAlignment: CellAlignment[];
  filterKeywords: FilterKeywords;
  activeFilterKeywords: ActiveFilterKeywords;
  setActiveFilterKeywords: React.Dispatch<React.SetStateAction<ActiveFilterKeywords>>;
}

export default function DataListHeader(props: DataListHeaderProps) {
  const { headerList, checkedAttributes, summary, cellAlignment, filterKeywords } = props;

  return (
    <thead>
      <tr>
        <th colSpan={3}>프로젝트</th>
        {headerList.map((item, index) => {
          if (index < 2) {
            return null;
          } else {
            const prevItem = headerList[index - 1];
            if (headerMeta[item.id].group === headerMeta[prevItem.id].group) {
              return null;
            }
          }
          const meta = headerMeta[item.id];
          const sameGroupHeaderConfs = headerList.filter(
            (value) => headerMeta[value.id].group === meta.group
          );
          let width = 0;
          sameGroupHeaderConfs.forEach((value) => {
            width += headerMeta[value.id].width;
          });

          const attributeConf = checkedAttributes[item.id];
          return (
            <th
              key={item.id}
              colSpan={sameGroupHeaderConfs.length}
              style={{ width: `${width}px` }}
            >
              {attributeConf?.parentName}
            </th>
          );
        })}
      </tr>
      <tr>
        {headerList.map((item, index) => (
          <th
            key={item.id}
            style={{ width: `${headerMeta[item.id].width}px` }}
          >
            <>
              {headerMeta[item.id].filterable && (
                <FilterPopOver
                  keywords={filterKeywords[index] ?? {}}
                  index={index}
                  {...props}
                />
              )}
              {item.name}
            </>
          </th>
        ))}
      </tr>
      <tr className="summary">
        {headerList.map((item, index) => {
          const value = !isNaN(summary[index] as number) ? summary[index]?.toLocaleString() : '-';
          return (
            <th
              className={cellAlignment[index]}
              key={`summary-${item.id}`}
            >
              {value}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
