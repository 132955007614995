import {
  type ContractPaymentTermFormValues,
  type ContractPaymentTermListFormValues,
} from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';
import { getContractPaymentTermSortedIdList } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/utils';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import type { UseFormReturn } from 'react-hook-form';
import type { ContractPaymentTermUpdateParameter } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/types/parameter';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { MutateOptions } from 'react-query';
import { useCallback } from 'react';

const useGetContractPaymentTermSubmit = (
  formUpdateFormContext: UseFormReturn<ContractPaymentTermFormValues, any, undefined>,
  onUpdate: (
    params: ContractPaymentTermUpdateParameter,
    options?: MutateOptions<'', Error, ContractPaymentTermUpdateParameter, unknown> | undefined
  ) => void,
  commonParams: UIBuilderParameter
) => {
  const { enqueueSnackbar } = useSnackbar();

  const { getValues } = formUpdateFormContext;

  const onSubmit = useCallback(
    (data) => {
      const every = data.list.every((item) => !!item.expectedPaymentDate);
      if (!every) {
        enqueueSnackbar('계약응당일을 입력해주세요', { variant: 'warning' });
        return;
      }

      const sortedIdList = getContractPaymentTermSortedIdList(
        getValues('list'),
        getValues('remainsList')
      );

      const params = {
        updateList: [
          ...data.list.map((item) => ({
            ...toParameter(item),
            paymentSequence: sortedIdList.findIndex((f) => f === item.id) + 1,
            isDeleted: false,
          })),
          ...data.remainsList.map((item) => ({
            ...toParameter(item),
            paymentSequence: sortedIdList.findIndex((f) => f === item.id) + 1,
            isDeleted: false,
          })),
          ...data.deleteList.map((item) => ({
            ...toParameter(item),
            isDeleted: true,
          })),
        ],
        idsDataParameter: {
          ...commonParams,
        },
      };
      onUpdate(params, {
        onSuccess: () => {
          enqueueSnackbar('저장했습니다.', {
            variant: 'success',
          });
        },
      });
    },
    [enqueueSnackbar, getValues, onUpdate]
  );

  return onSubmit;
};

export default useGetContractPaymentTermSubmit;

const toParameter = (item: ContractPaymentTermListFormValues) => ({
  ...item,
  id: item.rowId,
  parentId: item.parentId,
  paymentStage: item.paymentStage || null,
  paymentSequence: item.paymentSequence,
  paymentTiming: item.paymentTiming || null,
  expectedPaymentDate: item.expectedPaymentDate
    ? dayjs(item.expectedPaymentDate).format(YYYY_MM_DD)
    : null,
  clientRatio: item.clientRatio,
  companyStageRatio: item.companyStageRatio,
  totalContractPrice: item.totalContractPrice ? +item.totalContractPrice : null,
  windTunnelPrice: item.windTunnelPrice,
  structuralReviewPrice: item.structuralReviewPrice,
  structuralReviewRatio: item.structuralReviewRatio,
  structuralReviewPaymentTiming: item.structuralReviewPaymentTiming || null,
  structuralReviewPaymentTargetId: item.structuralReviewPaymentTarget?.id ?? null,
  structuralReviewPaymentDate: item.structuralReviewPaymentDate
    ? dayjs(item.structuralReviewPaymentDate).format(YYYY_MM_DD)
    : null,
  numberAttr1: item.numberAttr1,
  numberAttr2: item.numberAttr2,
  numberAttr3: item.numberAttr3,
  textAttr1: item.textAttr1,
  textAttr2: item.textAttr2,
  textAttr3: item.textAttr3,
  dateAttr1: item.dateAttr1 ? dayjs(item.dateAttr1).format(YYYY_MM_DD) : null,
  dateAttr2: item.dateAttr2 ? dayjs(item.dateAttr2).format(YYYY_MM_DD) : null,
  dateAttr3: item.dateAttr3 ? dayjs(item.dateAttr3).format(YYYY_MM_DD) : null,
});
