import headerMeta, { arraySupportKeyword } from '@front/src/components/ui-builder/headerMeta';
import { getNestedAttribute } from '@front/src/utils';
import type { HeaderSettingView } from '@front/src/types';
import type { CheckedAttributes } from '@front/src/features/project-sales-db/types';
import type {
  CellAlignment,
  FilterKeywords,
} from '@front/src/features/project-sales-db/features/data-list/types';

interface SummaryRowProps {
  accumulatedSummaryData: (number | undefined)[];
  item: any;
  headerList: HeaderSettingView[];
}

interface FilterProps {
  filterKeywords: FilterKeywords;
  item: any;
  headerList: HeaderSettingView[];
}

export default function useTableLogic() {
  function prepareHeaderList(checkedAttributes: CheckedAttributes) {
    const newHeaderList: HeaderSettingView[] = [];
    newHeaderList.push({
      id: 100000,
      name: '프로젝트코드',
      note: '',
      categoryId: 0,
      isBlind: false,
      isRequired: true,
      isFixed: false,
      position: '',
      seq: 1,
      headerId: 100000,
      isUsedAsModal: false,
      modalHeaderList: [],
      version: 1,
      headerVersion: 1,
      use: '',
      isFoldStartColumn: false,
    } as HeaderSettingView);

    newHeaderList.push({
      id: 100001,
      name: '프로젝트명',
      note: '',
      categoryId: 0,
      isBlind: false,
      isRequired: true,
      isFixed: false,
      position: '',
      seq: 2,
      headerId: 100001,
      isUsedAsModal: false,
      modalHeaderList: [],
      version: 1,
      headerVersion: 1,
      use: '',
      isFoldStartColumn: false,
    } as HeaderSettingView);

    newHeaderList.push({
      id: 100002,
      name: '최초등록일',
      note: '',
      categoryId: 0,
      isBlind: false,
      isRequired: true,
      isFixed: false,
      position: '',
      seq: 2,
      headerId: 100001,
      isUsedAsModal: false,
      modalHeaderList: [],
      version: 1,
      headerVersion: 1,
      use: '',
      isFoldStartColumn: false,
    } as HeaderSettingView);

    const attributeIds = Object.keys(checkedAttributes);
    const temp: { [key: number]: HeaderSettingView[] } = {};
    attributeIds.forEach((key) => {
      const treeData = checkedAttributes[key];
      !temp[treeData.parentId] && (temp[treeData.parentId] = []);
      treeData.configuration && temp[treeData.parentId].push(treeData.configuration);
    });

    const tempKeys = Object.keys(temp);
    tempKeys.forEach((key) => {
      if (temp[key]) {
        temp[key].sort((a, b) => (a.seq > b.seq ? 1 : -1));
        temp[key].forEach((headerSettingView) => {
          newHeaderList.push(headerSettingView);
        });
      }
    });

    return newHeaderList;
  }

  function calcSummaryData(props: SummaryRowProps) {
    const { accumulatedSummaryData, item, headerList } = props;

    // summary
    headerList.forEach((newHeader, index) => {
      const meta = headerMeta[newHeader.id];
      if (meta.summary && meta.attributeName) {
        const attrName = meta.attributeName.join('.');
        if (meta.group) {
          const temp = getNestedAttribute(item, meta.group);
          if (Array.isArray(temp)) {
            if (temp && temp.length > 0) {
              temp.forEach((innerItem) => {
                if (meta.attributeName?.includes(arraySupportKeyword)) {
                  const arrayIndexPosition = meta.attributeName?.findIndex(
                    (value1) => value1 === arraySupportKeyword
                  )!;
                  const temp2 = getNestedAttribute(
                    innerItem,
                    meta.attributeName[arrayIndexPosition - 1]
                  );
                  temp2.forEach((innerItem2) => {
                    const attrNameWithoutIndex = meta.attributeName
                      ?.slice(arrayIndexPosition + 1)
                      .join('.');

                    const value = getNestedAttribute(innerItem2, attrNameWithoutIndex!);
                    accumulatedSummaryData[index] += value;
                  });
                } else {
                  const value = getNestedAttribute(innerItem, attrName);
                  accumulatedSummaryData[index] += value;
                }
              });
            }
          } else {
            const value = getNestedAttribute(temp, attrName) ?? 0;
            accumulatedSummaryData[index] += value;
          }
        } else {
          const value = getNestedAttribute(item.data, attrName) ?? 0;
          accumulatedSummaryData[index] += value;
        }
      }
    });
  }

  function calcFilterData(props: FilterProps) {
    const { filterKeywords, item, headerList } = props;

    headerList.forEach((newHeader, index) => {
      const meta = headerMeta[newHeader.id];
      if (meta.attributeName) {
        const attrName = meta.attributeName.join('.');
        if (meta.group) {
          const temp = getNestedAttribute(item, meta.group);
          if (Array.isArray(temp)) {
            if (temp && temp.length > 0) {
              temp.forEach((innerItem) => {
                if (meta.attributeName?.includes(arraySupportKeyword)) {
                  const arrayIndexPosition = meta.attributeName?.findIndex(
                    (value1) => value1 === arraySupportKeyword
                  )!;
                  const temp2 = getNestedAttribute(
                    innerItem,
                    meta.attributeName[arrayIndexPosition - 1]
                  );
                  temp2.forEach((innerItem2) => {
                    const attrNameWithoutIndex = meta.attributeName
                      ?.slice(arrayIndexPosition + 1)
                      .join('.');

                    const value = getNestedAttribute(innerItem2, attrNameWithoutIndex!) ?? '';
                    processFilterData(filterKeywords, index, value);
                  });
                } else {
                  const value = getNestedAttribute(innerItem, attrName) ?? '';
                  processFilterData(filterKeywords, index, value);
                }
              });
            }
          } else {
            const value = getNestedAttribute(temp, attrName) ?? '';
            processFilterData(filterKeywords, index, value);
          }
        } else {
          const value = getNestedAttribute(item, attrName) ?? '';
          processFilterData(filterKeywords, index, value);
        }
      }
    });
  }

  function getMaxRowCount(item: any, headerList: HeaderSettingView[]): number {
    let result = 0;

    headerList.forEach((cell) => {
      let value = 0;
      const meta = headerMeta[cell.id];
      if (meta.attributeName) {
        if (meta.group) {
          const groupItem = getNestedAttribute(item, meta.group);
          if (Array.isArray(groupItem)) {
            if (groupItem && groupItem.length > 0) {
              groupItem.forEach((innerItem) => {
                if (meta.attributeName?.includes(arraySupportKeyword)) {
                  const arrayIndexPosition = meta.attributeName?.findIndex(
                    (value1) => value1 === arraySupportKeyword
                  )!;
                  const temp2 = getNestedAttribute(
                    innerItem,
                    meta.attributeName[arrayIndexPosition - 1]
                  );
                  value += Object.keys(temp2).length;
                } else {
                  // innerItem 형제들 중에 배열이 있을지 탐색해야 함
                  let innerItemSiblingMaxArraySize = 0;
                  Object.keys(innerItem).forEach((innerItemKey) => {
                    const siblingOfInnerItem = innerItem[innerItemKey];
                    if (Array.isArray(siblingOfInnerItem) && siblingOfInnerItem.length > 0) {
                      innerItemSiblingMaxArraySize = Math.max(
                        siblingOfInnerItem.length,
                        innerItemSiblingMaxArraySize
                      );
                    }
                  });

                  value += innerItemSiblingMaxArraySize === 0 ? 1 : innerItemSiblingMaxArraySize;
                }
              });
            } else {
              value += 1;
            }
          }
        }
      }

      if (value > result) {
        result = value;
      }
    });

    return result;
  }

  function handleCellValue(value: any) {
    if (typeof value === 'number') {
      return value.toLocaleString();
    } else if (typeof value === 'boolean') {
      return value ? 'Y' : 'N';
    } else if (!value) {
      return '-';
    } else {
      return value;
    }
  }

  function getCellAlignment(value: any): CellAlignment {
    return typeof value === 'number' ? 'right' : 'center';
  }

  return {
    calcSummaryData,
    calcFilterData,
    prepareHeaderList,
    getMaxRowCount,
    handleCellValue,
    getCellAlignment,
  };
}

function processFilterData(filterKeywords: FilterKeywords, index: number, value) {
  const stringValue = typeof value === 'boolean' ? (value ? 'Y' : 'N') : `${value}`;
  if (stringValue === '') {
    return;
  }
  if (!filterKeywords[index]) {
    filterKeywords[index] = {};
  }
  if (filterKeywords[index][stringValue]) {
    filterKeywords[index][stringValue]++;
  } else {
    filterKeywords[index][stringValue] = 1;
  }
}
