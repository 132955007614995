import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import type { CollectionClaimDocumentView } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/types/views';
import { useCallback, useEffect } from 'react';

const useCollectionClaimDocumentForm = (list: CollectionClaimDocumentView[]) => {
  const methods = useForm<CollectionClaimDocumentFormValues>({
    defaultValues: getDefaultValues(list),
  });

  const { reset } = methods;

  const initializeForm = useCallback(() => {
    reset(getDefaultValues(list));
  }, [reset, list]);

  useEffect(() => {
    initializeForm();
  }, [list]);

  return methods;
};

export default useCollectionClaimDocumentForm;

const convertList = (list: CollectionClaimDocumentView[]) =>
  list.map((l) => ({
    ...l,
    id: uuid(),
    rowId: l.id,
  }));

const getDefaultValues = (list: CollectionClaimDocumentView[]) => ({
  list: convertList(list),
  lastUpdatedId: null,
  editOpenIdList: [],
  deleteList: [],
});

export interface CollectionClaimDocumentFormValues {
  lastUpdatedId: string | null;
  editOpenIdList: string[];
  list: CollectionClaimDocumentFormListValues[];
  deleteList: CollectionClaimDocumentFormListValues[];
}

export interface CollectionClaimDocumentFormListValues
  extends Omit<CollectionClaimDocumentView, 'id' | 'fileItem'> {
  id: string;
  rowId: number | null;
  fileItem: any | null;
}
