import React, { useEffect } from 'react';
import { adminMenuSettingsQuery } from '@front/src/features/admin/features/menu-settings/features/menu-tab-section-settings/query/query';
import { TreeView } from '@mui/x-tree-view';

import useDataSetTreeLogic from '@front/src/features/project-sales-db/features/data-set-tree/hooks/useDataSetTreeLogic';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import './data-set-tree.scss';
import { CheckedAttributes } from '@front/src/features/project-sales-db/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DataSetTreeProps {
  menuId: number;
  projectSalesMenuId: number;
  checkedAttributes: CheckedAttributes;
  setCheckedAttributes: React.Dispatch<React.SetStateAction<CheckedAttributes>>;
}

export default function DataSetTree(props: DataSetTreeProps) {
  const { menuId, projectSalesMenuId } = props;
  const { TreeItems, expandedList, setTreeData } = useDataSetTreeLogic({
    ...props,
  });

  const { data } = adminMenuSettingsQuery.useTabListGet(projectSalesMenuId, menuId);

  useEffect(() => {
    setTreeData(
      data.map((item: any) => ({
        id: item.id,
        name: item.name,
        type: 'tab',
        parentName: '',
        parentId: 0,
        children: [],
      }))
    );
  }, [data]);

  return (
    <>
      <div className="aside-title">
        <FontAwesomeIcon
          icon="database"
        />
        분석대상 데이터셋 선택
      </div>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandLess style={{ fontSize: '14px' }} />}
        defaultExpandIcon={<ExpandMore style={{ fontSize: '14px' }} />}
        defaultExpanded={expandedList.current}
        expanded={expandedList.current}
        disableSelection={true}
      >
        <TreeItems />
      </TreeView>
    </>
  );
}
