import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import Box from '@mui/material/Box';
import type { OptionType } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import DropDownWithHookForm from '@front/src/components/hook-form/DropDown';
import { useFormContext } from 'react-hook-form';

interface Props extends Omit<CellComponentProps, 'options'> {
  options: OptionType[];
  displayValue?: string;
  name: string;
  disabled?: boolean;
  onAfterChange?: () => void;
  convertObject?: any;
}

export default function UIBuilderTableCellDropDown(props: Props) {
  const {
    onClick,
    sx,
    name,
    readOnly,
    isEditMode,
    onSubmit,
    isForm,
    displayValue,
    options,
    disabled = false,
    onAfterChange,
    convertObject,
  } = props;

  const { getValues } = useFormContext();

  //TODO: displayValue 로직 의도 확인 필요
  const renderContent = () => {
    if (readOnly || !isEditMode)
      return (
        displayValue ?? (convertObject ? convertObject[getValues(name)] : getValues(name) || '-')
      );

    return (
      <DropDownWithHookForm
        defaultLabel="선택"
        name={name}
        options={options}
        onSubmit={isForm ? undefined : onSubmit}
        disabled={disabled}
        onAfterChange={onAfterChange}
      />
    );
  };

  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {renderContent()}
    </Box>
  );
}
