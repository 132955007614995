import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import React from 'react';

import UIBuilderTableCellText from '@front/src/components/ui-builder/table/cell-renderer/Text';
import type { CollectionDueDateHistoryView } from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/types/view';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';

interface Props extends CellComponentProps<CollectionDueDateHistoryView> {}

/**
 * 변경처리일
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    readOnly
    name="changeProcessingDate"
  />
);

/**
 * 이전 수금응당일
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    readOnly
    name="previousCollectionDueDate"
  />
);

/**
 * 변경된 수금응당일
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    readOnly
    name="changedCollectionDueDate"
  />
);

/**
 * 변경자
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="writerName"
  />
);

export const collectionDeuDateHistoryTableCells: CellComponent = {
  650: CellRenderer1, // 변경처리일
  651: CellRenderer2, // 이전 수금응당일
  652: CellRenderer3, // 변경된 수금응당일
  653: CellRenderer4, // 변경자
  // ...generateSpareAttrCellRenders(654),
};
