import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';

export const calculateEstimationTotalPriceDiff = (
  totalPrice: number | null,
  list: EstimationHistoryView[] | undefined
) => {
  if (!totalPrice || !list) return null;

  const confirmedTotal = list
    ?.filter((f) => !!f.estimation.isConfirmed)
    .flatMap((f) => f.estimationClient)
    .filter((f) => !!f.totalEstimationPrice)
    .reduce((acc, value) => acc + value.totalEstimationPrice!, 0);

  return (totalPrice ?? 0) - (confirmedTotal ?? 0);
};
