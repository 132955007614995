import type { EstimationHistoryFormValues } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useTableRowForm';
import type { EstimationHistoryUpdateParameter } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/parameter';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

const useEstimationHistoryTableRowUpdate = (
  onUpdate: (params: EstimationHistoryUpdateParameter) => void,
  item: EstimationHistoryView
) => {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    (data: EstimationHistoryFormValues) => {
      if (!item.estimation.canDelete) {
        enqueueSnackbar('계약과 연결되어 있어 수정할 수 없습니다.', {
          variant: 'warning',
        });

        return;
      }

      if (!data.id) return;

      const params = {
        id: data.id,
        seq: data.seq ?? null,
        isConfirmed: data.isConfirmed === '' ? null : data.isConfirmed === 'Y',
        estimationDate: data.estimationDate ? dayjs(data.estimationDate).format(YYYY_MM_DD) : null,
        type: data.type || null,
        status: data.status || null,
        possibilityOfOrder: data.possibilityOfOrder || null,
        projectDifficulty: data.projectDifficulty || null,
        modelDifficulty: data.modelDifficulty || null,
        estimationRequestCompanyId: data.estimationRequestCompany?.id ?? null,
        estimationRequestCompanyPersonId: data.estimationRequestCompanyPerson?.id ?? null,
        nextContactDateWithRequestCompany: data.nextContactDateWithRequestCompany
          ? dayjs(data.nextContactDateWithRequestCompany).format(YYYY_MM_DD)
          : null,

        numberAttr1: data.numberAttr1 ?? null,
        numberAttr2: data.numberAttr2 ?? null,
        numberAttr3: data.numberAttr3 ?? null,
        textAttr1: data.textAttr1 ?? null,
        textAttr2: data.textAttr2 ?? null,
        textAttr3: data.textAttr3 ?? null,
        dateAttr1: data.dateAttr1 ? dayjs(data.dateAttr1).format(YYYY_MM_DD) : null,
        dateAttr2: data.dateAttr2 ? dayjs(data.dateAttr2).format(YYYY_MM_DD) : null,
        dateAttr3: data.dateAttr3 ? dayjs(data.dateAttr3).format(YYYY_MM_DD) : null,
      };

      onUpdate(params);
    },
    [onUpdate, item, enqueueSnackbar]
  );

  return onSubmit;
};

export default useEstimationHistoryTableRowUpdate;
