import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';
import { estimationHistoryTableCells } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/components/table-cells';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { EstimationFinalTable } from '@front/src/features/project-sales/features/tabs/estimation/sections/final/components/table';

export { Section as EstimationFinalSection };

/**
 * [메뉴]프로젝트-영업부-영업정보 / [탭]견적 / [섹션]최종 견적
 * @param props
 * @constructor
 */
function Section(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(estimationHistoryTableCells);

  const { Layout, TitleWrap } = SectionWrapperUI;
  return (
    <Layout>
      <TitleWrap left={props.name} />
      <Suspense fallback={<LoadingSpinnerUI />}>
        {/* 견적 이력 헤더와 동일한 헤더를 사용한다 */}
        <EstimationFinalTable
          {...props}
          sectionId={99}
        />
      </Suspense>
    </Layout>
  );
}
