import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AccountingBudgetForm } from '@front/src/features/accounting/features/budget/type';
import { DefaultSectionComponent } from '@front/type/Function';
import useAccountingBudgetBottomUpTableHook from '@front/src/features/accounting/features/budget/hook/bottom-up-table';
import AccountingBudgetTable from '@front/src/features/accounting/features/budget/component/common/table';

const AccountingBudgetBottomUpTable: DefaultSectionComponent = (props) => {

  const {
    methods,
    reflectBudgetYear,
  } = useAccountingBudgetBottomUpTableHook();

  const { watch: watchParent } = useFormContext<AccountingBudgetForm>();
  const budgetYear = watchParent('budgetYear');

  useEffect(() => {
    reflectBudgetYear(budgetYear);
  }, [budgetYear]);

  return (
    <AccountingBudgetTable
      {...props}
      methods={methods}
    />
  );
};

export default AccountingBudgetBottomUpTable;
