import create from 'zustand';

interface State {
  isOpen: boolean;
  clientId: number | null;
}

interface Actions {
  onOpen: () => void;
  onClose: () => void;
  setClientId: (clientId: number) => void;
}

const useContractCmModalStore = create<State & Actions>((set) => ({
  isOpen: false,
  clientId: null,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setClientId: (clientId) => set({ clientId }),
}));

export default useContractCmModalStore;
