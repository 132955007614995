import React, { useCallback } from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import useFileItemDownload from '@front/src/features/file-item/hooks/useDownload';
import { hasImageExtension } from '@front/src/utils';
import Box from '@mui/material/Box';
import { FileDisplayText } from '@front/src/components/file-display-text/file-display-text';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useImageDraw from '@front/src/features/image-draw/hooks/useImageDraw';
import { useFormContext } from 'react-hook-form';
import FileUI from '@front/src/components/components-with-design/component/File';

interface Props extends Omit<CellComponentProps, 'item' | 'onSubmit'> {
  name: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function UIBuilderTableCellFormUpdateFileAttachment(props: Props) {
  const { isEditMode, onClick, sx = {}, readOnly, name, disabled = false, onChange } = props;

  const formContext = useFormContext();
  const { getValues, setValue } = formContext;

  const fileItem = getValues(name);

  const { open: openImageDraw } = useImageDraw();
  const onOpenImageModal = useCallback(() => {
    if (fileItem) {
      openImageDraw(fileItem);
    }
  }, [getValues]);

  const { onDownload } = useFileItemDownload({ fileKey: fileItem?.fileKey });

  const handleDelete = () => {
    setValue(name, {
      filename: '',
      isNew: true,
    });
  };

  const renderContent = () => {
    if (isEditMode && !readOnly) {
      return (
        <FileUI
          value={fileItem}
          onChange={onChange}
          onDelete={handleDelete}
          onDownload={onDownload}
          disabled={disabled}
        />
      );
    }
    if (!fileItem) return <>-</>;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <FileDisplayText
          fileName={fileItem.filename}
          onClick={onDownload}
          align="left"
        />

        {hasImageExtension(fileItem.ext) && (
          <ButtonIconUI
            shape="tertiary"
            size="medium"
            onClick={onOpenImageModal}
          >
            <FontAwesomeIcon icon="magnifying-glass" />
          </ButtonIconUI>
        )}
      </Box>
    );
  };
  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
}
