import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProjectSalesDbFeature from '@front/src/features/project-sales-db/index';

export const dbAnalysisPath = '/project/sales/db-analysis';

const ProjectSalesDbRoutes = () => (
  <Routes>
    <Route
      path=""
      element={<ProjectSalesDbFeature />}
    ></Route>
  </Routes>
);

export default ProjectSalesDbRoutes;
