import React from 'react';
import {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import type { CollectionAddModalProps } from '@front/src/features/project-sales/features/tabs/collection/sections/history/components/AddModal';
import type { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import type { CollectionAddFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/hooks/useForm';
import { useCustomDialog } from '@front/src/features/dialog';
import CollectionAddTable from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/components/Table';

export interface CollectionAddFormProps extends Omit<CollectionAddModalProps, 'collectionData'> {
  formContext: UseFormReturn<CollectionAddFormValues, any, undefined>;
  fieldArray: UseFieldArrayReturn<CollectionAddFormValues, 'list', 'id'>;
}

export default function CollectionAddModalBody(props: CollectionAddFormProps) {
  const { formContext, onClose } = props;
  const {
    formState: { isDirty },
  } = formContext;

  const { confirm } = useCustomDialog();
  const handleCancel = () => {
    if (isDirty) {
      confirm({
        lineBreakChildren: [
          { value: '저장되지 않은 변경사항은 반영되지 않습니다.' },
          { value: '닫으시겠습니까?' },
        ],
        confirmText: '확인',
        closeText: '취소',
        afterConfirm: () => {
          onClose();
        },
      });
      return;
    }

    onClose();
  };

  return (
    <ModalBodyUI>
      <ModalHeaderUI>
        저장하지 않은 내용은 반영되지 않습니다.
        <ButtonBasicUI
          shape="tertiary"
          size="medium"
          onClick={handleCancel}
          sx={{
            width: '64px',
          }}
        >
          취소
        </ButtonBasicUI>
        <ButtonBasicUI
          type="submit"
          shape="primary2"
          size="medium"
          sx={{
            width: '64px',
          }}
        >
          저장
        </ButtonBasicUI>
      </ModalHeaderUI>
      <ModalContentUI>
        <CollectionAddTable {...props} />
      </ModalContentUI>
    </ModalBodyUI>
  );
}
