import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React, { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import type { EstimationCompetitorView } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/types/views';
import useEstimationCompetitorTableRowForm from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/hooks/useTableRowForm';

import useUIBuilderTableRowMutation from '@front/src/hooks/ui-builder-table/useTableRowMutation';
import estimationCompetitorMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/query/mutation';
import useUIBuilderTableRowLogic from '@front/src/hooks/ui-builder-table/useTableRowLogic';
import type { UpdateByFormDataParameter } from '@front/src/types/parameter';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';

export { TableBody as EstimationCompetitorTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<EstimationCompetitorView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<EstimationCompetitorView>) {
  const { item, formContext, dataId, menuId, sectionId } = props;

  const { methods } = useEstimationCompetitorTableRowForm(item);
  const { handleSubmit } = methods;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const { onAdd, onUpdateSeq, onDelete, onUpdate } =
    useUIBuilderTableRowMutation<UpdateByFormDataParameter>({
      onAdd: estimationCompetitorMutation.useAdd(commonParams),
      onUpdateSeq: estimationCompetitorMutation.useUpdateSeq(commonParams),
      onDelete: estimationCompetitorMutation.useDelete(commonParams),
      onUpdate: estimationCompetitorMutation.useUpdate(commonParams),
      formContext,
      queryKey: ['project', 'sales', commonParams.dataId, 'estimation', 'competitor'],
    });

  const { sx, isEditOpen, contextMenuItems } = useUIBuilderTableRowLogic({
    formContext,
    item,
    onAdd,
    onUpdateSeq,
    onDelete,
  });

  const onSubmit = handleSubmit((data) => {
    const formData = new FormData();

    if (
      data.estimationDocument &&
      data.estimationDocument.isNew &&
      data.estimationDocument.multipartFile
    ) {
      formData.append('pdf', data.estimationDocument.multipartFile);
    }

    const request = {
      seq: data.seq ?? null,
      estimationDate: data.estimationDate ? dayjs(data.estimationDate).format(YYYY_MM_DD) : null,
      competitorCompanyId: data.competitorCompany?.id ?? null,
      testPlan: data.testPlan ?? null,
      totalPriceDiff: data.totalPriceDiff ?? null,
      competitorTotalPrice: data.competitorTotalPrice ?? null,
      windTunnelPrice: data.windTunnelPrice ?? null,
      structuralReviewPrice: data.structuralReviewPrice ?? null,
      pureUnitPrice: data.pureUnitPrice ?? null,
      requiredWeeksStart: data.requiredWeeksStart ?? null,
      requiredWeeksEnd: data.requiredWeeksEnd ?? null,
      isNew: data.estimationDocument?.isNew ?? false,
      managerId: data.createdBy?.id ?? null,

      ...commonParams,

      numberAttr1: data.numberAttr1 ?? null,
      numberAttr2: data.numberAttr2 ?? null,
      numberAttr3: data.numberAttr3 ?? null,
      textAttr1: data.textAttr1 ?? null,
      textAttr2: data.textAttr2 ?? null,
      textAttr3: data.textAttr3 ?? null,
      dateAttr1: data.dateAttr1 ? dayjs(data.dateAttr1).format(YYYY_MM_DD) : null,
      dateAttr2: data.dateAttr2 ? dayjs(data.dateAttr2).format(YYYY_MM_DD) : null,
      dateAttr3: data.dateAttr3 ? dayjs(data.dateAttr3).format(YYYY_MM_DD) : null,
    };

    formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

    onUpdate({
      id: item.id,
      formData,
    });
  });

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={onSubmit}
        itemList={contextMenuItems}
        isEditMode={isEditOpen}
        sx={sx}
      />
    </FormProvider>
  );
}
