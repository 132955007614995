import {
  AccountingBudgetApiListView,
  AccountingBudgetListView,
  AccountingBudgetManagement,
  AccountingBudgetPoint,
  AccountingManagementView,
  VerticalTableCellProps,
} from '@front/src/features/accounting/features/budget/type';


export const apiResponseToAccountBudgetManagementAccountingList = (apiList: AccountingManagementView[]) => {
  const result: AccountingBudgetManagement[] = [];

  function depthLooper(tree: AccountingManagementView[], col: number) {
    for (let row = 0; row < tree.length; row++) {
      const item = tree[row];
      const accountBudgetManagementAccounting: AccountingBudgetManagement = {
        id: item.id,
        row,
        col,
        seq: item.sequence,
        depth: item.depth,
        name: item.name,
        children: (item.children && Array.isArray(item.children)
          ? item.children.map(child => child.id)
          : undefined),
      };
      result.push(accountBudgetManagementAccounting);

      if (item.children && Array.isArray(item.children) && item.depth < 4) {
        depthLooper(item.children, col + 1);
      }
    }
  }

  depthLooper(apiList, 0);
  for (let i = 0; i < result.length; i++) {
    const parent = result[i];
    if (!parent.children || !Array.isArray(parent.children)) {
      continue;
    }
    for (let j = 0; j < parent.children.length; j++) {
      const childId = parent.children[j];

      for (let k = 0; k < result.length; k++) {
        if (i === k) {
          continue;
        }
        const item = result[k];
        if (item.id === childId) {

          if (result[k].parent) {
            continue;
          }
          result[k].parent = parent.id;
        }
      }
    }
  }
  result.sort((a, b) => {
    if (a.depth === b.depth) {
      return a.seq - b.seq;
    }
    return a.depth - b.depth;
  });
  return result;
};

export function convertAccountingBudgetApiView(apiList: AccountingBudgetApiListView[]): AccountingBudgetListView[] {
  return apiList.map(item => ({
    id: item.id,
    title: item.title,
    budgetYear: item.standardYear,
    noteCount: item.noteCount,
    lastModifiedDate: item.lastSaveDate,
  }));
}

export function getReducedValue<T>(
  list: T[],
  mappingFn: (cell: T) => number,
  compareFn: (a: number, b: number) => number,
  initialValue: number = 0,
): number {
  return list.map(mappingFn).reduce(compareFn, initialValue);
}

export const getMaxCol = <T extends AccountingBudgetPoint>(list: T[]): number => {
  return getReducedValue(list, cell => cell.col, (a, b) => a > b ? a : b);
};

export const getMaxRow = <T extends AccountingBudgetPoint>(list: T[]): number => {
  return getReducedValue(list, cell => cell.row, (a, b) => a > b ? a : b);
};

export const getSortedRow = <T extends AccountingBudgetPoint>(list: T[], col: number): T[] => {
  return list.filter(cell => cell.col === col).sort((a, b) => a.row - b.row);
};

export const sortRow = <T extends AccountingBudgetPoint>(list: T[]): T[] => {
  return list.sort((a, b) => a.col - b.col);
};

export const getSortedRowList = <T extends VerticalTableCellProps>(list: T[]): T[][] => {
  const result: T[][] = [];
  const maxRow = getMaxRow(list);
  const fullList = list.map(cell => {
    if (!cell.cellGroupId) {
      cell.groupPointList = list
        .filter(c => c.cellGroupId === cell.cellGroupId)
        .map(c => ({ row: c.row, col: c.col }));
    }
    return cell;
  });

  for (let row = 0; row <= maxRow; row++) {
    const cellList = fullList.filter(cell => cell.row === row);
    result.push(sortRow(cellList));
  }
  return result;
};

export function nodeVisitor(tree: ManagementAccountingTreeView[], result: number[]) {

  for (let i = 0; i < tree.length; i++) {
    const branch = tree[i];
    result.push(branch.id);
    if (Array.isArray(branch.children) && branch.children.length > 0) {
      nodeVisitor(branch.children, result);
    }
  }
  return result;
}