import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import React, { useCallback, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormProvider, useFormContext } from 'react-hook-form';
import type {
  ContractCmFormValues,
  ContractCmListFormValues,
} from '@front/src/features/project-sales/features/tabs/contract/sections/cm/hooks/useTabForm';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import type { ControlPanelItem } from '@front/src/types/domain';
import EditIcon from '@mui/icons-material/Edit';
import { ColorPalette } from '@front/assets/theme';

export { TableBody as ContractCmTableBody };

interface TableBodyProps extends Omit<UIBuilderTableBodyProps, 'formUpdateFormContext'> {
  formUpdateFormContext: UseFormReturn<ContractCmFormValues, any, undefined>;
}

function TableBody(props: TableBodyProps) {
  const { formUpdateFormContext } = props;
  return (
    <UIBuilderTableBody<ContractCmListFormValues>
      {...props}
      RowComponent={(rowProps) => (
        <FormProvider {...formUpdateFormContext}>
          <TableRow
            {...rowProps}
            formUpdateFormContext={formUpdateFormContext}
          />
        </FormProvider>
      )}
    />
  );
}

interface TableRowProps
  extends Omit<UIBuilderTableRowProps<ContractCmListFormValues>, 'formUpdateFormContext'> {
  formUpdateFormContext: UseFormReturn<ContractCmFormValues, any, undefined>;
}

function TableRow(props: TableRowProps) {
  const { rowIndex } = props;

  const formUpdateFormContext = useFormContext<ContractCmFormValues>();
  const { getValues, setValue, watch } = formUpdateFormContext;

  const item = useMemo(() => getValues('list')[rowIndex], [rowIndex]);
  const isEditMode = watch('editOpenIdList')?.includes(item?.id);

  const onToggleEdit = useCallback(() => {
    const copy = [...getValues('editOpenIdList')];
    const findIndex = copy.findIndex((f) => f === item.id);
    if (findIndex > -1) {
      copy.splice(findIndex, 1);
      setValue('editOpenIdList', copy);
    } else {
      setValue('editOpenIdList', [...copy, item.id]);
    }
  }, [setValue, item]);

  const handleAfterChange = useCallback(() => {
    setValue('lastUpdatedId', getValues(`list.${rowIndex}`)?.id ?? null);
  }, [setValue, getValues]);

  const disabled = useMemo(() => !getValues(`list.${rowIndex}.canDelete`), [getValues, rowIndex]);

  const contextMenuItems: ControlPanelItem[] = useMemo(
    () => [
      {
        text: isEditMode ? '수정 닫기' : '수정',
        icon: EditIcon,
        action: onToggleEdit,
        seq: 3,
      },
    ],
    [isEditMode, onToggleEdit]
  );

  const sx = {
    backgroundColor: watch('lastUpdatedId') === item?.id ? `${ColorPalette._d2e7fa}` : 'inherit',
  };

  return (
    <UiBuilderTableRow
      {...props}
      itemList={contextMenuItems}
      isEditMode={isEditMode}
      sx={sx}
      onAfterChange={handleAfterChange}
      disabled={disabled}
    />
  );
}
