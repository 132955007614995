import React, { useCallback } from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import Box from '@mui/material/Box';
import type { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';

import { useFormContext } from 'react-hook-form';
import useManagedVariable from '@front/src/components/ui-builder/managed-variable/hooks/useManageableVariable';
import { convertToOptionList } from '@front/src/utils';
import DropDownWithHookForm from '@front/src/components/hook-form/DropDown';

interface Props extends CellComponentProps {
  code: ManagedVariable;
  displayValue?: string;
  name: string;
  disabled?: boolean;
  onAfterChange?: () => void;
}

export default function UIBuilderTableCellManagedDropDown(props: Props) {
  const {
    onClick,
    sx,
    name,
    readOnly,
    isEditMode,
    onSubmit,
    isForm,
    code,
    displayValue,
    disabled = false,
    onAfterChange,
  } = props;

  const list = useManagedVariable(code);
  const options = convertToOptionList(list);
  const { getValues } = useFormContext();

  const renderReadOnly = useCallback(
    () => displayValue ?? (getValues(name) || '-'),
    [displayValue]
  );

  const renderContent = useCallback(
    () => (
      <DropDownWithHookForm
        defaultLabel="선택"
        name={name}
        options={options}
        onSubmit={isForm ? undefined : onSubmit}
        disabled={disabled}
        onAfterChange={onAfterChange}
      />
    ),
    [name, options, isForm, onSubmit, disabled, onAfterChange]
  );

  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {readOnly || !isEditMode ? renderReadOnly() : renderContent()}
    </Box>
  );
}
