import { useForm, UseFormReturn } from 'react-hook-form';
import {
  AccountingBudgetForm,
  UpdateActivatedManagementType,
  UpdateBudgetYearType,
} from '@front/src/features/accounting/features/budget/type';
import dayjs from 'dayjs';

type MethodsType = UseFormReturn<AccountingBudgetForm>;
type HookType = () => {
  methods: MethodsType,
  updateBudgetYear: UpdateBudgetYearType,
  updateActivatedManagement: UpdateActivatedManagementType,
}

export const accountingBudgetHookDefaultValues: AccountingBudgetForm = {
  budgetYear: dayjs().add(1, 'years').year(),
  activatedManagement: undefined,
};

const useAccountingBudgetHook: HookType = () => {
  const methods: MethodsType = useForm({
    defaultValues: accountingBudgetHookDefaultValues,
  });

  const {
    getValues,
    reset,
  } = methods;

  const updateBudgetYear: UpdateBudgetYearType = (budgetYear) => {
    reset({
      ...getValues(),
      budgetYear,
    });
  };
  const updateActivatedManagement: UpdateActivatedManagementType = (activatedManagement) => {
    reset({
      ...getValues(),
      activatedManagement,
    });
  };

  return {
    methods,
    updateBudgetYear,
    updateActivatedManagement,
  };
};

export default useAccountingBudgetHook;
