import React from 'react';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';

export default function AccountingBudgetCopyButton() {

  return (
    <ButtonBasicUI
      shape="ghost"
      size="medium"
    >
      복제
    </ButtonBasicUI>
  );
}
