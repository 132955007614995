import React, { useCallback, useMemo } from 'react';
import {
  ModalBodyUI,
  ModalContentUI,
  ModalHeaderUI,
} from '@front/src/components/components-with-design/layout/ModalUI';

import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { useShallow } from 'zustand/react/shallow';
import { useFormContext } from 'react-hook-form';
import useContractCmModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/useCmModalStore';
import { ContractCmTable } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/table';
import contractCmQuery from '@front/src/features/project-sales/features/tabs/contract/sections/cm/query/query';
import type { ContractCmFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/hooks/useTabForm';
import ContractClientTabs from '@front/src/features/project-sales/features/tabs/contract/components/client-tabs';
import { getContractCmFormUpdateValues } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/utils';
import { useCustomDialog } from '@front/src/features/dialog';
import type { ContractCmModalProps } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/Modal';

export default function ContractCmModalBody(props: ContractCmModalProps) {
  const { dataId, menuId, sectionId, defaultClientId } = props;

  const { onClose } = useContractCmModalStore(
    useShallow((state) => ({
      onClose: state.onClose,
    }))
  );

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const {
    getValues,
    watch,
    reset,
    formState: { isDirty },
  } = useFormContext<ContractCmFormValues>();
  const clientId = watch('clientId');

  const dataObject = contractCmQuery.useGetObject(commonParams, defaultClientId);

  const handleAfterConfirm = useCallback(
    (value) => {
      if (dataObject) {
        const { clientId, list, deleteList, contractClientCompany } = getContractCmFormUpdateValues(
          value,
          dataObject
        );
        reset({
          totalContractPrice: getValues('totalContractPrice'),
          lastUpdatedId: null,
          editOpenIdList: [],
          clientId,
          list,
          deleteList,
          contractClientCompany,
        });
      }
    },
    [dataObject, reset, getValues]
  );

  const { confirm } = useCustomDialog();
  const handleChange = useCallback(
    (e, value) => {
      if (value === getValues('clientId')) return;

      if (isDirty) {
        confirm({
          lineBreakChildren: [
            { value: '저장되지 않은 변경사항은 반영되지 않습니다.' },
            { value: '탭을 이동하시겠습니까?' },
          ],
          confirmText: '확인',
          closeText: '취소',
          afterConfirm: () => {
            handleAfterConfirm(value);
          },
        });
        return;
      }

      handleAfterConfirm(value);
    },
    [getValues, isDirty, confirm, handleAfterConfirm]
  );

  return (
    <ModalBodyUI sx={modalBodySx}>
      {dataObject && (
        <ContractClientTabs
          {...props}
          value={clientId}
          dataObject={dataObject}
          onChange={handleChange}
        />
      )}
      <ModalHeaderUI>
        저장하지 않은 내용은 반영되지 않습니다.
        <ButtonBasicUI
          shape="tertiary"
          size="medium"
          onClick={onClose}
          sx={buttonSx}
        >
          취소
        </ButtonBasicUI>
        <ButtonBasicUI
          type="submit"
          shape="primary2"
          size="medium"
          sx={buttonSx}
        >
          저장
        </ButtonBasicUI>
      </ModalHeaderUI>
      <ModalContentUI sx={modalContentSx}>
        <ContractCmTable {...props} />
      </ModalContentUI>
    </ModalBodyUI>
  );
}

const modalBodySx = {
  minWidth: '70dvw',
  minHeight: '50dvh',
  // backfaceVisibility: 'hidden',
};

const buttonSx = {
  width: '64px',
};

const modalContentSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  maxWidth: '96dvw',
};
