import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';
import { contractPaymentTermTableCells } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/table-cells';
import ContractPaymentTermModal from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/Modal';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';

export { Section as ContractPaymentTermSection };

function Section(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(contractPaymentTermTableCells);
  return (
    <Suspense fallback={<LoadingSpinnerUI />}>
      <ContractPaymentTermModal {...props} />
    </Suspense>
  );
}
