import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import type { EstimationComparisonView } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/types/view';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import type { UserNameView } from '@front/src/types';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';

import type { SpareParameter } from '@front/src/types/parameter';
import type { FileItemFormValues } from '@front/src/components/hook-form/File';

const useEstimationComparisonTableRowForm = (item: EstimationComparisonView) => {
  const methods = useForm<EstimationComparisonFormValues>({
    defaultValues: getFormValues(item),
  });

  useEffect(() => {
    const {
      formState: { isDirty },
      setValue,
    } = methods;
    if (!isDirty) {
      (Object.keys(getFormValues(item)) as (keyof EstimationComparisonFormValues)[]).forEach(
        (field) => {
          setValue(field, getFormValues(item)[field]);
        }
      );
    }
  }, [item, methods]);

  return {
    methods,
  };
};

export default useEstimationComparisonTableRowForm;

const getFormValues = (item: EstimationComparisonView) => ({
  id: item.id ?? null,
  seq: item.seq ?? null,
  estimationDate: item.estimationDate ?? null,
  affiliatedCompany: item.affiliatedCompany ?? null,
  affiliatedCompanyPerson: item.affiliatedCompanyPerson ?? null,
  estimationRequestCompany: item.estimationRequestCompany ?? null,
  testPlan: item.testPlan ?? null,
  totalPriceDiff: item.totalPriceDiff ?? null,
  comparisonTotalPrice: item.comparisonTotalPrice ?? null,
  windTunnelPrice: item.windTunnelPrice ?? null,
  structuralReviewPrice: item.structuralReviewPrice ?? null,
  pureUnitPrice: item.pureUnitPrice ?? null,
  requiredWeeksStart: item.requiredWeeksStart ?? null,
  requiredWeeksEnd: item.requiredWeeksEnd ?? null,
  estimationDocument: item.estimationDocument ?? null,
  createdBy: item.createdBy ?? null,
  createdAt: item.createdAt ?? null,

  numberAttr1: item.numberAttr1 ?? null,
  numberAttr2: item.numberAttr2 ?? null,
  numberAttr3: item.numberAttr3 ?? null,
  textAttr1: item.textAttr1 ?? null,
  textAttr2: item.textAttr2 ?? null,
  textAttr3: item.textAttr3 ?? null,
  dateAttr1: item.dateAttr1 ?? null,
  dateAttr2: item.dateAttr2 ?? null,
  dateAttr3: item.dateAttr3 ?? null,
});

export interface EstimationComparisonFormValues extends SpareParameter {
  id: number | null;
  seq: number | null;
  estimationDate: string | null;
  affiliatedCompany: AffiliatedCompanyShortView | null;
  affiliatedCompanyPerson: AffiliatedCompanyPersonShortView | null;
  estimationRequestCompany: AffiliatedCompanyShortView | null;
  testPlan: string | null;
  totalPriceDiff: number | null;
  comparisonTotalPrice: number | null;
  windTunnelPrice: number | null;
  structuralReviewPrice: number | null;
  pureUnitPrice: number | null;
  requiredWeeksStart: number | null;
  requiredWeeksEnd: number | null;
  estimationDocument: FileItemFormValues | null;
  createdBy: UserNameView | null;
  createdAt: string | null;
}
