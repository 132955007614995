import React from 'react';
import {
  AccountingBudgetCommonTableForm,
  VerticalTableCellProps,
} from '@front/src/features/accounting/features/budget/type';
import { Box } from '@mui/material';
import { DefaultSectionComponent } from '@front/type/Function';
import AccountingBudgetTableCell
  from '@front/src/features/accounting/features/budget/component/common/table-cell';
import { UseFormReturn } from 'react-hook-form';

interface Props {
  cellList: VerticalTableCellProps[];
  methods: UseFormReturn<AccountingBudgetCommonTableForm>;
}

const AccountingBudgetTableRow: DefaultSectionComponent<Props> = (props) => {

  const { cellList } = props;

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      flexWrap: 'nowrap',
    }}>
      {cellList
        .map(cell => (
          <AccountingBudgetTableCell
            key={`col-${cell.col}-row-${cell.row}${cell.style === 'span' ? '-span' : ''}`}
            data-id={`col-${cell.col}-row-${cell.row}${cell.style === 'span' ? '-span' : ''}`}
            {...props}
            {...cell}
          />
        ))}
    </Box>
  );
};

export default AccountingBudgetTableRow;
