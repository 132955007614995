export const enum ManagedVariable {
  AffiliatedCompanyOutlineShortCategory = '관계사-업체구분',
  AffiliatedCompanyOutlineBankInfoBankName = '관계사-은행',
  WorkArea = '업무-영역',
  ProjectSalesEstimationType = '영업정보-견적구분',
  ProjectSalesEstimationStatus = '영업정보-견적상태',
  ProjectSalesEstimationPossibilityOfOrder = '영업정보-수주가능성',
  ProjectSalesEstimationProjectDifficulty = '영업정보-프로젝트난이도',
  ProjectSalesEstimationModelDifficulty = '영업정보-모형난이도',
  ProjectSalesEstimationOrderType = '영업정보-발주형태',
  ProjectSalesContractOrderType = '영업정보-수주구분',
  ProjectSalesContractType = '영업정보-계약구분',
  ProjectSalesStructuralReviewPaymentTiming = '영업정보-계약구검지급시기',
  ProjectSalesContractCmMethod = '영업정보-계약CM방식',
  ProjectSalesContractCmPaymentTiming = '영업정보-계약CM지급시기',
  ProjectSalesCollectionTiming = '영업정보-수금시기',
}
