import { useForm, UseFormReturn } from 'react-hook-form';
import { AccountingBudgetConfirmTableForm } from '@front/src/features/accounting/features/budget/type';

type MethodsType = UseFormReturn<AccountingBudgetConfirmTableForm>;
type HookType = () => {
  methods: MethodsType,
}

export const accountingBudgetConfirmTableHookDefaultValues: AccountingBudgetConfirmTableForm = {
  cellList: [{
    row: 0,
    col: 0,
    value: '확정예산 대비 실적 총액',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 0,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 2,
    col: 0,
    value: '확정예산 총액',
    isHead: true,
    clickable: false,
  }, {
    row: 2,
    col: 1,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 3,
    col: 0,
    value: '실적총액',
    isHead: true,
    clickable: false,
  }, {
    row: 3,
    col: 1,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 4,
    col: 0,
    value: '차이',
    isHead: true,
    clickable: false,
  }, {
    row: 4,
    col: 1,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 5,
    col: 0,
    value: '이익율',
    isHead: true,
    clickable: false,
  }, {
    row: 5,
    col: 1,
    value: null,
    style: 'percent',
    clickable: false,
  }],
};

const useAccountingBudgetConfirmTableHook: HookType = () => {

  const methods: MethodsType = useForm({
    defaultValues: accountingBudgetConfirmTableHookDefaultValues,
  });

  return {
    methods,
  };
};

export default useAccountingBudgetConfirmTableHook;
