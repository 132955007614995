import { useForm } from 'react-hook-form';
import {
  AccountingBudgetDetailTableForm,
  AccountingBudgetHookMethodsType,
  ReflectActivatedManagementType,
  ReflectBudgetYearType,
} from '@front/src/features/accounting/features/budget/type';
import { getMaxCol } from '@front/src/features/accounting/features/budget/util/convert';
import dayjs from 'dayjs';

type HookType = () => {
  methods: AccountingBudgetHookMethodsType<AccountingBudgetDetailTableForm>,
  reflectBudgetYear: ReflectBudgetYearType,
  reflectActivatedManagement: ReflectActivatedManagementType,
}

export const accountingBudgetDetailTableHookDefaultValues: AccountingBudgetDetailTableForm = {
  cellList: [{
    row: 0,
    col: 0,
    value: '미분류조정',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 0,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 2,
    value: '항목',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 3,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 4,
    value: '합계(년)',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 5,
    value: '평균(월)',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 2,
    value: null, // 최초 항목
  }, {
    row: 1,
    col: 3,
    value: '',
    style: 'span',
    clickable: false,
  }, {
    row: 1,
    col: 4,
    value: null,
    style: 'amount',
    clickable: false,
  }, {
    row: 1,
    col: 5,
    value: null,
    style: 'amount',
    clickable: false,
  }],
};

const useAccountingBudgetDetailTableHook: HookType = () => {

  const methods = useForm({
    defaultValues: accountingBudgetDetailTableHookDefaultValues,
  });

  const {
    getValues,
    reset,
  } = methods;

  const reflectBudgetYear: ReflectBudgetYearType = (budgetYear) => {
    const currentValues = getValues();
    const cellList = [...accountingBudgetDetailTableHookDefaultValues.cellList];
    const defaultHeaderMaxCol = getMaxCol(accountingBudgetDetailTableHookDefaultValues.cellList);

    for (let i = 0; i < 12; i++) {
      const value = dayjs().year(budgetYear).month(i).date(1).format('YYMM');
      const col = defaultHeaderMaxCol + i + 1;
      cellList.push({
        col: defaultHeaderMaxCol + i + 1,
        row: 0,
        value,
        isHead: true,
        clickable: false,
      });

      cellList.push({
        col,
        row: 1,
        value: null,
        style: 'amount',
        clickable: false,
      });

    }
    reset({
      ...currentValues,
      cellList,
    });
  };
  const reflectActivatedManagement: ReflectActivatedManagementType = (activatedManagement) => {
    if (activatedManagement && activatedManagement.depth === 4) {
      const currentValues = getValues();
      const pervCellList = getValues('cellList');
      const cellList: typeof pervCellList = [];
      for (let i = 0; i < pervCellList.length; i++) {
        const cell = pervCellList[i];
        if (cell.col === 1 && cell.row === 0) {
          cellList.push({
            ...cell,
            value: activatedManagement.name,
          });
        } else {
          cellList.push(cell);
        }
      }

      reset({
        ...currentValues,
        cellList,
      });
    }
  };

  return {
    methods,
    reflectBudgetYear,
    reflectActivatedManagement,
  };
};

export default useAccountingBudgetDetailTableHook;
