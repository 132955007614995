import type { ReactNode } from 'react';
import React from 'react';
import type { InputBaseComponentProps } from '@mui/material';
import { Fade, InputAdornment } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material/styles';
import TextFieldBasicUI from '@front/src/components/components-with-design/component/TextFieldBasicUI';
import type { UserVO } from '@front/user/domain';
import Box from '@mui/material/Box';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';

interface Props extends Omit<UserSelectorInputProps, 'onChange'> {
  endAdornment?: ReactNode;
  inputProps?: InputBaseComponentProps;
  onClick?: () => void;
  user?: UserVO;
  sx?: SxProps<Theme>;
}

export default function UserSelectorCommonInput({
  endAdornment,
  onClick,
  user,
  sx = {},
  disabled = false,
}: Readonly<Props>) {
  const getStartAdornment = () => {
    if (!user) return null;
    return (
      <InputAdornment position="start">
        <Box
          sx={{
            display: 'flex',
            fontSize: '18px',
            justifyContent: 'center',
            alignItems: 'flex-end',
            overflow: 'hidden',
            border: `1px solid ${ColorPalette._e4e9f2}`,
            color: ColorPalette._ffffff,
            backgroundColor: ColorPalette._e4e9f2,
            borderRadius: '25px',
            width: '25px',
            height: '25px',
            ...sx,
          }}
        >
          {user.profile?.id ? (
            <Fade in={true}>
              <img
                alt="프로필 사진"
                src={`/api/file-item/${user.profile.id}`}
                style={{
                  objectFit: 'cover',
                  width: '25px',
                  height: '25px',
                }}
              />
            </Fade>
          ) : (
            <Fade in={true}>
              <FontAwesomeIcon icon="user" />
            </Fade>
          )}
        </Box>
      </InputAdornment>
    );
  };
  return (
    <TextFieldBasicUI
      readOnly
      disabled={disabled}
      onClick={onClick}
      placeholder="인물 선택"
      value={user?.name ?? ''}
      startAdornment={getStartAdornment()}
      endAdornment={endAdornment}
      sx={{
        cursor: 'pointer',
        ...sx,
      }}
    />
  );
}
