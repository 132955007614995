type NumberFormattingType = (amount: number | undefined | null) => string;


export const numberFormatting: NumberFormattingType = (amount) => {
  if (amount === null || typeof amount === 'undefined') {
    return '';
  }
  return amount.toLocaleString();
};

type FloatFormattingType = (float: number | undefined | null, fractionDigits?: number) => string;

export const floatFormatting: FloatFormattingType = (float, fractionDigits = 2) => {
  if (typeof float === 'undefined' || float === null) {
    return '';
  }
  return float.toFixed(fractionDigits);
};
