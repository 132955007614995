import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import type {
  BondStatus,
  CollectionHistoryItemView,
  CollectionHistoryView,
} from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';

const useCollectionHistoryTableRowForm = (item: CollectionHistoryView) => {
  const methods = useForm<CollectionHistoryFormValues>({
    defaultValues: getFormValues(item),
  });

  const {
    formState: { isDirty },
    setValue,
    reset,
    getValues,
  } = methods;

  useEffect(() => {
    if (!isDirty) {
      (Object.keys(getFormValues(item)) as (keyof CollectionHistoryFormValues)[]).forEach(
        (field) => {
          setValue(field, getFormValues(item)[field]);
        }
      );
    }
  }, [item, setValue, isDirty]);

  useEffect(() => {
    reset({
      ...getValues(),
      collectionList: mapCollectionList(item.collectionList),
    });
  }, [item.collectionList]);

  return methods;
};

export default useCollectionHistoryTableRowForm;

const mapCollectionList = (collectionList: CollectionHistoryItemView[]) =>
  collectionList.map((m) => ({
    ...m,
    bondStatus: m.bondStatus ?? '',
  }));

const getFormValues = (item: CollectionHistoryView) => ({
  ...item,
  collectionList: mapCollectionList(item.collectionList),
});

export interface CollectionHistoryFormValues extends Omit<CollectionHistoryView, 'collectionList'> {
  collectionList: CollectionHistoryItemFormValues[];
}

interface CollectionHistoryItemFormValues extends Omit<CollectionHistoryItemView, 'bondStatus'> {
  bondStatus: BondStatus | string; // 채권상태
}
