export const calculateEstimationActualWindTunnelPrice = (
  totalEstimationPrice: number | string | null,
  structuralReviewPrice: number | string | null,
  estimationCm: number | string | null
) =>
  (totalEstimationPrice ? +totalEstimationPrice : 0) -
  (structuralReviewPrice ? +structuralReviewPrice : 0) +
  (estimationCm ? +estimationCm : 0);

export const calculateActualUnitPrice = (price: number | null, testPlan: string | null) => {
  const extractValues = extractTestPlanValues(testPlan);
  const fNumber = extractValues.F ?? 0;
  const pNumber = extractValues.P ?? 0;
  const aNumber = extractValues.A ?? 0;
  const testPlanNumber =
    (extractValues.F ? 1 : 0) + (extractValues.P ? 1 : 0) + (extractValues.A ? 1 : 0);

  if (testPlanNumber === 0) return null;

  return (price ?? 0) - (fNumber + pNumber + aNumber) / testPlanNumber;
};

type ExtractedTestPlanValues = {
  F: number | null;
  P: number | null;
  A: number | null;
};

export const extractTestPlanValues = (input: string | null): ExtractedTestPlanValues => {
  // 정규식: 숫자 + F, P, A를 찾기
  const result: ExtractedTestPlanValues = { F: null, P: null, A: null };
  if (!input) return result;

  const regex = /(\d+)([FPA])/g;

  let match;
  while ((match = regex.exec(input)) !== null) {
    // 매칭된 전체 값, 숫자, 문자
    const [_, value, key] = match;
    if (key === 'F' || key === 'P' || key === 'A') {
      result[key] = parseInt(value, 10);
    }
  }

  return result;
};
