import React, { useCallback, useRef, useState } from 'react';
import { TreeItem } from '@mui/x-tree-view';
import { Typography } from '@mui/material';
import CheckboxBasicUI from '@front/src/components/components-with-design/component/CheckboxBasicUI';
import {
  TreeData,
  TreeItemType,
} from '@front/src/features/project-sales-db/features/data-set-tree/types';
import { CheckedAttributes } from '@front/src/features/project-sales-db/types';
import headerMeta from '@front/src/components/ui-builder/headerMeta';
import { useSnackbar } from 'notistack';
import { projectSalesDbAnalysisApi } from '@front/src/features/project-sales-db/api/api';

interface DataSetTreeLogicProps {
  menuId: number;
  checkedAttributes: CheckedAttributes;
  setCheckedAttributes: React.Dispatch<React.SetStateAction<CheckedAttributes>>;
}

export default function useDataSetTreeLogic(props: DataSetTreeLogicProps) {
  const { checkedAttributes, setCheckedAttributes, menuId } = props;
  const expandedList = useRef<string[]>();
  const [treeData, setTreeData] = useState<TreeData[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleSectionClick = useCallback(
    (treeData: TreeData) => {
      const newId = `${treeData.parentId}-${treeData.id}`;
      expandedList.current?.push(newId);
      projectSalesDbAnalysisApi
        .getHeaderList(treeData.id, { position: 'table' }, menuId)
        .then((response) => {
          setTreeData((prevNodes) => {
            const targetNode = findTreeItem(prevNodes, treeData.id, 'section');

            if (targetNode && targetNode.children?.length === 0) {
              const newChildren = response.map((item) => ({
                id: item.id,
                name: item.name,
                type: 'attribute' as TreeItemType,
                parentName: treeData.name,
                parentId: treeData.id,
                configuration: item,
                children: [],
                tabId: treeData.tabId,
              }));

              targetNode.children.push(...newChildren);
              return [...prevNodes];
            }
            return prevNodes;
          });
        });
    },
    [expandedList]
  );

  const handleTabClick = useCallback(
    (treeData: TreeData) => {
      const expandedItemIndex = expandedList.current?.findIndex(
        (value) => value === `${treeData.parentId}-${treeData.id}`
      );

      if (expandedItemIndex && expandedItemIndex > -1) {
        expandedList.current?.splice(expandedItemIndex, 1);
      } else {
        expandedList.current?.push(`${treeData.parentId}-${treeData.id}`);
      }

      projectSalesDbAnalysisApi.getSectionList(+treeData.id, menuId).then((response) => {
        setTreeData((prevNodes) => {
          const targetNode = findTreeItem(prevNodes, treeData.id, 'tab');

          if (targetNode && targetNode.children?.length === 0) {
            const newChildren = response
              .filter((item) => item.type === 'TABLE')
              .map((item) => ({
                id: item.id,
                name: item.name,
                type: 'section' as TreeItemType,
                parentName: treeData.name,
                parentId: treeData.id,
                children: [],
                tabId: treeData.id,
              }));

            if (newChildren.length === 0) {
              newChildren.push({
                id: 0,
                name: '제공 가능 데이터셋 없음',
                type: 'section' as TreeItemType,
                parentName: treeData.name,
                parentId: treeData.id,
                children: [],
                tabId: treeData.id,
              });
            }

            targetNode.children.push(...newChildren);
            return [...prevNodes];
          }
          return prevNodes;
        });
      });
    },
    [expandedList]
  );

  const handleAttributeClick = useCallback((treeData: TreeData) => {
    const id = `${treeData.id}`;
    setCheckedAttributes((prev) => {
      const updated = { ...prev };
      if (updated[id]) {
        delete updated[id];
      } else {
        updated[id] = treeData;
      }
      return updated;
    });
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent, treeData: TreeData) => {
      if (treeData.type === 'tab') {
        handleTabClick(treeData);
      } else if (treeData.type === 'section') {
        handleSectionClick(treeData);
      } else {
        event.preventDefault();
        event.stopPropagation();
        handleAttributeClick(treeData);
      }
    },
    [handleTabClick, handleSectionClick, handleAttributeClick]
  );

  const renderTree = useCallback(
    (nodes?: TreeData[]) => (
      <>
        {nodes?.map((node) => {
          const nodeId = `${node.parentId}-${node.id}`;
          expandedList.current?.push(nodeId);
          return (
            <TreeItem
              key={nodeId}
              nodeId={nodeId}
              label={
                <Typography data-type={node.type}>
                  {node.type === 'attribute' && (
                    <CheckboxBasicUI
                      checked={!!checkedAttributes[node.id]}
                      disabled={!headerMeta[node.id] || !headerMeta[node.id].group}
                    />
                  )}
                  {node.name ? node.name : 'blank'}
                </Typography>
              }
              onClick={(event) => {
                if (node.type === 'attribute') {
                  if (headerMeta[node.id] && headerMeta[node.id].group) {
                    handleClick(event, node);
                  } else {
                    enqueueSnackbar('준비중인 데이터셋 입니다', { variant: 'info' });
                  }
                } else {
                  handleClick(event, node);
                }
              }}
            >
              {Array.isArray(node.children) ? renderTree(node.children) : null}
            </TreeItem>
          );
        })}
      </>
    ),
    [expandedList, checkedAttributes, handleClick]
  );

  const TreeItems = useCallback(() => {
    return renderTree(treeData);
  }, [treeData, renderTree]);

  return {
    TreeItems,
    setTreeData,
    expandedList,
  };
}

function findTreeItem(
  tree: TreeData[],
  targetId: number,
  targetType: TreeItemType
): TreeData | null {
  for (const item of tree) {
    if (item.id === targetId && item.type === targetType) {
      return item;
    }
    if (item.children && item.children.length > 0) {
      const found = findTreeItem(item.children, targetId, targetType);
      if (found) {
        return found;
      }
    }
  }
  return null;
}
