import React from 'react';
import {
  TabMain2ItemUI,
  TabMain2UI,
} from '@front/src/components/components-with-design/component/TabUI';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import Box from '@mui/material/Box';
import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import type { ContractPaymentTermView } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/types/views';

interface Props extends SectionComponentProps {
  dataObject: any;
  value: number | null;
  onChange: (event: any, value: number) => void;
}

export default function ContractClientTabs(props: Props) {
  const { dataObject, value, onChange } = props;

  const clients = getClientList(dataObject);

  return (
    <Box sx={{ backgroundColor: '#d8dce2', padding: '1rem 0 0 0' }}>
      <TabMain2UI
        key={`contract-tabs-${value}`}
        value={value}
        onChange={onChange}
      >
        {clients.map(({ id, name }) => (
          <TabMain2ItemUI
            key={id}
            value={id}
            label={name}
          />
        ))}
      </TabMain2UI>
    </Box>
  );
}

interface DataObject {
  [clientId: string]: {
    contractClientId: number;
    contractClientCompany: AffiliatedCompanyShortView;
    list: ContractPaymentTermView[];
  };
}

const getClientList = (dataObject: DataObject): { id: number; name: string }[] => {
  if (!dataObject) return [];

  const clients = Object.values(dataObject).map((value) => ({
    id: value.contractClientId as number,
    name: value.contractClientCompany.name as string,
  }));

  return clients;
};
