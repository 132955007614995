import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  bondStatus,
  bondStatusLabel,
  type CollectionHistoryView,
} from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import collectionHistoryCollectionMutation from '@front/src/features/project-sales/features/tabs/collection/sections/history/query/collectionMutation';
import { useCustomDialog } from '@front/src/features/dialog';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import { useCallback, useMemo } from 'react';

interface Props extends CellComponentProps<CollectionHistoryView> {
  onOpen: (id: number) => void;
}

const useCollectionInnerCellMenuLogic = (props: Props) => {
  const { dataId, menuId, sectionId, onOpen } = props;

  const commonParams = useMemo(() => ({ dataId, sectionId, menuId }), [dataId, sectionId, menuId]);

  const onDelete = collectionHistoryCollectionMutation.useDelete(commonParams);

  const handleDeleteAfterConfirm = useCallback(
    (id: number) => {
      const params = {
        ...commonParams,
        id,
      };
      onDelete(params);
    },
    [commonParams, onDelete]
  );

  const { confirm } = useCustomDialog();
  const handleClickDelete = useCallback(
    (id) => {
      confirm({
        lineBreakChildren: [{ value: '항목을 삭제하시겠습니까?' }],
        confirmText: '확인',
        closeText: '취소',
        afterConfirm: () => {
          handleDeleteAfterConfirm(id);
        },
      });
    },
    [confirm, handleDeleteAfterConfirm]
  );

  const getMenuItems = useCallback(
    (id: number) => [
      {
        text: '추가',
        icon: AddCircleOutlineIcon,
        action: () => {
          onOpen(id);
        },
        seq: 1,
        split: true,
      },
      {
        text: '삭제',
        icon: DeleteIcon,
        action: () => {
          handleClickDelete(id);
        },
        iconColor: '#e43333',
        seq: 100,
      },
    ],
    [onOpen, handleClickDelete]
  );

  const options = useMemo(
    () =>
      Object.values(bondStatus).map((m) => ({
        label: bondStatusLabel[m],
        value: m,
      })),
    []
  );

  return {
    getMenuItems,
    options,
  };
};

export default useCollectionInnerCellMenuLogic;
