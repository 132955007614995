import React, { useMemo } from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import estimationHistoryQuery from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/query';

import { EstimationFinalTableBody } from '@front/src/features/project-sales/features/tabs/estimation/sections/final/components/table-body';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';

export { Table as EstimationFinalTable };

function Table(props: SectionComponentProps) {
  const { dataId, menuId, sectionId } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);
  const list = estimationHistoryQuery.useGetList(commonParams);

  const filtered = useMemo(() => list?.filter((item) => item.estimation.isConfirmed), [list]);

  return (
    <UiBuilderTable
      {...props}
      HeadComponent={UIBuilderTableHeader}
      BodyComponent={EstimationFinalTableBody}
      readOnly // 읽기모드에서는 동작버튼[:]을 제공하지 않는다
      useNote={false} // 노트 사용 여부
      useSummary // 합산행 사용 여부
      list={filtered}
    />
  );
}
