import React from 'react';
import UIBuilderTableHeader, {
  type UIBuilderTableHeadProps,
} from '@front/src/components/ui-builder/table/Header';
import type { UseFieldArrayReturn } from 'react-hook-form';
import type { CollectionClaimDocumentFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/hooks/useForm';
import useCollectionModalTableRowLogic from '@front/src/features/project-sales/features/tabs/collection/hooks/useModalTableHeadLogic';

interface Props extends Omit<UIBuilderTableHeadProps, 'list'> {
  fieldArray: UseFieldArrayReturn<CollectionClaimDocumentFormValues, 'list', string>;
}

export default function CollectionClaimDocumentTableHead(props: Props) {
  const { fieldArray, formUpdateFormContext } = props;

  const { itemList } = useCollectionModalTableRowLogic(formUpdateFormContext!, fieldArray);

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={itemList}
    />
  );
}
