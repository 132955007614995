import { useMutation, useQueryClient } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';
import type { ContractPaymentTermUpdateParameter } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/types/parameter';

const contractPaymentTermMutation = {
  useUpdate: (clientId: number, commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId, clientId);

    const { mutate } = useMutation<'', Error, ContractPaymentTermUpdateParameter>({
      mutationFn: (params) =>
        commonApi.update(
          `/api/project/sales/sales-information/contract/contact-client/contract-payment-term`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },
};

export default contractPaymentTermMutation;

const getQueryKey = (dataId, clientId) => [
  'project',
  'sales',
  dataId,
  'contract',
  'history',
  clientId,
  'payment-terms',
];
