import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';
import { v4 as uuid } from 'uuid';
import type { CollectionFinalView } from '@front/src/features/project-sales/features/tabs/collection/sections/final/types/views';
import type { AxiosError } from 'axios';

const collectionFinalQuery = {
  useGet: (commonParams: UIBuilderParameter) => {
    const url = `/api/project/sales/sales-information/${commonParams.dataId}/collection-by-confirmed-contract`;
    const queryKey = ['project', 'sales', commonParams.dataId, 'collection', 'final'];

    const { data } = useQuery<CollectionFinalView[], AxiosError, CollectionFinalView[]>({
      queryFn: () => getFinal(url, commonParams),
      queryKey,
      suspense: true,
      enabled: !!commonParams.dataId && !!commonParams.menuId,
    });

    return data;
  },
};

export default collectionFinalQuery;

const getFinal = async (url, commParams) => {
  const data = await commonApi.getList(url, commParams);

  return data.map((d) => ({
    ...d,
    id: uuid(),
  }));
};
