import { useEffect } from 'react';

interface VirtualScrollbarProps {
  outerRef: React.RefObject<HTMLDivElement>;
  scrollbarRef: React.RefObject<HTMLDivElement>;
}

export const useVirtualScrollbar = (props: VirtualScrollbarProps) => {
  const { outerRef, scrollbarRef } = props;
  useEffect(() => {
    const onVirtualScrollbarDivScroll = (event) => {
      const scrollTop = (event.target as HTMLDivElement).scrollTop;
      if (outerRef.current) {
        (outerRef.current as HTMLDivElement).scrollTop = scrollTop;
      }
    };

    if (scrollbarRef.current) {
      const scrollbar = scrollbarRef.current as HTMLDivElement;
      scrollbar.addEventListener('scroll', onVirtualScrollbarDivScroll, { passive: true });
    }

    const onOuterDivScroll = () => {
      if (scrollbarRef.current && outerRef.current) {
        const scrollbar = scrollbarRef.current as HTMLDivElement;
        scrollbar.scrollTop = outerRef.current.scrollTop;
      }
    };

    if (outerRef.current) {
      outerRef.current.addEventListener('scroll', onOuterDivScroll, { passive: true });
    }

    return () => {
      if (outerRef.current) {
        outerRef.current.removeEventListener('scroll', onOuterDivScroll);
      }
      if (scrollbarRef.current) {
        const scrollbar = scrollbarRef.current as HTMLDivElement;
        scrollbar.removeEventListener('scroll', onVirtualScrollbarDivScroll);
      }
    };
  }, [outerRef, scrollbarRef]);
};
