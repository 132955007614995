import type { ContractHistoryFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/history/hooks/useForm';
import type { ContractHistoryUpdateParameter } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/parameter';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import type { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

interface Props {
  onUpdate: (params: ContractHistoryUpdateParameter) => void;
  item: ContractHistoryView;
}

const useContractHistoryTableRowUpdate = ({ onUpdate, item }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    (data: ContractHistoryFormValues) => {
      if (item.contractClient.some((s) => !s.canDelete)) {
        enqueueSnackbar('수금액이 있는 계약이 있어 수정할 수 없습니다.', {
          variant: 'warning',
        });

        return;
      }

      if (!data.id) return;

      const params = {
        id: data.id,
        seq: data.seq ?? null,
        isConfirmed: data.isConfirmed === '' ? null : data.isConfirmed === 'Y',
        orderType: data.orderType ?? null,
        orderDate: data.orderDate ?? null,
        estimationId: data.estimationId ? +data.estimationId : null,

        numberAttr1: data.numberAttr1 ?? null,
        numberAttr2: data.numberAttr2 ?? null,
        numberAttr3: data.numberAttr3 ?? null,
        textAttr1: data.textAttr1 ?? null,
        textAttr2: data.textAttr2 ?? null,
        textAttr3: data.textAttr3 ?? null,
        dateAttr1: data.dateAttr1 ? dayjs(data.dateAttr1).format(YYYY_MM_DD) : null,
        dateAttr2: data.dateAttr2 ? dayjs(data.dateAttr2).format(YYYY_MM_DD) : null,
        dateAttr3: data.dateAttr3 ? dayjs(data.dateAttr3).format(YYYY_MM_DD) : null,
      };

      onUpdate(params);
    },
    [enqueueSnackbar, onUpdate]
  );

  return onSubmit;
};

export default useContractHistoryTableRowUpdate;
