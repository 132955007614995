import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AddPosition } from '@front/src/utils';
import { DeleteSweep } from '@mui/icons-material';
import { useMemo } from 'react';

interface Props {
  onAdd: (position: AddPosition) => void;
  onDeleteAll: () => void;
}

const useGetUIBuilderTableHeadContextMenu = ({ onAdd, onDeleteAll }: Props) => {
  const itemList = useMemo(
    () => [
      {
        text: '최상단에 추가',
        icon: AddCircleOutlineIcon,
        action: () => {
          onAdd(AddPosition.TOP);
        },
        seq: 4,
      },
      {
        text: '최하단에 추가',
        icon: AddCircleOutlineIcon,
        action: () => {
          onAdd(AddPosition.BOTTOM);
        },
        seq: 5,
        split: true,
      },
      {
        text: '전체 삭제',
        icon: DeleteSweep,
        action: onDeleteAll,
        seq: 100,
        iconColor: '#e43333',
      },
    ],
    [onAdd, onDeleteAll]
  );

  return {
    itemList,
  };
};

export default useGetUIBuilderTableHeadContextMenu;
