import React from 'react';
import { useOutletContext, useParams } from 'react-router';
import type { ProjectSalesCommonFeatureProps } from '@front/src/features/project-sales';
import CommonSectionList from '@front/src/components/ui-builder/CommonSectionList';
import { EstimationComparisonSection } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison';
import { useSectionComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useSectionComponentLifeCycleManager';
import useActiveTab from '@front/src/features/project-sales/hooks/useActiveTab';
import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import { EstimationCompetitorSection } from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor';
import { EstimationHistorySection } from '@front/src/features/project-sales/features/tabs/estimation/sections/history';
import { EstimationFinalSection } from '@front/src/features/project-sales/features/tabs/estimation/sections/final';

export { Tab as EstimationTab };

const sectionComponents: SectionComponent = {
  98: EstimationFinalSection,
  99: EstimationHistorySection,
  100: EstimationCompetitorSection,
  101: EstimationComparisonSection,
};

/**
 * [메뉴]프로젝트-영업부-영업정보 / [탭]견적
 * @constructor
 */
function Tab() {
  const { id } = useParams<{ id: string }>();
  const { tabList } = useOutletContext<ProjectSalesCommonFeatureProps>();
  const activeTab = useActiveTab(tabList);
  useSectionComponentLifeCycleManager(sectionComponents);

  return (
    <>
      {activeTab && id && (
        <CommonSectionList
          menuId={activeTab.id}
          dataId={+id}
        />
      )}
    </>
  );
}
