import type { FieldArrayWithId } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import { arraySupportKeyword, SummaryRenderer } from '@front/src/components/ui-builder/headerMeta';
import { getNestedAttribute } from '@front/src/utils';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import React from 'react';

/**
 * 속성명이 키로 제공됩니다. 해당 키에 대한 요약행 랜더러는 인자로 전체 속성에 대한 합산 결과를 제공합니다.
 * @example
 * {
 *  structuralReviewRatio: (summaryData) => {
 *      const totalContractPrice = summaryData['totalContractPrice'];
 *      const structuralReviewPrice = summaryData['structuralReviewPrice'];
 *      if (totalContractPrice && structuralReviewPrice) {
 *        return `${Math.round((structuralReviewPrice / totalContractPrice) * 100)}%`;
 *      } else {
 *        return '-';
 *      }
 *    },
 * }
 */
export interface SummaryRenderer {
  [key: string]: (summaryData: { [key: string]: number | null }) => string;
}

interface HeaderSummaryRowProps {
  useNote: boolean | undefined;
  readOnly: boolean | undefined;
  bundleFields: FieldArrayWithId<UIBuilderTableFieldValues, 'filterBundle', 'id'>[];
  list: any[] | undefined;
  summaryRenderer?: SummaryRenderer;
}
export default function HeaderSummaryRow(props: HeaderSummaryRowProps) {
  const { useNote, readOnly, bundleFields, list, summaryRenderer } = props;
  const renderCells = () => {
    const result: (number | null)[] = bundleFields.map(() => null);
    let listLength = list?.length ?? 0;
    list?.forEach((item) => {
      bundleFields.forEach((fieldMeta, index) => {
        if (fieldMeta.attributeName && fieldMeta.attributeName.length > 0) {
          let currentItems: any[] = [item]; // 초기 데이터는 최상위 `item`
          let remainingAttributes = [...fieldMeta.attributeName]; // 남은 속성 목록

          while (remainingAttributes.length > 0) {
            const arraySupportKeywordIndex = remainingAttributes.indexOf(arraySupportKeyword);

            if (arraySupportKeywordIndex !== -1) {
              // `arraySupportKeyword`가 발견되었을 경우
              const attributeNameBeforeArray = remainingAttributes.slice(
                0,
                arraySupportKeywordIndex
              );
              const attributeNameAfterArray = remainingAttributes.slice(
                arraySupportKeywordIndex + 1
              );

              currentItems = currentItems.flatMap((currentItem) => {
                const innerList: any[] = getNestedAttribute(
                  currentItem,
                  attributeNameBeforeArray.join('.')
                );
                return innerList ?? []; // 배열이 없으면 빈 배열 반환
              });

              listLength = currentItems.length;

              remainingAttributes = attributeNameAfterArray; // 이후 속성들을 계속 처리
            }
            const attributeNameWithDot = remainingAttributes.join('.');
            currentItems.forEach((currentItem) => {
              const value = getNestedAttribute(currentItem, attributeNameWithDot);
              if (typeof value === 'number') {
                result[index] = (result[index] ?? 0) + +(value ?? 0);
              }
            });
            break; // 처리 완료 후 종료
          }
        }
      });
    });

    // 커스텀 요약행 셀 랜더러 처리
    const summaryData: { [key: string]: number | null } = {};
    summaryRenderer &&
      bundleFields.forEach((fieldMeta, index) => {
        const attrName = fieldMeta.attributeName?.join('.');
        if (attrName) {
          if (fieldMeta.summaryType === SummaryRenderer.SUM) {
            summaryData[attrName] = result[index];
          } else if (listLength && fieldMeta.summaryType === SummaryRenderer.AVERAGE) {
            summaryData[attrName] = (result[index] ?? 0) / listLength;
          } else {
            summaryData[attrName] = null;
          }
        }
      });

    return result.map((value, index) => {
      let finalValue;
      const attrName = bundleFields[index].attributeName?.join('.');
      if (summaryRenderer && attrName && summaryRenderer[attrName]) {
        finalValue = `${summaryRenderer[attrName](summaryData)}`;
      } else {
        if (bundleFields[index].summaryType === SummaryRenderer.SUM) {
          finalValue = (value ? Math.round(value) : 0).toLocaleString();
        } else if (listLength && bundleFields[index].summaryType === SummaryRenderer.AVERAGE) {
          finalValue = `${value ? Number((value / listLength).toFixed(2)) : 0}%`;
        } else {
          finalValue = '-';
        }
      }

      return (
        <TableCellUI
          isHead
          sx={{ width: 'auto', backgroundColor: '#e9f2ff' }}
          key={index}
        >
          {finalValue}
        </TableCellUI>
      );
    });
  };

  return (
    <TableRowUI key="header-summary-row">
      {!readOnly && (
        <TableCellUI
          isHead
          sx={{ width: 'auto', backgroundColor: '#e9f2ff' }}
        >
          -
        </TableCellUI>
      )}
      {useNote && (
        <TableCellUI
          isHead
          sx={{ width: 'auto', backgroundColor: '#e9f2ff' }}
        >
          -
        </TableCellUI>
      )}
      {renderCells()}
      <TableCellUI
        isHead
        sx={{ width: 'auto', backgroundColor: '#e9f2ff' }}
      />
    </TableRowUI>
  );
}
