import React from 'react';
import { Box } from '@mui/material';
import { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import {
  AccountStatusSummaryView,
} from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/types/view';
import { floatFormatting, numberFormatting } from '@front/util/NumberUtil';

type CellProps = Readonly<CellComponentProps<AccountStatusSummaryView>>;
type ElementType = (props: CellProps) => React.ReactElement;
type CellComponentType = { [key: number]: ElementType };

const AccountStatusSummaryTableCellComponent: CellComponentType = {
  314: (props) => (
    <Box
      sx={props.sx ?? {}}
      data-cell-component-id={314}
      key={314}
      onClick={() => {
        if (props.onClick) {
          props.onClick(314);
        }
      }}
    >
      {`${props.item.aliasCount} 개`}
    </Box>
  ),
  315: (props) => (
    <Box
      sx={{
        ...(props.sx ?? {}),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      data-cell-component-id={315}
      key={315}
      onClick={() => {
        if (props.onClick) {
          props.onClick(315);
        }
      }}
    >
      {numberFormatting(props.item.totalAmount)}
    </Box>
  ),
  316: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${316}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            data-cell-component-id={316}
            onClick={() => {
              if (props.onClick) {
                props.onClick(316);
              }
            }}
          >
            {numberFormatting(props.item.typeList[index].amount)}
          </Box>
        </div>
      ))}
    </>
  ),
  317: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${317}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
          }}
        >
          <Box
            sx={props.sx ?? {}}
            data-cell-component-id={317}
            key={317}
            onClick={() => {
              if (props.onClick) {
                props.onClick(317);
              }
            }}
          >
            {props.item.typeList[index].type}
          </Box>
        </div>
      ))}
    </>
  ),
  318: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${318}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
          }}
        >
          <Box
            sx={props.sx ?? {}}
            data-cell-component-id={318}
            key={318}
            onClick={() => {
              if (props.onClick) {
                props.onClick(318);
              }
            }}
          >
            {`${props.item.typeList[index].bankCount}개 은행, ${props.item.typeList[index].branchCount}개 지점`}
          </Box>
        </div>
      ))}
    </>
  ),
  319: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${319}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
          }}
        >
          <Box
            sx={props.sx ?? {}}
            data-cell-component-id={319}
            key={319}
            onClick={() => {
              if (props.onClick) {
                props.onClick(319);
              }
            }}
          >
            {`${props.item.typeList[index].codeCount}개 통장 운영중`}
          </Box>
        </div>
      ))}
    </>
  ),
  320: (props) => (
    <Box
      sx={{
        ...(props.sx ?? {}),
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      data-cell-component-id={320}
      key={320}
      onClick={() => {
        if (props.onClick) {
          props.onClick(320);
        }
      }}
    >
      {numberFormatting(props.item.expectedPrincipalInterest)}
    </Box>
  ),
  321: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${321}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              ...(props.sx ?? {}),
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            data-cell-component-id={321}
            key={321}
            onClick={() => {
              if (props.onClick) {
                props.onClick(321);
              }
            }}
          >
            {numberFormatting(props.item.typeList[index].expectedProductInterest)}
          </Box>
        </div>
      ))}
    </>
  ),
  322: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${322}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              ...(props.sx ?? {}),
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            data-cell-component-id={322}
            key={322}
            onClick={() => {
              if (props.onClick) {
                props.onClick(322);
              }
            }}
          >
            {numberFormatting(props.item.typeList[index].expectedInterest)}
          </Box>
        </div>
      ))}
    </>
  ),
  323: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${323}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              ...(props.sx ?? {}),
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            data-cell-component-id={323}
            key={323}
            onClick={() => {
              if (props.onClick) {
                props.onClick(323);
              }
            }}
          >
            {numberFormatting(props.item.typeList[index].expectedTax)}
          </Box>
        </div>
      ))}
    </>
  ),
  324: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${324}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              ...(props.sx ?? {}),
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            data-cell-component-id={324}
            key={324}
            onClick={() => {
              if (props.onClick) {
                props.onClick(324);
              }
            }}
          >
            {`${floatFormatting(props.item.typeList[index].averageInterestRate)}%`}
          </Box>
        </div>
      ))}
    </>
  ),
  325: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${325}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              ...(props.sx ?? {}),
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            data-cell-component-id={325}
            key={325}
            onClick={() => {
              if (props.onClick) {
                props.onClick(325);
              }
            }}
          >
            {`${floatFormatting(props.item.typeList[index].taxRate1)}%`}
          </Box>
        </div>
      ))}
    </>
  ),
  326: (props) => (
    <>
      {props.item.typeList.map((type, index) => (
        <div
          key={`${326}-${index}`}
          className="inner-cell"
          style={{
            padding: '0 4px',
            borderRight: '1px',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              ...(props.sx ?? {}),
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            data-cell-component-id={326}
            key={326}
            onClick={() => {
              if (props.onClick) {
                props.onClick(326);
              }
            }}
          >
            {`${floatFormatting(props.item.typeList[index].taxRate2)}%`}
          </Box>
        </div>
      ))}
    </>
  ),
};

export default AccountStatusSummaryTableCellComponent;
