import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import React, { useCallback } from 'react';
import type { CollectionHistoryView } from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import { bondStatusLabel } from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import InnerCellSupport from '@front/src/components/ui-builder/table/InnerCellSupport';
import UIBuilderTableCellDropDown from '@front/src/components/ui-builder/table/cell-renderer/DropDown';
import UIBuilderTableCellAffiliatedCompanyPersonSelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanyPersonSelector';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';
import UIBuilderTableCellText from '@front/src/components/ui-builder/table/cell-renderer/Text';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';
import type { CollectionHistoryFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/history/hooks/useForm';
import UIBuilderTableCellManagedDropDown from '@front/src/components/ui-builder/table/cell-renderer/ManagedDropDown';
import { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';
import useCollectionClaimDocumentModalStore from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/hooks/useClaimDocumentModalStore';
import { useShallow } from 'zustand/react/shallow';
import useCollectionTaxInvoiceModalStore from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/hooks/useTaxInvoiceModalStore';
import useGetCollectionCellSx from '@front/src/features/project-sales/features/tabs/collection/sections/history/hooks/useGetBackgroundColor';
import dayjs from 'dayjs';
import { ColorPalette } from '@front/assets/theme';
import useCollectionAddModalState from '@front/src/features/project-sales/features/tabs/collection/sections/history/hooks/useModalState';
import useCollectionInnerCellMenuLogic from '@front/src/features/project-sales/features/tabs/collection/sections/history/hooks/useInnerCellMenuLogic';
import CollectionAddModal from '@front/src/features/project-sales/features/tabs/collection/sections/history/components/AddModal';
import { getDiff } from '@front/src/utils';
import useCollectionDueDateHistoryStore from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/hooks/useStore';

interface Props extends CellComponentProps<CollectionHistoryView> {}

/**
 * 계약지불단계
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="paymentStage"
  />
);

/**
 * 계약지불순번
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="paymentSequence"
  />
);
/**
 * 계약발주처
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanySelector
    {...props}
    readOnly
    name="clientCompany"
  />
);

/**
 * 채권상태
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => {
  const { onInnerCellSubmit, item, readOnly } = props;
  const { getSx } = useGetCollectionCellSx();

  const { getValues } = useFormContext<CollectionHistoryFormValues>();

  const { open, onOpen, onClose, collectionId } = useCollectionAddModalState();

  const { options, getMenuItems } = useCollectionInnerCellMenuLogic({
    ...props,
    onOpen,
  });

  const getData = useCallback(() => {
    const collection = getValues('collectionList').find((f) => f.id === collectionId);
    if (collection) {
      return {
        collectionHistoryId: item.id,
        collectionId: collection.id,
        collectionSequence: collection.collectionSequence,
        collectionDueDate: collection.collectionDueDate,
        unpaidAmount: collection.unpaidAmount,
      };
    }
    return null;
  }, [getValues, collectionId]);

  const collectionData = getData();

  return (
    <>
      {open && collectionData && (
        <CollectionAddModal
          {...props}
          open={open}
          onClose={onClose}
          collectionData={collectionData}
        />
      )}
      <InnerCellSupport
        {...props}
        listAttrName="collectionList"
        useContextMenu={!readOnly}
        getContextMenuItems={getMenuItems}
        onSubmit={onInnerCellSubmit}
        getSx={getSx}
      >
        <UIBuilderTableCellDropDown
          {...props}
          name="bondStatus"
          options={options}
          convertObject={bondStatusLabel}
        />
      </InnerCellSupport>
    </>
  );
};

/**
 * 수금번호
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      onSubmit={props.onInnerCellSubmit}
      getSx={getSx}
    >
      <UIBuilderTableCellText
        {...props}
        name="collectionSequence"
      />
    </InnerCellSupport>
  );
};

/**
 * 관계수금번호
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      onSubmit={props.onInnerCellSubmit}
      getSx={getSx}
    >
      <UIBuilderTableCellText
        {...props}
        name="collectionParentSequence"
      />
    </InnerCellSupport>
  );
};

/**
 * 발주처 지불담당자
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => {
  const { readOnly, isEditMode, isForm, onInnerCellSubmit } = props;
  const { getSx } = useGetCollectionCellSx();

  const { getValues } = useFormContext<CollectionHistoryFormValues>();
  const list = getValues('collectionList');

  const renderContent = (index: number) => {
    const clientCompanyPerson = getValues(`collectionList.${index}.clientCompanyPerson`);
    if (readOnly || !isEditMode) return clientCompanyPerson?.name ?? '-';

    return (
      <UIBuilderTableCellAffiliatedCompanyPersonSelector
        {...props}
        companyName="clientCompany"
        personName={`collectionList.${index}.clientCompanyPerson`}
        onSubmit={isForm ? undefined : () => onInnerCellSubmit?.(index)}
      />
    );
  };

  return (
    <>
      {list.map((item, index) => (
        <div
          key={index}
          className="inner-cell"
          style={{
            ...getSx(index),
          }}
        >
          {renderContent(index)}
        </div>
      ))}
    </>
  );
};

/**
 * 수금시기
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      onSubmit={props.onInnerCellSubmit}
      getSx={getSx}
    >
      <UIBuilderTableCellManagedDropDown
        {...props}
        name="collectionTiming"
        code={ManagedVariable.ProjectSalesCollectionTiming}
      />
    </InnerCellSupport>
  );
};

/**
 * 수금응당일
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  const { getValues, setValue } = useFormContext<CollectionHistoryFormValues>();

  const handleAfterChange = useCallback(
    (index: number) => {
      const collectionDueDate = getValues(`collectionList.${index}.collectionDueDate`);
      const collectionCompletionDate = getValues(
        `collectionList.${index}.collectionCompletionDate`
      );

      setValue(
        `collectionList.${index}.collectionDelayDays`,
        calculateCollectionDelayDays(collectionDueDate, collectionCompletionDate)
      );
    },
    [getValues, setValue]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      onSubmit={props.onInnerCellSubmit}
      getSx={getSx}
      onAfterChange={handleAfterChange}
    >
      <UIBuilderTableCellInputDate
        {...props}
        name="collectionDueDate"
      />
    </InnerCellSupport>
  );
};

/**
 * 수금응당일 변경일
 * @param props
 * @constructor
 */
const CellRenderer10 = () => {
  const { setCollectionId } = useCollectionDueDateHistoryStore(
    useShallow((state) => ({
      setCollectionId: state.setCollectionId,
    }))
  );

  const { getValues } = useFormContext<CollectionHistoryFormValues>();
  const list = getValues('collectionList');

  const handleClick = useCallback(
    (id: number) => {
      setCollectionId(id);
    },
    [setCollectionId]
  );

  const renderContent = useCallback(
    (collectionDueDateChangeDate: string | null) => (
      <Box
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        {collectionDueDateChangeDate ?? '-'}
      </Box>
    ),
    []
  );
  const { getSx } = useGetCollectionCellSx();
  return (
    <>
      {list.map((item, index) => (
        <div
          key={index}
          className="inner-cell"
          onClick={() => handleClick(item.id)}
          style={{
            ...getSx(index)
          }}
        >
          {renderContent(item.collectionDueDateChangeDate)}
        </div>
      ))}
    </>
  );
};

/**
 * 수금지연일
 * @param props
 * @constructor
 * @remarks 수금응당일이 (수금완료일 또는 오늘날짜) 보다 이전일 경우 수금응당일과의 차이일수
 */
const CellRenderer11 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  const { getValues } = useFormContext<CollectionHistoryFormValues>();
  const getColor = useCallback(
    (index: number) => {
      const value = getValues(`collectionList.${index}`);

      const convertColor = () => {
        if (value.collectionDelayDays && value.collectionDelayDays > 0)
          return ColorPalette.greyscale.text_secondary;
        return 'default';
      };

      return {
        color: convertColor(),
      };
    },
    [getValues]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      getSx={getSx}
      getColor={getColor}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        readOnly
        name="collectionDelayDays"
      />
    </InnerCellSupport>
  );
};

/**
 * 수금가능성
 * @param props
 * @constructor
 */
const CellRenderer12 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      onSubmit={props.onInnerCellSubmit}
      getSx={getSx}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="collectionPossibility"
        type="percent"
      />
    </InnerCellSupport>
  );
};

/**
 * 수금예정액
 * @param props
 * @constructor
 * @remarks [수금예정액] 중 [수금순번]과 연관된 [관계수금순번]에 해당하는 [수금예정액]을 합계에서 제외하고 [영업집계-자금관리]에서도 해당 [수금예정액]은 제외하여 관리 [수금예정액]의 합계는 반드시 [계약총액]의 합계와 동일해야함. [수금예정액] 합계를 증감하기 위해서는 계약탭에서 새로운 계약을 형성하거나 기존계약을 편집하여 [계약총액]과 일치해야함
 */
const CellRenderer13 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  const { getValues, setValue } = useFormContext<CollectionHistoryFormValues>();
  const handleAfterChange = useCallback(
    (index: number) => {
      const expectedCollectionAmount = getValues(
        `collectionList.${index}.expectedCollectionAmount`
      );
      const collectionAmount = getValues(`collectionList.${index}.collectionAmount`);

      setValue(
        `collectionList.${index}.unpaidAmount`,
        getDiff(expectedCollectionAmount, collectionAmount)
      );
    },
    [setValue, getValues]
  );

  const getColor = useCallback(
    (index: number) => {
      const collectionList = getValues('collectionList');
      const { collectionSequence, unpaidAmount } = collectionList[index];

      const convertColor = () => {
        if (
          unpaidAmount !== null &&
          unpaidAmount > 0 &&
          collectionList.some((s) => s.collectionParentSequence === collectionSequence)
        )
          return ColorPalette.greyscale.text_secondary;
        return 'default';
      };

      return {
        color: convertColor(),
      };
    },
    [getValues, setValue]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      onSubmit={props.onInnerCellSubmit}
      getSx={getSx}
      getColor={getColor}
      onAfterChange={handleAfterChange}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="expectedCollectionAmount"
      />
    </InnerCellSupport>
  );
};

/**
 * 수금액
 * @param props
 * @constructor
 * @remarks [수금액] 합계와 [미수금] 합계의 합은 [계약총액]과 동일 하여야 하며 오류시 오류메세지와 함께 다음단계 진행불가
 */
const CellRenderer14 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  const { getValues, setValue } = useFormContext<CollectionHistoryFormValues>();
  const handleAfterChange = useCallback(
    (index: number) => {
      const expectedCollectionAmount = getValues(
        `collectionList.${index}.expectedCollectionAmount`
      );
      const collectionAmount = getValues(`collectionList.${index}.collectionAmount`);

      setValue(
        `collectionList.${index}.unpaidAmount`,
        getDiff(expectedCollectionAmount, collectionAmount)
      );
    },
    [getValues, setValue]
  );

  const getColor = useCallback(
    (index: number) => {
      const collectionList = getValues('collectionList');
      const { collectionSequence, unpaidAmount } = collectionList[index];

      const convertColor = () => {
        if (
          unpaidAmount !== null &&
          unpaidAmount > 0 &&
          collectionList.some((s) => s.collectionParentSequence === collectionSequence)
        )
          return ColorPalette.greyscale.text_secondary;
        return 'default';
      };

      return {
        color: convertColor(),
      };
    },
    [getValues]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      onSubmit={props.onInnerCellSubmit}
      onAfterChange={handleAfterChange}
      getSx={getSx}
      getColor={getColor}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="collectionAmount"
      />
    </InnerCellSupport>
  );
};

/**
 * 미수금
 * @param props
 * @constructor
 * @remarks [수금예정액] 보다 적은 [수금액]의 경우 해당 [미수금] 차액은새롭계 수금순번을 부여받아 수금예정액을 생성해야 하며 이떼 수금예정액과 수금액에 차액이 발생한 수금순번의 수금예정액은 수금예정액의 합산과 [영업집계-수금관리]에서 해당금액을 제외해야함. 합계에 포함되지 않는 수금예정액은 미색으로 표현하여 구별하되 표현적으로 잔존.
 */
const CellRenderer15 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  const { getValues, setValue } = useFormContext<CollectionHistoryFormValues>();

  const getColor = useCallback(
    (index: number) => {
      const collectionList = getValues('collectionList');
      const { collectionSequence, unpaidAmount } = collectionList[index];

      const convertColor = () => {
        if (
          unpaidAmount !== null &&
          unpaidAmount > 0 &&
          collectionList.some((s) => s.collectionParentSequence === collectionSequence)
        )
          return ColorPalette.greyscale.text_secondary;
        return 'default';
      };

      return {
        color: convertColor(),
      };
    },
    [getValues, setValue]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      getSx={getSx}
      getColor={getColor}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        readOnly
        name="unpaidAmount"
      />
    </InnerCellSupport>
  );
};

/**
 * 수금완료일
 * @param props
 * @constructor
 */
const CellRenderer16 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  const { getValues, setValue } = useFormContext<CollectionHistoryFormValues>();
  const handleAfterChange = useCallback(
    (index: number) => {
      const collectionDueDate = getValues(`collectionList.${index}.collectionDueDate`);
      const collectionCompletionDate = getValues(
        `collectionList.${index}.collectionCompletionDate`
      );

      setValue(
        `collectionList.${index}.collectionDelayDays`,
        calculateCollectionDelayDays(collectionDueDate, collectionCompletionDate)
      );
    },
    [setValue, getValues]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      onSubmit={props.onInnerCellSubmit}
      getSx={getSx}
      onAfterChange={handleAfterChange}
    >
      <UIBuilderTableCellInputDate
        {...props}
        name="collectionCompletionDate"
      />
    </InnerCellSupport>
  );
};

/**
 * 수금담당자
 * @param props
 * @constructor
 */
const CellRenderer17 = (props: Props) => {
  const { getSx } = useGetCollectionCellSx();

  return (
    <InnerCellSupport
      {...props}
      listAttrName="collectionList"
      onSubmit={props.onInnerCellSubmit}
      getSx={getSx}
    >
      <UIBuilderTableCellUserSelector
        {...props}
        name="manager"
      />
    </InnerCellSupport>
  );
};

/**
 * 청구공문
 * @param props
 * @constructor
 */
const CellRenderer18 = (props: Props) => {
  const { sx, readOnly, isEditMode } = props;
  const { getSx } = useGetCollectionCellSx();

  const { setCollectionId, onOpen } = useCollectionClaimDocumentModalStore(
    useShallow((state) => ({
      setCollectionId: state.setCollectionId,
      onOpen: state.onOpen,
    }))
  );

  const { getValues } = useFormContext<CollectionHistoryFormValues>();
  const list = getValues('collectionList');

  const handleClick = useCallback(
    (id: number) => {
      setCollectionId(id);
      onOpen();
    },
    [setCollectionId, onOpen]
  );

  const renderReadOnlyContent = useCallback(
    (claimDocument: string | null, onClick: () => void) => (
      <Box
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        {claimDocument ?? '-'}
      </Box>
    ),
    []
  );

  const renderEditModeContent = useCallback(
    (handleClick: () => void) => (
      <Box sx={sx}>
        <ButtonBasicUI
          shape="ghost"
          size="small"
          sx={{ minWidth: '5rem' }}
          onClick={handleClick}
        >
          등록
        </ButtonBasicUI>
      </Box>
    ),
    []
  );

  return (
    <>
      {list.map((item, index) => {
        const handleContentClick = () => {
          handleClick(item.id);
        };

        return (
          <div
            key={index}
            className="inner-cell"
            onClick={handleContentClick}
            style={{
              ...getSx(index),
            }}
          >
            {readOnly || !isEditMode || !item.isActive
              ? renderReadOnlyContent(item.claimDate, handleContentClick)
              : renderEditModeContent(handleContentClick)}
          </div>
        );
      })}
    </>
  );
};

/**
 * 세금계산서
 * @param props
 * @constructor
 */
const CellRenderer19 = (props: Props) => {
  const { sx, readOnly, isEditMode } = props;
  const { getSx } = useGetCollectionCellSx();

  const { setCollectionId, onOpen } = useCollectionTaxInvoiceModalStore(
    useShallow((state) => ({
      setCollectionId: state.setCollectionId,
      onOpen: state.onOpen,
    }))
  );

  const { getValues } = useFormContext<CollectionHistoryFormValues>();
  const list = getValues('collectionList');

  const handleClick = useCallback(
    (id: number) => {
      setCollectionId(id);
      onOpen();
    },
    [setCollectionId, onOpen]
  );

  const renderReadOnlyContent = useCallback(
    (claimDocument: string | null, onClick: () => void) => (
      <Box
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        {claimDocument ?? '-'}
      </Box>
    ),
    []
  );

  const renderEditModeContent = useCallback(
    (handleClick: () => void) => (
      <Box sx={sx}>
        <ButtonBasicUI
          shape="ghost"
          size="small"
          sx={{ minWidth: '5rem' }}
          onClick={handleClick}
        >
          등록
        </ButtonBasicUI>
      </Box>
    ),
    []
  );

  return (
    <>
      {list.map((item, index) => {
        const handleContentClick = () => {
          handleClick(item.id);
        };

        return (
          <div
            key={index}
            className="inner-cell"
            onClick={handleContentClick}
            style={{
              ...getSx(index),
            }}
          >
            {readOnly || !isEditMode || !item.collectionAmount
              ? renderReadOnlyContent(item.invoiceDate, handleContentClick)
              : renderEditModeContent(handleContentClick)}
          </div>
        );
      })}
    </>
  );
};

export const collectionHistoryTableCells: CellComponent = {
  621: CellRenderer1, // 계약지불단계
  622: CellRenderer2, // 계약지불순번
  623: CellRenderer3, // 계약발주처
  624: CellRenderer4, // 채권상태
  625: CellRenderer5, // 수금순번
  626: CellRenderer6, // 관계수금순번
  627: CellRenderer7, // 발주처 지불담당자
  628: CellRenderer8, // 수금시기
  629: CellRenderer9, // 수금응당일
  630: CellRenderer10, // 수금응당일 변경일
  631: CellRenderer11, // 수금지연일
  632: CellRenderer12, // 수금가능성
  633: CellRenderer13, // 수금예정액
  634: CellRenderer14, // 수금액
  635: CellRenderer15, // 미수금
  637: CellRenderer18, // 청구공문
  636: CellRenderer16, // 수금완료일
  638: CellRenderer17, // 수금담당자
  639: CellRenderer18, // 청구공문
  640: CellRenderer19, // 세금계산서
  ...generateSpareAttrCellRenders(641),
};

const calculateCollectionDelayDays = (
  collectionDueDate: string | null,
  collectionCompletionDate: string | null
) => {
  if (collectionDueDate === null) return null;

  const compareTarget = collectionCompletionDate
    ? dayjs(collectionCompletionDate)
    : dayjs(Date.now());

  return dayjs(compareTarget).diff(collectionDueDate, 'day');
};
