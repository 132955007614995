import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import React from 'react';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';

import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';

import type { CollectionTaxInvoiceFormListValues } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/hooks/useForm';
import { useSnackbar } from 'notistack';
import { checkByteValidation } from '@front/src/utils';
import HookFormWithFile from '@front/src/components/hook-form/File';

interface Props extends CellComponentProps<CollectionTaxInvoiceFormListValues> {}

/**
 * 날짜
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => {
  const { rowIndex, formUpdateFormContext } = props;

  const { setValue, getValues } = formUpdateFormContext!;
  const handleAfterChange = () => {
    setValue('lastUpdatedId', getValues(`list.${rowIndex}`)?.id ?? null);
  };

  return (
    <UIBuilderTableCellInputDate
      {...props}
      name={`list.${rowIndex}.invoiceDate`}
      onAfterChange={handleAfterChange}
    />
  );
};

/**
 * 발송자
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => {
  const { rowIndex, formUpdateFormContext } = props;

  const { setValue, getValues } = formUpdateFormContext!;
  const handleAfterChange = () => {
    setValue('lastUpdatedId', getValues(`list.${rowIndex}`)?.id ?? null);
  };

  return (
    <UIBuilderTableCellUserSelector
      {...props}
      name={`list.${rowIndex}.writer`}
      onAfterChange={handleAfterChange}
    />
  );
};

/**
 * 파일
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => {
  const { rowIndex, formUpdateFormContext } = props;

  const { setValue, getValues } = formUpdateFormContext!;
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (e) => {
    const [file] = e.target.files;

    if (checkByteValidation(file.size, 'MB', 10)) {
      enqueueSnackbar('첨부 가능한 최대 용량은 10MB입니다.', {
        variant: 'warning',
      });
      return;
    }

    if (file.name.split('.').at(-1) !== 'pdf') {
      enqueueSnackbar(
        '첨부 가능한 파일 형식이 아닙니다. 파일 형식을 확인하고 다시 시도해 주세요.',
        {
          variant: 'warning',
        }
      );
      return;
    }

    setValue(`list.${rowIndex}.fileItem`, {
      filename: file.name,
      multipartFile: file,
      isNew: true,
    });

    setValue('lastUpdatedId', getValues(`list.${rowIndex}`)?.id ?? null);
  };

  return (
    <HookFormWithFile
      {...props}
      name={`list.${rowIndex}.fileItem`}
      onChange={handleChange}
      formContext={formUpdateFormContext!}
    />
  );
};

export const collectionTaxInvoiceTableCells: CellComponent = {
  654: CellRenderer1, // 날짜
  655: CellRenderer2, // 발송자
  656: CellRenderer3, // 파일
  ...generateSpareAttrCellRenders(657),
};
