import React, { type ChangeEvent, useRef } from 'react';
import classes from '@front/src/components/file-uploader/file-uploader.module.scss';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import TextLink from '@front/layouts/TextLink';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from '@mui/material/Box';
import { useCustomDialog } from '@front/src/features/dialog';
import type { FileItemFormValues } from '@front/src/components/hook-form/File';

interface Props {
  value: FileItemFormValues;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  onDownload: () => void;
  disabled?: boolean;
}

export default function FileUI({
  value,
  onChange,
  onDelete,
  onDownload,
  disabled,
}: Readonly<Props>) {
  const ref = useRef<HTMLInputElement | null>(null);
  const handleClick = () => {
    ref.current?.click();
  };

  const { confirm } = useCustomDialog();
  const handleDelete = () => {
    confirm({
      lineBreakChildren: [{ value: '삭제하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        onDelete();
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        flexWrap: 'nowrap',
        width: '100%',
      }}
    >
      <input
        ref={ref}
        hidden
        type="file"
        onChange={onChange}
        className={classes.input}
      />
      <ButtonBasicUI
        shape="ghost"
        size="medium"
        disabled={disabled}
        onClick={handleClick}
        sx={{
          minWidth: '78px',
        }}
      >
        파일 선택
      </ButtonBasicUI>
      {value && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: '200px',
            fontSize: '12px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        >
          <TextLink onClick={onDownload}>{value.filename}</TextLink>
          {!disabled && value.filename && <DeleteForeverIcon onClick={handleDelete} />}
        </Box>
      )}
    </Box>
  );
}
