import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import useHistoryForm from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useTableRowForm';

export { TableBody as EstimationFinalTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<EstimationHistoryView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<EstimationHistoryView>) {
  const { methods } = useHistoryForm(props.item);

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow {...props} />
    </FormProvider>
  );
}
