import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type {
  ContractClientCmGetMappedByContractClientIdDto,
  ContractCmObjectView,
} from '@front/src/features/project-sales/features/tabs/contract/sections/cm/types/views';
import { commonApi } from '@front/src/api/api';

const contractCmQuery = {
  useGetObject: (commonParams: UIBuilderParameter, clientId: number) => {
    const url = `/api/project/sales/sales-information/contract/contact-client/${clientId}/contract-cm`;
    const queryKey = [
      'project',
      'sales',
      commonParams.dataId,
      'contract',
      'history',
      clientId,
      'cm',
    ];

    const enabled = !!commonParams.dataId && !!clientId;

    const { data } = useQuery<
      ContractClientCmGetMappedByContractClientIdDto,
      Error,
      ContractCmObjectView
    >({
      queryFn: () => commonApi.getListByParams(url, commonParams),
      queryKey,
      suspense: true,
      enabled,
      select: (data) => {
        const transformedData: ContractCmObjectView = {};

        for (const [key, value] of Object.entries(data.cmMap)) {
          transformedData[key] = {
            contractClientId: value?.[0].contractClientId,
            contractClientCompany: value?.[0].contractClientCompany,
            list: value?.filter((v) => !!v.id) ?? [],
          };
        }

        return transformedData;
      },
    });

    return data;
  },
};

export default contractCmQuery;
