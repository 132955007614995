import React, { useMemo } from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import CollectionClaimDocumentTableHead from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/components/TableHead';
import CollectionClaimDocumentTableBody from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/components/TableBody';
import { useIsMutating } from 'react-query';
import { useFieldArray, useFormContext } from 'react-hook-form';
import type { CollectionClaimDocumentFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/hooks/useForm';
import type { CollectionClaimDocumentModalProps } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/components/Modal';

export default function CollectionClaimDocumentTable(props: CollectionClaimDocumentModalProps) {
  const { dataId, collectionId } = props;

  const formContext = useFormContext<CollectionClaimDocumentFormValues>();

  const filters = useMemo(
    () => ({
      mutationKey: [
        'project',
        'sales',
        dataId,
        'collection',
        collectionId,
        'claim-document',
        'update',
      ],
    }),
    [dataId, collectionId]
  );

  const isLoading = !!useIsMutating(filters);

  const fieldArray = useFieldArray<CollectionClaimDocumentFormValues, 'list', string>({
    control: formContext.control,
    name: 'list',
    keyName: 'fieldId',
  });

  return (
    <UiBuilderTable
      {...props}
      useFormUpdate
      formUpdateFormContext={formContext}
      isLoading={isLoading}
      HeadComponent={(headProps) => (
        <CollectionClaimDocumentTableHead
          {...headProps}
          fieldArray={fieldArray}
        />
      )}
      BodyComponent={(bodyProps) => (
        <CollectionClaimDocumentTableBody
          {...bodyProps}
          fieldArray={fieldArray}
        />
      )}
      list={fieldArray.fields}
    />
  );
}
