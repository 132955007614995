import React, { useMemo } from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { CollectionFinalTableBody } from '@front/src/features/project-sales/features/tabs/collection/sections/final/components/table-body';
import collectionFinalQuery from '@front/src/features/project-sales/features/tabs/collection/sections/final/query/query';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';

export { Table as CollectionFinalTable };

function Table(props: SectionComponentProps) {
  const { dataId, menuId, sectionId } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);
  const list = collectionFinalQuery.useGet(commonParams);

  return (
    <UiBuilderTable
      {...props}
      HeadComponent={UIBuilderTableHeader}
      BodyComponent={CollectionFinalTableBody}
      readOnly // 읽기모드에서는 동작버튼[:]을 제공하지 않는다
      useNote={false} // 노트 사용 여부
      useSummary // 합산행 사용 여부
      list={list}
    />
  );
}
