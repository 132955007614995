import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type {
  ContractHistoryView,
  EstimationShortDto,
} from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';
import { commonApi } from '@front/src/api/api';

const contractHistoryQuery = {
  useGetList: (commonParams: UIBuilderParameter) => {
    const url = `/api/project/sales/sales-information/${commonParams.dataId}/contract`;
    const queryKey = ['project', 'sales', commonParams.dataId, 'contract', 'history'];
    const enabled = !!commonParams.dataId && !!commonParams.menuId;

    const { data } = useQuery<ContractHistoryView[], AxiosError, ContractHistoryView[]>({
      queryFn: () => getHistory(url, commonParams),
      queryKey,
      suspense: true,
      enabled,
    });
    return data;
  },

  useGetContractList: (commonParams: UIBuilderParameter) => {
    const url = `/api/project/sales/sales-information/${commonParams.dataId}/estimation-short`;
    const queryKey = [
      'project',
      'sales',
      commonParams.dataId,
      'contract',
      'history',
      'estimation-short',
    ];
    const enabled = !!commonParams.dataId && !!commonParams.menuId;

    const { data } = useQuery<EstimationShortDto[], AxiosError, EstimationShortDto[]>({
      queryFn: async () => {
        const { data } = await axios.get(url);

        return data;
      },
      queryKey,
      suspense: true,
      enabled,
    });

    return data;
  },
};

export default contractHistoryQuery;

const getHistory = async (url, commonParams) => {
  const data = await commonApi.getListByParams(url, commonParams);

  const mapped = [...data].map((d) => {
    const copy = [...d.contractClient];

    return {
      ...d,
      ...d.contract,
      contractClient: copy.sort((a, b) => a.seq - b.seq),
    };
  });

  return mapped.sort((a, b) => a.contract.seq - b.contract.seq);
};
