import { useMutation, useQueryClient } from 'react-query';
import type { IdsDataParameter, UIBuilderParameter } from '@front/src/types/parameter';
import axios from 'axios';
import type {
  CollectionAddParameter,
  CollectionUpdateParameter,
} from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';
import { commonApi } from '@front/src/api/api';

const collectionHistoryCollectionMutation = {
  useAdd: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const url = `/api/project/sales/sales-information/collection-history/collection`;
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, CollectionAddParameter>({
      mutationFn: async (params) => {
        const { data } = await axios.post(url, params, {
          headers: getMenuIdHeaders(commonParams.menuId),
        });

        return data;
      },
      mutationKey: [...queryKey, 'add'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getQueryKeyForInvalidates(commonParams.dataId),
        });
      },
    });

    return mutate;
  },

  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const url = `/api/project/sales/sales-information/collection-history/collection`;
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, CollectionUpdateParameter>({
      mutationFn: (params) => commonApi.update(url, params, commonParams),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getQueryKeyForInvalidates(commonParams.dataId),
        });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales', commonParams.dataId, 'collection', 'final'],
        });
      },
    });

    return mutate;
  },

  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const url = `/api/project/sales/sales-information/collection-history/collection`;
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, IdsDataParameter>({
      mutationFn: (params) => commonApi.delete(url, params, commonParams),
      mutationKey: [...queryKey, 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getQueryKeyForInvalidates(commonParams.dataId),
        });
      },
    });

    return mutate;
  },
};

export default collectionHistoryCollectionMutation;

const getQueryKey = (dataId: number | undefined) => [
  'project',
  'sales',
  dataId,
  'collection',
  'history',
  'collection',
];

const getQueryKeyForInvalidates = (dataId: number | undefined) => [
  'project',
  'sales',
  dataId,
  'collection',
  'history',
];
