import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AccountingBudgetForm } from '@front/src/features/accounting/features/budget/type';
import { DefaultSectionComponent } from '@front/type/Function';
import useAccountingBudgetSummaryTableHook from '@front/src/features/accounting/features/budget/hook/summary-table';
import AccountingBudgetTable from '@front/src/features/accounting/features/budget/component/common/table';

const AccountingBudgetSummaryTable: DefaultSectionComponent = (props) => {

  const {
    methods,
    reflectBudgetYear,
    reflectActivatedManagement,
  } = useAccountingBudgetSummaryTableHook();

  const { watch: watchParent } = useFormContext<AccountingBudgetForm>();
  const budgetYear = watchParent('budgetYear');
  const activatedManagement = watchParent('activatedManagement');

  useEffect(() => {
    reflectBudgetYear(budgetYear);
  }, [budgetYear]);

  useEffect(() => {
    reflectActivatedManagement(activatedManagement);
  }, [activatedManagement]);

  if (!activatedManagement || activatedManagement.depth !== 4) {
    return (<></>);
  }

  return (
    <AccountingBudgetTable
      {...props}
      methods={methods}
    />
  );
};

export default AccountingBudgetSummaryTable;
