import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import ContextMenuNewIconUI from '@front/src/components/components-with-design/compound/ContextMenuNewIconUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import type { CollectionAddFormProps } from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/components/ModalBody';
import { getCollectionDefaultRow } from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/utils';
import InputWithHookForm from '@front/src/components/hook-form/Input';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';
import Box from '@mui/material/Box';
import { getRatio, multipleNumberAndRatio } from '@front/src/utils';

interface Props extends CollectionAddFormProps {
  index: number;
}

export default function CollectionAddTableListItem(props: Props) {
  const { fieldArray, index, formContext, readOnly } = props;

  const { getValues } = formContext;
  const { fields, remove, insert, update, replace } = fieldArray;
  const field = fields[index];

  const itemList = [
    {
      text: '추가',
      IconComponent: <FontAwesomeIcon icon="plus" />,
      action: () => {
        const defaultRow = getCollectionDefaultRow();
        insert(index + 1, defaultRow);
      },
    },
    {
      text: '삭제',
      IconComponent: <FontAwesomeIcon icon="trash" />,
      action: () => {
        remove(index);
      },
    },
  ];

  const handleAfterChange = () => {
    const list = getValues(`list`);
    const sorted = [...list].sort((a, b) => {
      if (!a.collectionDueDate || !b.collectionDueDate) return 0;
      return a.collectionDueDate > b.collectionDueDate ? -1 : 1;
    });
    replace(sorted);
  };

  const handleUnpaidAmountBlur = () => {
    const item = getValues(`list.${index}`);
    const collectionData = getValues('collectionData');

    const ratio = getRatio(item.expectedCollectionAmount, collectionData.unpaidAmount);

    update(index, {
      ...item,
      ratio,
    });
  };

  const handleRatioBlur = () => {
    const item = getValues(`list.${index}`);
    const collectionData = getValues('collectionData');

    update(index, {
      ...item,
      expectedCollectionAmount: multipleNumberAndRatio(collectionData.unpaidAmount, item.ratio),
    });
  };

  return (
    <TableRowUI key={field.id}>
      <TableCellUI>
        <ContextMenuNewIconUI itemList={itemList} />
      </TableCellUI>
      <TableCellUI>
        <DatePickerWithHookForm
          readOnly={readOnly}
          name={`list.${index}.collectionDueDate`}
          formContext={formContext}
          onAfterChange={handleAfterChange}
        />
      </TableCellUI>
      <TableCellUI>
        <InputWithHookForm
          readOnly={readOnly}
          name={`list.${index}.expectedCollectionAmount`}
          onBlur={handleUnpaidAmountBlur}
          formContext={formContext}
        />
      </TableCellUI>
      <TableCellUI>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InputWithHookForm
            readOnly={readOnly}
            name={`list.${index}.ratio`}
            onBlur={handleRatioBlur}
            formContext={formContext}
          />
          %
        </Box>
      </TableCellUI>
    </TableRowUI>
  );
}
