import type { ContractCmFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/hooks/useTabForm';
import { v4 as uuid } from 'uuid';
import type {
  ContractCmObjectView,
  ContractCmView,
} from '@front/src/features/project-sales/features/tabs/contract/sections/cm/types/views';

const convertList = (clientId: number, listData: ContractCmView[], contractClientCompany) =>
  listData.map((item) => ({
    ...item,
    id: uuid(),
    rowId: item.id,
    parentId: clientId,
    paymentStage: item.paymentStage ?? '',
    cmMethod: item.cmMethod ?? '',
    cmPaymentTiming: item.cmPaymentTiming ?? '',
    cmRecipientCompany: contractClientCompany,
  }));

export const getContractCmFormUpdateValues = (
  clientId: number,
  dataObject: ContractCmObjectView
): Omit<
  ContractCmFormValues,
  'defaultClientId' | 'editOpenIdList' | 'lastUpdatedId' | 'totalContractPrice'
> => {
  const stringClientId = clientId.toString();

  const contractClientCompany = dataObject[stringClientId]?.contractClientCompany || null;

  return {
    clientId,
    contractClientCompany,
    list: convertList(clientId, dataObject[stringClientId]?.list || [], contractClientCompany),
    deleteList: [],
  };
};
