import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DeleteSweep, Download } from '@mui/icons-material';
import { useCustomDialog } from '@front/src/features/dialog';
import UIBuilderTableHeader, {
  type UIBuilderTableHeadProps,
} from '@front/src/components/ui-builder/table/Header';
import { getCollectionTaxInvoiceFormDefaultRow } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/utils';
import { onDownloadByFileUrls } from '@front/src/utils';
import type { UseFieldArrayReturn } from 'react-hook-form';
import type { CollectionTaxInvoiceFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/hooks/useForm';

interface Props extends Omit<UIBuilderTableHeadProps, 'list'> {
  fieldArray: UseFieldArrayReturn<CollectionTaxInvoiceFormValues, 'list', string>;
}

export default function CollectionTaxInvoiceTableHead(props: Props) {
  const { fieldArray, formUpdateFormContext } = props;

  const { setValue, getValues } = formUpdateFormContext!;
  const { prepend, append } = fieldArray;

  const handleAddTop = () => {
    const defaultRow = getCollectionTaxInvoiceFormDefaultRow();
    prepend(defaultRow);
    setValue('lastUpdatedId', defaultRow.id);
    setValue('editOpenIdList', [...getValues('editOpenIdList'), defaultRow.id]);
  };

  const handleAddBottom = () => {
    const defaultRow = getCollectionTaxInvoiceFormDefaultRow();
    append(defaultRow);
    setValue('lastUpdatedId', defaultRow.id);
    setValue('editOpenIdList', [...getValues('editOpenIdList'), defaultRow.id]);
  };

  const handleCloseAllEditOpen = () => {
    setValue('editOpenIdList', []);
  };

  const { confirm } = useCustomDialog();

  const handleAfterConfirm = () => {
    setValue('deleteList', [...getValues('list')]);
    setValue('list', []);
    setValue('lastUpdatedId', null);
    setValue('editOpenIdList', []);
  };

  const handleRemoveAll = () => {
    confirm({
      lineBreakChildren: [{ value: '전체 삭제 하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        handleAfterConfirm();
      },
    });
  };

  const handleDownloadAll = () => {
    onDownloadByFileUrls({
      fileUrls: getValues('list')
        .filter((f) => !!f.fileItem?.id)
        .map((f) => `/api/file-item/${f.fileItem.id}`),
    });
  };

  const itemList = [
    {
      text: '최상단에 추가',
      icon: AddCircleOutlineIcon,
      action: handleAddTop,
      seq: 4,
    },
    {
      text: '최하단에 추가',
      icon: AddCircleOutlineIcon,
      action: handleAddBottom,
      seq: 5,
    },
    {
      text: '전체 수정 닫기',
      icon: HighlightOffIcon,
      action: handleCloseAllEditOpen,
      seq: 98,
    },
    {
      text: '전체 다운로드',
      icon: Download,
      action: handleDownloadAll,
      seq: 99,
    },
    {
      text: '전체 삭제',
      icon: DeleteSweep,
      action: handleRemoveAll,
      iconColor: '#e43333',
      seq: 100,
    },
  ];

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={itemList}
    />
  );
}
