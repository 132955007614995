import { useForm } from 'react-hook-form';
import type { ContractFormUpdateFormValues } from '@front/src/features/project-sales/features/tabs/contract/types';
import type {
  ContractPaymentTermObjectView,
  ContractPaymentTermView,
} from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/types/views';
import { getContractPaymentTermTabFormUpdateValues } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/utils';
import { useEffect } from 'react';
import type {
  ContractClientQueryDto,
  ContractHistoryView,
} from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

const useContractPaymentTermTabForm = (
  defaultClientId: number,
  dataObject: ContractPaymentTermObjectView | undefined,
  historyList: ContractHistoryView[] | undefined
) => {
  const methods = useForm<ContractPaymentTermFormValues>({
    defaultValues: getDefaultValues(defaultClientId, dataObject, historyList),
  });

  const { watch, getValues, reset } = methods;

  useEffect(() => {
    const watchClientId = watch('clientId');
    if (dataObject && watchClientId) {
      const { clientId, list, deleteList, contractClientCompany, remainsList } =
        getContractPaymentTermTabFormUpdateValues(watchClientId, dataObject);

      reset({
        lastUpdatedId: getValues('lastUpdatedId'),
        editOpenIdList: getValues('editOpenIdList'),
        parent: getValues('parent'),
        clientId,
        list,
        deleteList,
        contractClientCompany,
        remainsList,
      });
    }
  }, [dataObject, reset, getValues]);

  return methods;
};

export default useContractPaymentTermTabForm;

const getDefaultValues = (
  clientId: number,
  dataObject: ContractPaymentTermObjectView | undefined,
  historyList: ContractHistoryView[] | undefined
): ContractPaymentTermFormValues => {
  const find = historyList?.flatMap((f) => f.contractClient).find((f) => f.id === clientId);

  return {
    ...getContractPaymentTermTabFormUpdateValues(clientId, dataObject ?? {}),
    lastUpdatedId: null,
    editOpenIdList: [],
    parent: find ?? null,
  };
};

export interface ContractPaymentTermFormValues
  extends ContractFormUpdateFormValues<ContractPaymentTermListFormValues> {
  remainsList: ContractPaymentTermListFormValues[];
  parent: ContractClientQueryDto | null;
}

export interface ContractPaymentTermListFormValues extends Omit<ContractPaymentTermView, 'id'> {
  id: string;
  rowId: number | null;
  parentId: number | null;
}
