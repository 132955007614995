import React from 'react';
import { DefaultSectionComponent } from '@front/type/Function';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import DropDownUI from '@front/src/components/components-with-design/component/DropDownUI';
import { range } from '@front/util/ArrayUtil';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { AccountingBudgetForm } from '@front/src/features/accounting/features/budget/type';

/**
 *
 * 해당 항목은 실제 기획 요구사항에 포함된 항목이 아님<br>
 * 오로지 개발 단계에서 기간 조회를 위해 구현된 장치이며, 이후 삭제 또는 변경될 여지가 있음<br>
 * 따라서 기존 구 기능을 사용하였음을 메모로 남김.
 *
 *
 *  */
const AccountingBudgetBudgetYearFilter: DefaultSectionComponent = () => {

  const { watch, setValue } = useFormContext<AccountingBudgetForm>();
  const budgetYear = watch('budgetYear');

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'noWrap',
        padding: '10px 0',
        width: '10dvw',
        height: '2dvh',
      }}
    >
      <TableTableUI>
        <TableBodyUI>
          <TableRowUI>
            <TableCellUI
              isHead
              sx={{
                width: '50%',
                borderRadius: '5px',
              }}
            >
              생성 연도
            </TableCellUI>
            <TableCellUI sx={{ width: '50%' }}>
              <DropDownUI
                name="budgetYear"
                defaultLabel="선택"
                value={budgetYear}
                options={Array.from(
                  range(
                    dayjs().subtract(10, 'year').year(),
                    dayjs().add(10, 'year').year(),
                  )
                    .map(year => ({
                        label: `${year}년`,
                        value: year,
                      }),
                    ),
                )}
                onChange={(e) => {
                  const value = e.target.value;
                  if (typeof value === 'number' && value >= 2025) {
                    setValue('budgetYear', value);
                  }
                }}
              />
            </TableCellUI>
          </TableRowUI>
        </TableBodyUI>
      </TableTableUI>
    </Box>
  );
};

export default AccountingBudgetBudgetYearFilter;
