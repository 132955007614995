import React, { Suspense } from 'react';
import ModalUI, {
  ModalBodyUI,
  ModalContentUI,
} from '@front/src/components/components-with-design/layout/ModalUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import CollectionDueDateHistoryTable from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/copmonents/Table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

export interface CollectionDueDateHistoryModalProps extends SectionComponentProps {
  open: boolean;
  collectionId: number;
  onClose: () => void;
}

export default function CollectionDueDateHistoryModal(props: CollectionDueDateHistoryModalProps) {
  const { open, onClose } = props;

  return (
    <ModalUI
      title="수금응당일 변경일 상세"
      onClose={onClose}
      open={open}
      allowOverflowComponent={true} // 모달 내부에서 date-picker 오동작 처리
      disableEnforceFocus={true} // 모달에서 비고 사용시 form 요소 포커스 안되는 문제 처리
    >
      <ModalBodyUI>
        <ModalContentUI>
          <Suspense fallback={<LoadingSpinnerUI />}>
            <CollectionDueDateHistoryTable {...props} />
          </Suspense>
        </ModalContentUI>
      </ModalBodyUI>
    </ModalUI>
  );
}
