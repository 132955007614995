import React, { Suspense, useCallback } from 'react';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { collectionDeuDateHistoryTableCells } from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/copmonents/TableCell';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import CollectionDueDateHistoryModal from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/copmonents/ChangeDateModal';
import useCollectionDueDateHistoryStore from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/hooks/useStore';
import { useShallow } from 'zustand/react/shallow';

export default function CollectionDueDateHistorySection(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(collectionDeuDateHistoryTableCells);

  const { collectionId, setCollectionId } = useCollectionDueDateHistoryStore(
    useShallow((state) => ({
      collectionId: state.collectionId,
      setCollectionId: state.setCollectionId,
    }))
  );

  const open = !!collectionId;
  const onClose = useCallback(() => {
    setCollectionId(null);
  }, [setCollectionId]);

  return (
    <Suspense fallback={<LoadingSpinnerUI />}>
      {collectionId && (
        <CollectionDueDateHistoryModal
          {...props}
          open={open}
          onClose={onClose}
          collectionId={collectionId}
        />
      )}
    </Suspense>
  );
}
