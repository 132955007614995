import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import type { AffiliatedCompanyPersonShortView } from '@front/src/features/affiliated-company/features/person/types/view';
import type { UserNameView } from '@front/src/types';
import type { SpareParameter } from '@front/src/types/parameter';

export interface CollectionHistoryView extends SpareParameter {
  id: number; // 고유 ID
  paymentStage: string | null; // 계약지불단계
  paymentSequence: number; // 계약지불순번
  clientCompany: AffiliatedCompanyShortView | null; // 계약발주처
  isActive: boolean | null;
  noteCount: number;

  collectionList: CollectionHistoryItemView[];
}

export interface CollectionHistoryItemView {
  id: number; // 고유 ID
  seq: number;
  bondStatus: BondStatus | null; // 채권상태
  collectionSequence: string | null; // 수금순번
  collectionParentSequence: string | null;
  clientCompanyPerson: AffiliatedCompanyPersonShortView | null; // 발주처 지불담당자
  collectionTiming: string | null; // 수금시기
  collectionDueDate: string | null; // 수금응당일
  collectionDelayDays: number | null; // 수금지연일
  collectionPossibility: number | null; // 수금가능성
  expectedCollectionAmount: number | null; // 수금예정액
  collectionAmount: number | null; // 수금액
  unpaidAmount: number | null; // 미수금
  collectionCompletionDate: string | null; // 수금완료일
  manager: UserNameView | null; // 수금담당자
  isActive: boolean | null;

  collectionDueDateChangeDate: string | null; // 수금응당일 변경일
  claimDate: string | null;
  invoiceDate: string | null;
}

export const bondStatus = {
  NORMAL: 'NORMAL',
  DELAY: 'DELAY',
  IMPAIRMENT: 'IMPAIRMENT',
  NPL: 'NPL',
  LOSS: 'LOSS',
  INACTIVE: 'INACTIVE',
} as const;

export type BondStatus = (typeof bondStatus)[keyof typeof bondStatus];

export const bondStatusLabel: Record<BondStatus, string> = {
  [bondStatus.NORMAL]: '정상',
  [bondStatus.DELAY]: '지연',
  [bondStatus.IMPAIRMENT]: '부실',
  [bondStatus.NPL]: '악성',
  [bondStatus.LOSS]: '망실',
  [bondStatus.INACTIVE]: '비활성화',
};
