import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import React, { useCallback, useMemo } from 'react';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';
import UIBuilderTableCellAffiliatedCompanyPersonSelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanyPersonSelector';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';
import UIBuilderTableCellFileAttachment from '@front/src/components/ui-builder/table/cell-renderer/FileAttachment';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellInputText from '@front/src/components/ui-builder/table/cell-renderer/InputText';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellDropDown from '@front/src/components/ui-builder/table/cell-renderer/DropDown';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import InnerCellSupport from '@front/src/components/ui-builder/table/InnerCellSupport';
import UIBuilderTableCellText from '@front/src/components/ui-builder/table/cell-renderer/Text';
import { ArithmeticOperator, createDropdownOptions, getDiff, getRatio } from '@front/src/utils';
import estimationHistoryClientMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/clientMutation';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import useEstimationHistoryClientUpdate from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useClientUpdate';
import useUIBuilderTableRowChildMutation from '@front/src/hooks/ui-builder-table/useTableRowChildMutation';
import UIBuilderTableCellManagedDropDown from '@front/src/components/ui-builder/table/cell-renderer/ManagedDropDown';
import { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import type { EstimationHistoryFormValues } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useTableRowForm';
import {
  calculateActualUnitPrice,
  calculateEstimationActualWindTunnelPrice,
} from '@front/src/features/project-sales/features/tabs/estimation/sections/history/utils';
import { useSnackbar } from 'notistack';

interface Props extends CellComponentProps<EstimationHistoryView> {}

/**
 * 확정여부
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellDropDown
    {...props}
    name="isConfirmed"
    options={createDropdownOptions(['Y', 'N'])}
  />
);

/**
 * 견적 No.
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="seq"
  />
);

/**
 * 견적서번호
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="code"
  />
);

/**
 * 견적일자
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="estimationDate"
  />
);

/**
 * 견적구분
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => (
  <UIBuilderTableCellManagedDropDown
    {...props}
    name="type"
    code={ManagedVariable.ProjectSalesEstimationType}
  />
);

/**
 * 견적상태
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => (
  <UIBuilderTableCellManagedDropDown
    {...props}
    name="status"
    code={ManagedVariable.ProjectSalesEstimationStatus}
  />
);

/**
 * 수주가능성
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => (
  <UIBuilderTableCellManagedDropDown
    {...props}
    name="possibilityOfOrder"
    code={ManagedVariable.ProjectSalesEstimationPossibilityOfOrder}
  />
);

/**
 * 프로젝트 난이도
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => (
  <UIBuilderTableCellManagedDropDown
    {...props}
    name="projectDifficulty"
    code={ManagedVariable.ProjectSalesEstimationProjectDifficulty}
  />
);

/**
 * 모형 난이도
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => (
  <UIBuilderTableCellManagedDropDown
    {...props}
    name="modelDifficulty"
    code={ManagedVariable.ProjectSalesEstimationModelDifficulty}
  />
);

/**
 * 견적요청사
 * @param props
 * @constructor
 */
const CellRenderer10 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanySelector
    {...props}
    name="estimationRequestCompany"
  />
);

/**
 * 견적요청사인명
 * @param props
 * @constructor
 */
const CellRenderer11 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanyPersonSelector
    {...props}
    companyName="estimationRequestCompany"
    personName="estimationRequestCompanyPerson"
  />
);

/**
 * 요청사 차기연락일
 * @param props
 * @constructor
 */
const CellRenderer12 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="nextContactDateWithRequestCompany"
  />
);

/**
 * 발주처
 * @param props
 * @constructor
 */
const CellRenderer13 = (props: Props) => {
  const { dataId, menuId, sectionId, formContext, item, readOnly } = props;
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);
  const { onAdd, onUpdateSeq, onDelete } = useUIBuilderTableRowChildMutation({
    onAdd: estimationHistoryClientMutation.useAdd(commonParams),
    onUpdateSeq: estimationHistoryClientMutation.useUpdateSeq(commonParams),
    onDelete: estimationHistoryClientMutation.useDelete(commonParams),
    formContext,
    queryKey: ['project', 'sales', dataId, 'estimation', 'history', 'client'],
  });

  const { enqueueSnackbar } = useSnackbar();
  const { getValues } = useFormContext<EstimationHistoryFormValues>();
  const onSubmit = useEstimationHistoryClientUpdate(props);

  const handleDelete = useCallback(
    (id: number) => {
      if (getValues('estimationClient').length < 2) {
        enqueueSnackbar('마지막 남은 행은 삭제할 수 없습니다.', { variant: 'warning' });
        return;
      }
      onDelete(id, item.id);
    },
    [getValues, enqueueSnackbar, onDelete]
  );

  const getMenuItems = useCallback(
    (id: number) => [
      {
        text: '추가',
        icon: AddCircleOutlineIcon,
        action: () => onAdd(id, item.id),
        seq: 1,
        split: true,
      },
      {
        text: '이동(위)',
        icon: ArrowUpwardIcon,
        action: () => {
          onUpdateSeq(id, ArithmeticOperator.SUBTRACTION, item.id);
        },
        seq: 2,
      },
      {
        text: '이동(아래)',
        icon: ArrowDownwardIcon,
        action: () => {
          onUpdateSeq(id, ArithmeticOperator.ADDITION, item.id);
        },
        seq: 3,
        split: true,
      },
      {
        text: '삭제',
        icon: DeleteIcon,
        action: () => {
          handleDelete(id);
        },
        iconColor: '#e43333',
        seq: 100,
      },
    ],
    [onAdd, onUpdateSeq, handleDelete]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      getContextMenuItems={getMenuItems}
      useContextMenu={!readOnly}
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellAffiliatedCompanySelector
        {...props}
        name="clientCompany"
      />
    </InnerCellSupport>
  );
};

/**
 * 발주처인명
 * @param props
 * @constructor
 */
const CellRenderer14 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellAffiliatedCompanyPersonSelector
        {...props}
        companyName="clientCompany"
        personName="clientCompanyPerson"
      />
    </InnerCellSupport>
  );
};

/**
 * 발주형태
 * @param props
 * @constructor
 */
const CellRenderer15 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellManagedDropDown
        {...props}
        name="orderType"
        code={ManagedVariable.ProjectSalesEstimationOrderType}
      />
    </InnerCellSupport>
  );
};

/**
 * 발주처 차기연락일
 * @param props
 * @constructor
 */
const CellRenderer16 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellInputDate
        {...props}
        name="nextContactDateClient"
      />
    </InnerCellSupport>
  );
};

/**
 * 단지명(블럭명)
 * @param props
 * @constructor
 */
const CellRenderer17 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellInputText
        {...props}
        name="blockName"
      />
    </InnerCellSupport>
  );
};

/**
 * 대지모형
 * @param props
 * @constructor
 */
const CellRenderer18 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="siteModel"
      />
    </InnerCellSupport>
  );
};

/**
 * 실험대상
 * @param props
 * @constructor
 */
const CellRenderer19 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  const { setValue, getValues } = useFormContext<EstimationHistoryFormValues>();

  const handleAfterChange = useCallback(
    (index: number) => {
      const actualWindTunnelPrice = getValues(`estimationClient.${index}.actualWindTunnelPrice`);
      const testPlan = getValues(`estimationClient.${index}.testPlan`);

      setValue(
        `estimationClient.${index}.actualUnitPrice`,
        calculateActualUnitPrice(actualWindTunnelPrice, testPlan)
      );
    },
    [getValues, setValue]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
      onAfterChange={handleAfterChange}
    >
      <UIBuilderTableCellInputText
        {...props}
        name="testPlan"
      />
    </InnerCellSupport>
  );
};

/**
 * 소요기간(주)
 * @param props
 * @constructor
 */
const CellRenderer20 = (props: Props) => {
  const { sx, readOnly, isEditMode, isForm } = props;

  const onSubmit = useEstimationHistoryClientUpdate(props);
  const { getValues } = useFormContext();
  const list = getValues('estimationClient');

  const renderContent = useCallback(
    (index: number) => {
      const requiredWeeksStart = getValues(`estimationClient.${index}.requiredWeeksStart`);
      const requiredWeeksEnd = getValues(`estimationClient.${index}.requiredWeeksEnd`);
      if (readOnly || !isEditMode) return `${requiredWeeksStart ?? '-'}/${requiredWeeksEnd ?? '-'}`;

      const onBlur = isForm ? undefined : () => onSubmit(index);

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ...sx,
          }}
        >
          <UncontrolledInput
            type="number"
            name={`estimationClient.${index}.requiredWeeksStart`}
            onBlur={onBlur}
            readOnly={readOnly || !isEditMode}
          />
          /
          <UncontrolledInput
            type="number"
            name={`estimationClient.${index}.requiredWeeksEnd`}
            onBlur={onBlur}
            readOnly={readOnly || !isEditMode}
          />
        </Box>
      );
    },
    [getValues, readOnly, isEditMode, isForm, onSubmit]
  );

  return (
    <>
      {list.map((item, index) => (
        <div
          key={index}
          className="inner-cell"
        >
          {renderContent(index)}
        </div>
      ))}
    </>
  );
};

/**
 * 예상착수시기
 * @param props
 * @constructor
 */
const CellRenderer21 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellInputDate
        {...props}
        name="estimatedStartDate"
      />
    </InnerCellSupport>
  );
};

/**
 * 견적총액
 * @param props
 * @constructor
 */
const CellRenderer22 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);
  const { setValue, getValues } = useFormContext<EstimationHistoryFormValues>();

  const handleAfterChange = useCallback(
    (index: number) => {
      const totalEstimationPrice = getValues(`estimationClient.${index}.totalEstimationPrice`);
      const structuralReviewPrice = getValues(`estimationClient.${index}.structuralReviewPrice`);
      const estimationCm = getValues(`estimationClient.${index}.estimationCm`);

      setValue(
        `estimationClient.${index}.estimatedWindTunnelPrice`,
        getDiff(totalEstimationPrice, structuralReviewPrice)
      );

      setValue(
        `estimationClient.${index}.actualWindTunnelPrice`,
        calculateEstimationActualWindTunnelPrice(
          totalEstimationPrice,
          structuralReviewPrice,
          estimationCm
        )
      );

      setValue(
        `estimationClient.${index}.structuralReviewRatio`,
        getRatio(structuralReviewPrice, totalEstimationPrice)
      );

      setValue(`estimationClient.${index}.cmRatio`, getRatio(estimationCm, totalEstimationPrice));
    },
    [getValues, setValue]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
      onAfterChange={handleAfterChange}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="totalEstimationPrice"
      />
    </InnerCellSupport>
  );
};

/**
 * 견적순풍동금액
 * @param props
 * @constructor
 */
const CellRenderer23 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="estimationClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="estimatedWindTunnelPrice"
    />
  </InnerCellSupport>
);

/**
 * 견적실풍동금액
 * @param props
 * @constructor
 */
const CellRenderer24 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="estimationClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="actualWindTunnelPrice"
    />
  </InnerCellSupport>
);

/**
 * 견적구검
 * @param props
 * @constructor
 */
const CellRenderer25 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);
  const { setValue, getValues } = useFormContext<EstimationHistoryFormValues>();

  const handleAfterChange = useCallback(
    (index: number) => {
      const totalEstimationPrice = getValues(`estimationClient.${index}.totalEstimationPrice`);
      const structuralReviewPrice = getValues(`estimationClient.${index}.structuralReviewPrice`);
      const estimationCm = getValues(`estimationClient.${index}.estimationCm`);

      setValue(
        `estimationClient.${index}.estimatedWindTunnelPrice`,
        getDiff(totalEstimationPrice, structuralReviewPrice)
      );

      setValue(
        `estimationClient.${index}.actualWindTunnelPrice`,
        calculateEstimationActualWindTunnelPrice(
          totalEstimationPrice,
          structuralReviewPrice,
          estimationCm
        )
      );

      setValue(
        `estimationClient.${index}.structuralReviewRatio`,
        getRatio(structuralReviewPrice, totalEstimationPrice)
      );
    },
    [getValues, setValue]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
      onAfterChange={handleAfterChange}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="structuralReviewPrice"
      />
    </InnerCellSupport>
  );
};

/**
 * 견적구검비율
 * @param props
 * @constructor
 */
const CellRenderer26 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="estimationClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      type="percent"
      name="structuralReviewRatio"
    />
  </InnerCellSupport>
);

/**
 * 견적CM
 * @param props
 * @constructor
 */
const CellRenderer27 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);
  const { setValue, getValues } = useFormContext<EstimationHistoryFormValues>();

  const handleAfterChange = useCallback(
    (index: number) => {
      const totalEstimationPrice = getValues(`estimationClient.${index}.totalEstimationPrice`);
      const structuralReviewPrice = getValues(`estimationClient.${index}.structuralReviewPrice`);
      const estimationCm = getValues(`estimationClient.${index}.estimationCm`);
      const estimationCmPostDeduction = getValues(
        `estimationClient.${index}.estimationCmPostDeduction`
      );

      setValue(
        `estimationClient.${index}.actualWindTunnelPrice`,
        calculateEstimationActualWindTunnelPrice(
          totalEstimationPrice,
          structuralReviewPrice,
          estimationCm
        )
      );

      setValue(
        `estimationClient.${index}.cmDeductionRatio`,
        getRatio(estimationCmPostDeduction, estimationCm)
      );

      setValue(`estimationClient.${index}.cmRatio`, getRatio(estimationCm, totalEstimationPrice));
    },
    [setValue, getValues]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
      onAfterChange={handleAfterChange}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="estimationCm"
      />
    </InnerCellSupport>
  );
};

/**
 * 공제 후 견적CM
 * @param props
 * @constructor
 */
const CellRenderer28 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  const { setValue, getValues } = useFormContext<EstimationHistoryFormValues>();

  const handleAfterChange = useCallback(
    (index: number) => {
      const estimationCmPostDeduction = getValues(
        `estimationClient.${index}.estimationCmPostDeduction`
      );
      const estimationCm = getValues(`estimationClient.${index}.estimationCm`);

      setValue(
        `estimationClient.${index}.cmDeductionRatio`,
        getRatio(estimationCmPostDeduction, estimationCm)
      );
    },
    [getValues, setValue]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
      onAfterChange={handleAfterChange}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="estimationCmPostDeduction"
      />
    </InnerCellSupport>
  );
};

/**
 * 견적CM공제비율
 * @param props
 * @constructor
 */
const CellRenderer29 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="estimationClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="cmDeductionRatio"
      type="percent"
    />
  </InnerCellSupport>
);

/**
 * 견적CM비율
 * @param props
 * @constructor
 */
const CellRenderer30 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="estimationClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="cmRatio"
      type="percent"
    />
  </InnerCellSupport>
);

/**
 * 견적 실동단가
 * @param props
 * @constructor
 */
const CellRenderer31 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="estimationClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="actualUnitPrice"
    />
  </InnerCellSupport>
);

/**
 * 목표 견적실동단가
 * @param props
 * @constructor
 */
const CellRenderer32 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="targetUnitPrice"
      />
    </InnerCellSupport>
  );
};

/**
 * 최저 견적실동단가
 * @param props
 * @constructor
 */
const CellRenderer33 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="minimumUnitPrice"
      />
    </InnerCellSupport>
  );
};

/**
 * 견적실원가
 * @param props
 * @constructor
 */
const CellRenderer34 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="actualCostPrice"
      />
    </InnerCellSupport>
  );
};

/**
 * 견적서파일(엑셀)
 * @param props
 * @constructor
 */
const CellRenderer35 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      fileItemAttrName="excelFile"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellFileAttachment
        {...props}
        name="excelFile"
      />
    </InnerCellSupport>
  );
};

/**
 * 견적서파일(PDF)
 * @param props
 * @constructor
 */
const CellRenderer36 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      fileItemAttrName="pdfFile"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellFileAttachment
        {...props}
        name="pdfFile"
      />
    </InnerCellSupport>
  );
};

/**
 * 담당자
 * @param props
 * @constructor
 */
const CellRenderer37 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      displayValueAttrName="createdBy.name"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellUserSelector
        {...props}
        name="createdBy"
      />
    </InnerCellSupport>
  );
};

/**
 * 등록일
 * @param props
 * @constructor
 */
const CellRenderer38 = (props: Props) => {
  const onSubmit = useEstimationHistoryClientUpdate(props);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="estimationClient"
      onSubmit={onSubmit}
    >
      <UIBuilderTableCellText
        {...props}
        name="createdAt"
      />
    </InnerCellSupport>
  );
};

export const estimationHistoryTableCells: CellComponent = {
  388: CellRenderer1, // 견적 No.
  389: CellRenderer2, // 확정여부
  390: CellRenderer3, // 견적서번호
  391: CellRenderer4, // 견적일자
  392: CellRenderer5, // 견적구분
  393: CellRenderer6, // 견적상태
  394: CellRenderer7, // 수주가능성
  395: CellRenderer8, // 프로젝트 난이도
  396: CellRenderer9, // 모형 난이도
  477: CellRenderer10, // 견적요청사
  397: CellRenderer11, // 견적요청사인명
  398: CellRenderer12, // 요청사 차기연락일
  399: CellRenderer13, // 발주처
  400: CellRenderer14, // 발주처인명
  401: CellRenderer15, // 발주형태
  402: CellRenderer16, // 발주처 차기연락일
  403: CellRenderer17, // 단지명(블럭명)
  404: CellRenderer18, // 대지모형
  405: CellRenderer19, // 실험대상
  406: CellRenderer20, // 소요기간(주)
  407: CellRenderer21, // 예상착수시기
  408: CellRenderer22, // 견적총액
  409: CellRenderer23, // 견적순풍동금액
  478: CellRenderer24, // 견적실풍동금액
  410: CellRenderer25, // 견적구검
  411: CellRenderer26, // 견적구검비율
  412: CellRenderer27, // 견적CM
  413: CellRenderer28, // 공제 후 견적CM
  414: CellRenderer29, // 견적CM공제비율
  415: CellRenderer30, // 견적CM비율
  416: CellRenderer31, // 견적 실동단가
  417: CellRenderer32, // 목표 견적실동단가
  418: CellRenderer33, // 최저 견적실동단가
  419: CellRenderer34, // 견적실원가
  420: CellRenderer35, // 견적서파일(엑셀)
  421: CellRenderer36, // 견적서파일(PDF)
  422: CellRenderer37, // 담당자
  423: CellRenderer38, // 등록일
  ...generateSpareAttrCellRenders(424),
};
