import React from 'react';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';

export default function AccountingBudgetConfirmButton() {

  return (
    <ButtonBasicUI
      shape="primary2"
      size="medium"
    >
      확정
    </ButtonBasicUI>
  );
}
