import type { AffiliatedCompanyShortView } from '@front/src/features/affiliated-company/types/view';
import type {
  ContractPaymentTermFormValues,
  ContractPaymentTermListFormValues,
} from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import type {
  ContractPaymentTermObjectView,
  ContractPaymentTermView,
} from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/types/views';
import type { ContractClientQueryDto } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

const convertList = (
  clientId: number,
  listData: ContractPaymentTermView[]
): ContractPaymentTermListFormValues[] =>
  listData.map((item) => ({
    ...item,
    id: uuid(),
    rowId: item.id,
    parentId: clientId,
    paymentStage: item.paymentStage ?? '',
    paymentTiming: item.paymentTiming ?? '',
    structuralReviewPaymentTiming: item.structuralReviewPaymentTiming ?? '',
    totalContractPrice: item.totalContractPrice ? +item.totalContractPrice : null,
  }));

export const getContractPaymentTermTabFormUpdateValues = (
  clientId: number,
  dataObject: ContractPaymentTermObjectView
): Omit<
  ContractPaymentTermFormValues,
  'defaultClientId' | 'editOpenIdList' | 'lastUpdatedId' | 'parent'
> => {
  const stringClientId = clientId.toString();

  const remainsList = Object.entries(dataObject)
    .filter(([key]) => key !== stringClientId)
    .reduce<ContractPaymentTermListFormValues[]>(
      (acc, [key, value]) => acc.concat(convertList(Number(key), value.list)),
      []
    );

  return {
    clientId,
    contractClientCompany: dataObject[stringClientId]?.contractClientCompany || null,
    list: convertList(clientId, dataObject[stringClientId]?.list || []),
    deleteList: [],
    remainsList,
  };
};

export const getContractPaymentTermDefaultRow = (
  contractClientId: number | null,
  contractClientCompany: AffiliatedCompanyShortView | null,
  parent: ContractClientQueryDto | null
): ContractPaymentTermListFormValues => ({
  id: uuid(),
  rowId: null,
  noteCount: null,
  parentId: contractClientId,
  contractClientId,
  contractClientCompany,
  contractClientCompanyId: contractClientCompany?.id ?? null,
  paymentStage: '',
  paymentSequence: null,
  paymentTiming: '',
  expectedPaymentDate: null,
  clientRatio: parent?.clientRatio ?? null,
  companyStageRatio: null,
  totalContractPrice: null,
  windTunnelPrice: null,
  structuralReviewPrice: null,
  structuralReviewRatio: parent?.structuralReviewRatio ?? null,
  structuralReviewPaymentTiming: '',
  structuralReviewPaymentTarget: null,
  structuralReviewPaymentDate: null,
  canDelete: true,

  numberAttr1: null,
  numberAttr2: null,
  numberAttr3: null,
  textAttr1: null,
  textAttr2: null,
  textAttr3: null,
  dateAttr1: null,
  dateAttr2: null,
  dateAttr3: null,
});

export const getContractPaymentTermSortedIdList = (
  a: ContractPaymentTermListFormValues[],
  b: ContractPaymentTermListFormValues[]
): string[] =>
  sortedContractPaymentTermListByExpectedPaymentDate(
    a.concat(b).filter((f) => f.id && f.expectedPaymentDate)
  ).map((m) => m.id);

export const sortedContractPaymentTermListByExpectedPaymentDate = (
  list: ContractPaymentTermListFormValues[]
) =>
  [...list].sort((a, b) => {
    if (!a.expectedPaymentDate || !b.expectedPaymentDate) return 0;

    return dayjs(a.expectedPaymentDate).format(YYYY_MM_DD) >
      dayjs(b.expectedPaymentDate).format(YYYY_MM_DD)
      ? 1
      : -1;
  });
