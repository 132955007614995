import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import React, { useCallback } from 'react';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellInputText from '@front/src/components/ui-builder/table/cell-renderer/InputText';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellDropDown from '@front/src/components/ui-builder/table/cell-renderer/DropDown';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';
import { createDropdownOptions, getDiff, multipleNumberAndRatio, YY_MM_DD } from '@front/src/utils';
import type {
  ContractPaymentTermFormValues,
  ContractPaymentTermListFormValues,
} from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';

import UIBuilderTableCellManagedDropDown from '@front/src/components/ui-builder/table/cell-renderer/ManagedDropDown';
import { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';
import {
  getContractPaymentTermSortedIdList,
  sortedContractPaymentTermListByExpectedPaymentDate,
} from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/utils';
import { useFormContext } from 'react-hook-form';

interface Props extends CellComponentProps<ContractPaymentTermListFormValues> {}

/**
 * 계약지불단계
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => {
  const { rowIndex, onAfterChange, disabled } = props;

  return (
    <UIBuilderTableCellDropDown
      {...props}
      name={`list.${rowIndex}.paymentStage`}
      disabled={disabled}
      options={createDropdownOptions(['계약금', '중도금', '잔금'])}
      onAfterChange={onAfterChange}
    />
  );
};

/**
 * 계약지불순번
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => {
  const { rowIndex } = props;

  return (
    <UIBuilderTableCellInputText
      {...props}
      readOnly
      name={`list.${rowIndex}.paymentSequence`}
    />
  );
};

/**
 * 계약발주처
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => {
  const { rowIndex } = props;

  return (
    <UIBuilderTableCellAffiliatedCompanySelector
      {...props}
      readOnly
      name={`list.${rowIndex}.contractClientCompany`}
    />
  );
};

/**
 * 계약수금시기
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => {
  const { rowIndex, disabled, onAfterChange } = props;

  return (
    <UIBuilderTableCellDropDown
      {...props}
      name={`list.${rowIndex}.paymentTiming`}
      disabled={disabled}
      options={createDropdownOptions([
        '계약전',
        '계약시',
        '업무개시전',
        '업무개시후',
        '설풍납품전',
        '설풍납품시',
        '최종보고서 납품전',
        '최종보고서 납품시',
        '구조심의전',
        '구조심의 완료시',
        'PF완료시',
        '기타',
      ])}
      onAfterChange={onAfterChange}
    />
  );
};

/**
 * 계약응당일
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => {
  const {
    rowIndex,
    formUpdateFormContext,
    readOnly,
    isForm,
    onSubmit,
    isEditMode,
    onClick,
    sx,
    onAfterChange,
    disabled,
  } = props;

  const { getValues, setValue } = useFormContext<ContractPaymentTermFormValues>();
  const value = getValues(`list.${rowIndex}.expectedPaymentDate`);

  const handleAfterChange = useCallback(() => {
    const sortedIdList = getContractPaymentTermSortedIdList(
      getValues('list'),
      getValues('remainsList')
    );

    const setSequence = getValues('list').map((item) => ({
      ...item,
      paymentSequence: sortedIdList.findIndex((f) => f === item.id) + 1,
    }));

    const sorted = sortedContractPaymentTermListByExpectedPaymentDate(setSequence);

    setValue('list', sorted);
    onAfterChange?.();
  }, [onAfterChange, setValue, getValues]);

  const renderContent = useCallback(() => {
    if (readOnly && (value === '' || value === null)) {
      return <>-</>;
    }

    if (readOnly && value !== null) {
      return <>{dayjs(value).format(YY_MM_DD)}</>;
    }

    return (
      <DatePickerWithHookForm
        name={`list.${rowIndex}.expectedPaymentDate`}
        disabled={disabled}
        formContext={formUpdateFormContext}
        onSubmit={isForm ? undefined : onSubmit}
        readOnly={readOnly || !isEditMode}
        onAfterChange={handleAfterChange}
      />
    );
  }, [
    readOnly,
    value,
    name,
    disabled,
    formUpdateFormContext,
    isForm,
    onSubmit,
    isEditMode,
    handleAfterChange,
  ]);

  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
};

/**
 * 발주처별 비율
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => {
  const { rowIndex } = props;

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name={`list.${rowIndex}.clientRatio`}
      type="percent"
    />
  );
};

/**
 * 각사 지불단계비율
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => {
  const { rowIndex } = props;

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name={`list.${rowIndex}.companyStageRatio`}
      type="percent"
    />
  );
};

/**
 * 계약총액
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => {
  const { rowIndex, onAfterChange, disabled } = props;
  const { setValue, getValues } = useFormContext<ContractPaymentTermFormValues>();

  const handleAfterChange = useCallback(() => {
    const parent = getValues(`parent`);
    const totalContractPrice = getValues(`list.${rowIndex}.totalContractPrice`);

    const companyStageRatio = calculateCompanyStageRatio(totalContractPrice, getValues('list'));
    const structuralReviewPrice = multipleNumberAndRatio(
      parent?.structuralReviewPrice ?? null,
      companyStageRatio
    );

    setValue(`list.${rowIndex}.companyStageRatio`, companyStageRatio);
    setValue(`list.${rowIndex}.structuralReviewPrice`, structuralReviewPrice);
    setValue(
      `list.${rowIndex}.windTunnelPrice`,
      getDiff(totalContractPrice, structuralReviewPrice)
    );

    onAfterChange?.();
  }, [onAfterChange, setValue, getValues]);

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      name={`list.${rowIndex}.totalContractPrice`}
      disabled={disabled}
      onAfterChange={handleAfterChange}
    />
  );
};

/**
 * 계약순풍동금액
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => {
  const { rowIndex } = props;

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name={`list.${rowIndex}.windTunnelPrice`}
    />
  );
};

/**
 * 계약구검
 * @param props
 * @constructor
 */
const CellRenderer10 = (props: Props) => {
  const { rowIndex } = props;

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name={`list.${rowIndex}.structuralReviewPrice`}
    />
  );
};

/**
 * 계약구검비율
 * @param props
 * @constructor
 */
const CellRenderer11 = (props: Props) => {
  const { rowIndex } = props;

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name={`list.${rowIndex}.structuralReviewRatio`}
      type="percent"
    />
  );
};

/**
 * 계약구검 지급시기
 * @param props
 * @constructor
 */
const CellRenderer12 = (props: Props) => {
  const { rowIndex, onAfterChange, disabled } = props;

  return (
    <UIBuilderTableCellManagedDropDown
      {...props}
      name={`list.${rowIndex}.structuralReviewPaymentTiming`}
      disabled={disabled}
      code={ManagedVariable.ProjectSalesStructuralReviewPaymentTiming}
      onAfterChange={onAfterChange}
    />
  );
};

/**
 * 계약구검 지급대상
 * @param props
 * @constructor
 */
const CellRenderer13 = (props: Props) => {
  const { rowIndex, onAfterChange, disabled } = props;

  return (
    <UIBuilderTableCellAffiliatedCompanySelector
      {...props}
      name={`list.${rowIndex}.structuralReviewPaymentTarget`}
      disabled={disabled}
      onAfterChange={onAfterChange}
    />
  );
};

/**
 * 계약구검 지급일
 * @param props
 * @constructor
 */
const CellRenderer14 = (props: Props) => {
  const { rowIndex, onAfterChange, disabled } = props;

  return (
    <UIBuilderTableCellInputDate
      {...props}
      name={`list.${rowIndex}.structuralReviewPaymentDate`}
      disabled={disabled}
      onAfterChange={onAfterChange}
    />
  );
};

export const contractPaymentTermTableCells: CellComponent = {
  562: CellRenderer1, // 계약지불단계
  563: CellRenderer2, // 계약지불순번
  564: CellRenderer3, // 계약발주처
  565: CellRenderer4, // 계약수금시기
  566: CellRenderer5, // 계약응당일
  567: CellRenderer6, // 발주처별 비율
  568: CellRenderer7, // 각사 지불단계비율
  569: CellRenderer8, // 계약총액
  570: CellRenderer9, // 계약순풍동금액
  571: CellRenderer10, // 계약구검
  572: CellRenderer11, // 계약구검비율
  573: CellRenderer12, // 계약구검 지급시기
  574: CellRenderer13, // 계약구검 지급대상
  575: CellRenderer14, // 계약구검 지급일
  ...generateSpareAttrCellRenders(585),
};

const calculateCompanyStageRatio = (
  totalContractPrice: number | null,
  list: ContractPaymentTermListFormValues[]
) => {
  const total = list
    ?.filter((l) => !!l.totalContractPrice)
    .reduce((acc, cur) => acc + cur.totalContractPrice!, 0);

  if (!total || total === 0) return null;

  return Number((((totalContractPrice ?? 0) / total) * 100).toFixed(2));
};
