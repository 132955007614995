import React from 'react';
import UIBuilderTableBody, {
  type UIBuilderTableBodyProps,
} from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';

import type {
  CollectionTaxInvoiceFormListValues,
  CollectionTaxInvoiceFormValues,
} from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/hooks/useForm';

import type { UseFieldArrayReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import useCollectionModalTableRowLogic from '@front/src/features/project-sales/features/tabs/collection/hooks/useModalTableRowLogic';

interface TableBodyProps extends UIBuilderTableBodyProps {
  fieldArray: UseFieldArrayReturn<CollectionTaxInvoiceFormValues, 'list', string>;
}

export default function CollectionTaxInvoiceTableBody(props: TableBodyProps) {
  return (
    <UIBuilderTableBody<CollectionTaxInvoiceFormListValues>
      {...props}
      RowComponent={(rowProps) => (
        <TableRow
          {...rowProps}
          fieldArray={props.fieldArray}
        />
      )}
    />
  );
}

interface TableRowProps extends UIBuilderTableRowProps {
  fieldArray: UseFieldArrayReturn<CollectionTaxInvoiceFormValues, 'list', string>;
}

function TableRow(props: TableRowProps) {
  const { fieldArray, formUpdateFormContext, rowIndex } = props;

  const { contextMenuItems, isEditMode, sx } = useCollectionModalTableRowLogic(
    formUpdateFormContext,
    fieldArray,
    rowIndex
  );

  return (
    <FormProvider {...formUpdateFormContext!}>
      <UiBuilderTableRow
        {...props}
        itemList={contextMenuItems}
        isEditMode={isEditMode}
        sx={sx}
      />
    </FormProvider>
  );
}
