import create from 'zustand';

interface State {
  collectionId: number | null;
  open: boolean;
}

interface Actions {
  setCollectionId: (collectionId: number) => void;
  onOpen: () => void;
  onClose: () => void;
}

const useCollectionClaimDocumentModalStore = create<State & Actions>((set) => ({
  collectionId: null,
  setCollectionId: (collectionId) => set({ collectionId }),
  open: false,
  onOpen: () => set({ open: true }),
  onClose: () => set({ open: false }),
}));

export default useCollectionClaimDocumentModalStore;
