import React from 'react';
import { DefaultSectionComponent } from '@front/type/Function';
import useAccountingBudgetConfirmTableHook from '@front/src/features/accounting/features/budget/hook/comfirm-table';
import AccountingBudgetTable from '@front/src/features/accounting/features/budget/component/common/table';

const AccountingBudgetConfirmTable: DefaultSectionComponent = (props) => {

  const { methods } = useAccountingBudgetConfirmTableHook();

  return (
    <AccountingBudgetTable
      {...props}
      methods={methods}
    />
  );
};

export default AccountingBudgetConfirmTable;
