import { useIsMutating } from 'react-query';
import { useMemo } from 'react';

const useGetContractHistoryLoading = (dataId: number | undefined) => {
  const mutationKeys = useMemo(() => {
    const mutationKey = getMutationKey(dataId);

    return {
      add: [...mutationKey, 'add'],
      update: [...mutationKey, 'update'],
      delete: [...mutationKey, 'delete'],
    };
  }, [dataId]);

  const addLoading = !!useIsMutating({ mutationKey: mutationKeys.add });
  const updateLoading = !!useIsMutating({ mutationKey: mutationKeys.update });
  const deleteLoading = !!useIsMutating({ mutationKey: mutationKeys.delete });

  return addLoading || updateLoading || deleteLoading;
};

export default useGetContractHistoryLoading;

const getMutationKey = (dataId: number | undefined) => [
  'project',
  'sales',
  dataId,
  'collection',
  'history',
  'collection',
];
