import axios, { type AxiosResponse } from 'axios';
import { apiAminSectionRequestUrl, getMenuIdHeaders, menuRequestUrl } from '@front/src/utils';
import { ProjectSalesDbSearchParameter } from '@front/src/features/project-sales-db/features/data-list/types';
import type { HeaderSettingView, SectionView } from '@front/src/types';
import type { HeaderGetParams } from '@front/src/types/parameter';

const url = {
  getList: () => '/api/project/sales/sales-information/db-analysis',
  getSectionList: (id?: number) => `${menuRequestUrl}/${id}/section`,
  getHeaderList: (id?: number) => `${apiAminSectionRequestUrl}/${id}/header-setting`,
};

export const projectSalesDbAnalysisApi = {
  getList: async <T = any[]>(params: ProjectSalesDbSearchParameter, menuId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getList(), {
      headers: getMenuIdHeaders(menuId),
      params: params,
      paramsSerializer: (params) => {
        return Object.keys(params)
          .map((key) =>
            Array.isArray(params[key])
              ? params[key].map((value) => `${key}=${encodeURIComponent(value)}`).join('&')
              : `${key}=${encodeURIComponent(params[key])}`
          )
          .join('&');
      },
    });
    return data;
  },
  getSectionList: async <T = SectionView[]>(searchTargetMenuId: number, menuId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getSectionList(searchTargetMenuId), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getHeaderList: async <T = HeaderSettingView[]>(
    id?: number,
    params?: HeaderGetParams,
    authMenuId?: number
  ) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getHeaderList(id), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    });
    return data;
  },
};
