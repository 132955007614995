import React from 'react';
import { useOutletContext, useParams } from 'react-router';
import type { ProjectSalesCommonFeatureProps } from '@front/src/features/project-sales';
import { CollectionFinalSection } from '@front/src/features/project-sales/features/tabs/collection/sections/final';
import { CollectionHistorySection } from '@front/src/features/project-sales/features/tabs/collection/sections/history';
import CommonSectionList from '@front/src/components/ui-builder/CommonSectionList';
import { useSectionComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useSectionComponentLifeCycleManager';
import useActiveTab from '@front/src/features/project-sales/hooks/useActiveTab';
import CollectionDueDateHistorySection from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history';
import CollectionClaimDocumentSection from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document';
import CollectionTaxInvoiceSection from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice';
import CollectionNoticeMessage from '@front/src/features/project-sales/features/tabs/collection/features/notice-message';

export { Tab as CollectionTab };

// 객체 key는 mn_section 엔티티의 id 값
const sectionComponents = {
  107: CollectionFinalSection,
  108: CollectionHistorySection,
  109: CollectionClaimDocumentSection,
  110: CollectionTaxInvoiceSection,
  111: CollectionDueDateHistorySection,
};

/**
 * [메뉴]프로젝트-영업부-영업정보 / [탭]수금
 * @constructor
 */
function Tab() {
  const { id } = useParams<{ id: string }>();
  const { tabList } = useOutletContext<ProjectSalesCommonFeatureProps>();
  const activeTab = useActiveTab(tabList);
  useSectionComponentLifeCycleManager(sectionComponents);

  if (!activeTab || !id) return <></>;

  return (
    <>
      <CollectionNoticeMessage
        menuId={activeTab.id}
        dataId={+id}
        sectionId={108}
      />
      <CommonSectionList
        menuId={activeTab.id}
        dataId={+id}
      />
    </>
  );
}
