import type { ReactNode } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useCustomDialog } from '@front/src/features/dialog';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { handleError } from '@front/src/utils';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AxiosError } from 'axios';
import { loginAction } from '@front/login/action';
import { useShallow } from 'zustand/react/shallow';
import useQueryClientState from '@front/src/config/useQueryClientState';

interface Props {
  children: ReactNode;
}

export default function CustomQueryClientProvider({ children }: Readonly<Props>) {
  const { alert } = useCustomDialog();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { refetchOnWindowFocus } = useQueryClientState(
    useShallow((state) => ({
      refetchOnWindowFocus: state.refetchOnWindowFocus,
    }))
  );

  const client = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: true,
            staleTime: 0,
          },
          mutations: {
            onError: async (error) => {
              console.log('er', error);
              handleError(error, alert);
            },
          },
        },
        queryCache: new QueryCache({
          onError: async (error) => {
            if (error instanceof AxiosError) {
              if (error.response?.status === 401) {
                dispatch(loginAction.setSessionExpired(true));
                return Promise.resolve();
              }
            } else {
              handleError(error, alert, navigate);
            }
          },
        }),
      }),
    []
  );

  useEffect(() => {
    client.setDefaultOptions({
      queries: {
        retry: 0,
        refetchOnWindowFocus,
        staleTime: 0,
      },
      mutations: {
        onError: async (error) => {
          handleError(error, alert);
        },
      },
    });

    // 활성 쿼리를 무효화하여 새로운 설정 적용
    client.invalidateQueries();
  }, [refetchOnWindowFocus, client]);

  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools
        initialIsOpen={false}
        position="bottom-right"
      />
    </QueryClientProvider>
  );
}
