import React from 'react';
import { Box } from '@mui/material';
import { DefaultSectionComponent } from '@front/type/Function';
import {
  AccountingBudgetCommonTableForm,
  VerticalTableCellProps,
} from '@front/src/features/accounting/features/budget/type';
import AccountingBudgetTableRow from '@front/src/features/accounting/features/budget/component/common/table-row';
import { ColorPalette } from '@front/assets/theme';
import { UseFormReturn } from 'react-hook-form';

interface Props {
  rowCellList: VerticalTableCellProps[][];
  methods: UseFormReturn<AccountingBudgetCommonTableForm>;
}

const AccountingBudgetTableBody: DefaultSectionComponent<Props> = (props) => {

  const { rowCellList } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        background: ColorPalette.background.bg,
        flexWrap: 'wrap',
      }}
    >
      {rowCellList
        .map((cellList, row) => (
          <AccountingBudgetTableRow
            {...props}
            key={row}
            cellList={cellList}
          />
        ))}
    </Box>
  );
};

export default AccountingBudgetTableBody;
