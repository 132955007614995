import { useQuery } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { CollectionClaimDocumentView } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/types/views';
import { commonApi } from '@front/src/api/api';

const collectionClaimDocumentQuery = {
  useGet: (collectionId: number, commonParams: UIBuilderParameter) => {
    const url = `/api/project/sales/sales-information/collection-history/collection/${collectionId}/claim-document`;
    const queryKey = [
      'project',
      'sales',
      commonParams.dataId,
      'collection',
      collectionId,
      'claim-document',
    ];
    const enabled = !!commonParams.dataId && !!collectionId;

    const { data } = useQuery<CollectionClaimDocumentView[], Error, CollectionClaimDocumentView[]>({
      queryFn: () => commonApi.getListByParams(url, commonParams),
      queryKey,
      suspense: true,
      enabled,
    });

    return data;
  },
};

export default collectionClaimDocumentQuery;
