import React from 'react';
import { FormProvider, UseFormWatch } from 'react-hook-form';
import AccountingBudgetTableBody from '@front/src/features/accounting/features/budget/component/common/table-body';
import {
  AccountingBudgetCommonTableForm,
  AccountingBudgetHookMethodsType,
} from '@front/src/features/accounting/features/budget/type';
import { getSortedRowList } from '@front/src/features/accounting/features/budget/util/convert';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { Box } from '@mui/material';
import { ColorPalette } from '@front/assets/theme';

interface Props<F extends AccountingBudgetCommonTableForm> extends SectionComponentProps {
  methods: AccountingBudgetHookMethodsType<F>;
}

const AccountingBudgetTable = <F extends AccountingBudgetCommonTableForm>(props: Props<F>): React.ReactElement => {

  const { methods } = props;

  const { watch } = methods;
  const allCellList = (watch as unknown as UseFormWatch<AccountingBudgetCommonTableForm>)('cellList');
  const rowCellList = getSortedRowList(allCellList);

  return (
    <FormProvider {...methods}>
      <Box
        sx={{
          width: 'fit-content',
          borderRadius: '5px',
          border: `1px solid ${ColorPalette.line.line}`,
          overflowY: 'visible !important',
          overflowX: 'visible !important',
          height: 'min-content',
        }}
      >
        <Box>
          <AccountingBudgetTableBody
            {...props}
            methods={methods}
            rowCellList={rowCellList}
          />
        </Box>
      </Box>
    </FormProvider>
  );
};

export default AccountingBudgetTable;
