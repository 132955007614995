import { useForm, UseFormReturn } from 'react-hook-form';
import {
  AccountingBudgetBottomUpTableForm,
  ReflectBudgetYearType,
} from '@front/src/features/accounting/features/budget/type';
import { accountingBudgetHookDefaultValues } from '@front/src/features/accounting/features/budget/hook/index';

type MethodsType = UseFormReturn<AccountingBudgetBottomUpTableForm>;
type HookType = () => {
  methods: MethodsType,
  reflectBudgetYear: ReflectBudgetYearType,
}

export const accountingBudgetBottomUpTableHookDefaultValues: AccountingBudgetBottomUpTableForm = {
  cellList: [{
    row: 0,
    col: 0,
    value: `${accountingBudgetHookDefaultValues.budgetYear - 1}년 대비 미분류조정`,
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 0,
    value: '증감비율',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 1,
    value: '목표액',
    isHead: true,
    clickable: false,
  }, {
    row: 2,
    col: 0,
    value: null, // 수금
    style: 'percent',
  }, {
    row: 2,
    col: 1,
    value: null, // 수금
    style: 'amount',
  }, {
    row: 3,
    col: 0,
    value: null, // 지출
    style: 'percent',
  }, {
    row: 3,
    col: 1,
    value: null, // 지출
    style: 'amount',
  }, {
    row: 4,
    col: 0,
    value: '', // 순이익
    clickable: false,
  }, {
    row: 4,
    col: 1,
    value: null, // 순이익
    style: 'amount',
  }, {
    row: 5,
    col: 0,
    value: null, // 이익율
    style: 'percent',
    clickable: false,
  }, {
    row: 5,
    col: 1,
    value: '', // 이익율
    clickable: false,
  }],
};

const useAccountingBudgetBottomUpTableHook: HookType = () => {

  const methods: MethodsType = useForm({
    defaultValues: accountingBudgetBottomUpTableHookDefaultValues,
  });

  const {
    getValues,
    reset,
  } = methods;

  const reflectBudgetYear: ReflectBudgetYearType = (budgetYear) => {
    const currentValues = getValues();
    const prevCellList = getValues('cellList');
    const cellList: typeof prevCellList = [];
    for (let i = 0; i < prevCellList.length; i++) {
      const cell = prevCellList[i];
      if (cell.col === 0 && cell.row === 0) {
        cellList.push({
          ...cell,
          value: `${budgetYear - 1}년 대비 미분류조정`,
        });
      } else {
        cellList.push({
          ...cell,
        });
      }
    }
    reset({
      ...currentValues,
      cellList,
    });
  };

  return {
    methods,
    reflectBudgetYear,
  };
};

export default useAccountingBudgetBottomUpTableHook;
