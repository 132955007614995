import React from 'react';
import FileUI from '@front/src/components/components-with-design/component/File';
import { useController, type UseFormReturn } from 'react-hook-form';
import useFileItemDownload from '@front/src/features/file-item/hooks/useDownload';

interface Props {
  name: string;
  onSubmit?: any;
  ext?: string;
  message?: string;
  isPossibleDownload?: boolean;
  disabled?: boolean;
  formContext: UseFormReturn;
  required?: string;
  onAfterChange?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function HookFormWithFile({
  name,
  required = '',
  onSubmit,
  formContext,
  disabled,
  onAfterChange,
  onChange,
}: Readonly<Props>) {
  const { control } = formContext;

  const {
    field: { value, onChange: setFormValue },
  } = useController({ control, name, rules: { required } });

  const handleChange = (e) => {
    const [file] = e.target.files;

    setFormValue({
      multipartFile: file,
      filename: file.name,
      isNew: true,
    });

    e.target.value = '';
    onAfterChange?.();
    onSubmit?.();

    setFormValue({
      filename: file.name,
      isNew: false,
    });
  };

  const handleDelete = () => {
    setFormValue({
      filename: '',
      isNew: true,
    });

    onSubmit?.();
  };

  const { onDownload } = useFileItemDownload({ fileKey: value?.fileKey });

  return (
    <FileUI
      value={value}
      onChange={onChange ? onChange : handleChange}
      onDelete={handleDelete}
      onDownload={onDownload}
      disabled={disabled}
    />
  );
}

export interface FileItemFormValues {
  id?: number;
  filename: string;
  fileKey?: string;

  multipartFile?: File;
  isNew?: boolean;
}
