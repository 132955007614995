import { useMutation, useQueryClient } from 'react-query';
import type {
  AddParameter,
  IdsDataParameter,
  SeqUpdateParameter,
  UIBuilderParameter,
  UpdateByFormDataParameter,
} from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';

const estimationComparisonMutation = {
  useAdd: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, AddParameter>({
      mutationFn: (params) =>
        commonApi.add(
          `/api/project/sales/sales-information/${commonParams.dataId}/estimation-comparison`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'add'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },

  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, UpdateByFormDataParameter>({
      mutationFn: (params) =>
        commonApi.updateByFormData(
          `/api/project/sales/sales-information/estimation-comparison/${params.id}`,
          params.formData,
          commonParams
        ),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },

  useUpdateSeq: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, SeqUpdateParameter>({
      mutationFn: (params) =>
        commonApi.updateSeq(
          `/api/project/sales/sales-information/${commonParams.dataId}/estimation-comparison/seq`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'update-seq'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },

  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, IdsDataParameter>({
      mutationFn: (params) =>
        commonApi.delete(
          `/api/project/sales/sales-information/${commonParams.dataId}/estimation-comparison`,
          { ...params, ...commonParams },
          commonParams
        ),
      mutationKey: [...queryKey, 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },
};

export default estimationComparisonMutation;

const getQueryKey = (dataId) => ['project', 'sales', dataId, 'estimation', 'comparison'];
