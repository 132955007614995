import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
  AccountingBudgetHookMethodsType,
  AccountingBudgetSummaryTableForm,
  ReflectActivatedManagementType,
  ReflectBudgetYearType,
} from '@front/src/features/accounting/features/budget/type';
import { getMaxCol } from '@front/src/features/accounting/features/budget/util/convert';

type HookType = () => {
  methods: AccountingBudgetHookMethodsType<AccountingBudgetSummaryTableForm>,
  reflectBudgetYear: ReflectBudgetYearType,
  reflectActivatedManagement: ReflectActivatedManagementType,
}

export const accountingBudgetSummaryTableHookDefaultValues: AccountingBudgetSummaryTableForm = {
  cellList: [{
    row: 0,
    col: 0,
    value: '선택 미분류',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 1,
    value: '',
    style: 'span',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 2,
    value: '조정구분',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 3,
    value: '비율',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 4,
    value: '합계(년)',
    isHead: true,
    clickable: false,
  }, {
    row: 0,
    col: 5,
    value: '평균(월)',
    isHead: true,
    clickable: false,
  }, {
    row: 1,
    col: 0,
    value: null, // 선택 미분류
    clickable: false,
  }, {
    row: 1,
    col: 1,
    value: '',
    style: 'span',
    clickable: false,
  }, {
    row: 1,
    col: 2,
    value: '계층조정',
    justifyContent: 'flex-start',
    clickable: false,
  }, {
    row: 1,
    col: 3,
    value: null, // 계층조정 - 비율
    style: 'percent',
    clickable: false,
  }, {
    row: 1,
    col: 4,
    value: null, // 계층조정 - 합계(년)
    style: 'amount',
    clickable: false,
  }, {
    row: 1,
    col: 5,
    value: null, // 계층조정 - 평균(월)
    style: 'amount',
    clickable: false,
  }, {
    row: 2,
    col: 0,
    value: '',
    style: 'span',
    clickable: false,
  }, {
    row: 2,
    col: 1,
    value: '',
    style: 'span',
    clickable: false,
  }, {
    row: 2,
    col: 2,
    value: '미분류조정',
    justifyContent: 'flex-start',
    clickable: false,
  }, {
    row: 2,
    col: 3,
    value: null, // 미분류조정 - 비율
    style: 'percent',
    clickable: false,
  }, {
    row: 2,
    col: 4,
    value: null, // 미분류조정 - 합계(년)
    style: 'amount',
    clickable: false,
  }, {
    row: 2,
    col: 5,
    value: null, // 미분류조정 - 평균(월)
    style: 'amount',
    clickable: false,
  }],
};

const useAccountingBudgetSummaryTableHook: HookType = () => {

  const methods = useForm({
    defaultValues: accountingBudgetSummaryTableHookDefaultValues,
  });

  const {
    getValues,
    reset,
  } = methods;

  const reflectBudgetYear: ReflectBudgetYearType = (budgetYear) => {
    const currentValues = getValues();
    const cellList = [...accountingBudgetSummaryTableHookDefaultValues.cellList];
    const defaultHeaderMaxCol = getMaxCol(accountingBudgetSummaryTableHookDefaultValues.cellList);

    for (let i = 0; i < 12; i++) {
      const value = dayjs().year(budgetYear).month(i).date(1).format('YYMM');
      const col = defaultHeaderMaxCol + i + 1;
      cellList.push({
        col,
        row: 0,
        value,
        isHead: true,
        clickable: false,
      });

      cellList.push({
        col,
        row: 1,
        value: null,
        style: 'amount',
        clickable: false,
      });

      cellList.push({
        col,
        row: 2,
        value: null,
        style: 'amount',
        clickable: false,
      });
    }
    reset({
      ...currentValues,
      cellList,
    });
  };

  const reflectActivatedManagement: ReflectActivatedManagementType = (activatedManagement) => {
    if (activatedManagement && activatedManagement.depth === 4) {
      const currentValues = getValues();
      const pervCellList = getValues('cellList');
      const cellList: typeof pervCellList = [];
      for (let i = 0; i < pervCellList.length; i++) {
        const cell = pervCellList[i];
        if (cell.col === 0 && cell.row === 1) {
          cellList.push({
            ...cell,
            value: activatedManagement.name,
          });
        } else {
          cellList.push(cell);
        }
      }

      reset({
        ...currentValues,
        cellList,
      });
    }
  };

  return {
    methods,
    reflectBudgetYear,
    reflectActivatedManagement,
  };
};

export default useAccountingBudgetSummaryTableHook;
