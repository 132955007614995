import AccountingBudgetQuery from '@front/src/features/accounting/features/budget/query';
import {
  AccountingManagementView,
  AccountingBudgetListView,
  AddBudgetParameter,
  CommonParameter,
} from '@front/src/features/accounting/features/budget/type';
import { CallbackOption, getNoOpCallback, getSetCallbackFunc } from '@front/src/utils';
import AccountingBudgetMutation from '@front/src/features/accounting/features/budget/mutation';
import { convertAccountingBudgetApiView } from '@front/src/features/accounting/features/budget/util/convert';

type RepositoryType = {
  useGetAccountManagementList: (params: CommonParameter) => {
    data: AccountingManagementView[] | undefined,
    isLoading: boolean,
  },
  useGetBudgetList: (params: CommonParameter) => {
    data: AccountingBudgetListView[] | undefined,
    isLoading: boolean,
  },
  useAddBudget: (commonParams: CommonParameter) => {
    run: (params: AddBudgetParameter) => void,
    setCallback: (to: Partial<CallbackOption>) => void,
  }
}

const AccountingBudgetRepository: RepositoryType = {
  useGetAccountManagementList: (params) => {
    const { data, isLoading } = AccountingBudgetQuery.useGetAccountManagementList(params);
    return { data, isLoading };
  },
  useAddBudget: (commonParams) => {
    const { mutate } = AccountingBudgetMutation.addBudget(commonParams);
    const callback = getNoOpCallback();
    return {
      run: (params) => {
        mutate(params, callback);
      },
      setCallback: getSetCallbackFunc(callback),
    };
  },
  useGetBudgetList: (params) => {
    const { data, isLoading } = AccountingBudgetQuery.useGetBudgetList(params);
    return {
      data: data ? convertAccountingBudgetApiView(data) : undefined,
      isLoading,
    };
  },
};

export default AccountingBudgetRepository;
