import { useMutation, useQueryClient } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';

import type { AxiosError } from 'axios';
import axios from 'axios';
import type { CollectionAddParameter } from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';

interface Props {
  commonParams: UIBuilderParameter;
  collectionId: number;
}

const collectionAddMutation = {
  useAdd: ({ commonParams, collectionId }: Props) => {
    const queryClient = useQueryClient();
    const url = `/api/project/sales/sales-information/collection-history/collection`;
    const mutationKey = [...getQueryKey(commonParams.dataId, collectionId), 'add'];

    const { mutate } = useMutation<'', AxiosError, CollectionAddParameter>({
      mutationFn: (params) => add(url, params, commonParams),
      mutationKey,
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales', commonParams.dataId, 'collection', 'history'],
        });
      },
    });

    return mutate;
  },
};

export default collectionAddMutation;

const add = async (url, params, commonParams) => {
  const { data } = await axios.post(url, params, {
    headers: getMenuIdHeaders(commonParams.menuId),
  });

  return data;
};

const getQueryKey = (dataId, collectionId) => [
  'project',
  'sales',
  dataId,
  'collection',
  'history',
  collectionId,
];
