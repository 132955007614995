import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import type { ModalProps } from '@mui/material/Modal';
import Modal from '@mui/material/Modal';
import ModalUIPositionDropDown, {
  getSxFromModalPosition,
} from '@front/src/components/components-with-design/layout/modal/PositionDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';
import { useShallow } from 'zustand/react/shallow';
import useQueryClientState from '@front/src/config/useQueryClientState';

export type ModalPositionType =
  | 'left'
  | 'top'
  | 'right'
  | 'bottom'
  | 'center'
  | 'fullscreen'
  | 'none';

/**
 * 커스터마이즈 가능한 모달 컴포넌트의 Props 인터페이스.
 *
 * `ModalProps`에서 `onClose`와 `children` 속성을 제외한 나머지 속성을 상속합니다.
 *
 * @interface Props
 * @extends Omit<ModalProps, 'onClose' | 'children'>
 *
 * @property {string} title - 모달의 제목. (필수)
 * @property {string} [subTitle] - 모달의 부제목 (선택 사항).
 * @property {'modal-default' | 'modal-full-screen'} [shape] - 모달의 형태를 결정. 기본값은 'modal-default'.
 * @property {boolean} [allowOverflowComponent] - 모달 경계를 넘는 컴포넌트 허용 여부. Date-Picker 등의 오동작 처리에 활용.
 * @property {boolean} [disableEnforceFocus] - 모달 포커스 트랩(focus trap) 사용 여부.
 * @property {ModalPositionType} [position] - 모달의 위치. 사용자 정의 배치를 설정할 수 있음.
 * @property {() => void} onClose - 모달 닫기 동작을 처리하는 콜백 함수. (필수)
 * @property {ReactNode} children - 모달 내부에 렌더링될 콘텐츠. (필수)
 */
interface Props extends Omit<ModalProps, 'onClose' | 'children'> {
  shape?: 'modal-default' | 'modal-full-screen';
  allowOverflowComponent?: boolean;
  disableEnforceFocus?: boolean;
  title: string;
  subTitle?: string;
  position?: ModalPositionType;
  onClose: () => void;
  children: ReactNode;
}

const componentObj = {
  'modal-default': ModalDefault,
  'modal-full-screen': ModalFullScreen,
};

export default function ModalUI({
  shape = 'modal-default',
  position = 'center',
  children,
  ...rest
}: Readonly<Props>) {
  const Component = componentObj[shape];

  return (
    <Component
      {...rest}
      position={position}
    >
      {children}
    </Component>
  );
}

interface ModalDefaultProps extends Omit<Props, 'shape' | 'position'> {
  position: ModalPositionType;
}

function ModalDefault(props: ModalDefaultProps) {
  const { sx = {}, children, ...rest } = props;

  const overflowStyle = props.allowOverflowComponent ?  {
    '& .MuiBox-root': {
      overflow: 'visible',
    },
    '& .MuiTableContainer-root' : {
      overflow: 'visible',
    },
  } : {};

  return (
    <Modal
      {...rest}
      className="modal-header"
      disableEnforceFocus={!!props.disableEnforceFocus}
      sx={{
        '& .MuiModal-backdrop': {
          background: ColorPalette.etc.modal_scrim,
        },
        ...sx,
        ...overflowStyle
      }}
    >
      <ModalDefaultBody {...rest}>{children}</ModalDefaultBody>
    </Modal>
  );
}

const ModalDefaultBody = React.forwardRef<HTMLDivElement, Omit<ModalDefaultProps, 'sx'>>(
  (props, ref) => {
    const { children, title, subTitle, position, onClose } = props;
    const [positionType, setPositionType] = useState<ModalPositionType>(position);

    const { setRefetchOnWindowFocus } = useQueryClientState(
      useShallow((state) => ({
        setRefetchOnWindowFocus: state.setRefetchOnWindowFocus,
      }))
    );

    useEffect(() => {
      setRefetchOnWindowFocus(false);
      return () => {
        setRefetchOnWindowFocus(true);
      };
    }, [setRefetchOnWindowFocus]);

    return (
      <Box
        ref={ref}
        tabIndex={-1}
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          minWidth: '400px',
          minHeight: '200px',
          maxHeight: '90%',
          borderRadius: '5px',
          boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.40)',
          overflow: 'hidden',
          outline: 'none',
          ...getSxFromModalPosition(positionType),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '42px',
            background: ColorPalette.background.bg04,
            padding: '0px 20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '1.6rem',
              fontWeight: '600',
              lineHeight: '2.2rem',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                color: ColorPalette.greyscale.white,
              }}
            >
              {title}
            </Box>
            {subTitle && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    fontWeight: '100',
                    color: ColorPalette.line.line02,
                  }}
                >
                  |
                </Box>
                <Box
                  sx={{
                    color: ColorPalette.sub.sub_primary,
                  }}
                >
                  {subTitle}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '14px',
            }}
          >
            <Box>
              <ModalUIPositionDropDown
                position={positionType}
                setPositionType={setPositionType}
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  '& span': {
                    color: '#a0a0a0 !important',
                  },
                  '& fieldset': {
                    border: 'none !important',
                  },
                }}
              />
            </Box>
            <Box
              onClick={onClose}
              sx={{
                fontSize: '2.0rem',
                color: ColorPalette.greyscale.white,
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon="xmark" />
            </Box>
          </Box>
        </Box>
        {children}
      </Box>
    );
  }
);

ModalDefaultBody.displayName = 'ModalDefaultBody';

interface ModalFullScreenProps extends Omit<Props, 'shape' | 'position'> {}

function ModalFullScreen({
  children,
  title,
  subTitle,
  onClose,
  ...rest
}: Readonly<ModalFullScreenProps>) {
  return (
    <Modal {...rest}>
      <Box
        sx={{
          position: 'fixed',
          width: '100dvw',
          height: '100dvh',
          outline: 'none',
        }}
      >
        <Box
          className="modal-header"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '50px',
            background: ColorPalette.background.bg,
            padding: '0px 15px 0px 10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '1.8rem',
              fontWeight: '600',
              lineHeight: '2rem',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                color: ColorPalette.greyscale.text_primary,
              }}
            >
              {title}
            </Box>
            {subTitle && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    fontWeight: '100',
                    color: ColorPalette.line.line02,
                  }}
                >
                  |
                </Box>
                <Box
                  sx={{
                    color: ColorPalette.sub.sub_primary,
                  }}
                >
                  {subTitle}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              fontSize: '2.0rem',
              color: ColorPalette.greyscale.text_secondary,
              cursor: 'pointer',
            }}
          >
            <Box onClick={onClose}>
              <FontAwesomeIcon icon="xmark" />
            </Box>
          </Box>
        </Box>
        <Box sx={{ height: 'calc(100% - 50px)' }}>{children}</Box>
      </Box>
    </Modal>
  );
}

export function ModalBodyUI({ children, sx = {} }: BoxProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        background: ColorPalette.background.bg,
        padding: '20px 0px',
        gap: '14px',
        overflow: 'hidden',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export function ModalContentUI({ children, sx = {} }: BoxProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: '1',
        padding: '0px 20px',
        overflowY: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export function ModalHeaderUI({ children, sx = {} }: BoxProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 20px',
        gap: '10px',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
