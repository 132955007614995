import React from 'react';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import type { CollectionHistoryView } from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import CollectionAddFeature from 'src/features/project-sales/features/tabs/collection/sections/history/features/add';

export interface CollectionAddModalProps
  extends Pick<
    CellComponentProps<CollectionHistoryView>,
    'menuId' | 'sectionId' | 'dataId' | 'readOnly'
  > {
  open: boolean;
  onClose: () => void;
  collectionData: {
    collectionHistoryId: number;
    collectionId: number;
    collectionSequence: string | null;
    collectionDueDate: string | null;
    unpaidAmount: number | null;
  };
}

export default function CollectionAddModal(props: CollectionAddModalProps) {
  const { open, onClose } = props;

  return (
    <ModalUI
      title="지급 단계 분할"
      onClose={onClose}
      open={open}
      allowOverflowComponent={true} // 모달 내부에서 date-picker 오동작 처리
    >
      <CollectionAddFeature {...props} />
    </ModalUI>
  );
}
