import { PresetData, PresetTransientData } from '@front/src/features/project-sales-db/features/preset/type';
import { CheckedAttributes } from '@front/src/features/project-sales-db/types';

/**
 * preset 목록에서 특정 id의 preset 리턴
 * @param presetId 목표 preset id
 * @param presetList preset 목록
 */
export const getActivePreset = (presetId: number, presetList: undefined | PresetData[]) => {
  return presetList?.find((presetItem) => presetItem.id === presetId);
};

/**
 * preset 데이터 serialization 함수
 * @param checkedAttributes 선택된 preset attrs.
 * @return serialized(=stringifies object) object
 */
export const serializePreset = (checkedAttributes: CheckedAttributes): string => {
  const saveData: PresetTransientData = {};
  Object.keys(checkedAttributes).forEach((headerId) => {
    const item = checkedAttributes[headerId];
    if (!item.tabId) return;
    if (!saveData[item.tabId]) {
      saveData[item.tabId] = {};
    }
    if (!saveData[item.tabId][item.parentId]) {
      saveData[item.tabId][item.parentId] = [];
    }
    saveData[item.tabId][item.parentId].push(item.id);
  });

  return JSON.stringify(saveData);
};
