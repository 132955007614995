import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import Box from '@mui/material/Box';
import DatePickerWithHookForm from '@front/src/components/hook-form/DatePicker';
import dayjs from 'dayjs';
import { YY_MM_DD } from '@front/src/utils';
import { useFormContext } from 'react-hook-form';

interface Props extends Omit<CellComponentProps, 'item' | 'formContext' | 'onCloseEditModal'> {
  name: string;
  disabled?: boolean;
  onAfterChange?: () => void;
}

export default function UIBuilderTableCellInputDate(props: Props) {
  const {
    onClick,
    sx,
    isEditMode,
    onSubmit,
    isForm,
    readOnly,
    name,
    disabled = false,
    onAfterChange,
  } = props;

  const { getValues } = useFormContext();

  const renderContent = () => {
    if (readOnly && (getValues(name) === '' || getValues(name) === null)) {
      return <>-</>;
    }

    if (readOnly && getValues(name) !== null) {
      return <>{dayjs(getValues(name)).format(YY_MM_DD)}</>;
    }

    return (
      <DatePickerWithHookForm
        name={name}
        onSubmit={isForm ? undefined : onSubmit}
        readOnly={readOnly || !isEditMode}
        disabled={disabled}
        onAfterChange={onAfterChange}
      />
    );
  };

  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
}
