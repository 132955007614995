import { useMutation, useQueryClient } from 'react-query';
import type {
  AddParameter,
  IdsDataParameter,
  SeqUpdateParameter,
  UIBuilderParameter,
  UpdateByFormDataParameter,
} from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';
import usePaymentTermModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/usePaymentTermsModalStore';
import { useShallow } from 'zustand/react/shallow';

const contractHistoryClientMutation = {
  useAdd: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, AddParameter>({
      mutationFn: (params) =>
        commonApi.add(
          `/api/project/sales/sales-information/contract/${params.parentId}/contract-client`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'add'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getParentQueryKey(commonParams.dataId),
        });
      },
    });

    return mutate;
  },

  useUpdateByFormData: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { clientId } = usePaymentTermModalStore(
      useShallow((state) => ({
        clientId: state.clientId,
      }))
    );

    const { mutate } = useMutation<unknown, Error, UpdateByFormDataParameter>({
      mutationFn: (params) =>
        commonApi.update(
          `/api/project/sales/sales-information/contract/contract-client/${params.id}`,
          params.formData,
          commonParams
        ),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getParentQueryKey(commonParams.dataId),
        });

        await queryClient.invalidateQueries({
          queryKey: [
            'project',
            'sales',
            commonParams.dataId,
            'contract',
            'history',
            clientId,
            'payment-terms',
          ],
        });
      },
    });

    return mutate;
  },

  useUpdateSeq: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, SeqUpdateParameter>({
      mutationFn: (params) =>
        commonApi.updateSeq(
          `/api/project/sales/sales-information/contract/${params.parentId}/contract-client/seq`,
          {
            id: params.id,
            operator: params.operator,
          },
          commonParams
        ),
      mutationKey: [...queryKey, 'update-seq'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getParentQueryKey(commonParams.dataId),
        });
      },
    });

    return mutate;
  },

  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { clientId } = usePaymentTermModalStore(
      useShallow((state) => ({
        clientId: state.clientId,
      }))
    );

    const { mutate } = useMutation<unknown, Error, IdsDataParameter>({
      mutationFn: (params) =>
        commonApi.delete(
          `/api/project/sales/sales-information/contract/${params.parentId}/contract-client`,
          {
            id: params.id,
            ...commonParams,
          },
          commonParams
        ),
      mutationKey: [...queryKey, 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: getParentQueryKey(commonParams.dataId),
        });

        await queryClient.invalidateQueries({
          queryKey: [
            'project',
            'sales',
            commonParams.dataId,
            'contract',
            'history',
            clientId,
            'payment-terms',
          ],
        });
      },
    });

    return mutate;
  },
};

export default contractHistoryClientMutation;

const getQueryKey = (dataId) => ['project', 'sales', dataId, 'contract', 'history', 'client'];

const getParentQueryKey = (dataId) => ['project', 'sales', dataId, 'contract', 'history'];
