import type { AddParameter } from '@front/src/types/parameter';
import { type IdsDataParameter, type SeqUpdateParameter } from '@front/src/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';
import type { ArithmeticOperator } from '@front/src/utils';
import { AddPosition } from '@front/src/utils';
import type { MutateOptions } from 'react-query';
import { useQueryClient } from 'react-query';
import type { UseFormReturn } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

interface Props<T = any> {
  onAdd: (
    params: AddParameter,
    options?: MutateOptions<unknown, Error, AddParameter, unknown> | undefined
  ) => void;

  onUpdateSeq: (
    params: SeqUpdateParameter,
    options?: MutateOptions<unknown, Error, SeqUpdateParameter, unknown> | undefined
  ) => void;
  onDelete: (
    params: IdsDataParameter,
    options?: MutateOptions<unknown, Error, IdsDataParameter, unknown> | undefined
  ) => void;

  onUpdate: (params: T, options?: MutateOptions<unknown, Error, T, unknown> | undefined) => void;
  formContext: UseFormReturn<UIBuilderTableFieldValues, any, undefined>;
  queryKey: (string | number | undefined)[];
}

const useUIBuilderTableRowMutation = <
  T extends {
    id: number;
  }
>({
  onAdd,
  onUpdateSeq,
  onDelete,
  formContext,
  onUpdate,
  queryKey,
}: Props<T>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useCustomDialog();
  const queryClient = useQueryClient();
  const { setValue } = formContext;
  const { toggleEditOpenId } = useGetUIBuilderTableEditState(formContext);

  const handleAdd = useCallback(
    (rowId: number) => {
      onAdd(
        {
          rowId,
          position: AddPosition.DEFAULT,
        },
        {
          onSuccess: (data, variables) => {
            enqueueSnackbar('새 항목이 추가되었습니다.', {
              variant: 'success',
            });

            const prevData: any[] | undefined = queryClient.getQueryData(queryKey);

            const findIndex = prevData?.findIndex((f) => f.id === variables.rowId);
            if (findIndex !== undefined && findIndex > -1 && prevData?.[findIndex + 1]) {
              const id = prevData[findIndex + 1].id;
              setValue('recentUpdatedId', id);
              toggleEditOpenId(id);
            }
          },
        }
      );
    },
    [onAdd, setValue, toggleEditOpenId, enqueueSnackbar, queryClient]
  );

  const handleUpdateSeq = useCallback(
    (id: number, operator: ArithmeticOperator) => {
      onUpdateSeq(
        {
          id,
          operator,
        },
        {
          onSuccess: async (data, variables) => {
            enqueueSnackbar('항목이 이동되었습니다.', {
              variant: 'success',
            });

            setValue('recentUpdatedId', variables.id);
          },
        }
      );
    },
    [onUpdateSeq, enqueueSnackbar, setValue]
  );

  const handleUpdate = useCallback(
    (params: T) => {
      onUpdate(params, {
        onSuccess: async (data, variables) => {
          enqueueSnackbar('변경사항이 저장되었습니다.', {
            variant: 'success',
          });

          setValue('recentUpdatedId', variables.id);
        },
      });
    },
    [onUpdate, enqueueSnackbar, setValue]
  );

  const handleDelete = useCallback(
    (id) => {
      onDelete(
        { id },
        {
          onSuccess: async () => {
            enqueueSnackbar('항목이 삭제되었습니다.', {
              variant: 'success',
            });

            setValue('recentUpdatedId', undefined);
          },
        }
      );
    },
    [onDelete, setValue, enqueueSnackbar]
  );

  const handleDeleteAfterConfirm = useCallback(
    (id) => {
      confirm({
        lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
        confirmText: '확인',
        closeText: '취소',
        afterConfirm: () => {
          handleDelete(id);
        },
      });
    },
    [confirm, handleDelete]
  );

  return {
    onAdd: handleAdd,
    onUpdateSeq: handleUpdateSeq,
    onDelete: handleDeleteAfterConfirm,
    onUpdate: handleUpdate,
  };
};

export default useUIBuilderTableRowMutation;
