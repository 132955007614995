import { useMutation, useQueryClient } from 'react-query';
import type {
  AddParameter,
  IdsDataParameter,
  SeqUpdateParameter,
  UIBuilderParameter,
} from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';
import type { ContractHistoryUpdateParameter } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/parameter';

const contractHistoryMutation = {
  useAdd: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, AddParameter>({
      mutationFn: (params) =>
        commonApi.add(
          `/api/project/sales/sales-information/${commonParams.dataId}/contract`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'add'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },

  useUpdate: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, ContractHistoryUpdateParameter>({
      mutationFn: (params) =>
        commonApi.update(
          `/api/project/sales/sales-information/contract/${params.id}`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },

  useUpdateSeq: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, SeqUpdateParameter>({
      mutationFn: (params) =>
        commonApi.updateSeq(
          `/api/project/sales/sales-information/${commonParams.dataId}/contract/seq`,
          params,
          commonParams
        ),
      mutationKey: [...queryKey, 'update-seq'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },

  useDelete: (commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId);

    const { mutate } = useMutation<unknown, Error, IdsDataParameter>({
      mutationFn: (params) =>
        commonApi.delete(
          `/api/project/sales/sales-information/${commonParams.dataId}/contract`,
          { ...params, ...commonParams },
          commonParams
        ),
      mutationKey: [...queryKey, 'delete'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },
};

export default contractHistoryMutation;

const getQueryKey = (dataId) => ['project', 'sales', dataId, 'contract', 'history'];
