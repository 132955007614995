import { useFieldArray, useForm } from 'react-hook-form';

import type { CollectionAddModalProps } from '@front/src/features/project-sales/features/tabs/collection/sections/history/components/AddModal';

const useCollectionAddForm = ({
  collectionData,
}: Pick<CollectionAddModalProps, 'collectionData'>) => {
  const methods = useForm<CollectionAddFormValues>({
    defaultValues: {
      list: [],
      collectionData,
    },
  });

  const fieldArray = useFieldArray<CollectionAddFormValues, 'list', 'id'>({
    control: methods.control,
    name: 'list',
  });

  return {
    methods,
    fieldArray,
  };
};

export default useCollectionAddForm;

export interface CollectionAddFormValues {
  list: CollectionAddFormListValues[];
  collectionData: {
    collectionHistoryId: number;
    collectionId: number;
    collectionSequence: string | null;
    collectionDueDate: string | null;
    unpaidAmount: number | null;
  };
}

export interface CollectionAddFormListValues {
  collectionDueDate: string | null;
  expectedCollectionAmount: number | null;
  ratio: number | null;
}
