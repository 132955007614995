import headerMeta, { arraySupportKeyword } from '@front/src/components/ui-builder/headerMeta';
import { VariableSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HeaderSettingView } from '@front/src/types';
import projectSalesDbAnalysisQuery from '@front/src/features/project-sales-db/query/query';
import { useVirtualScrollbar } from '@front/src/features/project-sales-db/hooks/useVirtualScrollbar';
import useTableBodyRowRenderer from '@front/src/features/project-sales-db/features/data-list/hooks/useTableBodyRowRenderer';
import useTableLogic from '@front/src/features/project-sales-db/features/data-list/hooks/useTableLogic';
import DataListHeader from '@front/src/features/project-sales-db/features/data-list/components/data-list-header';
import { CheckedAttributes } from '@front/src/features/project-sales-db/types';
import './data-list.scss';
import {
  ActiveFilterKeywords,
  CellAlignment,
  FilterKeywords,
  ProjectSalesDbSearchParameter,
  SummaryRowData,
} from '@front/src/features/project-sales-db/features/data-list/types';
import { getNestedAttribute } from '@front/src/utils';

interface DataListProps {
  menuId: number;
  cellHeight: number;
  searchParams: ProjectSalesDbSearchParameter;
  checkedAttributes: CheckedAttributes;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  scrollbarRef: React.RefObject<HTMLDivElement>;
}

export default function DataList(props: DataListProps) {
  const {
    menuId,
    cellHeight,
    searchParams,
    checkedAttributes,
    setIsLoading,
    scrollbarRef,
  } = props;
  const outerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<VariableSizeList>(null);
  const [searchedList, setSearchedList] = useState<any[]>([]);
  const [list, setList] = useState<any[]>([]);
  const [headerList, setHeaderList] = useState<HeaderSettingView[]>([]);
  const [cellAlignment, setCellAlignment] = useState<CellAlignment[]>([]);
  const [tableWidth, setTableWidth] = useState(300);
  const [summary, setSummary] = useState<SummaryRowData>([]);
  const [filterKeywords, setFilterKeywords] = useState<FilterKeywords>({});
  const [activeFilterKeywords, setActiveFilterKeywords] = useState<ActiveFilterKeywords>({});
  const [headerHeight, setHeaderHeight] = useState(80);

  const {
    calcSummaryData,
    calcFilterData,
    prepareHeaderList,
    getMaxRowCount,
    handleCellValue,
    getCellAlignment,
  } = useTableLogic();
  useVirtualScrollbar({ outerRef, scrollbarRef });

  const { data: rawList, isLoading } = projectSalesDbAnalysisQuery.useList(searchParams, menuId);

  useEffect(() => {
    const headerElement = document.getElementsByClassName('header-container')[0];
    if (headerElement) {
      setHeaderHeight(headerElement.clientHeight);
    }
  }, []);

  useEffect(() => {
    const newHeaderList = prepareHeaderList(checkedAttributes);
    setHeaderList(newHeaderList);
    setTableWidth(
      newHeaderList.reduce((tableWidth, headerConf) => {
        return tableWidth + headerMeta[headerConf.id].width;
      }, 0)
    );

    let newList = rawList?.map((item) => ({ ...item.data })) || [];
    //newList.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    // const temp: any[] = [];
    // newList.forEach((value) => {
    //   for (let i = 0; i < 1000; i++) {
    //     temp.push({ ...value, id: value.id + i * 50 });
    //   }
    // });
    // setSearchedList(temp);
    setSearchedList(newList);
  }, [rawList, checkedAttributes]);

  useEffect(() => {
    const newFilterKeywords: FilterKeywords = {};
    searchedList.forEach((item) => {
      calcFilterData({
        filterKeywords: newFilterKeywords,
        headerList: headerList,
        item: item,
      });
    });
    setFilterKeywords(newFilterKeywords);

    const newState: ActiveFilterKeywords = {};
    Object.keys(newFilterKeywords).forEach((headerIndex) => {
      const temp = Object.keys(newFilterKeywords[headerIndex]);
      newState[headerIndex] = temp.map((value) => ({ keyword: value, use: true }));
    });
    setActiveFilterKeywords(newState);
  }, [searchedList]);

  useEffect(() => {
    let newList = [...searchedList];
    const filterColumnIndices = Object.keys(activeFilterKeywords);

    if (filterColumnIndices.length > 0) {
      newList = searchedList.filter((item) => {
        let found = true;
        filterColumnIndices.forEach((index) => {
          const headerItem = headerList[index];
          if (!headerItem) return;

          const meta = headerMeta[headerItem.id];
          let compareValue: any = undefined;

          if (meta.attributeName) {
            const attrName = meta.attributeName.join('.');
            if (meta.group) {
              const temp = getNestedAttribute(item, meta.group);
              if (Array.isArray(temp)) {
                if (temp && temp.length > 0) {
                  temp.forEach((innerItem) => {
                    if (meta.attributeName?.includes(arraySupportKeyword)) {
                      const arrayIndexPosition = meta.attributeName?.findIndex(
                        (value1) => value1 === arraySupportKeyword
                      )!;
                      const temp2 = getNestedAttribute(
                        innerItem,
                        meta.attributeName[arrayIndexPosition - 1]
                      );
                      temp2.forEach((innerItem2) => {
                        const attrNameWithoutIndex = meta.attributeName
                          ?.slice(arrayIndexPosition + 1)
                          .join('.');

                        compareValue = getNestedAttribute(innerItem2, attrNameWithoutIndex!);
                      });
                    } else {
                      compareValue = getNestedAttribute(innerItem, attrName);
                    }
                  });
                }
              } else {
                compareValue = getNestedAttribute(temp, attrName) ?? 0;
              }
            } else {
              compareValue = getNestedAttribute(item, attrName) ?? 0;
            }
          }

          const hasKeyword =
            !compareValue && typeof compareValue !== 'boolean'
              ? false
              : activeFilterKeywords[index]
                  ?.filter((value) => !value.use)
                  .some((value) => {
                    if (typeof compareValue === 'boolean') {
                      compareValue = compareValue ? 'Y' : 'N';
                    }
                    return value.keyword === `${compareValue}`; // && value.use;
                  });
          found = found && !hasKeyword;
        });
        return found;
      });
    }

    if (searchParams.keyword.length > 0) {
      newList = filterList(newList, searchParams.keyword);
    }

    const newSummary: SummaryRowData = headerList.map((headerConf) =>
      headerMeta[headerConf.id].summary ? 0 : undefined
    );
    newList.forEach((item) => {
      calcSummaryData({
        accumulatedSummaryData: newSummary,
        headerList: headerList,
        item: item,
      });
    });
    setSummary(newSummary);
    setCellAlignment(newSummary.map(getCellAlignment));

    listRef.current?.resetAfterIndex(0, true);

    setList(newList);
  }, [searchParams, activeFilterKeywords]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  const onItemsRendered = useCallback(() => {
    if (scrollbarRef.current && outerRef.current) {
      const heightHolder = scrollbarRef.current.getElementsByClassName(
        'height-mirror'
      )[0] as HTMLDivElement;
      if (heightHolder) {
        heightHolder.style.height = `${outerRef.current.scrollHeight}px`;
      }
    }
  }, [scrollbarRef, outerRef]);

  const getRowHeight = useCallback(
    (index: number) => {
      const filteredItem = {};
      Object.keys(checkedAttributes).forEach((key) => {
        const value = checkedAttributes[key];
        const meta: any = headerMeta[value.id];
        filteredItem[meta.group] = list[index][meta.group];
      });
      const size = getMaxRowCount(filteredItem, headerList);
      return size === 0 ? cellHeight : size * cellHeight;
    },
    [list, checkedAttributes]
  );

  const { Row } = useTableBodyRowRenderer({
    cellHeight: cellHeight,
    headerList: headerList,
    getRowHeight: getRowHeight,
    handleCellValue: handleCellValue,
    cellAlignment: cellAlignment,
  });

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <table>
            <DataListHeader
              headerList={headerList}
              list={list}
              cellAlignment={cellAlignment}
              checkedAttributes={checkedAttributes}
              summary={summary}
              filterKeywords={filterKeywords}
              activeFilterKeywords={activeFilterKeywords}
              setActiveFilterKeywords={setActiveFilterKeywords}
            />
            <VariableSizeList
              ref={listRef}
              height={height - headerHeight} // 전체 높이에서 헤더의 높이를 뺌
              width={tableWidth > width ? tableWidth : width - 2}
              itemCount={list.length}
              itemSize={getRowHeight} // 각 행 높이
              itemData={list} // 데이터를 전달
              overscanCount={3}
              outerRef={outerRef}
              innerElementType={'tbody'}
              onItemsRendered={onItemsRendered}
            >
              {Row}
            </VariableSizeList>
          </table>
        );
      }}
    </AutoSizer>
  );
}

// 특정 keyword를 포함하는 항목만 필터링하는 함수
function containsKeyword(value: unknown, keyword: string): boolean {
  if (typeof value === 'string') {
    return value.toLowerCase().includes(keyword.toLowerCase());
  } else if (typeof value === 'number') {
    return `${value}`.includes(keyword);
  } else if (typeof value === 'boolean') {
    return value ? keyword === 'Y' : keyword === 'N';
  }

  if (typeof value === 'object' && value !== null) {
    // 객체일 경우 재귀적으로 검사
    return Object.values(value).some((val) => containsKeyword(val, keyword));
  }

  return false;
}

function filterList(arr: unknown[], keyword: string): unknown[] {
  return arr.filter((item) => {
    if (typeof item === 'object' && item !== null) {
      // 각 항목에 대해 `containsKeyword`를 호출하여 필터링
      return containsKeyword(item, keyword);
    }
    return false;
  });
}
