import type { SectionComponent } from '@front/src/components/ui-builder/sectionComponent';
import React from 'react';
import AccountingSettingsBankAccountFeature from '@front/src/features/accounting/features/settings/features/bank-account';
import AccountingSettingsDailyReportStatusFeature from '@front/src/features/accounting/features/settings/features/daily-report-status';
import AccountingUploadHistoryManagementUploadFeature from '@front/src/features/accounting/features/upload/features/history-management-upload';
import AccountingUploadSalesInfoFeature from '@front/src/features/accounting/features/upload/features/sales-info';
import AccountingAccountManagementAccountSettingsTaxAccountFeature from '@front/src/features/accounting/features/account-management/features/account-settings/features/tax-account';
import ManagementAccountFeature from '@front/src/features/accounting/features/account-management/features/account-settings/features/management-account';
import AccountStatusSummaryTableFeature
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/sections/summary';
import AccountStatusDetailTableFeature
  from '@front/src/features/accounting/features/account-management/features/account-settings/features/account-status-table/sections/detail';
import AccountingBudgetSection from '@front/src/features/accounting/features/budget/section';

export { sectionComponent as accountingSectionComponent };

const sectionComponent: SectionComponent = {
  // 82: ({ ...rest }) => <AccountingSettingsTaxAccountingAccountFeature {...rest} />,
  82: ({ ...rest }) => <AccountingAccountManagementAccountSettingsTaxAccountFeature {...rest} />,
  // 83: ({ ...rest }) => <AccountingSettingsManagementAccountingAccountFeature {...rest} />,
  83: ({ ...rest }) => <ManagementAccountFeature {...rest} />,
  84: ({ ...rest }) => <AccountingSettingsBankAccountFeature {...rest} />,
  85: ({ ...rest }) => <AccountingSettingsDailyReportStatusFeature {...rest} />,
  86: ({ ...rest }) => <AccountingUploadHistoryManagementUploadFeature {...rest} />,
  // 87: ({ ...rest }) => <AccountingSettingsManagementAccountingReadFeature {...rest} />,
  87: ({ ...rest }) => (
    <ManagementAccountFeature
      {...rest}
      readOnly
    />
  ),
  89: ({ ...rest }) => <AccountingUploadSalesInfoFeature {...rest} />,
  96: ({ ...rest }) =>   <AccountStatusSummaryTableFeature {...rest} />,
  97: ({ ...rest }) => <AccountStatusDetailTableFeature {...rest} />,
  106: ({...rest }) => <AccountingBudgetSection {...rest} />,
};
