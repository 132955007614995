import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React, { useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import type { EstimationHistoryView } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/views';
import useUIBuilderTableRowMutation from '@front/src/hooks/ui-builder-table/useTableRowMutation';
import useUIBuilderTableRowLogic from '@front/src/hooks/ui-builder-table/useTableRowLogic';
import useEstimationHistoryTableRowForm from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useTableRowForm';
import estimationHistoryMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/mutation';
import type { EstimationHistoryUpdateParameter } from '@front/src/features/project-sales/features/tabs/estimation/sections/history/types/parameter';
import useEstimationHistoryTableRowUpdate from '@front/src/features/project-sales/features/tabs/estimation/sections/history/hooks/useUpdate';
import { useSnackbar } from 'notistack';

export { TableBody as EstimationHistoryTableBody };

function TableBody(props: Readonly<UIBuilderTableBodyProps>) {
  return (
    <UIBuilderTableBody<EstimationHistoryView>
      {...props}
      RowComponent={TableRow}
    />
  );
}

function TableRow(props: UIBuilderTableRowProps<EstimationHistoryView>) {
  const { item, formContext, dataId, menuId, sectionId } = props;

  const { methods } = useEstimationHistoryTableRowForm(item);
  const { handleSubmit } = methods;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const { onAdd, onUpdateSeq, onDelete, onUpdate } =
    useUIBuilderTableRowMutation<EstimationHistoryUpdateParameter>({
      onAdd: estimationHistoryMutation.useAdd(commonParams),
      onUpdateSeq: estimationHistoryMutation.useUpdateSeq(commonParams),
      onDelete: estimationHistoryMutation.useDelete(commonParams),
      onUpdate: estimationHistoryMutation.useUpdate(commonParams),
      formContext,
      queryKey: ['project', 'sales', dataId, 'estimation', 'history'],
    });

  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = useCallback(() => {
    if (!item.estimation.canDelete) {
      enqueueSnackbar('계약과 연결된 행이 있어 삭제할 수 없습니다.', {
        variant: 'warning',
      });
      return;
    }

    onDelete(item.id);
  }, [item, enqueueSnackbar, onDelete]);

  const { sx, isEditOpen, contextMenuItems } = useUIBuilderTableRowLogic({
    formContext,
    item,
    onAdd,
    onUpdateSeq,
    onDelete: handleDelete,
  });

  const onSubmit = useEstimationHistoryTableRowUpdate(onUpdate, item);
  const disabled = useMemo(() => !item.estimation.canDelete, [item]);

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow
        {...props}
        onSubmit={handleSubmit(onSubmit)}
        itemList={contextMenuItems}
        isEditMode={isEditOpen}
        sx={sx}
        disabled={disabled}
      />
    </FormProvider>
  );
}
