import React from 'react';
import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';

import type { CollectionFinalView } from '@front/src/features/project-sales/features/tabs/collection/sections/final/types/views';

import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellText from '@front/src/components/ui-builder/table/cell-renderer/Text';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';

interface Props extends CellComponentProps<CollectionFinalView> {}

/**
 * 발주처별 비율
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="paymentStage"
  />
);

/**
 *  각사 지불단계비율
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="paymentSequence"
    type="percent"
  />
);

/**
 * 계약발주처
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanySelector
    {...props}
    name="client"
  />
);

/**
 * 발주처 지불담당자
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <UIBuilderTableCellUserSelector
    {...props}
    name="clientCollectionManager"
  />
);

/**
 * 계약수금시기
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="paymentTiming"
  />
);

/**
 * 계약응당일
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="expectedPaymentDate"
  />
);

/**
 * 발주처별 비율
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="clientRatio"
    type="percent"
  />
);

/**
 * 각사 지불단계비율
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="companyStageRatio"
    type="percent"
  />
);

/**
 * 계약총액
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="totalContractPrice"
  />
);

/**
 * 계약순풍동금액
 * @param props
 * @constructor
 */
const CellRenderer10 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="windTunnelPrice"
  />
);

/**
 * 계약실풍동금액 = 계약총액 - (계약구검비율액 + 계약CM비율액)
 * @param props
 * @constructor
 */
const CellRenderer11 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="actualWindTunnelPrice"
  />
);

/**
 * 계약구검
 * @param props
 * @constructor
 */
const CellRenderer13 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="structuralReviewPrice"
  />
);

/**
 * 계약구검비율
 * @param props
 * @constructor
 */
const CellRenderer14 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="structuralReviewRatio"
    type="percent"
  />
);

/**
 * 계약구검 지급대상
 * @param props
 * @constructor
 */
const CellRenderer15 = (props: Props) => (
  <UIBuilderTableCellAffiliatedCompanySelector
    {...props}
    name="structuralReviewPaymentTarget"
  />
);

/**
 * 계약구검 지급예정일
 * @param props
 * @constructor
 */
const CellRenderer16 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="structuralReviewPaymentDate"
  />
);

/**
 * 계약CM비율액 = 계약총액 * 계약CM비율
 * @param props
 * @constructor
 */
const CellRenderer17 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="contractCM"
  />
);

/**
 * 계약CM비율
 * @param props
 * @constructor
 */
const CellRenderer18 = (props: Props) => (
  <UIBuilderTableCellInputNumber
    {...props}
    name="cmRatio"
    type="percent"
  />
);

export const collectionFinalTableCells: CellComponent = {
  603: CellRenderer1, // 계약지불단계
  604: CellRenderer2, // 계약지불순번
  605: CellRenderer3, // 계약발주처
  606: CellRenderer4, // 발주처 지불담당자
  607: CellRenderer5, // 계약수금시기
  608: CellRenderer6, // 계약응당일
  609: CellRenderer7, // 발주처별 비율
  610: CellRenderer8, // 각사 지불단계비율
  611: CellRenderer9, // 계약총액
  612: CellRenderer9, // 계약총액
  613: CellRenderer10, // 계약순풍동금액
  614: CellRenderer11, // 계약실풍동금액
  615: CellRenderer13, // 계약구검
  616: CellRenderer14, // 계약구검비율
  617: CellRenderer15, // 계약구검 지급대상
  618: CellRenderer16, // 계약구검 지급예정일
  619: CellRenderer17, // 계약CM비율액 = 계약총액 * 계약CM비율
  620: CellRenderer18, // 계약CM비율
};
