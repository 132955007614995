import {
  ActiveFilterKeywords,
  FilterKeywords,
  KeywordWithOccurrenceCount,
} from '@front/src/features/project-sales-db/features/data-list/types';
import React, { useCallback, useState } from 'react';
import useTableHeadPopOver from '@front/src/features/project-sales-db/features/data-list/hooks/useTableHeadPopOver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorPalette } from '@front/assets/theme';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import TextFieldBasicUI from '@front/src/components/components-with-design/component/TextFieldBasicUI';
import CheckboxBasicUI from '@front/src/components/components-with-design/component/CheckboxBasicUI';
import useTableLogic from '@front/src/features/project-sales-db/features/data-list/hooks/useTableLogic';
import { HeaderSettingView } from '@front/src/types';

interface FilterPopOverProps {
  index: number;
  list: any[];
  headerList: HeaderSettingView[];
  keywords: KeywordWithOccurrenceCount;
  activeFilterKeywords: ActiveFilterKeywords;
  setActiveFilterKeywords: React.Dispatch<React.SetStateAction<ActiveFilterKeywords>>;
}
export default function FilterPopOver(props: FilterPopOverProps) {
  const { keywords, index, activeFilterKeywords } = props;
  const { anchorEl, onOpen, onClose } = useTableHeadPopOver();
  const open = Boolean(anchorEl);

  const handleOpen = useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      onOpen(event);
    },
    [keywords]
  );

  const activeFilterItemCount =
    activeFilterKeywords[index]?.filter((value) => value.use).length ?? 0;
  const filterItemCount = activeFilterKeywords[index]?.length ?? 0;

  return (
    <>
      <FontAwesomeIcon
        icon="square-caret-down"
        onClick={handleOpen}
        style={{
          cursor: 'pointer',
          color:
            activeFilterItemCount === filterItemCount ? '#333' : ColorPalette.main.main_primary,
        }}
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <FilterPopOverContents
          {...props}
          onClose={onClose}
        />
      </Menu>
    </>
  );
}

interface FilterPopOverContentsProps {
  index: number;
  list: any[];
  headerList: HeaderSettingView[];
  keywords: KeywordWithOccurrenceCount;
  activeFilterKeywords: ActiveFilterKeywords;
  setActiveFilterKeywords: React.Dispatch<React.SetStateAction<ActiveFilterKeywords>>;
  onClose: () => void;
}

function FilterPopOverContents(props: Readonly<FilterPopOverContentsProps>) {
  const {
    list,
    headerList,
    onClose,
    keywords,
    index,
    activeFilterKeywords,
    setActiveFilterKeywords,
  } = props;
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const { calcFilterData } = useTableLogic();

  const handleSearchKeyword = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearchKeyword(event.target.value);
    },
    []
  );

  const newFilterKeywords: FilterKeywords = {};
  list.forEach((item) => {
    calcFilterData({
      filterKeywords: newFilterKeywords,
      headerList: headerList,
      item: item,
    });
  });

  const [filterKeywords, setFilterKeywords] = useState<{ keyword: string; use: boolean }[]>(
    activeFilterKeywords[index] ? [...activeFilterKeywords[index]] : []
  );

  const onSubmit = useCallback(() => {
    setActiveFilterKeywords((prevState) => {
      const newState = { ...prevState };
      if (filterKeywords.length > 0) {
        newState[index] = filterKeywords;
      } else {
        delete newState[index];
      }
      return newState;
    });
    onClose();
  }, [filterKeywords, index]);

  const onChangeItem = useCallback((checked: boolean, value: string) => {
    setFilterKeywords((prevState) => {
      let newState = [...prevState];

      newState = newState.map((value1) => ({
        ...value1,
        use: value1.keyword === value ? checked : value1.use,
      }));

      return newState;
    });
  }, []);

  return (
    <Box
      sx={{
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        '& .Mui-disabled': {
          display: 'none',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
        }}
      >
        <ButtonBasicUI
          shape="tertiary"
          size="medium"
          onClick={onClose}
          sx={{
            width: '64px',
          }}
        >
          취소
        </ButtonBasicUI>
        <ButtonBasicUI
          shape="primary2"
          size="medium"
          onClick={onSubmit}
          sx={{
            width: '64px',
          }}
        >
          확인
        </ButtonBasicUI>
      </Box>
      <TextFieldBasicUI onChange={handleSearchKeyword} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {Object.keys(keywords)
          .sort()
          .filter((value) => value.indexOf(searchKeyword) > -1)
          .filter((value) => {
            const visibleKeywordsInList = newFilterKeywords[index] ? Object.keys(newFilterKeywords[index]) : [];
            const filterState = activeFilterKeywords[index].find(
              (value1) => value1.keyword === value
            );

            if (visibleKeywordsInList.includes(value)) {
              return true;
            } else {
              if (filterState) {
                return !filterState.use;
              } else {
                return true;
              }
            }
          })
          .map((keyword) => {
            const temp = filterKeywords.find((value) => value.keyword === keyword);

            return (
              <FilterPopOverContentItem
                key={keyword}
                value={keyword}
                checked={temp?.use ?? false}
                handleChange={onChangeItem}
                {...props}
              />
            );
          })}
      </Box>
    </Box>
  );
}

interface FilterPopOverContentItemProps {
  index: number;
  value: string;
  checked: boolean;
  activeFilterKeywords: ActiveFilterKeywords;
  setActiveFilterKeywords: React.Dispatch<React.SetStateAction<ActiveFilterKeywords>>;
  handleChange: (checked: boolean, value: string) => void;
}

function FilterPopOverContentItem({
  value,
  checked,
  handleChange,
}: Readonly<FilterPopOverContentItemProps>) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.checked, value);
  };

  return (
    <CheckboxBasicUI
      label={`${value}`}
      checked={checked}
      onChange={onChange}
    />
  );
}
