import React, { Suspense } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import { contractCmTableCells } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/table-cells';
import ContractCmModal from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/Modal';

export { Section as ContractCmSection };

function Section(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(contractCmTableCells);
  return (
    <Suspense fallback={<LoadingSpinnerUI />}>
      <ContractCmModal {...props} />
    </Suspense>
  );
}
