import type { UseFieldArrayReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import type { ControlPanelItem } from '@front/src/types/domain';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ColorPalette } from '@front/assets/theme';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import type { ContractPaymentTermFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';
import { getContractPaymentTermDefaultRow } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/utils';

const useContractPaymentTermFormUpdateTableRowLogic = (
  fieldArray: UseFieldArrayReturn<ContractPaymentTermFormValues, 'list', string>,
  rowIndex: number
) => {
  const { getValues, setValue, watch } = useFormContext<ContractPaymentTermFormValues>();
  const { insert } = fieldArray;

  const item = useMemo(() => getValues('list')[rowIndex], [rowIndex]);
  const isEditMode = watch('editOpenIdList')?.includes(item?.id);

  const onAdd = useCallback(() => {
    const defaultRow = getContractPaymentTermDefaultRow(
      getValues('clientId'),
      getValues('contractClientCompany'),
      getValues('parent')
    );
    insert(rowIndex + 1, defaultRow);

    setValue('lastUpdatedId', defaultRow.id);
    setValue('editOpenIdList', [...getValues('editOpenIdList'), defaultRow.id]);
  }, [insert, setValue]);

  const onToggleEdit = useCallback(() => {
    const copy = [...getValues('editOpenIdList')];
    const findIndex = copy.findIndex((f) => f === item.id);
    if (findIndex > -1) {
      copy.splice(findIndex, 1);
      setValue('editOpenIdList', copy);
    } else {
      setValue('editOpenIdList', [...copy, item.id]);
    }
  }, [getValues]);

  const { enqueueSnackbar } = useSnackbar();
  const onDelete = useCallback(() => {
    const list = getValues('list');
    if (!list[rowIndex].canDelete) {
      enqueueSnackbar('수금액이 있어 삭제할 수 없습니다.', {
        variant: 'warning',
      });

      return;
    }

    if (item.rowId) {
      setValue('deleteList', [...getValues('deleteList'), item]);
    }

    list.splice(rowIndex, 1);
    setValue('list', list);
  }, [getValues, enqueueSnackbar, setValue]);

  const contextMenuItems: ControlPanelItem[] = useMemo(
    () => [
      {
        text: '추가',
        icon: AddCircleOutlineIcon,
        action: onAdd,
        seq: 2,
      },
      {
        text: isEditMode ? '수정 닫기' : '수정',
        icon: EditIcon,
        action: onToggleEdit,
        split: true,
        seq: 3,
      },
      {
        text: '삭제',
        icon: DeleteIcon,
        action: onDelete,
        iconColor: '#e43333',
        seq: 100,
      },
    ],
    [onAdd, isEditMode, onToggleEdit, onDelete]
  );

  const sx = {
    backgroundColor: watch('lastUpdatedId') === item?.id ? `${ColorPalette._d2e7fa}` : 'inherit',
  };

  return {
    sx,
    contextMenuItems,
    isEditMode,
  };
};

export default useContractPaymentTermFormUpdateTableRowLogic;
