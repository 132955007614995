import type React from 'react';
import { useState } from 'react';

const useTableHeadPopOver = () => {
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return {
    anchorEl,
    onOpen: handleClick,
    onClose: handleClose,
  };
};

export default useTableHeadPopOver;
