import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DefaultSectionComponent } from '@front/type/Function';
import useAccountingBudgetDiagramTableHook from '@front/src/features/accounting/features/budget/hook/diagram-table';
import AccountingBudgetRepository from '@front/src/features/accounting/features/budget/repository';
import {
  apiResponseToAccountBudgetManagementAccountingList,
} from '@front/src/features/accounting/features/budget/util/convert';
import {
  AccountingBudgetForm,
  UpdateActivatedManagementType,
} from '@front/src/features/accounting/features/budget/type';
import AccountingBudgetTable from '@front/src/features/accounting/features/budget/component/common/table';

const AccountingBudgetDiagramTable: DefaultSectionComponent = (props) => {
  const { menuId, dataId } = props;

  const {
    data: managementAccountingDataList,
  } = AccountingBudgetRepository.useGetAccountManagementList({
    menuId,
    dataId,
  });

  const {
    watch: watchParent,
    setValue: setParentValue,
  } = useFormContext<AccountingBudgetForm>();

  const updateActivateManagement: UpdateActivatedManagementType = (activatedManagement) => {
    setParentValue('activatedManagement', activatedManagement);
  };

  const {
    methods,
    updateManagementList,
    reflectActivatedManagement,
    reflectBudgetYear,
  } = useAccountingBudgetDiagramTableHook(updateActivateManagement);
  const { watch } = methods;

  const managementList = watch('managementList');
  const budgetYear = watchParent('budgetYear');
  const activatedManagement = watchParent('activatedManagement');

  useEffect(() => {
    updateActivateManagement(undefined);
  }, [managementList]);

  useEffect(() => {
    if (managementAccountingDataList) {
      updateManagementList(apiResponseToAccountBudgetManagementAccountingList(managementAccountingDataList));
    }
  }, [managementAccountingDataList]);

  useEffect(() => {
    reflectBudgetYear(budgetYear);
  }, [budgetYear]);

  useEffect(() => {
    reflectActivatedManagement(activatedManagement);
  }, [activatedManagement]);


  return (
    <AccountingBudgetTable
      {...props}
      methods={methods}
    />
  );
};

export default AccountingBudgetDiagramTable;
