import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { CheckedAttributes } from '@front/src/features/project-sales-db/types';
import './preset-tabs.scss';
import usePresetLogic from '@front/src/features/project-sales-db/features/preset/hooks/usePresetLogic';
import { LoadingSpinner } from '@front/src/components/loading-spinner';
import useGetReadOnlyFromMenuId from '@front/src/hooks/useGetReadOnlyFromMenuId';

interface PresetTabsProps {
  menuId: number;
  checkedAttributes: CheckedAttributes;
  setCheckedAttributes: React.Dispatch<React.SetStateAction<CheckedAttributes>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PresetTabs(props: PresetTabsProps) {
  const { readOnly } = useGetReadOnlyFromMenuId(props.menuId);
  const { isLoading, presetId, presetList, onPresetSelect, onSave, onSaveAs, onDelete } =
    usePresetLogic({
      ...props,
    });

  return (
    <>
      <div className="tabs-wrap">
        {isLoading && <LoadingSpinner width="30px" />}
        {!isLoading && (
          <Tabs
            value={presetId}
            onChange={onPresetSelect}
            variant="scrollable"
            scrollButtons={true}
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="기본"
              value={0}
              key={0}
            />
            {presetList?.map((preset) => (
              <Tab
                label={preset.name? preset.name : 'blank'}
                value={preset.id}
                key={preset.id}
              />
            ))}
          </Tabs>
        )}
      </div>
      {!readOnly && (
        <div className="actions-wrap">
          <ButtonBasicUI
            onClick={onSave}
            shape="primary2"
          >
            저장
          </ButtonBasicUI>
          <ButtonBasicUI
            onClick={onSaveAs}
            shape="primary2"
            disabled={presetId === 0}
          >
            다른이름으로 저장
          </ButtonBasicUI>
          <ButtonBasicUI
            onClick={onDelete}
            shape="secondary2"
            disabled={presetId === 0}
          >
            삭제
          </ButtonBasicUI>
        </div>
      )}
    </>
  );
}
