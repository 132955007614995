import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { usePersonalSettingsModalState } from '@front/src/features/personal-settings-modal/useState';
import { useShallow } from 'zustand/react/shallow';
import type { AuthPersonalSettingsUserView } from '@front/src/features/personal-settings-modal/features/auth-settings/types/view';
import {
  getTab,
  sortKor,
} from '@front/src/features/personal-settings-modal/features/auth-settings/utils';
import {
  getPersonalSettingsModalControlPanelHeaderItemList,
  settingsModalRowDummy,
} from '@front/src/features/personal-settings-modal/features/auth-settings/utils/contstants';
import {
  Box,
  MenuItem,
  Select,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Input from '@front/layouts/Input';
import Button from '@front/layouts/Button';
import { Table } from '@front/layouts/Table';
import { WorkTh } from '@front/src/features/work/features/work/components/work-th';
import { ControlPanel } from '@front/src/components/control-panel/control-panel';
import TextBox from '@front/layouts/Text';
import { NoResult } from '@front/src/components/layout/table/no-result';
import { ColorPalette } from '@front/assets/theme';
import { PersonalSettingsModalAuthSettingsContext } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/user-settings/provider/context';
import { PersonalSettingsModalDeptSettingsWidget } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/dept-settings/widget';
import { PersonalSettingsModalTableUserRow } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/user-settings/components/settings-modal-table-user-row';
import { PersonalSettingsModalTableRow } from '@front/src/features/personal-settings-modal/features/auth-settings/widgets/user-settings/components/settings-modal-table-row';
import { useCancelBlockModalState } from '@front/src/features/modal/features/cancel-block-modal/useState';

export { UserSettings as PersonalSettingsModalAuthSettings };
interface Props {
  authMenuId: number | undefined;
}
const UserSettings = ({ authMenuId }: Props) => {
  const {
    setEditOpenList,
    setAnchorEls,
    setColoredId,
    newItemIdList,
    setNewItemIdList,
    createList,
    updateList,
    deleteList,
    setSearchKeyword,
    setUpdateList,
    setCreateList,
    setDeleteList,
    authType,
    setAuthType,
    clearEditOpenList,
  } = usePersonalSettingsModalState(
    useShallow((state) => ({
      setAnchorEls: state.setAnchorEls,
      setEditOpenList: state.setEditOpenList,
      setColoredId: state.setColoredId,
      newItemIdList: state.newItemIdList,
      setNewItemIdList: state.setNewItemIdList,
      createList: state.createList,
      updateList: state.updateList,
      deleteList: state.deleteList,
      setSearchKeyword: state.setSearchKeyword,
      setUpdateList: state.setUpdateList,
      setCreateList: state.setCreateList,
      setDeleteList: state.setDeleteList,
      authType: state.authType,
      setAuthType: state.setAuthType,
      clearEditOpenList: state.clearEditOpenList,
    }))
  );
  const {
    setOpen,
    reset: resetStore,
    setIsDirty,
    setTitle,
  } = useCancelBlockModalState(
    useShallow((state) => ({
      setOpen: state.setOpen,
      reset: state.reset,
      setIsDirty: state.setIsDirty,
      setTitle: state.setTitle,
    }))
  );
  const tableRef = useRef<HTMLDivElement>(null);
  const { clientUserList, onAuthCud, setClientUserList } = useContext(
    PersonalSettingsModalAuthSettingsContext
  );
  const newList = clientUserList?.map((item) => ({
    ...item,
    originalId: item.id,
  }));
  const methods = useForm<AuthPersonalSettingsUserView>({
    values: {
      users: sortKor(newList ?? []),
    },
  });
  const {
    reset,
    control,
    formState: { isDirty },
  } = methods;
  const { fields, append, prepend, replace, insert, remove } = useFieldArray({
    control: control,
    name: 'users',
  });
  const [inputVal, setInputVal] = useState('');
  const actions = {
    onAddTop: () => {
      const newDummy = settingsModalRowDummy();
      prepend(newDummy);
      setColoredId(newDummy.id);
      setNewItemIdList([...newItemIdList, newDummy.id]);
    },
    onAddBottom: () => {
      const newDummy = settingsModalRowDummy();
      append(newDummy);
      setColoredId(newDummy.id);
      setNewItemIdList([...newItemIdList, newDummy.id]);
    },
    onCloseAll: () => setEditOpenList(),
  };
  const onModalConfirmClick = useCallback(() => {
    setUpdateList([]);
    setCreateList([]);
    setDeleteList([]);
    setColoredId(0);
    clearEditOpenList();
    methods.reset();
    reset();
  }, [
    setUpdateList,
    setCreateList,
    setDeleteList,
    setColoredId,
    clearEditOpenList,
    methods,
    reset,
  ]);
  const onSaveClick = () => {
    onAuthCud({ createIdList: createList, updateIdList: updateList, deleteIdList: deleteList });
    onModalConfirmClick();
  };
  const onCancelClick = useCallback(() => {
    if (isDirty) {
      setOpen(true, () => {
        onModalConfirmClick();
        resetStore();
      });
      setTitle('취소 확인');
      return;
    }
    onModalConfirmClick();
  }, [isDirty, setOpen, onModalConfirmClick, resetStore]);
  const onSelectChange = useCallback(
    (e) => {
      if (isDirty) {
        setOpen(true, () => {
          onModalConfirmClick();
          setAuthType(e.target.value);
          resetStore();
        });
        return;
      }
      setAuthType(e.target.value);
    },
    [isDirty, setOpen, setAuthType, resetStore, onModalConfirmClick]
  );
  useEffect(() => () => setAnchorEls({}), [setAnchorEls]);
  useEffect(() => {
    setIsDirty(isDirty);
  }, [setIsDirty, isDirty]);
  useEffect(
    () => () => {
      reset();
      setIsDirty(false);
      onModalConfirmClick();
    },
    [reset, setIsDirty, onModalConfirmClick]
  );

  const authScopeName = getTab() ?? '페이지';
  if (!clientUserList) return null;
  return (
    <>
      {authType === '부서권한' && (
        <PersonalSettingsModalDeptSettingsWidget authMenuId={authMenuId} />
      )}
      {authType === '개인권한' && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
            >
              <Select
                sx={{
                  height: '32px',
                  fontSize: '13px',
                }}
                value={authType}
                onChange={(e) => onSelectChange(e)}
                variant="outlined"
              >
                <MenuItem
                  value={'개인권한'}
                  selected={true}
                  sx={{
                    fontSize: '13px',
                  }}
                >
                  개인권한
                </MenuItem>
                <MenuItem
                  value={'부서권한'}
                  sx={{
                    fontSize: '13px',
                  }}
                >
                  부서권한
                </MenuItem>
              </Select>
              <Input
                variant="outlined"
                onChange={(e) => setInputVal(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSearchKeyword({ keyword: inputVal });
                  }
                }}
                value={inputVal}
              />
              <Button onClick={() => setSearchKeyword({ keyword: inputVal })}>검색</Button>
              <Button
                shape="basic2"
                onClick={() => {
                  setSearchKeyword({ keyword: '' });
                  setInputVal('');
                }}
              >
                초기화
              </Button>
            </Box>
            <Box
              display="flex"
              gap="8px"
              alignItems="center"
            >
              <TextBox variant="body2">저장하지 않은 내용은 반영되지 않습니다.</TextBox>
              <Button
                shape="basic2"
                onClick={onCancelClick}
              >
                취소
              </Button>
              <Button onClick={() => onSaveClick()}>저장</Button>
            </Box>
          </Box>
          <FormProvider {...methods}>
            <TableContainer
              sx={{
                display: 'flex',
                maxHeight: '65dvh',
                gap: '6px',
                backgroundColor: ColorPalette._252627,
              }}
              ref={tableRef}
            >
              <Table
                stickyHeader
                sx={{
                  borderRadius: 'none',
                }}
              >
                <TableHead>
                  <TableRow>
                    <WorkTh width="1%">
                      <ControlPanel
                        isHeader={true}
                        itemList={getPersonalSettingsModalControlPanelHeaderItemList({
                          actions,
                        })}
                      />
                    </WorkTh>
                    <WorkTh
                      height="50px"
                      width="120px"
                    >
                      부서명
                    </WorkTh>
                    <WorkTh
                      width="100px"
                      sx={{ fontWeight: 'bold' }}
                    >
                      직책
                    </WorkTh>
                    <WorkTh
                      width="100px"
                      sx={{ fontWeight: 'bold' }}
                    >
                      사번
                    </WorkTh>
                    <WorkTh
                      width="120px"
                      sx={{ fontWeight: 'bold' }}
                    >
                      이름
                    </WorkTh>
                    <WorkTh width="120px">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box
                          fontSize="13px"
                          fontWeight="bold"
                        >
                          관리등급
                        </Box>
                        <Box
                          fontSize="13px"
                          fontWeight="bold"
                        >
                          (역할)
                        </Box>
                      </Box>
                    </WorkTh>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.length === 0 && <NoResult colSpan={6} />}
                  {fields.length > 0 &&
                    fields?.map((item, index, arr) => (
                      <PersonalSettingsModalTableUserRow
                        key={item.id}
                        item={item}
                        tableRef={tableRef}
                        setClientUserList={setClientUserList}
                        index={index}
                        insert={insert}
                        remove={remove}
                        arr={arr}
                        replace={replace}
                      />
                    ))}
                </TableBody>
              </Table>
              <Table
                sx={{
                  borderRadius: 'none',
                }}
              >
                <TableHead sx={{ position: 'sticky', top: 0 }}>
                  <TableRow>
                    <WorkTh
                      colSpan={2}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {authScopeName}
                    </WorkTh>
                  </TableRow>
                  <TableRow>
                    <WorkTh sx={{ fontWeight: 'bold' }}>부서</WorkTh>
                    <WorkTh sx={{ fontWeight: 'bold' }}>개인</WorkTh>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.length === 0 && <NoResult colSpan={2} />}
                  {fields.length > 0 &&
                    fields?.map((item, index) => (
                      <PersonalSettingsModalTableRow
                        key={item.id}
                        rowIdx={index}
                        item={item}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </FormProvider>
        </>
      )}
    </>
  );
};
