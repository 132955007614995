import React, { useCallback, useEffect, useRef, useState } from 'react';
import useSetMenuAndTab from '@front/src/hooks/useSetMenuAndTab';
import useNoteMenuId from '@front/src/hooks/useNoteMenuId';
import '@front/src/components/ui-builder/table/ui-builder-table.css';

import DataList from '@front/src/features/project-sales-db/features/data-list/components/data-list';
import './components/project-sales-db.scss';
import {
  projectSalesDbTableCellHeight,
  projectSalesMenuIdForProjectSalesDb,
} from '@front/src/features/project-sales-db/utils/constants';
import { CheckedAttributes } from '@front/src/features/project-sales-db/types';
import DataSetTree from '@front/src/features/project-sales-db/features/data-set-tree/components/data-set-tree';
import PresetTabs from '@front/src/features/project-sales-db/features/preset/components/preset-tabs';
import BackdropLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/BackdropLoadingSpinnerUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@front/layouts/IconButton';
import { ProjectSalesDbSearchParameter } from '@front/src/features/project-sales-db/features/data-list/types';
import SearchForm, {
  projectSalesDbDefaultSearchCondition,
} from './features/search-form/components/search-form';
import headerMeta from '@front/src/components/ui-builder/headerMeta';
import { shallowEqual, useSelector } from 'react-redux';
import type { RootState } from '@front/services/reducer';
import PersonalSettingsFeature from '@front/src/features/personal-settings-modal';

export default function ProjectSalesDbFeature() {
  const [isLoading, setIsLoading] = React.useState(false);
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const listWrapRef = useRef<HTMLDivElement>(null);
  const { menuId } = useSetMenuAndTab();
  useNoteMenuId(menuId);
  if (!menuId) {
    console.info(`No menuId found. Cannot serve requested feature.`);
    return <>Unauthorized</>;
  }

  const loginUser = useSelector((root: RootState) => root.login.detail, shallowEqual);
  const isValid = loginUser?.role.canAccessPageAuthorization || loginUser?.role.canAccessPageView;

  const [searchParams, setSearchParams] = useState<ProjectSalesDbSearchParameter>(
    projectSalesDbDefaultSearchCondition
  );
  const [checkedAttributes, setCheckedAttributes] = useState<CheckedAttributes>({});
  const [asideOpen, setAsideOpen] = useState<boolean>(true);

  useEffect(() => {
    const headerMetaIds = Object.keys(checkedAttributes);
    const dataGroupMap = {};
    headerMetaIds.forEach((headerMetaIds) => {
      const meta = headerMeta[headerMetaIds];
      if (meta.group) {
        dataGroupMap[meta.group] = true;
      }
    });
    setSearchParams((prevState) => ({
      ...prevState,
      dataGroup: [...Object.keys(dataGroupMap)],
    }));
  }, [checkedAttributes]);

  const handleButtonClick = useCallback(() => {
    setAsideOpen((prevState) => !prevState);
  }, []);

  return (
    <div className="project-sales-db">
      <BackdropLoadingSpinnerUI open={isLoading} />
      <div className="header-container">
        <div className="search-form-wrap">
          <SearchForm setSearchParams={setSearchParams} />
          <div
            style={{
              position: 'absolute',
              right: 15,
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
            }}
          >
            {isValid && <PersonalSettingsFeature authMenuId={menuId} />}
          </div>
        </div>
        <div className="preset-wrap">
          <PresetTabs
            menuId={menuId}
            checkedAttributes={checkedAttributes}
            setCheckedAttributes={setCheckedAttributes}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
      <div className="body-container">
        <div
          className="list-wrap"
          ref={listWrapRef}
        >
          <DataList
            menuId={menuId}
            cellHeight={projectSalesDbTableCellHeight}
            searchParams={searchParams}
            checkedAttributes={checkedAttributes}
            scrollbarRef={scrollbarRef}
            setIsLoading={setIsLoading}
          />
        </div>
        <div className="scroll-wrap">
          <div className="virtual-scroll-bar-empty-area"></div>
          <div
            className="virtual-scroll-bar"
            ref={scrollbarRef}
          >
            <div className="height-mirror"></div>
          </div>
        </div>
        <div className={`tree-wrap ${asideOpen ? 'open' : 'closed'}`}>
          <IconButton
            tooltip={asideOpen ? '접기' : '펴기'}
            onClick={handleButtonClick}
          >
            <FontAwesomeIcon
              icon="angle-left"
              style={{
                fontSize: '1.4rem',
                color: '#ffffff',
                transition: 'transform .2s',
                transform: asideOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </IconButton>
          <DataSetTree
            menuId={menuId}
            projectSalesMenuId={projectSalesMenuIdForProjectSalesDb}
            checkedAttributes={checkedAttributes}
            setCheckedAttributes={setCheckedAttributes}
          />
        </div>
      </div>
    </div>
  );
}
