import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import React, { useMemo } from 'react';

import estimationCompetitorMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/competitor/query/mutation';
import useUIBuilderTableHeadMutation from '@front/src/hooks/ui-builder-table/useTableHeadMutation';
import useGetUIBuilderTableHeadContextMenu from '@front/src/hooks/ui-builder-table/useTableHeadLogic';

export { TableHead as EstimationCompetitorTableHead };

function TableHead(props: UIBuilderTableHeadProps) {
  const { dataId, menuId, sectionId } = props;
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const { onAdd, onDeleteAll } = useUIBuilderTableHeadMutation({
    onAdd: estimationCompetitorMutation.useAdd(commonParams),
    onDelete: estimationCompetitorMutation.useDelete(commonParams),
    commonParams,
    queryKey: ['project', 'sales', dataId, 'estimation', 'competitor'],
  });

  const { itemList } = useGetUIBuilderTableHeadContextMenu({
    onAdd,
    onDeleteAll,
  });

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={itemList}
    />
  );
}
