import React, { Suspense } from 'react';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { collectionClaimDocumentTableCells } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/components/TableCell';
import CollectionClaimDocumentModal from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/components/Modal';

export default function CollectionClaimDocumentSection(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(collectionClaimDocumentTableCells);

  return (
    <Suspense fallback={<LoadingSpinnerUI />}>
      <CollectionClaimDocumentModal {...props} />
    </Suspense>
  );
}
