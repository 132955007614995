import type { AddParameter } from '@front/src/types/parameter';
import { type IdsDataParameter, type SeqUpdateParameter } from '@front/src/types/parameter';
import { useCustomDialog } from '@front/src/features/dialog';
import type { ArithmeticOperator } from '@front/src/utils';
import { AddPosition } from '@front/src/utils';
import type { MutateOptions } from 'react-query';
import useGetUIBuilderTableEditState from '@front/src/components/ui-builder/table/hooks/useGetEditState';
import type { UseFormReturn } from 'react-hook-form';
import type { UIBuilderTableFieldValues } from '@front/src/components/ui-builder/table/hooks/useForm';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

interface Props {
  onAdd: (
    params: AddParameter,
    options?: MutateOptions<unknown, Error, AddParameter, unknown> | undefined
  ) => void;

  onUpdateSeq: (
    params: SeqUpdateParameter,
    options?: MutateOptions<unknown, Error, SeqUpdateParameter, unknown> | undefined
  ) => void;
  onDelete: (
    params: IdsDataParameter,
    options?: MutateOptions<unknown, Error, IdsDataParameter, unknown> | undefined
  ) => void;

  formContext: UseFormReturn<UIBuilderTableFieldValues, any, undefined>;
  queryKey: (string | number | undefined)[];
}

const useUIBuilderTableRowChildMutation = ({
  onAdd,
  onUpdateSeq,
  onDelete,
  formContext,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useCustomDialog();
  const { setValue } = formContext;
  const { addIfNotExist } = useGetUIBuilderTableEditState(formContext);

  const handleAdd = useCallback(
    (rowId: number, parentId: number) => {
      onAdd(
        {
          parentId,
          rowId,
          position: AddPosition.DEFAULT,
        },
        {
          onSuccess: (data, variables) => {
            enqueueSnackbar('새 항목이 추가되었습니다.', {
              variant: 'success',
            });

            setValue('recentUpdatedId', variables.parentId);
            addIfNotExist(variables.parentId);
          },
        }
      );
    },
    [onAdd, enqueueSnackbar, setValue, addIfNotExist]
  );

  const handleUpdateSeq = useCallback(
    (id: number, operator: ArithmeticOperator, parentId: number) => {
      onUpdateSeq(
        {
          id,
          operator,
          parentId,
        },
        {
          onSuccess: async (data, variables) => {
            enqueueSnackbar('항목이 이동되었습니다.', {
              variant: 'success',
            });

            setValue('recentUpdatedId', variables.parentId);
          },
        }
      );
    },
    [onUpdateSeq, enqueueSnackbar, setValue]
  );

  const handleDelete = useCallback(
    (id: number, parentId: number) => {
      onDelete(
        { id, parentId },
        {
          onSuccess: async (data, variables) => {
            enqueueSnackbar('항목이 삭제되었습니다.', {
              variant: 'success',
            });

            setValue('recentUpdatedId', variables.parentId);
          },
        }
      );
    },
    [onDelete, enqueueSnackbar, setValue]
  );

  const handleDeleteAfterConfirm = useCallback(
    (id: number, parentId: number) => {
      confirm({
        lineBreakChildren: [{ value: '선택한 내용을 삭제하시겠습니까?' }],
        confirmText: '확인',
        closeText: '취소',
        afterConfirm: () => {
          handleDelete(id, parentId);
        },
      });
    },
    [confirm, handleDelete]
  );

  return {
    onAdd: handleAdd,
    onUpdateSeq: handleUpdateSeq,
    onDelete: handleDeleteAfterConfirm,
  };
};

export default useUIBuilderTableRowChildMutation;
