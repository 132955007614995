import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import { useFormContext } from 'react-hook-form';
import { ContractCmTableBody } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/table-body';
import { ContractCmTableHead } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/table-head';
import type { ContractCmFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/hooks/useTabForm';
import type { ContractCmModalProps } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/Modal';

export { Table as ContractCmTable };

function Table(props: ContractCmModalProps) {
  const formContext = useFormContext<ContractCmFormValues>();

  const list = formContext.watch('list');

  return (
    <UiBuilderTable
      {...props}
      useFormUpdate
      useSummary
      formUpdateFormContext={formContext}
      HeadComponent={(props) => (
        <ContractCmTableHead
          {...props}
          formUpdateFormContext={formContext}
        />
      )}
      BodyComponent={(props) => (
        <ContractCmTableBody
          {...props}
          formUpdateFormContext={formContext}
        />
      )}
      list={list}
    />
  );
}
