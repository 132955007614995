import React, { useMemo } from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import contractHistoryQuery from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/query';
import { ContractHistoryTableBody } from '@front/src/features/project-sales/features/tabs/contract/sections/history/components/table-body';
import { ContractHistoryTableHead } from '@front/src/features/project-sales/features/tabs/contract/sections/history/components/table-head';
import useGetUIBuilderTableLoading from '@front/src/hooks/ui-builder-table/useGetLoading';

export { Table as ContractHistoryTable };

function Table(props: SectionComponentProps) {
  const { dataId, menuId, sectionId } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const list = contractHistoryQuery.useGetList(commonParams);

  const isLoading = useGetUIBuilderTableLoading(dataId, (dataId) => [
    'project',
    'sales',
    dataId,
    'contract',
    'history',
  ]);

  const isClientLoading = useGetUIBuilderTableLoading(dataId, (dataId) => [
    'project',
    'sales',
    dataId,
    'contract',
    'history',
    'client',
  ]);

  return (
    <UiBuilderTable
      {...props}
      HeadComponent={ContractHistoryTableHead}
      BodyComponent={ContractHistoryTableBody}
      list={list}
      isLoading={isLoading || isClientLoading}
    />
  );
}
