import type { UIBuilderParameter } from '@front/src/types/parameter';
import axios, { type AxiosResponse } from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';
import {
  PresetData,
  PresetParameter,
} from '@front/src/features/project-sales-db/features/preset/type';

const baseUrl = '/api/project/sales/sales-information/db-analysis/preset';

const url = {
  create: () => `${baseUrl}`,
  getList: () => `${baseUrl}`,
  getOne: (id: number) => `${baseUrl}/${id}`,
  modify: (id: number) => `${baseUrl}/${id}`,
  remove: (id: number) => `${baseUrl}/${id}`,
};

export const projectSalesDbAnalysisPresetApi = {
  create: async (presetParams: PresetParameter, menuId: number) => {
    const { data } = await axios.post(url.create(), presetParams, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  getList: async <T = PresetData[]>(menuId: number) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getList(), {
      headers: getMenuIdHeaders(menuId),
      params: {},
    });
    return data;
  },
  getOne: async <T = PresetData>(commonParams: UIBuilderParameter) => {
    const { data } = await axios.get<T, AxiosResponse<T>>(url.getOne(commonParams.dataId!), {
      headers: getMenuIdHeaders(commonParams.menuId),
    });
    return data;
  },
  modify: async (presetParams: PresetParameter, presetId: number, menuId: number) => {
    const { data } = await axios.patch(url.modify(presetId), presetParams, {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
  remove: async (id: number, menuId: number) => {
    const { data } = await axios.delete(url.remove(id), {
      headers: getMenuIdHeaders(menuId),
    });
    return data;
  },
};
