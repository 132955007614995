import React from 'react';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import CollectionTaxInvoiceTableHead from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/TableHead';
import CollectionTaxInvoiceTableBody from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/TableBody';
import { useIsMutating } from 'react-query';
import { useFieldArray, useFormContext } from 'react-hook-form';
import type { CollectionTaxInvoiceFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/hooks/useForm';
import type { CollectionTaxInvoiceModalProps } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/Modal';

export default function CollectionTaxInvoiceTable(props: CollectionTaxInvoiceModalProps) {
  const { dataId, collectionId } = props;

  const formContext = useFormContext<CollectionTaxInvoiceFormValues>();

  const isLoading = !!useIsMutating({
    mutationKey: ['project', 'sales', dataId, 'collection', collectionId, 'tax-invoice', 'update'],
  });

  const fieldArray = useFieldArray<CollectionTaxInvoiceFormValues, 'list', string>({
    control: formContext.control,
    name: 'list',
    keyName: 'fieldId',
  });

  return (
    <UiBuilderTable
      {...props}
      useFormUpdate
      isLoading={isLoading}
      formUpdateFormContext={formContext}
      HeadComponent={(headProps) => (
        <CollectionTaxInvoiceTableHead
          {...headProps}
          fieldArray={fieldArray}
        />
      )}
      BodyComponent={(bodyProps) => (
        <CollectionTaxInvoiceTableBody
          {...bodyProps}
          fieldArray={fieldArray}
        />
      )}
      list={fieldArray.fields}
    />
  );
}
