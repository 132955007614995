import create from 'zustand';

interface State {
  collectionId: number | null;
}

interface Actions {
  setCollectionId: (collectionId: number | null) => void;
}

const useCollectionDueDateHistoryStore = create<State & Actions>((set) => ({
  collectionId: null,
  setCollectionId: (collectionId) => set({ collectionId }),
}));

export default useCollectionDueDateHistoryStore;
