import React from 'react';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';

export default function AccountingBudgetSaveButton() {

  return (
    <ButtonBasicUI
      shape="tertiary"
      size="medium"
    >
      임시 저장
    </ButtonBasicUI>
  );
}
