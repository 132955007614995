import { useCallback, useMemo } from 'react';
import { collectionClaimDocumentFormDefaultRow } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/utils';
import { onDownloadFromATag } from '@front/src/utils';
import type { ControlPanelItem } from '@front/src/types/domain';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Download } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ColorPalette } from '@front/assets/theme';

const useCollectionModalTableRowLogic = (formUpdateFormContext, fieldArray, rowIndex) => {
  const { watch, getValues, setValue } = formUpdateFormContext!;
  const { insert } = fieldArray;
  const editOpenIdList = watch('editOpenIdList');
  const lastUpdatedId = watch('lastUpdatedId');
  const item = useMemo(() => watch('list')[rowIndex], [watch, rowIndex]);
  const isEditMode = useMemo(() => editOpenIdList.includes(item?.id), [item, editOpenIdList]);

  const onAdd = useCallback(() => {
    const defaultRow = collectionClaimDocumentFormDefaultRow();
    insert(rowIndex + 1, defaultRow);

    setValue('lastUpdatedId', defaultRow.id);
    setValue('editOpenIdList', [...getValues('editOpenIdList'), defaultRow.id]);
  }, [insert, setValue, getValues()]);

  const onToggleEdit = useCallback(() => {
    const copy = [...getValues('editOpenIdList')];
    const findIndex = copy.findIndex((f) => f === item.id);
    if (findIndex > -1) {
      copy.splice(findIndex, 1);
      setValue('editOpenIdList', copy);
    } else {
      setValue('editOpenIdList', [...copy, item.id]);
    }
  }, [getValues, setValue, item]);

  const onDelete = useCallback(() => {
    const list = [...getValues('list')];
    if (item.rowId) {
      setValue('deleteList', [...getValues('deleteList'), list[rowIndex]]);
    }
    list.splice(rowIndex, 1);
    setValue('list', list);
  }, [setValue, getValues, rowIndex, item]);

  const handleDownload = useCallback(() => {
    if (item.fileItem) {
      onDownloadFromATag(`/api/file-item/${item.fileItem.id}`);
    }
  }, [item]);

  const contextMenuItems: ControlPanelItem[] = useMemo(
    () => [
      {
        text: '추가',
        icon: AddCircleOutlineIcon,
        action: onAdd,
        seq: 2,
      },
      {
        text: isEditMode ? '수정 닫기' : '수정',
        icon: EditIcon,
        action: onToggleEdit,
        split: true,
        seq: 3,
      },
      {
        text: '다운로드',
        icon: Download,
        action: handleDownload,
        seq: 99,
      },
      {
        text: '삭제',
        icon: DeleteIcon,
        action: onDelete,
        iconColor: '#e43333',
        seq: 100,
      },
    ],
    [onAdd, isEditMode, onToggleEdit, handleDownload, onDelete]
  );

  const sx = useMemo(
    () => ({
      backgroundColor: lastUpdatedId === item?.id ? `${ColorPalette._d2e7fa}` : 'inherit',
    }),
    [lastUpdatedId, item]
  );

  return {
    contextMenuItems,
    isEditMode,
    sx,
  };
};

export default useCollectionModalTableRowLogic;
