import React from 'react';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import type { CollectionAddFormProps } from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/components/ModalBody';
import CollectionAddTableListItem from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/components/ListItem';

export default function CollectionAddTableList(props: CollectionAddFormProps) {
  const { fieldArray } = props;

  const { fields } = fieldArray;

  if (fields.length === 0)
    return (
      <TableRowUI>
        <TableCellUI colSpan={4}>결과가 없습니다.</TableCellUI>
      </TableRowUI>
    );

  return (
    <>
      {fields.map((field, index) => (
        <CollectionAddTableListItem
          {...props}
          key={field.id}
          index={index}
        />
      ))}
    </>
  );
}
