import collectionHistoryCollectionMutation from '@front/src/features/project-sales/features/tabs/collection/sections/history/query/collectionMutation';
import type { UseFormReturn } from 'react-hook-form';
import type { CollectionHistoryFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/history/hooks/useForm';
import type { CollectionHistoryView } from '@front/src/features/project-sales/features/tabs/collection/sections/history/types/views';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import { useCallback, useMemo } from 'react';

interface Props
  extends Pick<CellComponentProps<CollectionHistoryView>, 'dataId' | 'sectionId' | 'menuId'> {
  formUpdateFormContext: UseFormReturn<CollectionHistoryFormValues, any, undefined>;
}

const useCollectionUpdate = ({ dataId, sectionId, menuId, formUpdateFormContext }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { getValues } = formUpdateFormContext;
  const commonParams = useMemo(() => ({ dataId, sectionId, menuId }), [dataId, sectionId, menuId]);
  const onUpdate = collectionHistoryCollectionMutation.useUpdate(commonParams);

  const onSubmit = useCallback(
    (index: number) => {
      const value = getValues(`collectionList.${index}`);

      const params = {
        id: value.id,
        bondStatus: value.bondStatus || null,
        clientCompanyPersonId: value.clientCompanyPerson?.id ?? null,
        collectionTiming: value.collectionTiming,
        collectionDueDate: value.collectionDueDate
          ? dayjs(value.collectionDueDate).format(YYYY_MM_DD)
          : null,
        collectionDelayDays: value.collectionDelayDays,
        collectionPossibility: value.collectionPossibility,
        expectedCollectionAmount: value.expectedCollectionAmount,
        collectionAmount: value.collectionAmount,
        unpaidAmount: value.unpaidAmount,
        collectionCompletionDate: value.collectionCompletionDate,
        managerId: value.manager?.id ?? null,
      };

      onUpdate(params, {
        onSuccess: () => {
          enqueueSnackbar('변경사항이 저장되었습니다.', {
            variant: 'success',
          });
        },
      });
    },
    [onUpdate, enqueueSnackbar]
  );

  return onSubmit;
};

export default useCollectionUpdate;
