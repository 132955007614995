import { useForm } from 'react-hook-form';
import type {
  ContractCmObjectView,
  ContractCmView,
} from '@front/src/features/project-sales/features/tabs/contract/sections/cm/types/views';
import type { ContractFormUpdateFormValues } from '@front/src/features/project-sales/features/tabs/contract/types';
import { useEffect } from 'react';
import { getContractCmFormUpdateValues } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/utils';
import type { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';

const useContractCmTabForm = (
  defaultClientId: number,
  dataObject: ContractCmObjectView | undefined,
  historyList: ContractHistoryView[] | undefined
) => {
  const methods = useForm<ContractCmFormValues>({
    defaultValues: getDefaultValues(defaultClientId, dataObject, historyList),
  });

  const { watch, getValues, reset } = methods;

  useEffect(() => {
    const watchClientId = watch('clientId');
    if (dataObject && watchClientId) {
      const { clientId, list, deleteList, contractClientCompany } = getContractCmFormUpdateValues(
        watchClientId,
        dataObject
      );

      reset({
        totalContractPrice: getValues('totalContractPrice'),
        lastUpdatedId: getValues('lastUpdatedId'),
        editOpenIdList: getValues('editOpenIdList'),
        clientId,
        list,
        deleteList,
        contractClientCompany,
      });
    }
  }, [reset, dataObject]);

  return methods;
};

export default useContractCmTabForm;

const getDefaultValues = (
  clientId: number,
  dataObject: ContractCmObjectView | undefined,
  historyList: ContractHistoryView[] | undefined
): ContractCmFormValues => {
  const find = historyList?.flatMap((f) => f.contractClient).find((f) => f.id === clientId);

  return {
    ...getContractCmFormUpdateValues(clientId, dataObject ?? {}),
    lastUpdatedId: null,
    editOpenIdList: [],
    totalContractPrice: find?.totalContractPrice,
  };
};

export interface ContractCmFormValues
  extends ContractFormUpdateFormValues<ContractCmListFormValues> {
  totalContractPrice: number | null | undefined;
}

export interface ContractCmListFormValues extends Omit<ContractCmView, 'id'> {
  id: string;
  rowId: number | null;
  parentId: number | null;
}
