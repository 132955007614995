import { useQuery } from 'react-query';
import { projectSalesDbAnalysisApi } from '@front/src/features/project-sales-db/api/api';
import { ProjectSalesDbSearchParameter } from '@front/src/features/project-sales-db/features/data-list/types';

const projectSalesDbAnalysisQuery = {
  useList: (params: ProjectSalesDbSearchParameter, menuId: number) => {
    const { data, isLoading } = useQuery({
      queryKey: [
        'project',
        'sales-db-analysis',
        'list',
        params.dataGroup.toString(),
        params.searchTarget,
        params.startDate,
        params.endDate,
      ],
      queryFn: () => projectSalesDbAnalysisApi.getList(params, menuId),
      select: (data) => data.slice().reverse(),
      suspense: false,
    });
    return {
      data,
      isLoading,
    };
  },
};

export default projectSalesDbAnalysisQuery;
