import type { UseFormReturn } from 'react-hook-form';

import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import type { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';
import contractHistoryClientMutation from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/clientMutation';
import type { ContractHistoryFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/history/hooks/useForm';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';

interface Props
  extends Pick<
    CellComponentProps<ContractHistoryView>,
    'dataId' | 'sectionId' | 'menuId' | 'item'
  > {
  formUpdateFormContext: UseFormReturn<ContractHistoryFormValues, any, undefined>;
}

const useContractHistoryClientUpdate = ({
  dataId,
  sectionId,
  menuId,
  item,
  formUpdateFormContext,
}: Props) => {
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);
  const { enqueueSnackbar } = useSnackbar();

  const { getValues } = formUpdateFormContext;
  const onUpdate = contractHistoryClientMutation.useUpdateByFormData(commonParams);

  const onSubmit = useCallback(
    (index) => {
      if (!item.contractClient[index].canDelete) {
        enqueueSnackbar('수금액이 있어 수정할 수 없습니다.', { variant: 'warning' });
        return;
      }

      const value = getValues(`contractClient.${index}`);

      if (!value?.id) return;

      const formData = new FormData();

      if (value.excelFile && value.excelFile.isNew && value.excelFile.multipartFile) {
        formData.append('excel', value.excelFile.multipartFile);
      }

      if (value.pdfFile && value.pdfFile.isNew && value.pdfFile.multipartFile) {
        formData.append('pdf', value.pdfFile.multipartFile);
      }

      const request = {
        seq: value.seq,
        contractType: value.contractType ?? null,
        contractDate: value.contractDate ? dayjs(value.contractDate).format(YYYY_MM_DD) : null,
        clientCompanyId: value.clientCompany?.id ?? null,
        clientRatio: value.clientRatio ?? null,
        projectName: value.projectName ?? null,
        blockName: value.blockName ?? null,
        siteModel: value.siteModel ?? null,
        testPlan: value.testPlan ?? null,
        requiredWeeksStart: value.requiredWeeksStart ?? null,
        requiredWeeksEnd: value.requiredWeeksEnd ?? null,

        targetUnitPrice: value.targetUnitPrice ?? null,
        minimumUnitPrice: value.minimumUnitPrice ?? null,
        actualCost: value.actualCost ?? null,
        isExcelNew: value.excelFile?.isNew ?? false,
        isPdfNew: value.pdfFile?.isNew ?? false,
        managerId: value.createdBy?.id ?? null,

        ...commonParams,
      };

      formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));

      onUpdate(
        {
          id: value.id,
          formData,
        },
        {
          onSuccess: () => {
            enqueueSnackbar('저장했습니다.', {
              variant: 'success',
            });
          },
        }
      );
    },
    [onUpdate, item, enqueueSnackbar]
  );

  return onSubmit;
};

export default useContractHistoryClientUpdate;
