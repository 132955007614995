import { useQuery } from 'react-query';
import { projectSalesDbAnalysisPresetApi } from '@front/src/features/project-sales-db/features/preset/api/preset-api';
import { PresetData } from '@front/src/features/project-sales-db/features/preset/type';

export const projectSalesDbAnalysisPresetQuery = {
  useGetList: (menuId: number) => {
    const { data, isLoading } = useQuery<PresetData[]>({
      queryKey: ['project', 'sales-db-analysis', 'preset', 'list'],
      queryFn: () => projectSalesDbAnalysisPresetApi.getList(menuId),
      enabled: !!menuId,
    });
    return {
      data,
      isLoading,
    };
  },
};
