import React from 'react';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

export default function CollectionChangeDateTableBody(props: UIBuilderTableBodyProps) {
  return (
    <UIBuilderTableBody
      {...props}
      RowComponent={RowComponent}
    />
  );
}

function RowComponent(props: UIBuilderTableRowProps) {
  const { item } = props;
  const methods = useForm({
    defaultValues: {
      changeProcessingDate: item.changeProcessingDate ? dayjs(item.changeProcessingDate) : null,
      previousCollectionDueDate: item.previousCollectionDueDate
        ? dayjs(item.previousCollectionDueDate)
        : null,
      changedCollectionDueDate: item.changedCollectionDueDate
        ? dayjs(item.changedCollectionDueDate)
        : null,
      writerName: item.writerName,
    },
  });

  return (
    <FormProvider {...methods}>
      <UiBuilderTableRow {...props} />
    </FormProvider>
  );
}
