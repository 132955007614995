import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import type { OptionType } from '@front/src/components/select-with-hook-form/select-with-hook-form';
import DropDownUI from '@front/src/components/components-with-design/component/DropDownUI';
import type { SelectInputProps } from '@mui/material/Select/SelectInput';
import type { SelectChangeEvent } from '@mui/material';

interface Props<T = string>
  extends Omit<SelectInputProps, 'autoWidth' | 'multiple' | 'native' | 'required'> {
  name: string;
  options: OptionType<T>[];
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  onChange?: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
  required?: string;
  defaultLabel?: string;
  onAfterChange?: () => void;
}

export default function DropDownWithHookForm({
  name,
  onSubmit,
  onChange,
  required,
  onAfterChange,
  ...rest
}: Readonly<Props>) {
  const { control } = useFormContext();

  const {
    field: { value, onChange: onHookFormChange },
  } = useController({ name, control, rules: { required } });

  async function handleChange(e, child) {
    onHookFormChange(e.target.value);
    onChange?.(e, child);
    onAfterChange?.();
    onSubmit?.();
  }

  return (
    <DropDownUI
      {...rest}
      value={value}
      onChange={handleChange}
    />
  );
}
