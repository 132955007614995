import React from 'react';
import Box from '@mui/material/Box';
import type { UiBuilderTableProps } from '@front/src/components/ui-builder/table/ui-builder-table';
import { useFieldArray, useFormContext } from 'react-hook-form';
import type {
  UIBuilderTableFieldValues,
  UIBuilderTableFilterItem,
} from '@front/src/components/ui-builder/table/hooks/useForm';
import UIBuilderTableHeaderCellItem from '@front/src/components/ui-builder/table/HeaderCellItem';
import {
  getUIBuilderFilterBundle,
  getUIBuilderFilteredList,
} from '@front/src/components/ui-builder/table/utils';
import ButtonIconPopover from '@front/src/components/components-with-design/component/ButtonIconPopover';
import TypographyUI from '@front/src/components/components-with-design/component/TypographyUI';
import type { SxProps } from '@mui/system';
import { ColorPalette } from '@front/assets/theme';

export default function UIBuilderTableHeaderCellList(props: Readonly<UiBuilderTableProps>) {
  const { list: originalList } = props;

  const { control, setValue } = useFormContext<UIBuilderTableFieldValues>();

  const sortFieldArray = useFieldArray<UIBuilderTableFieldValues, 'sortPriorityList', string>({
    control,
    name: 'sortPriorityList',
    keyName: 'fieldId',
  });

  const { fields: bundleFields } = useFieldArray({
    control,
    name: 'filterBundle',
  });

  const getContent = (name: string) => {
    const renderContent = (content: React.ReactNode) => (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '5px',
        }}
      >
        {name}
        {content}
      </Box>
    );

    switch (name) {
      case '파일명':
        return renderContent(<FileInfo />);
      case '견적서파일(엑셀)':
        return renderContent(<ExcelFileInfo />);
      case '견적서파일(PDF)':
        return renderContent(<PdfFileInfo />);
      case '파일':
        return renderContent(<PdfFileInfo />);
      default:
        return renderContent(null);
    }
  };

  const onFilterChange = (
    newFilter: UIBuilderTableFilterItem[],
    active: boolean,
    index: number
  ) => {
    if (!originalList) return;

    const newShowList = getUIBuilderFilteredList(newFilter, index, originalList, bundleFields);

    setValue('showList', newShowList);

    const newFilterBundle = getUIBuilderFilterBundle(
      newFilter,
      active,
      index,
      bundleFields,
      newShowList
    );

    setValue(`filterBundle`, newFilterBundle);
  };

  return (
    <>
      {bundleFields?.map((f, index) => (
        <UIBuilderTableHeaderCellItem
          {...props}
          key={f.id}
          headerId={f.headerId}
          sortFieldArray={sortFieldArray}
          bundleIndex={index}
          onFilterChange={(filter, active) => onFilterChange(filter, active, index)}
          active={bundleFields[index].active}
        >
          {getContent(bundleFields[index]?.name)}
        </UIBuilderTableHeaderCellItem>
      ))}
    </>
  );
}

const TextSxRegular = ({ children, sx = {} }: { children: string; sx?: SxProps }) => (
  <TypographyUI
    shape="text-xs-regular"
    sx={sx}
  >
    {children}
  </TypographyUI>
);

const FileInfo = () => (
  <ButtonIconPopover>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        padding: '10px',
      }}
    >
      <TypographyUI shape="text-m-semibold">첨부 가능한 파일</TypographyUI>
      <Box>
        <TextSxRegular>1. 문서 파일</TextSxRegular>
        <TextSxRegular>- PDF: .pdf</TextSxRegular>
        <TextSxRegular>- 워드 문서: .doc, .docx</TextSxRegular>
        <TextSxRegular>- 엑셀 문서: .xls, .xlsx</TextSxRegular>
        <TextSxRegular>- 파워포인트 문서: .ppt, .pptx</TextSxRegular>
        <TextSxRegular>- 텍스트 파일: .txt</TextSxRegular>
        <TextSxRegular>- 리치 텍스트 파일: .rtf</TextSxRegular>
      </Box>
      <Box>
        <TextSxRegular>2. 이미지 파일</TextSxRegular>
        <TextSxRegular>- JPEG: .jpg, .jpeg</TextSxRegular>
        <TextSxRegular>- PNG: .png</TextSxRegular>
        <TextSxRegular>- GIF: .gif</TextSxRegular>
        <TextSxRegular>- BMP: .bmp</TextSxRegular>
        <TextSxRegular>- TIFF: .tiff</TextSxRegular>
      </Box>
      <Box>
        <TextSxRegular>3. 압축 파일</TextSxRegular>
        <TextSxRegular>- ZIP: .zip</TextSxRegular>
        <TextSxRegular>- RAR: .rar</TextSxRegular>
        <TextSxRegular>- 7-Zip: .7z</TextSxRegular>
      </Box>
      <Box>
        <TextSxRegular>4. 오디오 파일</TextSxRegular>
        <TextSxRegular>- MP3: .mp3</TextSxRegular>
        <TextSxRegular>- WAV: .wav</TextSxRegular>
        <TextSxRegular>- AAC: .aac</TextSxRegular>
      </Box>
      <Box>
        <TextSxRegular>5. 비디오 파일</TextSxRegular>
        <TextSxRegular>- MP4: .mp4</TextSxRegular>
        <TextSxRegular>- AVI: .avi</TextSxRegular>
        <TextSxRegular>- MOV: .mov</TextSxRegular>
        <TextSxRegular>- WMV: .wmv</TextSxRegular>
      </Box>
    </Box>
  </ButtonIconPopover>
);

const ExcelFileInfo = () => (
  <ButtonIconPopover>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        padding: '10px',
      }}
    >
      <TypographyUI shape="text-m-semibold">첨부 가능한 파일</TypographyUI>
      <Box>
        <TextSxRegular>1. 엑셀 파일</TextSxRegular>
        <TextSxRegular>- 엑셀 문서: .xls, xlsx</TextSxRegular>
      </Box>
      <Box>
        <TextSxRegular
          sx={{
            color: ColorPalette.greyscale.text_tertiary,
          }}
        >
          ※ 파일 크기는 각 10MB를 초과할 수 없습니다.
        </TextSxRegular>
      </Box>
    </Box>
  </ButtonIconPopover>
);

const PdfFileInfo = () => (
  <ButtonIconPopover>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        padding: '10px',
      }}
    >
      <TypographyUI shape="text-m-semibold">첨부 가능한 파일</TypographyUI>
      <Box>
        <TextSxRegular>1. PDF 파일</TextSxRegular>
        <TextSxRegular>- PDF: .pdf</TextSxRegular>
      </Box>
      <Box>
        <TextSxRegular
          sx={{
            color: ColorPalette.greyscale.text_tertiary,
          }}
        >
          ※ 파일 크기는 각 10MB를 초과할 수 없습니다.
        </TextSxRegular>
      </Box>
    </Box>
  </ButtonIconPopover>
);
