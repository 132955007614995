import React from 'react';
import type { CollectionAddFormProps } from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/components/ModalBody';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import { commonRepository } from '@front/src/api/repository';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableRowUI from '@front/src/components/components-with-design/compound/table/TableRowUI';
import TableCellUI from '@front/src/components/components-with-design/compound/table/TableCellUI';
import CollectionAddTableList from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/components/List';
import dayjs from 'dayjs';
import { YY_MM_DD } from '@front/src/utils';
import ContextMenuNewIconUI from '@front/src/components/components-with-design/compound/ContextMenuNewIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCollectionDefaultRow } from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/utils';

export default function CollectionAddTable(props: CollectionAddFormProps) {
  const { sectionId, menuId, fieldArray, formContext } = props;

  const { data: headerList } = commonRepository.useGetHeaderList(sectionId, menuId);

  const { getValues } = formContext;
  const { prepend, append, replace } = fieldArray;
  const collectionData = getValues('collectionData');

  const itemList = [
    {
      text: '최상단에 추가',
      IconComponent: <FontAwesomeIcon icon="plus" />,
      action: () => {
        const defaultRow = getCollectionDefaultRow();
        prepend(defaultRow);
      },
    },
    {
      text: '최하단에 추가',
      IconComponent: <FontAwesomeIcon icon="plus" />,
      action: () => {
        const defaultRow = getCollectionDefaultRow();
        append(defaultRow);
      },
    },
    {
      text: '전체 삭제',
      IconComponent: <FontAwesomeIcon icon="trash" />,
      action: () => {
        replace([]);
      },
    },
  ];

  return (
    <TableContainerUI>
      <TableTableUI>
        <TableHeadUI>
          <TableRowUI>
            <TableCellUI>
              <ContextMenuNewIconUI itemList={itemList} />
            </TableCellUI>
            <TableCellUI>{headerList?.find((f) => f.id === 629)?.name}</TableCellUI>
            <TableCellUI>{headerList?.find((f) => f.id === 633)?.name}</TableCellUI>
            <TableCellUI>수금 예정액 비율(%)</TableCellUI>
          </TableRowUI>
        </TableHeadUI>
        <TableBodyUI>
          <TableRowUI key={collectionData.collectionId}>
            <TableCellUI>현재</TableCellUI>
            <TableCellUI>{dayjs(collectionData.collectionDueDate).format(YY_MM_DD)}</TableCellUI>
            <TableCellUI>{collectionData.unpaidAmount?.toLocaleString()}</TableCellUI>
            <TableCellUI>100%</TableCellUI>
          </TableRowUI>
          <CollectionAddTableList {...props} />
        </TableBodyUI>
      </TableTableUI>
    </TableContainerUI>
  );
}
