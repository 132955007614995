import {
  AccountingBudgetDiagramTableForm,
} from '@front/src/features/accounting/features/budget/type';
import { accountingBudgetHookDefaultValues } from '@front/src/features/accounting/features/budget/hook';
import {
  generateDefaultCellListValues,
} from '@front/src/features/accounting/features/budget/hook/diagram-table/util';


const accountingBudgetDiagramTableHookDefaultValues: AccountingBudgetDiagramTableForm = {
  managementList: [],
  selectedManagementList: [],
  visibleManagementList: [],
  cellList: generateDefaultCellListValues(accountingBudgetHookDefaultValues.budgetYear),
};

export default accountingBudgetDiagramTableHookDefaultValues;
