import React from 'react';
import { Box } from '@mui/material';

const SectionBox = (props: { children: React.ReactNode }): React.ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: 'min-content',
        height: 'min-content',
        minWidth: '10dvw',
        padding: '20px 0',
        '& > div': {
          marginRight: '3dvw',
        },
      }}
    >
      {props.children}
    </Box>
  );
};

export default SectionBox;
