import { useMemo } from 'react';
import { useIsMutating } from 'react-query';

const useGetUIBuilderTableLoading = (
  dataId: number | undefined,
  getMutationKey: (dataId: number | undefined) => (string | number | undefined)[]
) => {
  const mutationKeys = useMemo(() => {
    const mutationKey = getMutationKey(dataId);

    return {
      add: [...mutationKey, 'add'],
      update: [...mutationKey, 'update'],
      updateSeq: [...mutationKey, 'update-seq'],
      delete: [...mutationKey, 'delete'],
    };
  }, [dataId]);

  const addLoading = !!useIsMutating({ mutationKey: mutationKeys.add });
  const updateLoading = !!useIsMutating({ mutationKey: mutationKeys.update });
  const updateSeqLoading = !!useIsMutating({ mutationKey: mutationKeys.updateSeq });
  const deleteLoading = !!useIsMutating({ mutationKey: mutationKeys.delete });

  return addLoading || updateLoading || updateSeqLoading || deleteLoading;
};

export default useGetUIBuilderTableLoading;
