import { useQuery } from 'react-query';
import { menuSettingsApi } from '@front/src/features/admin/features/menu-settings/api/api';
import type { AdminDirectorySettingsHistoryParams } from '@front/src/features/admin/features/menu-settings/features/menu-tab-section-settings/types/parameter';

export const adminMenuSettingsQuery = {
  useMenuListGet: (authMenuId) => {
    const { data } = useQuery({
      queryFn: () => menuSettingsApi.getMenuList(authMenuId),
      queryKey: ['admin', 'menu', 'settings'],
      select: (res) => res.data,
      suspense: true,
    });

    return {
      data,
    };
  },

  useTabListGet: (menuId: number, authMenuId) => {
    const { data } = useQuery({
      queryFn: () => menuSettingsApi.getTabList(menuId, authMenuId),
      queryKey: ['admin', 'tab', 'settings', menuId],
      select: (res) => res.data,
      suspense: true,
    });

    return {
      data,
    };
  },

  useSectionListGet: (menuId: number, authMenuId) => {
    const { data } = useQuery({
      queryFn: () => menuSettingsApi.getSectionList(menuId, authMenuId),
      queryKey: ['admin', 'section', 'settings', menuId],
      select: (res) => res.data,
      suspense: true,
    });

    return { data };
  },

  useHistoryListGet: (params: AdminDirectorySettingsHistoryParams, authMenuId) => {
    const { data } = useQuery({
      queryFn: () => menuSettingsApi.getHistoryList(params, authMenuId),
      queryKey: ['admin', 'directory', 'settings', 'history', params],
      select: (res) => res.data,
      suspense: true,
    });

    return { data };
  },
};
