import React, { Suspense } from 'react';
import { useCellComponentLifeCycleManager } from '@front/src/features/project-sales/hooks/useTableCellComponentLifeCycleManager';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import CollectionTaxInvoiceModal from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/Modal';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { collectionTaxInvoiceTableCells } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/TableCell';

export default function CollectionTaxInvoiceSection(props: SectionComponentProps) {
  useCellComponentLifeCycleManager(collectionTaxInvoiceTableCells);

  return (
    <Suspense fallback={<LoadingSpinnerUI />}>
      <CollectionTaxInvoiceModal {...props} />
    </Suspense>
  );
}
