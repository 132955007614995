import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { ColorPalette } from '@front/assets/theme';
import type { SxProps } from '@mui/system';

interface Props {
  open: boolean;
  sx?: SxProps;
}

export default function TableBodyLoadingSpinnerUI({ open, sx = {} }: Props) {
  return (
    <Backdrop
      open={open}
      sx={{
        ...sx,
        zIndex: '3',
        position: 'absolute',
        background: ColorPalette.etc.loading_spinner,
      }}
    >
      <CircularProgress
        style={{
          width: '40px',
          height: '40px',
          strokeLinecap: 'round',
          strokeWidth: 4,
          color: ColorPalette.greyscale.white,
        }}
      />
    </Backdrop>
  );
}
