import { useQuery } from 'react-query';
import { commonApi } from '@front/src/api/api';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import type { CollectionDueDateHistoryView } from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/types/view';

const collectionChangeDateQuery = {
  useGet: (collectionId: number, commonParams: UIBuilderParameter) => {
    const url = `/api/project/sales/sales-information/collection-history/${collectionId}/collection-due-date-history`;
    const queryKey = [
      'project',
      'sales',
      commonParams.dataId,
      'collection',
      'history',
      collectionId,
      'change-date',
    ];
    const enabled = !!collectionId && !!commonParams.dataId;

    const { data } = useQuery<
      CollectionDueDateHistoryView[],
      Error,
      CollectionDueDateHistoryView[]
    >({
      queryFn: () => getList(url, commonParams),
      queryKey,
      enabled,
      suspense: true,
    });

    return data;
  },
};

export default collectionChangeDateQuery;

const getList = async (url, commonParams) => {
  const data = await commonApi.getListByParams(url, commonParams);

  return data?.filter((f) => !!f.id);
};
