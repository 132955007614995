import React, { useMemo, useState } from 'react';
import { DefaultSectionComponent } from '@front/type/Function';
import {
  AccountingBudgetCommonTableForm,
  VerticalTableCellProps,
} from '@front/src/features/accounting/features/budget/type';
import { ColorPalette } from '@front/assets/theme';
import { Box } from '@mui/material';
import { getReducedValue } from '@front/src/features/accounting/features/budget/util/convert';
import TextFieldBasicUI from '@front/src/components/components-with-design/component/TextFieldBasicUI';
import { UseFormReturn } from 'react-hook-form';

const CellMinWidthUnit = 'px';
const CellHeightUnit = 'px';
const CellWidthRatio = 64;
const CellHeightRatio = 36;

function calcCellWidth(): string {
  return `${CellWidthRatio}${CellMinWidthUnit}`;
}

function calcCellHeight(): string {
  return `${CellHeightRatio}${CellHeightUnit}`;
}

interface Props {
  methods: UseFormReturn<AccountingBudgetCommonTableForm>;
}

const AccountingBudgetTableCell: DefaultSectionComponent<VerticalTableCellProps & Props> = (props) => {
  const {
    methods,
    value,
    style,
    isHead,
    width,
    height,
    justifyContent,
    onClick,
    clickable,
    sx,
    cellGroupId,
    groupPointList,
    row,
    col,
  } = props;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [nextValue, setNextValue] = useState<string>(`${value || ''}`);
  const { getValues, setValue } = methods;
  const cellList = getValues('cellList');

  const text = useMemo(() => {
    if (style === 'span') {
      return '';
    }
    if (value === null) {
      return '-';
    }
    if (typeof value === 'undefined' || Number.isNaN(value)) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    if (style === 'percent') {
      return value.toFixed(1) + '%';
    }
    if (Number.isInteger(value)) {
      return value.toLocaleString();
    }
    return (+value.toFixed(0)).toLocaleString();

  }, [value, style]);

  const borderSx = useMemo(() => {
    if (!cellGroupId || !groupPointList || groupPointList.length <= 1) {
      return {
        borderTop: `1px solid ${ColorPalette.line.line}`,
        borderLeft: `1px solid ${ColorPalette.line.line}`,
        borderRight: `1px solid ${ColorPalette.line.line}`,
        borderBottom: `1px solid ${ColorPalette.line.line}`,
      };
    }
    const maxRow = getReducedValue(
      groupPointList,
      cell => cell.row,
      (a, b) => a > b ? a : b,
      0,
    );

    const minRow = getReducedValue(
      groupPointList,
      cell => cell.row,
      (a, b) => a > b ? b : a,
      1000,
    );
    const maxCol = getReducedValue(
      groupPointList,
      cell => cell.col,
      (a, b) => a > b ? a : b,
      0,
    );

    const minCol = getReducedValue(
      groupPointList,
      cell => cell.col,
      (a, b) => a > b ? b : a,
      1000,
    );

  }, [row, col, cellGroupId, groupPointList]);

  const escapeEditMode = (rawValue: string | number | '') => {
    const myCell = cellList.find(cell => cell.col === col && cell.row === row);
    if (!myCell) {
      console.error(`cannot found cell row: ${row}, col: ${col}`);
      setEditMode(false);
      return;
    }

    if (style === 'amount' || style === 'percent') {
      const numberValue = +rawValue;
      if (Number.isNaN(numberValue)) {
        console.error(`value require number value: ${e.target.value}, row: ${row}, col: ${col}`);
        setEditMode(false);
        return;
      }
    }

    const newValue: string | number | null = (style === 'amount' || style === 'percent') ? (rawValue === '' ? null : (+rawValue)) : (rawValue === '' ? null : rawValue);
    const nextCellList: typeof cellList = [];
    for (let i = 0; i < cellList.length; i++) {
      const cell = cellList[i];
      if (cell.col === col && cell.row === row) {
        nextCellList.push({
          ...cell,
          value: newValue,
        });
      } else {
        nextCellList.push(cell);
      }
    }
    setValue('cellList', nextCellList);
    setEditMode(false);
    setNextValue(`${newValue || ''}`);
  };

  return (
    <Box
      onClick={(e) => {
        if (onClick) {
          onClick(e);
          return;
        }
        if (clickable === false) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        if (style === 'amount' || style === 'percent') {
          if (!editMode) {
            setEditMode(true);
          }
        }
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.3rem',
        lineHeight: '1.5rem',
        fontWeight: isHead ? '600' : '400',
        verticalAlign: 'middle',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        background: isHead ? ColorPalette.background.bg02 : 'transparent',
        ...borderSx,
        padding: '0 4px',
        minWidth: calcCellWidth(),
        minHeight: calcCellHeight(),
        justifyContent: justifyContent || isHead ? 'center' : (style === 'amount' ? 'flex-end' : style === 'percent' ? 'center' : 'inherit'),
        color: typeof value === 'number' && value < 0 ? 'red' : 'inherit',
        width: typeof width === 'string' ? width : calcCellWidth(),
        height: typeof height === 'string' ? height : calcCellHeight(),
        cursor: (!!onClick || !clickable) ? 'pointer' : 'default',
        ...sx,
      }}
      data-clickable={!!onClick || !clickable}
      data-cell-group-id={props.cellGroupId ?? '-'}
      data-key-id={`col-${col}-row-${row}${style === 'span' ? '-span' : ''}`}
    >
      {editMode ? (
          <TextFieldBasicUI
            autoFocus
            value={nextValue ?? ''}
            onBlur={(e) => {
              escapeEditMode(e.target.value || '');
            }}
            onKeyDown={(e) => {
              if (e.code.toLowerCase() === 'enter') {
                e.preventDefault();
                e.stopPropagation();
                escapeEditMode(nextValue || '');
                return;
              }
              if (e.code.toLowerCase() === 'escape') {
                e.preventDefault();
                e.stopPropagation();
                escapeEditMode(value || '');
                return;
              }
            }}
            onChange={(e) => {
              setNextValue(e.target.value || '');
            }}
          />
        )
        : text}
    </Box>
  );
};

export default AccountingBudgetTableCell;


