import type { ControlPanelItem } from '@front/src/types/domain';
import React from 'react';
import type { FileItemView } from '@front/file-item';
import { getNestedAttribute } from '@front/src/utils';
import ContextMenuUI from '@front/src/components/components-with-design/compound/ContextMenuUI';
import { useFormContext } from 'react-hook-form';
import type { SxProps } from '@mui/system';

interface InnerCellSupportProps {
  item: any;
  listAttrName: string;
  fileItemAttrName?: string;
  displayValueAttrName?: string;
  innerCellIndex?: number;
  useContextMenu?: boolean;
  contextMenuItems?: ControlPanelItem[] | undefined;
  children: React.ReactElement<{
    name?: string;
    onClick?: (index: number) => void;
    companyName?: string;
    personName?: string;
    fileItem?: FileItemView | undefined;
    displayValue?: string;
    sx?: SxProps;
  }>;
  getContextMenuItems?: (id: number) => ControlPanelItem[];
  onSubmit?: (index: number) => void;
  onAfterChange?: (index: number) => void;
  getSx?: (index: number) => {
    backgroundColor: string;
    color: string;
  };
  getColor?: (index: number) => {
    color: string;
  };
}
const InnerCellSupport = (props: InnerCellSupportProps) => {
  const {
    listAttrName,
    fileItemAttrName,
    displayValueAttrName,
    children,
    getContextMenuItems,
    onSubmit,
    useContextMenu = true,
    onAfterChange,
    getSx,
    getColor,
  } = props;

  const { getValues } = useFormContext();
  const list = getValues(listAttrName);
  return (
    <>
      {list.map((item, index) => {
        if (props.innerCellIndex !== undefined && props.innerCellIndex !== index) {
          return null;
        }
        const extraProps = {
          onClick: () => {
            children.props.onClick && children.props.onClick(index);
          },
          onSubmit: () => {
            onSubmit?.(index);
          },
          onAfterChange: () => {
            onAfterChange?.(index);
          },
        };
        children.props.name &&
          (extraProps['name'] = `${listAttrName}[${index}].${children.props.name}`);
        children.props.companyName &&
          (extraProps['companyName'] = `${listAttrName}[${index}].${children.props.companyName}`);
        children.props.personName &&
          (extraProps['personName'] = `${listAttrName}[${index}].${children.props.personName}`);
        fileItemAttrName && (extraProps['fileItem'] = getNestedAttribute(item, fileItemAttrName));
        displayValueAttrName &&
          (extraProps['displayValue'] = getNestedAttribute(item, displayValueAttrName));

        const modifiedChildren = React.isValidElement(children)
          ? React.cloneElement(children, extraProps)
          : children;

        return (
          <div
            key={index}
            className={
              getContextMenuItems && useContextMenu
                ? 'inner-cell inner-cell-with-context-menu'
                : 'inner-cell'
            }
            style={{
              ...getSx?.(index),
              ...getColor?.(index),
            }}
          >
            <>
              {getContextMenuItems && useContextMenu && (
                <div className="inner-cell-action-button">
                  <ContextMenuUI
                    itemList={getContextMenuItems?.(getValues(`${listAttrName}.${index}`)?.id)}
                  />
                </div>
              )}
              {modifiedChildren}
            </>
          </div>
        );
      })}
    </>
  );
};

export default InnerCellSupport;
