import { commonQuery } from '@front/src/api/query';
import { useMemo } from 'react';
import type { UserListGetParams } from '@front/src/types/parameter';
import commonMutation from '@front/src/api/mutation';
import { getNoOpCallback, getSetCallbackFunc } from '@front/common/contants';

export const commonRepository = {
  useGetSectionList: (authMenuId?) => {
    const data = commonQuery.useGetSectionList(authMenuId);
    return {
      data,
    };
  },
  useGetHeaderList: (sectionId?: number, authMenuId?) => {
    const { data } = commonQuery.useGetHeaderList(sectionId, authMenuId);

    const stickyIdx = useMemo(() => data?.findIndex((item) => item.isFixed), [data]);

    return {
      data,
      stickyIdx: stickyIdx ?? -1,
    };
  },
  useGetUserList: (params: UserListGetParams, isMentionOpen: boolean, authMenuId?) => {
    const { data } = commonQuery.useGetUserList(params, isMentionOpen, authMenuId);
    return { data };
  },
  useCreateImage: (menuId) => {
    const callback = getNoOpCallback();
    const mutate = commonMutation.useCreateImage(menuId);
    return {
      run: (formData) => mutate(formData, callback),
      setCallback: getSetCallbackFunc(callback),
    };
  },
};
