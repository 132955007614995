import React, { Suspense } from 'react';
import SectionWrapperUI from '@front/src/components/components-with-design/layout/SectionWrapperUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import useNoteMenuId from '@front/src/hooks/useNoteMenuId';
import useSetMenuAndTab from '@front/src/hooks/useSetMenuAndTab';
import AccountingBudgetFeature from '@front/src/features/accounting/features/budget/feature';
import { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';

const AccountingBudgetSection = (props: Readonly<SectionComponentProps>) => {

  const { menuId } = useSetMenuAndTab();
  useNoteMenuId();
  const { Layout } = SectionWrapperUI;
  if (!menuId) {
    return (<LoadingSpinnerUI />);
  }

  return (
    <Layout>
      <Suspense fallback={<LoadingSpinnerUI />}>
        <AccountingBudgetFeature {...props} />
      </Suspense>
    </Layout>
  );
};

export default AccountingBudgetSection;
