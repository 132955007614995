import {
  adminMenuApi,
  adminMenuHistoryApi,
} from '@front/src/features/admin/features/menu-settings/utils/constants';
import type {
  AdminDirectorySettingsHistoryParams,
  AdminDirectorySettingsUpdateParams,
  AdminMenuSettingsNameUpdateParams,
} from '@front/src/features/admin/features/menu-settings/features/menu-tab-section-settings/types/parameter';
import type { HistoryPageParams } from '@front/src/features/admin/features/menu-settings/features/history/types/parameter';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

const url = {
  getMenuList: () => `${adminMenuApi}/without-tab`,
  getTabList: (menuId: number) => `${adminMenuApi}/${menuId}/tab`,
  updateMenuName: (menuId: number) => `${adminMenuApi}/${menuId}/name`,
  updateMenuSeqUp: (menuId: number) => `${adminMenuApi}/${menuId}/up`,
  updateMenuSeqDown: (menuId: number) => `${adminMenuApi}/${menuId}/down`,
  getSectionList: (menuId: number) => `${adminMenuApi}/${menuId}/section`,
  updateSectionName: (sectionId: number) => `${adminMenuApi}/section/${sectionId}/name`,
  updateSectionSeqUp: (sectionId: number) => `${adminMenuApi}/section/${sectionId}/up`,
  updateSectionSeqDown: (sectionId: number) => `${adminMenuApi}/section/${sectionId}/down`,
  getHistoryPage: () => `${adminMenuHistoryApi}/history`,
  updateDirectory: () => `${adminMenuHistoryApi}`,
  getHistoryList: () => `${adminMenuHistoryApi}/history-by-target`,
};

export const menuSettingsApi = {
  getMenuList: async (authMenuId) =>
    await axios.get(url.getMenuList(), {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getTabList: async (menuId: number, authMenuId) =>
    await axios.get(url.getTabList(menuId), {
      headers: getMenuIdHeaders(authMenuId),
    }),
  updateMenuName: async (menuId: number, params: AdminMenuSettingsNameUpdateParams, authMenuId) =>
    await axios.patch(url.updateMenuName(menuId), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  updateMenuSeqUp: async (menuId: number, authMenuId) =>
    await axios.patch(url.updateMenuSeqUp(menuId), null, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  updateMenuSeqDown: async (menuId: number, authMenuId) =>
    await axios.patch(url.updateMenuSeqDown(menuId), null, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getSectionList: async (menuId: number, authMenuId) =>
    await axios.get(url.getSectionList(menuId), {
      headers: getMenuIdHeaders(authMenuId),
    }),
  updateSectionName: async (
    sectionId: number,
    params: AdminMenuSettingsNameUpdateParams,
    authMenuId
  ) =>
    await axios.patch(url.updateSectionName(sectionId), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  updateSectionSeqUp: async (sectionId: number, authMenuId) =>
    await axios.patch(url.updateSectionSeqUp(sectionId), null, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  updateSectionSeqDown: async (sectionId: number, authMenuId) =>
    await axios.patch(url.updateSectionSeqDown(sectionId), null, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getHistoryPage: async (params: HistoryPageParams, authMenuId) =>
    await axios.get(url.getHistoryPage(), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    }),
  updateDirectory: async (params: AdminDirectorySettingsUpdateParams, authMenuId) =>
    await axios.patch(url.updateDirectory(), params, {
      headers: getMenuIdHeaders(authMenuId),
    }),
  getHistoryList: async (params: AdminDirectorySettingsHistoryParams, authMenuId) =>
    await axios.get(url.getHistoryList(), {
      params,
      headers: getMenuIdHeaders(authMenuId),
    }),
};
