import React, { useMemo } from 'react';
import collectionChangeDateQuery from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/query/query';
import UiBuilderTable from '@front/src/components/ui-builder/table/ui-builder-table';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';

import type { CollectionDueDateHistoryModalProps } from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/copmonents/ChangeDateModal';
import CollectionChangeDateTableBody from '@front/src/features/project-sales/features/tabs/collection/sections/due-date-history/copmonents/TableBody';

export default function CollectionDueDateHistoryTable(props: CollectionDueDateHistoryModalProps) {
  const { menuId, sectionId, dataId, collectionId } = props;
  const commonParams = useMemo(() => ({ dataId, sectionId, menuId }), [dataId, sectionId, menuId]);

  const list = collectionChangeDateQuery.useGet(collectionId, commonParams);

  return (
    <UiBuilderTable
      {...props}
      list={list}
      HeadComponent={UIBuilderTableHeader}
      BodyComponent={CollectionChangeDateTableBody}
    />
  );
}
