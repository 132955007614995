import React, { Suspense, useMemo } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { useShallow } from 'zustand/react/shallow';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import ContractPaymentTermModalForm from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/components/Form';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import contractPaymentTermQuery from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/query/query';
import usePaymentTermModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/usePaymentTermsModalStore';
import contractHistoryQuery from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/query';

export default function ContractPaymentTermModal(props: SectionComponentProps) {
  const { onClose, isOpen, clientId } = usePaymentTermModalStore(
    useShallow((state) => ({
      onClose: state.onClose,
      isOpen: state.isOpen,
      clientId: state.clientId,
    }))
  );

  if (!clientId) return <></>;

  return (
    <ModalUI
      title="지불조건"
      open={isOpen}
      onClose={onClose}
      disableEnforceFocus={true}  // 모달에서 비고 사용시 form 요소 포커스 안되는 문제 처리
      allowOverflowComponent={true}
      sx={
        {
          // backfaceVisibility: 'hidden',
        }
      }
    >
      <Suspense fallback={<LoadingSpinnerUI />}>
        <Form
          {...props}
          defaultClientId={clientId}
        />
      </Suspense>
    </ModalUI>
  );
}

export interface ContractPaymentTermModalProps extends SectionComponentProps {
  defaultClientId: number;
}

const Form = (props: ContractPaymentTermModalProps) => {
  const { menuId, sectionId, dataId, defaultClientId } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const dataObject = contractPaymentTermQuery.useGetObject(defaultClientId, commonParams);
  const historyList = contractHistoryQuery.useGetList(commonParams);

  if (
    !dataObject ||
    !historyList ||
    (Object.keys(dataObject).length === 0 && dataObject.constructor === Object)
  )
    return <></>;

  return <ContractPaymentTermModalForm {...props} />;
};
