import { useMutation, useQueryClient } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { getMenuIdHeaders } from '@front/src/utils';
import axios from 'axios';

const collectionClaimDocumentMutation = {
  useUpdate: (collectionId: number, commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const url = `/api/project/sales/sales-information/collection-history/collection/${collectionId}/claim-document`;
    const queryKey = getQueryKey(commonParams.dataId, collectionId);

    const { mutate } = useMutation<unknown, Error, FormData>({
      mutationFn: (params) => updateClaimDocument(url, params, commonParams),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales', commonParams.dataId, 'collection', 'history'],
        });
      },
    });

    return mutate;
  },
};

export default collectionClaimDocumentMutation;

const updateClaimDocument = async (url, params, commonParams) => {
  const { data } = await axios.put(url, params, {
    headers: {
      ...getMenuIdHeaders(commonParams.menuId),
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
};

const getQueryKey = (dataId, collectionId) => [
  'project',
  'sales',
  dataId,
  'collection',
  collectionId,
  'claim-document',
];
