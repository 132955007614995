import React from 'react';
import type { Control } from 'react-hook-form';
import { useController } from 'react-hook-form';
import type { UserVO } from '@front/user/domain';
import UserSelectorCommonInput from '@front/src/features/user-selector/features/input/components/CommonInput';
import AvatarUI from '@front/src/components/components-with-design/component/AvatarUI';
import type { UserSelectorInputProps } from '@front/src/features/user-selector/features/input';

interface Props extends UserSelectorInputProps {
  onOpen: () => void;
  control: Control<{ inputList: UserVO[] }, any>;
}

export default function UserSelectorDefaultInput({
  onOpen,
  control,
  onChange,
  disabled,
  ...rest
}: Readonly<Props>) {
  const {
    field: { value: inputList },
  } = useController({
    control,
    name: 'inputList',
  });
  const handleIconClick = (e) => {
    e.stopPropagation();
    if (disabled) return;
    onOpen();
  };
  const endAdornment = (
    <AvatarUI
      size="small"
      onClick={handleIconClick}
    />
  );

  const user = inputList.length > 0 ? inputList[0] : undefined;

  const onClick = () => {
    if (disabled) return;
    if (!user) {
      onOpen();
      return;
    }
    onChange(undefined);
  };
  return (
    <UserSelectorCommonInput
      {...rest}
      disabled={disabled}
      onClick={onClick}
      user={user}
      endAdornment={endAdornment}
      sx={{
        cursor: 'pointer',
      }}
    />
  );
}
