import React, { useMemo } from 'react';
import useCollectionTaxInvoiceForm from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/hooks/useForm';

import collectionTaxInvoiceMutation from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/query/mutation';
import type { CollectionTaxInvoiceModalProps } from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/Modal';
import useCollectionTaxInvoiceFormSubmit from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/hooks/useSubmit';
import CollectionTaxInvoiceModalBody from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/ModalBody';
import CollectionTaxInvoiceQuery from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/query/query';
import { FormProvider } from 'react-hook-form';

export default function CollectionTaxInvoiceForm(props: CollectionTaxInvoiceModalProps) {
  const { menuId, sectionId, dataId, collectionId } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);
  const list = CollectionTaxInvoiceQuery.useGet(collectionId, commonParams);
  const onUpdate = collectionTaxInvoiceMutation.useUpdate(collectionId, commonParams);

  const methods = useCollectionTaxInvoiceForm(list!);
  const { handleSubmit } = methods;

  const onSubmit = useCollectionTaxInvoiceFormSubmit(onUpdate, commonParams);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <CollectionTaxInvoiceModalBody {...props} />
      </FormProvider>
    </form>
  );
}
