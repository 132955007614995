import { useCallback, useMemo } from 'react';
import { collectionClaimDocumentFormDefaultRow } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/utils';
import { onDownloadByFileUrls } from '@front/src/utils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DeleteSweep, Download } from '@mui/icons-material';
import { useCustomDialog } from '@front/src/features/dialog';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const useCollectionModalTableRowLogic = (formUpdateFormContext, fieldArray) => {
  const { setValue, getValues } = formUpdateFormContext!;
  const { prepend, append } = fieldArray;

  const handleAddTop = useCallback(() => {
    const defaultRow = collectionClaimDocumentFormDefaultRow();
    setValue('lastUpdatedId', defaultRow.id);
    setValue('editOpenIdList', [...getValues('editOpenIdList'), defaultRow.id]);
    prepend(defaultRow);
  }, [setValue, getValues, prepend]);

  const handleAddBottom = useCallback(() => {
    const defaultRow = collectionClaimDocumentFormDefaultRow();
    setValue('lastUpdatedId', defaultRow.id);
    setValue('editOpenIdList', [...getValues('editOpenIdList'), defaultRow.id]);
    append(defaultRow);
  }, [setValue, append, getValues]);

  const handleCloseAllEditOpen = useCallback(() => {
    setValue('editOpenIdList', []);
  }, [setValue]);

  const { confirm } = useCustomDialog();

  const handleAfterConfirm = useCallback(() => {
    setValue('deleteList', [...getValues('list')]);
    setValue('list', []);
    setValue('lastUpdatedId', null);
    setValue('editOpenIdList', []);
  }, [setValue, getValues()]);

  const handleRemoveAll = useCallback(() => {
    confirm({
      lineBreakChildren: [{ value: '전체 삭제 하시겠습니까?' }],
      confirmText: '확인',
      closeText: '취소',
      afterConfirm: () => {
        handleAfterConfirm();
      },
    });
  }, [confirm, handleAfterConfirm]);

  const handleDownloadAll = useCallback(() => {
    onDownloadByFileUrls({
      fileUrls: getValues('list')
        .filter((f) => !!f.fileItem?.id)
        .map((f) => `/api/file-item/${f.fileItem.id}`),
    });
  }, [getValues]);

  const itemList = useMemo(
    () => [
      {
        text: '최상단에 추가',
        icon: AddCircleOutlineIcon,
        action: handleAddTop,
        seq: 4,
      },
      {
        text: '최하단에 추가',
        icon: AddCircleOutlineIcon,
        action: handleAddBottom,
        seq: 5,
      },
      {
        text: '전체 수정 닫기',
        icon: HighlightOffIcon,
        action: handleCloseAllEditOpen,
        seq: 98,
      },
      {
        text: '전체 다운로드',
        icon: Download,
        action: handleDownloadAll,
        seq: 99,
      },
      {
        text: '전체 삭제',
        icon: DeleteSweep,
        action: handleRemoveAll,
        iconColor: '#e43333',
        seq: 100,
      },
    ],
    [handleAddTop, handleAddBottom, handleCloseAllEditOpen, handleDownloadAll, handleRemoveAll]
  );

  return {
    itemList,
  };
};

export default useCollectionModalTableRowLogic;
