import React, { useMemo } from 'react';
import type { CollectionAddModalProps } from '@front/src/features/project-sales/features/tabs/collection/sections/history/components/AddModal';
import CollectionAddModalBody from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/components/ModalBody';
import type { CollectionAddFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/hooks/useForm';
import useCollectionAddForm from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/hooks/useForm';
import collectionAddMutation from '@front/src/features/project-sales/features/tabs/collection/sections/history/features/add/query/mutation';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';

export default function CollectionAddForm(props: CollectionAddModalProps) {
  const { dataId, sectionId, menuId, collectionData, onClose } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const onAdd = collectionAddMutation.useAdd({
    commonParams,
    collectionId: collectionData.collectionId,
  });

  const { methods, fieldArray } = useCollectionAddForm({ collectionData });

  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = (values: CollectionAddFormValues) => {
    onAdd(
      {
        collectionHistoryId: collectionData.collectionHistoryId,
        collectionId: collectionData.collectionId,
        list: values.list.map((l) => ({
          collectionDueDate: l.collectionDueDate
            ? dayjs(l.collectionDueDate).format(YYYY_MM_DD)
            : null,
          expectedCollectionAmount: l.expectedCollectionAmount,
        })),
      },
      {
        onSuccess: () => {
          onClose();
          enqueueSnackbar('항목이 추가되었습니다.', {
            variant: 'success',
          });
        },
      }
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <CollectionAddModalBody
        {...props}
        formContext={methods}
        fieldArray={fieldArray}
      />
    </form>
  );
}
