import React, { Suspense, useMemo } from 'react';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import { useShallow } from 'zustand/react/shallow';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import useCollectionTaxInvoiceModalStore from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/hooks/useTaxInvoiceModalStore';
import CollectionTaxInvoiceQuery from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/query/query';
import CollectionTaxInvoiceForm from '@front/src/features/project-sales/features/tabs/collection/sections/tax-invoice/components/Form';

export default function CollectionTaxInvoiceModal(props: SectionComponentProps) {
  const { collectionId, onClose, open } = useCollectionTaxInvoiceModalStore(
    useShallow((state) => ({
      collectionId: state.collectionId,
      onClose: state.onClose,
      open: state.open,
    }))
  );

  if (!collectionId) return <></>;

  return (
    <ModalUI
      title="세금계산서 상세"
      open={open}
      onClose={onClose}
      disableEnforceFocus={true} // 모달에서 비고 사용시 form 요소 포커스 안되는 문제 처리
      allowOverflowComponent={true} // 모달 내부에서 date-picker 오동작 처리
      sx={
        {
          // backfaceVisibility: 'hidden',
        }
      }
    >
      <Suspense fallback={<LoadingSpinnerUI />}>
        <Form
          {...props}
          collectionId={collectionId}
          onClose={onClose}
        />
      </Suspense>
    </ModalUI>
  );
}

export interface CollectionTaxInvoiceModalProps extends SectionComponentProps {
  collectionId: number;
  onClose: () => void;
}

const Form = (props: CollectionTaxInvoiceModalProps) => {
  const { menuId, sectionId, dataId, collectionId } = props;
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const list = CollectionTaxInvoiceQuery.useGet(collectionId, commonParams);

  if (!list) return <></>;

  return <CollectionTaxInvoiceForm {...props} />;
};
