import { useQuery } from 'react-query';
import uIBuilderApi from '@front/src/features/ui-builder/api/api';

const useGetUiMetaHiddenList = (commonParams) => {
  const queryKey = [
    'ui-builder',
    'ui-meta',
    'hidden-list',
    commonParams.dataId,
    commonParams.sectionId,
  ];
  const enabled = !!commonParams.dataId && !!commonParams.sectionId;

  const { data } = useQuery({
    queryFn: () => uIBuilderApi.getUIMetaList(commonParams),
    queryKey,
    enabled,
    suspense: true,
    select: (data) =>
      data.map((d) => ({
        id: d.id,
        targetId: d.targetId,
        isBlind: d.isBlind,
      })),
    refetchOnMount: false,
  });

  return data;
};

export default useGetUiMetaHiddenList;
