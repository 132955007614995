import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { YYYY_MM_DD } from '@front/src/utils';
import ContractCmModalBody from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/ModaBody';
import contractCmQuery from '@front/src/features/project-sales/features/tabs/contract/sections/cm/query/query';
import contractCmMutation from '@front/src/features/project-sales/features/tabs/contract/sections/cm/query/mutation';
import type { ContractCmListFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/hooks/useTabForm';
import useContractCmTabForm from '@front/src/features/project-sales/features/tabs/contract/sections/cm/hooks/useTabForm';
import contractHistoryQuery from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/query';
import { useSnackbar } from 'notistack';
import type { ContractCmModalProps } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/Modal';
import { FormProvider } from 'react-hook-form';

export default function ContractCmModalForm(props: ContractCmModalProps) {
  const { dataId, menuId, sectionId, defaultClientId } = props;

  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const dataObject = contractCmQuery.useGetObject(commonParams, defaultClientId);
  const onUpdate = contractCmMutation.useUpdate(commonParams, defaultClientId);
  const historyList = contractHistoryQuery.useGetList(commonParams);

  const methods = useContractCmTabForm(defaultClientId, dataObject, historyList);

  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = useCallback(
    (data) => {
      const params = {
        updateList: [
          ...data.list.map((item) => ({
            ...toParameter(item),
          })),
        ],
      };
      onUpdate(params, {
        onSuccess: () => {
          enqueueSnackbar('저장했습니다.', {
            variant: 'success',
          });
        },
      });
    },
    [onUpdate, enqueueSnackbar]
  );

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <ContractCmModalBody {...props} />
      </FormProvider>
    </form>
  );
}

const toParameter = (item: ContractCmListFormValues) => ({
  id: item.rowId,
  parentId: item.parentId,
  paymentStage: item.paymentStage || null,
  contractCm: item.contractCm ?? null,
  cmRatioAgainstContractPrice: item.cmRatioAgainstContractPrice ?? null,
  cmPostDeduction: item.cmPostDeduction ?? null,
  cmDeductionRatio: item.cmDeductionRatio ?? null,
  cmMethod: item.cmMethod || null,
  cmRecipientId: item.cmRecipient?.id ?? null,
  cmPaymentTiming: item.cmPaymentTiming || null,
  cmExpectedPaymentDate: item.cmExpectedPaymentDate
    ? dayjs(item.cmExpectedPaymentDate).format(YYYY_MM_DD)
    : null,

  numberAttr1: item.numberAttr1,
  numberAttr2: item.numberAttr2,
  numberAttr3: item.numberAttr3,
  textAttr1: item.textAttr1,
  textAttr2: item.textAttr2,
  textAttr3: item.textAttr3,
  dateAttr1: item.dateAttr1 ? dayjs(item.dateAttr1).format(YYYY_MM_DD) : null,
  dateAttr2: item.dateAttr2 ? dayjs(item.dateAttr2).format(YYYY_MM_DD) : null,
  dateAttr3: item.dateAttr3 ? dayjs(item.dateAttr3).format(YYYY_MM_DD) : null,
});
