import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import React, { useCallback, useMemo } from 'react';
import useGetUIBuilderTableHeadContextMenu from '@front/src/hooks/ui-builder-table/useTableHeadLogic';
import useUIBuilderTableHeadMutation from '@front/src/hooks/ui-builder-table/useTableHeadMutation';
import estimationHistoryMutation from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/mutation';
import estimationHistoryQuery from '@front/src/features/project-sales/features/tabs/estimation/sections/history/query/query';
import { useSnackbar } from 'notistack';

export { TableHead as EstimationHistoryTableHead };

function TableHead(props: UIBuilderTableHeadProps) {
  const { dataId, menuId, sectionId } = props;
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const { onAdd, onDeleteAll } = useUIBuilderTableHeadMutation({
    onAdd: estimationHistoryMutation.useAdd(commonParams),
    onDelete: estimationHistoryMutation.useDelete(commonParams),
    commonParams,
    queryKey: ['project', 'sales', commonParams.dataId, 'estimation', 'history'],
  });

  const list = estimationHistoryQuery.useGetList(commonParams);

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteAll = useCallback(() => {
    if (list?.some((f) => !f.estimation.canDelete)) {
      enqueueSnackbar('계약과 연결된 행이 있어 전체 삭제할 수 없습니다.', {
        variant: 'warning',
      });

      return;
    }

    onDeleteAll();
  }, [onDeleteAll, list, enqueueSnackbar]);

  const { itemList } = useGetUIBuilderTableHeadContextMenu({
    onAdd,
    onDeleteAll: handleDeleteAll,
  });

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={itemList}
    />
  );
}
