import headerMeta, {
  arraySupportKeyword,
  SummaryRenderer,
} from '@front/src/components/ui-builder/headerMeta';
import type {
  UIBuilderTableFilterBundle,
  UIBuilderTableFilterItem,
} from '@front/src/components/ui-builder/table/hooks/useForm';

export const getDefaultFilterBundle = (headerList, list) => {
  const getConverted = () =>
    // if (!hasFolded) return headerList;
    //
    // const findIndex = headerList?.findIndex((d) => d.isFoldStartColumn);
    //
    // return headerList?.slice(0, findIndex);
    headerList;
  return getConverted()?.map((h) => {
    const { attributeName, filterable, summaryType = SummaryRenderer.DEFAULT } = headerMeta[h.id];

    return {
      header: h,
      headerId: h.id,
      filterable,
      name: h.name,
      active: false,
      attributeName,
      filter: filterable ? getFilter(list, attributeName) : [],
      summaryType,
    };
  });
};

const getFilter = (list: any[], attributeName?: string[]) => {
  if (!attributeName || attributeName.length === 0) {
    return [];
  }

  const result = list.flatMap((item) => extractNestedValues(item, attributeName));

  const uniqueSortedValues = Array.from(new Set(result)).sort();

  return uniqueSortedValues.map((value) => ({
    value,
    checked: true,
    shown: true,
  }));
};

export const extractNestedValues = (item: any, keys?: string[]): string[] => {
  if (!keys) return [''];

  const [currentKey, ...remainingKeys] = keys;

  if (!currentKey || item == null) {
    return [''];
  }

  const value = item[currentKey];

  if (Array.isArray(value)) {
    return value.flatMap((v) => extractNestedValues(v, remainingKeys.slice(1)));
  }

  if (remainingKeys.length === 0) {
    return value != null ? [value] : [''];
  }

  return extractNestedValues(value, remainingKeys);
};

export const getUIBuilderFilteredList = (
  newFilter: UIBuilderTableFilterItem[],
  index: number,
  originalList: any[],
  bundleFields: UIBuilderTableFilterBundle[]
): any[] => {
  const applyChildFilter = (
    row: any,
    bundleField: UIBuilderTableFilterBundle,
    checkedValues: string[]
  ) => {
    const updatedRow = { ...row };

    bundleField.attributeName?.reduce((acc, key, index) => {
      const children = acc?.[key];

      if (Array.isArray(children)) {
        updatedRow[key] = children.filter((child) => {
          const childValues = extractNestedValues(
            child,
            bundleField.attributeName?.slice(index + 2)
          );

          return checkedValues.includes(childValues[0]);
        });
      }
    }, row);

    return updatedRow;
  };

  const hasNonEmptyChildren = (item: any, bundleField: UIBuilderTableFilterBundle) =>
    bundleField.attributeName?.every((key) => {
      const children = extractNestedValues(item, key.split('.'));
      return !(Array.isArray(children) && children.length === 0);
    });

  return bundleFields.reduce((currentList, bundleField, bundleIndex) => {
    const isCurrentFilter = bundleIndex === index;
    const isActiveFilter = bundleField.active;

    if (!isCurrentFilter && !isActiveFilter) {
      return currentList;
    }

    const checkedValues = (isCurrentFilter ? newFilter : bundleField.filter)
      .filter((f) => f.checked)
      .map((m) => m.value);

    // innerCell 필터 적용
    const filteredInChildren = currentList.map((row) =>
      applyChildFilter(row, bundleField, checkedValues)
    );

    // innerCell 필터 적용 부모에 영향
    if (bundleField.attributeName?.includes(arraySupportKeyword)) {
      return filteredInChildren.filter((item) => hasNonEmptyChildren(item, bundleField));
    }

    // 부모 필터 적용
    return filteredInChildren.filter((item) => {
      const values = extractNestedValues(item, bundleField.attributeName);
      return checkedValues.includes(values[0]);
    });
  }, originalList);
};

export const getUIBuilderFilterBundle = (
  newFilter: UIBuilderTableFilterItem[],
  active: boolean,
  index: number,
  bundleFields: UIBuilderTableFilterBundle[],
  newShowList: any[]
): UIBuilderTableFilterBundle[] => {
  const getUpdatedFilter = (filter: UIBuilderTableFilterItem[], attributeName?: string[]) => {
    const valuesInNewShowList = newShowList.flatMap((item) =>
      extractNestedValues(item, attributeName)
    );

    return filter.map((filterItem) => {
      const isValueInNewShowList = valuesInNewShowList.includes(filterItem.value);

      if (filterItem.checked) {
        return {
          ...filterItem,
          shown: isValueInNewShowList,
        };
      }

      return {
        ...filterItem,
        checked: isValueInNewShowList,
      };
    });
  };

  return bundleFields.map((bundleField, bundleIndex) => {
    if (bundleIndex === index) {
      return { ...bundleField, active, filter: newFilter };
    }

    const updatedFilter = getUpdatedFilter(bundleField.filter, bundleField.attributeName);

    if (bundleField.active) {
      return {
        ...bundleField,
        filter: updatedFilter,
      };
    }

    return {
      ...bundleField,
      filter: bundleField.filter.map((filterItem) => {
        const valuesInNewShowList = newShowList.flatMap((item) =>
          extractNestedValues(item, bundleField.attributeName)
        );

        return {
          ...filterItem,
          shown: valuesInNewShowList.includes(filterItem.value),
        };
      }),
    };
  });
};
