import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import React, { useCallback, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { ContractCmFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/hooks/useTabForm';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export { TableHead as ContractCmTableHead };

interface Props extends Omit<UIBuilderTableHeadProps, 'formUpdateFormContext'> {
  formUpdateFormContext: UseFormReturn<ContractCmFormValues, any, undefined>;
}

function TableHead(props: Props) {
  const { formUpdateFormContext } = props;

  const { setValue } = formUpdateFormContext;

  const handleCloseAllEditOpen = useCallback(() => {
    setValue('editOpenIdList', []);
  }, [setValue]);

  const itemList = useMemo(
    () => [
      {
        text: '전체 수정 닫기',
        icon: HighlightOffIcon,
        action: handleCloseAllEditOpen,
        seq: 98,
      },
    ],
    [handleCloseAllEditOpen]
  );

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={itemList}
    />
  );
}
