import React from 'react';
import DropDownUI from '@front/src/components/components-with-design/component/DropDownUI';
import DatePickerUI from '@front/src/components/components-with-design/component/DatePickerUI';
import TextFieldBasicUI from '@front/src/components/components-with-design/component/TextFieldBasicUI';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import './search-form.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { ProjectSalesDbSearchParameter } from '@front/src/features/project-sales-db/features/data-list/types';
import { YYYY_MM_DD } from '@front/src/utils';

interface FormValues {
  searchTarget: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  keyword: string;
}

export const projectSalesDbDefaultSearchCondition: ProjectSalesDbSearchParameter = {
  searchTarget: 'createdAt',
  startDate: dayjs().add(-365, 'd').format(YYYY_MM_DD),
  endDate: dayjs().format(YYYY_MM_DD),
  keyword: '',
  dataGroup: [],
};

interface SearchFormProps {
  setSearchParams: React.Dispatch<React.SetStateAction<ProjectSalesDbSearchParameter>>;
}

export default function SearchForm(props: SearchFormProps) {
  const { setSearchParams } = props;
  const { control, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      searchTarget: projectSalesDbDefaultSearchCondition.searchTarget,
      keyword: projectSalesDbDefaultSearchCondition.keyword,
      startDate: dayjs(projectSalesDbDefaultSearchCondition.startDate),
      endDate: dayjs(projectSalesDbDefaultSearchCondition.endDate),
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setSearchParams((prevState) => ({
      searchTarget: data.searchTarget,
      keyword: data.keyword,
      startDate: data.startDate?.format(YYYY_MM_DD) ?? '',
      endDate: data.endDate?.format(YYYY_MM_DD) ?? '',
      dataGroup: [...prevState.dataGroup],
    }));
  };

  const onReset = () => {
    setSearchParams((prevState) => ({
      ...prevState,
      keyword: '',
    }));
    setValue('keyword', '');
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="search-form"
    >
      {/* 검색조건 */}
      <div className="search-option">
        <span className="label wide">기간 검색조건</span>

        <Controller
          name="searchTarget"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DropDownUI
              defaultLabel="기간 검색대상"
              options={[{ label: '최초 등록일', value: 'createdAt' }]}
              size="medium"
              value={value}
              onChange={(newValue) => onChange(newValue)}
            />
          )}
        />
      </div>

      {/* 시작일 */}
      <div className="search-option">
        <span className="label">시작일</span>
        <Controller
          name="startDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePickerUI
              value={value}
              onChange={(newValue) => onChange(newValue)}
            />
          )}
        />
      </div>

      {/* 종료일 */}
      <div className="search-option">
        <span className="label">종료일</span>

        <Controller
          name="endDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePickerUI
              value={value}
              onChange={(newValue) => onChange(newValue)}
            />
          )}
        />
      </div>

      {/* 검색어 */}
      <div className="search-option">
        <span className="label">검색어</span>

        <Controller
          name="keyword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextFieldBasicUI
              value={value}
              placeholder="검색어 입력"
              onChange={(newValue) => onChange(newValue)}
            />
          )}
        />
      </div>

      {/* 검색 버튼 */}
      <div className="search-option">
        <ButtonBasicUI
          type="submit"
          shape="primary2"
        >
          검색
        </ButtonBasicUI>
        <ButtonBasicUI
          type="reset"
          shape="tertiary"
          style={{ marginLeft: '5px' }}
          onClick={onReset}
        >
          검색어 리셋
        </ButtonBasicUI>
      </div>
    </form>
  );
}
