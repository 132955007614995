import AccountingBudgetConstant from '@front/src/features/accounting/features/budget/constant';
import {
  AccountingBudgetApiListView,
  AccountingBudgetApiView,
  AccountingBudgetIncomeView,
  AccountingManagementView,
  AddBudgetParameter,
  CommonParameter,
  GetYearParameter,
} from '@front/src/features/accounting/features/budget/type';
import axios from 'axios';
import { getMenuIdHeaders } from '@front/src/utils';

type UrlType = {
  getAccountingManagementList: (params: CommonParameter) => string,
  getBudgetList: (params: CommonParameter) => string,
  getBudget: (params: CommonParameter) => string,
  addBudget: () => string,
}

type ApiType = {
  getAccountingManagementList: (params: CommonParameter) => Promise<AccountingManagementView[]>,
  getBudgetList: (params: CommonParameter) => Promise<AccountingBudgetApiListView[]>,
  getBudget: (params: CommonParameter) => Promise<AccountingBudgetApiView>,
  addBudget: (params: AddBudgetParameter) => Promise<unknown>,
  getIncomeList: (params: GetYearParameter) => Promise<AccountingBudgetIncomeView[]>
}

const url: UrlType = {
  getAccountingManagementList: (params) => `${AccountingBudgetConstant.URL}/${params.dataId}/account-management-item`,
  getBudgetList: (params) => `${AccountingBudgetConstant.URL}?menuId=${params.menuId}`,
  getBudget: (params) => `${AccountingBudgetConstant.URL}/${params.dataId}`,
  addBudget: () => `${AccountingBudgetConstant.URL}`,
};

const AccountingBudgetApi: ApiType = {
  getAccountingManagementList: async (params) => {
    const { data } = await axios.get<AccountingManagementView[]>(url.getAccountingManagementList(params), {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  },
  getBudgetList: async (params) => {
    const { data } = await axios.get<AccountingBudgetApiView[]>(url.getBudgetList(params), {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  },
  getBudget: async (params) => {
    const { data } = await axios.get<AccountingBudgetApiView>(url.getBudget(params), {
      headers: getMenuIdHeaders(params.menuId),
    });
    return data;
  },
  addBudget: async (params) => {
    const { data } = await axios.post<unknown>(url.addBudget(), {
      year: params.budgetYear,
      title: params.title,
    }, {
      headers: getMenuIdHeaders(params.menuId),
    });
    console.log(data);
    return data;
  },
  getIncomeList: async (params) => {
    // TODO: 서버 연결
    return await new Promise<AccountingBudgetIncomeView[]>(resolve => {
      resolve([{
        year: params.budgetYear - 3,
        totalIncome: 0,
      }, {
        year: params.budgetYear - 2,
        totalIncome: 0,
      }, {
        year: params.budgetYear - 1,
        totalIncome: 0,
      }]);
    });
  },
};

export default AccountingBudgetApi;
