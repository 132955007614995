import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import type { CollectionFinalView } from '@front/src/features/project-sales/features/tabs/collection/sections/final/types/views';

const useCollectionFinalForm = (item: CollectionFinalView) => {
  const methods = useForm<CollectionFinalView>({
    defaultValues: getFormValues(item),
  });

  useEffect(() => {
    methods.reset({
      ...getFormValues(item),
    });
  }, [item]);

  return methods;
};

const getFormValues = (item: CollectionFinalView) => ({
  ...item,
  id: item.id,
  paymentStage: item.paymentStage,
  paymentSequence: item.paymentSequence,
  client: item.client,
  paymentTiming: item.paymentTiming,
  expectedPaymentDate: item.expectedPaymentDate,
  clientRatio: item.clientRatio,
  companyStageRatio: item.companyStageRatio,
  totalContractPrice: item.totalContractPrice,
  windTunnelPrice: item.windTunnelPrice,
  actualWindTunnelPrice: item.actualWindTunnelPrice,
  structuralReviewPrice: item.structuralReviewPrice,
  structuralReviewRatio: item.structuralReviewRatio,
  structuralReviewPaymentTarget: item.structuralReviewPaymentTarget,
  structuralReviewPaymentDate: item.structuralReviewPaymentDate,
  contractCM: item.contractCM,
  cmRatio: item.cmRatio,
  clientCollectionManager: item.clientCollectionManager,
});

export default useCollectionFinalForm;
