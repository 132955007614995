import React from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';
import { AffiliatedCompanySelectorWidget } from '@front/src/features/affiliated-company-selector/widgets/widget';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';

interface Props extends Omit<CellComponentProps, 'item' | 'formContext' | 'onCloseEditModal'> {
  name: string;
  disabled?: boolean;
  onAfterChange?: () => void;
}

export default function UIBuilderTableCellAffiliatedCompanySelector(props: Props) {
  const {
    onClick,
    sx,
    readOnly,
    isEditMode,
    onSubmit,
    isForm,
    name,
    disabled = false,
    onAfterChange,
  } = props;

  const { getValues, setValue, watch } = useFormContext();

  const handleChange = (company, callback?) => {
    setValue(name, company);
    callback?.();
    onAfterChange?.();
  };

  const displayValue = getValues(name)?.name ?? '';

  const handleSelector = (company) => handleChange(company, isForm ? undefined : onSubmit);

  const renderContent = () => {
    if (readOnly || !isEditMode) return displayValue || '-';

    return (
      <AffiliatedCompanySelectorWidget
        value={{
          companyId: watch(name)?.id,
          textValue: displayValue,
        }}
        onCompanySelect={handleSelector}
        usePersonSelector={false}
        disabled={disabled}
      />
    );
  };

  return (
    <Box
      sx={sx}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {renderContent()}
    </Box>
  );
}
