import type { ReactNode } from 'react';
import React from 'react';
import ButtonIconUI from '@front/src/components/components-with-design/component/ButtonIconUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import PopoverUI from '@front/src/components/components-with-design/component/PopoverUI';

interface Props {
  children: ReactNode;
}

export default function ButtonIconPopover({ children }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  return (
    <Box>
      <ButtonIconUI
        shape="tertiary"
        size="medium"
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        <FontAwesomeIcon icon="circle-info" />
      </ButtonIconUI>
      <PopoverUI
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {children}
      </PopoverUI>
    </Box>
  );
}
