import React, { useState } from 'react';
import type { SelectInputProps } from '@mui/material/Select/SelectInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import { ColorPalette } from '@front/assets/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';

interface Props extends Omit<SelectInputProps, 'autoWidth' | 'multiple' | 'native'> {
  size?: 'medium' | 'small';
  sx?: SxProps<Theme> | undefined;
  options: IDropDownOption[];
  defaultLabel?: string;
}

export default function DropDownUI({
  disabled,
  defaultLabel,
  options,
  error,
  size = 'medium',
  sx,
  ...rest
}: Readonly<Props>) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (disabled) return;
    setOpen(true);
  };
  const handleClose = () => {
    if (disabled) return;
    setOpen(false);
  };
  const handleClick = () => {
    if (disabled) return;
    setOpen(!open);
  };

  const sizeStyles = {
    medium: {
      height: '32px',
      padding: '6px 10px',
      fontSize: '1.4rem',
      iconSize: '1.4rem',
      gap: '10px',
    },
    small: {
      height: '26px',
      padding: '4px 8px',
      fontSize: '1.2rem',
      iconSize: '1.2rem',
      gap: '8px',
    },
  };

  const menuItemStyles = {
    medium: {
      minHeight: '32px',
      padding: '6px 10px',
      fontSize: '1.4rem',
    },
    small: {
      minHeight: '26px',
      padding: '4px 8px',
      fontSize: '1.2rem',
    },
  };

  const selectedSizeStyles = sizeStyles[size] || sizeStyles.medium;

  return (
    <FormControl
      error={error}
      disabled={disabled}
      sx={{
        width: '100%',
      }}
    >
      <Select
        {...rest}
        displayEmpty
        defaultValue=""
        multiple={false}
        disabled={disabled}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onClick={handleClick}
        IconComponent={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: selectedSizeStyles.iconSize,
              height: selectedSizeStyles.iconSize,
              color: ColorPalette.main.main_primary,
            }}
          >
            <FontAwesomeIcon
              icon={open ? faAngleUp : faAngleDown}
              style={{
                fontSize: selectedSizeStyles.iconSize,
              }}
            />
          </div>
        )}
        renderValue={(selected) => {
          if (selected === '') {
            return (
              <span style={{ color: ColorPalette.greyscale.text_quaternary }}>{defaultLabel}</span>
            );
          }
          return (
            <span style={{ color: ColorPalette.greyscale.text_primary }}>
              {options.find((option) => option.value === selected)?.label}
            </span>
          );
        }}
        sx={{
          cursor: 'pointer',
          height: `${selectedSizeStyles.height}`,
          padding: `${selectedSizeStyles.padding}`,
          fontSize: `${selectedSizeStyles.fontSize}`,
          fontWeight: '400',
          lineHeight: 'normal',
          color: 'inherit',
          background: ColorPalette.background.bg,
          borderRadius: '5px',
          gap: `${selectedSizeStyles.gap}`,

          '& .MuiSelect-icon': {
            color: ColorPalette.main.main_primary,
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${ColorPalette.line.line02}`,
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${ColorPalette.main.main_hover}`,
          },

          '&:active .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${ColorPalette.main.main_hover}`,
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${ColorPalette.main.main_hover}`,

            '&:focus-visible': {
              border: `none`,
              outline: `2px solid ${ColorPalette.sub.sub_primary}`,
              outlineOffset: '-2px',
            },
          },

          '&.Mui-disabled': {
            background: ColorPalette.greyscale.disabled,

            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${ColorPalette.line.line02}`,
            },
          },

          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${ColorPalette.line.line02}`,
          },
          ...sx,
        }}
        inputProps={{
          sx: {
            minHeight: 'unset !important',
            padding: '0px !important',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: `0px 2px 6px 0px rgba(0, 0, 0, 0.25)`,

              '& .MuiMenu-list': {
                padding: '0 !important',
              },
            },
          },
        }}
      >
        {defaultLabel && (
          <MenuItem
            value=""
            sx={{
              ...menuItemStyles[size],
              fontWeight: '400',
              lineHeight: 'normal',
              color: ColorPalette.greyscale.text_quaternary,
              backgroundColor: `${ColorPalette.background.bg} !important`,
            }}
          >
            {defaultLabel}
          </MenuItem>
        )}
        {options.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            sx={{
              ...menuItemStyles[size],
              fontWeight: '400',
              lineHeight: 'normal',

              '&.Mui-selected': {
                backgroundColor: ColorPalette.main.main_tertiary,
              },

              '&:hover': {
                backgroundColor: ColorPalette.main.main_tertiary,
              },
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText
          sx={{
            fontSize: '1.2rem',
            color: ColorPalette.sub2.sub2_primary,
            margin: '2px 0 0 0',
          }}
        >
          Error
        </FormHelperText>
      )}
    </FormControl>
  );
}

export interface IDropDownOption {
  value: any;
  label: string;
}
