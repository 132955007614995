import React, { useCallback, useEffect, useRef } from 'react';
import type { CellComponentProps } from '@front/src/components/ui-builder/cellComponent';

import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';

import TextFieldBasic from '@front/src/components/components-with-design/component/TextFieldBasic';

interface Props extends CellComponentProps {
  name: string;
  disabled?: boolean;
  onAfterChange?: () => void;
}

export default function UIBuilderTableCellInputText(props: Props) {
  const {
    isEditMode,
    isForm,
    onSubmit,
    readOnly,
    onClick,
    sx,
    name,
    disabled = false,
    onAfterChange,
  } = props;

  const { getValues, setValue } = useFormContext();
  const value = getValues(name);

  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (e) => {
    if (ref.current) {
      ref.current.value = e.target.value;
    }
  };

  const handleBlur = useCallback(() => {
    if (ref.current) {
      setValue(name, ref.current.value);
    }

    if (isForm) return;
    onAfterChange?.();
    onSubmit?.();
  }, [setValue, isForm, onAfterChange, onSubmit]);

  const renderContent = useCallback(() => {
    if (readOnly || !isEditMode) return value || '-';

    return (
      <TextFieldBasic
        ref={ref}
        type="text"
        defaultValue={getValues(name) ?? ''}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={readOnly || !isEditMode}
        disabled={disabled}
      />
    );
  }, [readOnly, isEditMode, value, name, handleBlur, handleChange, disabled]);

  useEffect(() => {
    if (ref.current) {
      ref.current.value = getValues(name) ?? 0;
    }
  }, [getValues]);

  return (
    <Box
      onClick={() => {
        onClick && onClick();
      }}
      sx={sx}
    >
      {renderContent()}
    </Box>
  );
}
