import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import UIBuilderTableBody from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderTableRowProps } from '@front/src/components/ui-builder/table/Row';
import UiBuilderTableRow from '@front/src/components/ui-builder/table/Row';
import React, { useCallback, useMemo } from 'react';
import type {
  ContractPaymentTermFormValues,
  ContractPaymentTermListFormValues,
} from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';
import type { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';

import useContractPaymentTermFormUpdateTableRowLogic from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useFormUpdateTableRowLogic';

export { TableBody as ContractPaymentTermTableBody };

interface TableBodyProps extends Omit<UIBuilderTableBodyProps, 'formUpdateFormContext'> {
  fieldArray: UseFieldArrayReturn<ContractPaymentTermFormValues, 'list', string>;
  formUpdateFormContext: UseFormReturn<ContractPaymentTermFormValues, any, undefined>;
}

function TableBody(props: TableBodyProps) {
  const { formUpdateFormContext, fieldArray } = props;
  return (
    <UIBuilderTableBody<ContractPaymentTermListFormValues>
      {...props}
      RowComponent={(rowProps) => (
        <FormProvider {...formUpdateFormContext}>
          <TableRow
            {...rowProps}
            fieldArray={fieldArray}
          />
        </FormProvider>
      )}
    />
  );
}

interface TableRowProps
  extends Omit<UIBuilderTableRowProps<ContractPaymentTermListFormValues>, 'formUpdateFormContext'> {
  fieldArray: UseFieldArrayReturn<ContractPaymentTermFormValues, 'list', string>;
}

function TableRow(props: TableRowProps) {
  const { fieldArray, rowIndex } = props;

  const formUpdateFormContext = useFormContext<ContractPaymentTermFormValues>();
  const { getValues, setValue } = formUpdateFormContext;

  const { sx, contextMenuItems, isEditMode } = useContractPaymentTermFormUpdateTableRowLogic(
    fieldArray,
    rowIndex
  );

  const handleAfterChange = useCallback(() => {
    setValue('lastUpdatedId', getValues(`list.${rowIndex}`)?.id ?? null);
  }, [setValue, getValues]);

  const disabled = useMemo(() => !getValues(`list.${rowIndex}.canDelete`), [getValues, rowIndex]);

  return (
    <UiBuilderTableRow
      {...props}
      itemList={contextMenuItems}
      isEditMode={isEditMode}
      sx={sx}
      onAfterChange={handleAfterChange}
      disabled={disabled}
    />
  );
}
