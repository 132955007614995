import React, { useCallback, useEffect, useState } from 'react';
import {
  getActivePreset,
  serializePreset,
} from '@front/src/features/project-sales-db/features/preset/util';
import {
  PresetData,
  PresetParameter,
  PresetTransientData,
} from '@front/src/features/project-sales-db/features/preset/type';
import { CheckedAttributes } from '@front/src/features/project-sales-db/types';
import { useCustomDialog } from '@front/src/features/dialog';
import { projectSalesDbAnalysisPresetMutation } from '@front/src/features/project-sales-db/features/preset/query/mutation';
import { projectSalesDbAnalysisPresetQuery } from '@front/src/features/project-sales-db/features/preset/query/query';
import { useSnackbar } from 'notistack';
import { projectSalesDbAnalysisApi } from '@front/src/features/project-sales-db/api/api';

interface PresetLogicProps {
  menuId: number;
  checkedAttributes: CheckedAttributes;
  setCheckedAttributes: React.Dispatch<React.SetStateAction<CheckedAttributes>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function usePresetLogic(props: PresetLogicProps) {
  const { checkedAttributes, setCheckedAttributes, setIsLoading, menuId } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { confirm, prompt } = useCustomDialog();

  const [presetId, setPresetId] = React.useState<number>(0);
  const [remainedLoadingItemCount, setRemainedLoadingItemCount] = React.useState(0);
  const [transientCheckedAttributes, setTransientCheckedAttributes] = useState<CheckedAttributes>(
    {}
  );

  const { mutate: addPreset } = projectSalesDbAnalysisPresetMutation.useCreate(menuId);
  const { mutate: removePreset } = projectSalesDbAnalysisPresetMutation.useDelete(menuId);
  const { data: presetList, isLoading } = projectSalesDbAnalysisPresetQuery.useGetList(menuId);
  const { mutate: updatePreset } = projectSalesDbAnalysisPresetMutation.useUpdate(menuId, presetId);

  useEffect(() => {
    const loadingState = remainedLoadingItemCount !== 0;
    if (!loadingState) {
      setCheckedAttributes({ ...transientCheckedAttributes });
      setTimeout(() => {
        setIsLoading(loadingState);
      }, 500);
    }
  }, [remainedLoadingItemCount]);

  useEffect(() => {
    if (presetId === 0) {
      setCheckedAttributes({});
      return;
    }
    loadPresetData(presetId);
  }, [presetId]);

  const handleAddPreset = useCallback(
    (checkedAttributes: CheckedAttributes, name: string | undefined) => {
      addPreset(preparePresetParameter(checkedAttributes, name), {
        onSuccess: (newPresetItem: PresetData) => {
          enqueueSnackbar('등록했습니다', { variant: 'success' });
          setPresetId(newPresetItem.id);
        },
        onError: () => {
          enqueueSnackbar('등록실패', { variant: 'error' });
        },
      });
    },
    []
  );

  const handleUpdatePreset = useCallback(
    (checkedAttributes: CheckedAttributes, name: string | undefined) => {
      updatePreset(preparePresetParameter(checkedAttributes, name), {
        onSuccess: () => {
          enqueueSnackbar('저장했습니다', { variant: 'success' });
        },
        onError: () => {
          enqueueSnackbar('저장실패', { variant: 'error' });
        },
      });
    },
    []
  );

  const loadPresetData = useCallback(
    (presetId: number) => {
      const presetData = getActivePreset(presetId, presetList);
      if (!presetData) {
        console.error(`no preset data found for id=${presetId}`);
        return;
      }

      const data: PresetTransientData = JSON.parse(presetData.data);

      let headerItemCount = 0;
      data &&
        Object.keys(data).forEach((tabId) => {
          Object.keys(data[tabId]).forEach((sectionId) => {
            headerItemCount += data[tabId][sectionId].length;
          });
        });

      setIsLoading(headerItemCount > 0);
      setRemainedLoadingItemCount(headerItemCount);
      setTransientCheckedAttributes({});
      if (headerItemCount === 0) {
        setCheckedAttributes({});
      }

      data &&
        Object.keys(data).forEach((tabId) => {
          const sections = data[tabId];
          Object.keys(sections).forEach((sectionId) => {
            projectSalesDbAnalysisApi.getSectionList(+tabId, menuId).then((response) => {
              const headerIds = data[tabId][sectionId];
              const sectionItem = response.filter((value) => value.id === +sectionId)[0];

              projectSalesDbAnalysisApi
                .getHeaderList(+sectionId, { position: 'table' }, menuId)
                .then((response) => {
                  const headers = response.filter((headerItem) => {
                    return headerIds.includes(headerItem.id);
                  });

                  setTransientCheckedAttributes((prevState) => {
                    const temp: CheckedAttributes = { ...prevState };
                    headers.forEach((headerItem) => {
                      temp[headerItem.id] = {
                        id: headerItem.id,
                        name: headerItem.name,
                        type: 'attribute',
                        parentId: sectionItem.id,
                        parentName: sectionItem.name,
                        children: [],
                        configuration: headerItem,
                        tabId: +tabId,
                      };
                    });
                    return temp;
                  });

                  setRemainedLoadingItemCount((prevState) => {
                    return prevState - headers.length;
                  });
                });
            });
          });
        });
    },
    [presetList]
  );

  const onPresetSelect = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setPresetId(newValue);
  }, []);

  const onDelete = useCallback(() => {
    if (!presetId || presetId === 0) {
      return;
    }

    const presetData = getActivePreset(presetId, presetList);
    confirm({
      title: '삭제',
      children: [
        <div>
          삭제 대상 <strong style={{ color: '#ff3030' }}>{presetData?.name}</strong>
        </div>,
        <div>상기 프리셋을 삭제하겠습니까?</div>,
      ],
      afterConfirm: () => {
        removePreset(presetId, {
          onSuccess: () => {
            enqueueSnackbar('삭제했습니다', { variant: 'success' });
            setPresetId(0);
          },
          onError: () => {
            enqueueSnackbar('삭제실패', { variant: 'error' });
          },
        });
      },
    });
  }, [presetId]);

  const onSave = useCallback(() => {
    const presetData = getActivePreset(presetId, presetList);
    prompt({
      lineBreakChildren: [],
      defaultValue: presetData ? presetData.name : '',
      title: '프리셋 이름을 입력하세요',
      promptText: '확인',
      closeText: '취소',
      maxLength: 200,
      afterPrompt: (name: string) => {
        if (presetId > 0) {
          handleUpdatePreset(checkedAttributes, name);
        } else {
          handleAddPreset(checkedAttributes, name);
        }
      },
    });
  }, [checkedAttributes, presetList, presetId]);

  const onSaveAs = useCallback(() => {
    if (!presetId) return;

    prompt({
      lineBreakChildren: [],
      defaultValue: '',
      title: '프리셋 이름을 입력하세요',
      promptText: '확인',
      closeText: '취소',
      maxLength: 200,
      afterPrompt: (name: string) => {
        handleAddPreset(checkedAttributes, name);
      },
    });
  }, [checkedAttributes]);

  return {
    isLoading,
    presetId,
    presetList,
    onPresetSelect,
    onSave,
    onSaveAs,
    onDelete,
  };
}

function preparePresetParameter(
  checkedAttributes: CheckedAttributes,
  name: string | undefined
): PresetParameter {
  return {
    data: serializePreset(checkedAttributes),
    name: name && name.length > 0 ? name : '제목없음',
  };
}
