import { accountingURL } from '@front/src/features/accounting/utils/constant';

type ConstantType = {
  URL: string,
  queryKey: {
    accountingManagementList: string[],
    incomeList: string[],
    outcomeList: string[]
    budgetList: string[]
  },
}

const AccountingBudgetConstant: ConstantType = {
  URL: `${accountingURL}/budget`,
  queryKey: {
    accountingManagementList: ['accounting', 'budget', 'management', 'list'],
    incomeList: ['accounting', 'budget', 'income', 'list'],
    outcomeList: ['accounting', 'budget', 'outcome', 'list'],
    budgetList: ['accounting', 'budget', 'budget', 'list'],
  },
};

export default AccountingBudgetConstant;
