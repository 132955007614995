import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import UIBuilderTableHeader from '@front/src/components/ui-builder/table/Header';
import React from 'react';
import type { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import type { ContractPaymentTermFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useTabForm';
import { getContractPaymentTermDefaultRow } from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/utils';
import useContractPaymentTermTableHeadLogic from '@front/src/features/project-sales/features/tabs/contract/sections/payment-term/hooks/useFormUpdateTableHeadLogic';

export { TableHead as ContractPaymentTermTableHead };

interface Props extends Omit<UIBuilderTableHeadProps, 'formUpdateFormContext'> {
  fieldArray: UseFieldArrayReturn<ContractPaymentTermFormValues, 'list', string>;
  formUpdateFormContext: UseFormReturn<ContractPaymentTermFormValues, any, undefined>;
}

function TableHead(props: Props) {
  const { fieldArray, formUpdateFormContext } = props;

  const { getValues } = formUpdateFormContext;
  const { itemList } = useContractPaymentTermTableHeadLogic(
    formUpdateFormContext,
    fieldArray,
    getContractPaymentTermDefaultRow(
      getValues('clientId'),
      getValues('contractClientCompany'),
      getValues('parent')
    )
  );

  return (
    <UIBuilderTableHeader
      {...props}
      controlPanelHeaderItemList={itemList}
    />
  );
}
