import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import React, { useCallback, useMemo } from 'react';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import UIBuilderTableCellInputText from '@front/src/components/ui-builder/table/cell-renderer/InputText';
import UIBuilderTableCellDropDown from '@front/src/components/ui-builder/table/cell-renderer/DropDown';
import type { ContractHistoryView } from '@front/src/features/project-sales/features/tabs/contract/sections/history/types/views';
import InnerCellSupport from '@front/src/components/ui-builder/table/InnerCellSupport';
import UIBuilderTableCellAffiliatedCompanySelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanySelector';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellFileAttachment from '@front/src/components/ui-builder/table/cell-renderer/FileAttachment';
import UIBuilderTableCellUserSelector from '@front/src/components/ui-builder/table/cell-renderer/UserSelector';
import usePaymentTermsModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/usePaymentTermsModalStore';
import ButtonBasicUI from '@front/src/components/components-with-design/component/ButtonBasicUI';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';
import { ArithmeticOperator, createDropdownOptions } from '@front/src/utils';
import UIBuilderTableCellText from '@front/src/components/ui-builder/table/cell-renderer/Text';
import { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';
import UIBuilderTableCellManagedDropDown from '@front/src/components/ui-builder/table/cell-renderer/ManagedDropDown';
import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import UncontrolledInput from '@front/src/components/hook-form/uncontrolled/Input';
import useUIBuilderTableRowChildMutation from '@front/src/hooks/ui-builder-table/useTableRowChildMutation';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import contractHistoryClientMutation from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/clientMutation';
import { useShallow } from 'zustand/react/shallow';
import useContractCmModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/useCmModalStore';
import type { ContractHistoryFormValues } from '@front/src/features/project-sales/features/tabs/contract/sections/history/hooks/useForm';
import {
  calculateActualUnitPrice,
  extractTestPlanValues,
} from '@front/src/features/project-sales/features/tabs/estimation/sections/history/utils';
import { useSnackbar } from 'notistack';
import contractHistoryQuery from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/query';

interface Props extends CellComponentProps<ContractHistoryView> {}

/**
 * 확정여부
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => (
  <UIBuilderTableCellDropDown
    {...props}
    name="isConfirmed"
    options={createDropdownOptions(['Y', 'N'])}
  />
);

/**
 * 계약순번
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => (
  <UIBuilderTableCellText
    {...props}
    name="seq"
  />
);

/**
 * 견적서번호
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => {
  const { dataId, sectionId, menuId } = props;

  const commonParams = useMemo(() => ({ dataId, sectionId, menuId }), [dataId, sectionId, menuId]);

  const { getValues } = useFormContext<ContractHistoryFormValues>();

  const contractShortList = contractHistoryQuery.useGetContractList(commonParams);

  const options = contractShortList
    ? contractShortList.map((c) => ({
        label: c.code,
        value: c.id.toString(),
      }))
    : [];

  const displayValue = contractShortList?.find((c) => {
    const estimationId = getValues('estimationId');

    return estimationId && c.id === +estimationId;
  })?.code;

  return (
    <UIBuilderTableCellDropDown
      {...props}
      name="estimationId"
      displayValue={displayValue}
      options={options}
    />
  );
};

/**
 * 수주구분
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => (
  <UIBuilderTableCellManagedDropDown
    {...props}
    name="orderType"
    code={ManagedVariable.ProjectSalesContractOrderType}
  />
);

/**
 * 수주일
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => (
  <UIBuilderTableCellInputDate
    {...props}
    name="orderDate"
  />
);

/**
 * 계약구분
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => {
  const { dataId, menuId, sectionId, formContext, item, readOnly, disabled, onInnerCellSubmit } =
    props;
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);
  const { onAdd, onUpdateSeq, onDelete } = useUIBuilderTableRowChildMutation({
    onAdd: contractHistoryClientMutation.useAdd(commonParams),
    onUpdateSeq: contractHistoryClientMutation.useUpdateSeq(commonParams),
    onDelete: contractHistoryClientMutation.useDelete(commonParams),
    formContext,
    queryKey: ['project', 'sales', dataId, 'contract', 'history', 'client'],
  });

  const { enqueueSnackbar } = useSnackbar();
  const { getValues } = useFormContext<ContractHistoryFormValues>();
  const handleDelete = useCallback(
    (id: number) => {
      if (getValues('contractClient').length < 2) {
        enqueueSnackbar('마지막 남은 행은 삭제할 수 없습니다.', { variant: 'warning' });
        return;
      }
      onDelete(id, item.id);
    },
    [getValues, enqueueSnackbar, onDelete, item]
  );

  const getMenuItems = useCallback(
    (id: number) => [
      {
        text: '추가',
        icon: AddCircleOutlineIcon,
        action: () => onAdd(id, item.id),
        seq: 1,
        split: true,
      },
      {
        text: '이동(위)',
        icon: ArrowUpwardIcon,
        action: () => {
          onUpdateSeq(id, ArithmeticOperator.SUBTRACTION, item.id);
        },
        seq: 2,
      },
      {
        text: '이동(아래)',
        icon: ArrowDownwardIcon,
        action: () => {
          onUpdateSeq(id, ArithmeticOperator.ADDITION, item.id);
        },
        seq: 3,
        split: true,
      },
      {
        text: '삭제',
        icon: DeleteIcon,
        action: () => {
          handleDelete(id);
        },
        iconColor: '#e43333',
        seq: 100,
      },
    ],
    [onAdd, onUpdateSeq, handleDelete]
  );

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      getContextMenuItems={getMenuItems}
      useContextMenu={!readOnly}
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellManagedDropDown
        {...props}
        name="contractType"
        disabled={disabled}
        code={ManagedVariable.ProjectSalesContractType}
      />
    </InnerCellSupport>
  );
};

/**
 * 계약일자
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellInputDate
        {...props}
        name="contractDate"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 발주처
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellAffiliatedCompanySelector
        {...props}
        name="clientCompany"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 계약발주비율
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="clientRatio"
      type="percent"
    />
  </InnerCellSupport>
);

/**
 * 계약서 프로젝트명
 * @param props
 * @constructor
 */
const CellRenderer10 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellInputText
        {...props}
        name="projectName"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 단지명(블럭명)
 * @param props
 * @constructor
 */
const CellRenderer11 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellInputText
        {...props}
        name="blockName"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};
/**
 * 대지모형
 * @param props
 * @constructor
 */
const CellRenderer12 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="siteModel"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 실험대상
 * @param props
 * @constructor
 */
const CellRenderer13 = (props: Props) => {
  const { onInnerCellSubmit } = props;
  const { setValue, getValues } = useFormContext<ContractHistoryFormValues>();

  const handleAfterChange = useCallback(
    (index: number) => {
      const actualWindTunnelPrice = getValues(`contractClient.${index}.actualWindTunnelPrice`);
      const testPlan = getValues(`contractClient.${index}.testPlan`);

      setValue(
        `contractClient.${index}.structuralReviewPrice`,
        calculateStructuralReviewPrice(testPlan)
      );

      setValue(
        `contractClient.${index}.targetUnitPrice`,
        calculateActualUnitPrice(actualWindTunnelPrice, testPlan)
      );
    },
    [getValues, setValue]
  );

  const disabled = props.item.contractClient.some((s) => !s.canDelete);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      onSubmit={onInnerCellSubmit}
      onAfterChange={handleAfterChange}
    >
      <UIBuilderTableCellInputText
        {...props}
        name="testPlan"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 소요기간(주)
 * @param props
 * @constructor
 */
const CellRenderer14 = (props: Props) => {
  const { sx, readOnly, isEditMode, item, onInnerCellSubmit, isForm } = props;

  const disabled = item.contractClient.some((s) => !s.canDelete);
  const { getValues } = useFormContext();
  const list = getValues('contractClient');

  const renderContent = (index) => {
    const requiredWeeksStart = getValues(`contractClient.${index}.requiredWeeksStart`);
    const requiredWeeksEnd = getValues(`contractClient.${index}.requiredWeeksEnd`);
    if (readOnly || !isEditMode) return `${requiredWeeksStart ?? '-'}/${requiredWeeksEnd ?? '-'}`;

    const onBlur = isForm ? undefined : () => onInnerCellSubmit?.(index);

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ...sx,
        }}
      >
        <UncontrolledInput
          type="number"
          name={`contractClient.${index}.requiredWeeksStart`}
          disabled={disabled}
          onBlur={onBlur}
          readOnly={readOnly || !isEditMode}
        />
        /
        <UncontrolledInput
          type="number"
          name={`contractClient.${index}.requiredWeeksEnd`}
          disabled={disabled}
          onBlur={onBlur}
          readOnly={readOnly || !isEditMode}
        />
      </Box>
    );
  };

  return (
    <>
      {list.map((item, index) => (
        <div
          key={index}
          className="inner-cell"
        >
          {renderContent(index)}
        </div>
      ))}
    </>
  );
};

/**
 * 지불조건
 * @param props
 * @constructor
 */
const CellRenderer15 = (props: Props) => {
  const { sx, readOnly, isEditMode } = props;
  const { onOpen, setClientId } = usePaymentTermsModalStore(
    useShallow((state) => ({
      onOpen: state.onOpen,
      setClientId: state.setClientId,
    }))
  );

  const { enqueueSnackbar } = useSnackbar();
  const { getValues } = useFormContext<ContractHistoryFormValues>();
  const list = getValues('contractClient');

  const handleClick = useCallback(
    (id: number) => {
      setClientId(id);
      onOpen();
    },
    [setClientId, onOpen]
  );

  const renderReadOnlyContent = useCallback(
    (paymentTermCount: number | null, onClick: () => void, active: boolean) => (
      <Box
        sx={{
          textDecoration: active ? 'underline' : 'default',
          cursor: active ? 'pointer' : 'default',
        }}
        onClick={onClick}
      >
        {paymentTermCount ?? '-'}
      </Box>
    ),
    []
  );

  const renderEditModeContent = useCallback(
    (handleClick: () => void) => (
      <Box sx={sx}>
        <ButtonBasicUI
          shape="ghost"
          size="small"
          sx={{ minWidth: '5rem' }}
          onClick={handleClick}
        >
          등록
        </ButtonBasicUI>
      </Box>
    ),
    []
  );

  return (
    <>
      {list.map((item, index) => {
        const paymentTermCount = getValues(`contractClient.${index}.paymentTermCount`);
        const active = !!item.clientCompany?.id;
        const handleContentClick = () => {
          if (!active) {
            enqueueSnackbar('계약발주처를 먼저 입력해주세요.', { variant: 'warning' });
            return;
          }

          handleClick(item.id);
        };

        return (
          <div
            key={index}
            className="inner-cell"
            onClick={handleContentClick}
          >
            {readOnly || !isEditMode
              ? renderReadOnlyContent(paymentTermCount, handleContentClick, active)
              : renderEditModeContent(handleContentClick)}
          </div>
        );
      })}
    </>
  );
};

/**
 * 견적총액
 * @param props
 * @constructor
 */
const CellRenderer16 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="totalEstimationPrice"
    />
  </InnerCellSupport>
);

/**
 * 계약총액
 * @param props
 * @constructor
 */
const CellRenderer17 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="totalContractPrice"
    />
  </InnerCellSupport>
);

/**
 * 계약순풍동금액
 * @param props
 * @constructor
 */
const CellRenderer18 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="contractedWindTunnelPrice"
    />
  </InnerCellSupport>
);

/**
 * 계약실풍동금액
 * @param props
 * @constructor
 */
const CellRenderer19 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="actualWindTunnelPrice"
    />
  </InnerCellSupport>
);

/**
 * 계약구검
 * @param props
 * @constructor
 */
const CellRenderer20 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="structuralReviewPrice"
    />
  </InnerCellSupport>
);

/**
 * 계약구검비율
 * @param props
 * @constructor
 */
const CellRenderer21 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="structuralReviewRatio"
      type="percent"
    />
  </InnerCellSupport>
);

/**
 * 계약CM
 * @param props
 * @constructor
 */
const CellRenderer22 = (props: Props) => {
  const { sx, readOnly, isEditMode } = props;
  const { onOpen, setClientId } = useContractCmModalStore(
    useShallow((state) => ({
      onOpen: state.onOpen,
      setClientId: state.setClientId,
    }))
  );

  const { enqueueSnackbar } = useSnackbar();
  const { getValues } = useFormContext();
  const list = getValues('contractClient');

  const handleClick = useCallback(
    (id: number) => {
      setClientId(id);
      onOpen();
    },
    [setClientId, onOpen]
  );

  const renderReadOnlyContent = useCallback(
    (contractCm: number | null, handleClick: () => void, active: boolean) => (
      <Box
        sx={{
          textDecoration: active ? 'underline' : 'default',
          cursor: active ? 'pointer' : 'default',
        }}
        onClick={handleClick}
      >
        {contractCm?.toLocaleString() ?? '-'}
      </Box>
    ),
    []
  );

  const renderEditModeContent = useCallback(
    (handleClick: () => void) => (
      <Box sx={sx}>
        <ButtonBasicUI
          shape="ghost"
          size="small"
          sx={{ minWidth: '5rem' }}
          onClick={handleClick}
        >
          등록
        </ButtonBasicUI>
      </Box>
    ),
    []
  );

  return (
    <>
      {list.map((item, index) => {
        const contractCmCount = getValues(`contractClient.${index}.contractCm`);

        const active = !!item.clientCompany?.id;
        const handleContentClick = () => {
          if (!active) {
            enqueueSnackbar('계약발주처를 먼저 입력해주세요.', { variant: 'warning' });
            return;
          }

          handleClick(item.id);
        };

        return (
          <div
            key={index}
            className="inner-cell"
            onClick={handleContentClick}
          >
            {readOnly || !isEditMode
              ? renderReadOnlyContent(contractCmCount, handleContentClick, active)
              : renderEditModeContent(handleContentClick)}
          </div>
        );
      })}
    </>
  );
};

/**
 * 계약CM비율
 * @param props
 * @constructor
 */
const CellRenderer23 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="cmRatio"
      type="percent"
    />
  </InnerCellSupport>
);

/**
 * 견적 실동단가
 * @param props
 * @constructor
 */
const CellRenderer24 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="estimationActualUnitPrice"
    />
  </InnerCellSupport>
);

/**
 * 계약 실동단가
 * @param props
 * @constructor
 */
const CellRenderer25 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name="contractUnitPrice"
    />
  </InnerCellSupport>
);

/**
 * 목표계약실동단가
 * @param props
 * @constructor
 */
const CellRenderer26 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="targetUnitPrice"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 최저계약실동단가
 * @param props
 * @constructor
 */
const CellRenderer27 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="minimumUnitPrice"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 계약실동원가
 * @param props
 * @constructor
 */
const CellRenderer28 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellInputNumber
        {...props}
        name="actualCost"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 계약서파일(엑셀)
 * @param props
 * @constructor
 */
const CellRenderer29 = (props: Props) => {
  const { onInnerCellSubmit } = props;

  const disabled = props.item.contractClient.some((s) => !s.canDelete);

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      fileItemAttrName="excelFile"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellFileAttachment
        {...props}
        name="excelFile"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 계약서파일(PDF)
 * @param props
 * @constructor
 */
const CellRenderer30 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      fileItemAttrName="pdfFile"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellFileAttachment
        {...props}
        name="pdfFile"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 담당자
 * @param props
 * @constructor
 */
const CellRenderer31 = (props: Props) => {
  const { onInnerCellSubmit, disabled } = props;

  return (
    <InnerCellSupport
      {...props}
      listAttrName="contractClient"
      displayValueAttrName="createdBy.name"
      onSubmit={onInnerCellSubmit}
    >
      <UIBuilderTableCellUserSelector
        {...props}
        name="createdBy"
        disabled={disabled}
      />
    </InnerCellSupport>
  );
};

/**
 * 등록일
 * @param props
 * @constructor
 */
const CellRenderer32 = (props: Props) => (
  <InnerCellSupport
    {...props}
    listAttrName="contractClient"
  >
    <UIBuilderTableCellText
      {...props}
      name="createdAt"
    />
  </InnerCellSupport>
);

export const contractHistoryTableCells: CellComponent = {
  481: CellRenderer1, // 확정여부
  482: CellRenderer2, // 계약순번
  483: CellRenderer3, // 견적번호
  484: CellRenderer4, // 수주구분
  485: CellRenderer5, // 수주일
  486: CellRenderer6, // 계약구분
  487: CellRenderer7, // 계약일자
  488: CellRenderer8, // 계약발주처
  489: CellRenderer9, // 계약발주비율
  490: CellRenderer10, // 계약서 프로젝트명
  491: CellRenderer11, // 단지명(블럭명)
  492: CellRenderer12, // 대지모형
  493: CellRenderer13, // 실험대상
  494: CellRenderer14, // 소요기간(주)
  495: CellRenderer15, // 지불조건
  496: CellRenderer16, // 견적총액
  497: CellRenderer17, //계약총액
  498: CellRenderer18, //계약순풍동금액
  499: CellRenderer19, //계약실풍동금액
  500: CellRenderer20, //계약구검
  501: CellRenderer21, //계약구검비율
  502: CellRenderer22, //계약CM
  503: CellRenderer23, //계약CM비율
  504: CellRenderer24, //견적실동단가
  505: CellRenderer25, //계약실동단가
  506: CellRenderer26, //목표계약실동단가
  507: CellRenderer27, //최저계약실동단가
  508: CellRenderer28, //계약실동원가
  509: CellRenderer29, //계약서파일(한글)
  510: CellRenderer30, //계약서파일(PDF)
  511: CellRenderer31, //등록자
  512: CellRenderer32, //등록일시
  ...generateSpareAttrCellRenders(513),
};

const calculateStructuralReviewPrice = (testPlan: string | null) => {
  if (!testPlan) return null;

  const extractValues = extractTestPlanValues(testPlan);
  const fNumber = extractValues.F ?? 0;
  const pNumber = extractValues.P ?? 0;
  const aNumber = extractValues.A ?? 0;
  const testPlanNumber = Math.max(fNumber, pNumber, aNumber);

  return testPlanNumber * 3000000;
};
