import React, { Suspense, useMemo } from 'react';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import { useShallow } from 'zustand/react/shallow';
import ModalUI from '@front/src/components/components-with-design/layout/ModalUI';
import LoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/LoadingSpinnerUI';
import useContractCmModalStore from '@front/src/features/project-sales/features/tabs/contract/hooks/useCmModalStore';
import contractCmQuery from '@front/src/features/project-sales/features/tabs/contract/sections/cm/query/query';
import ContractCmModalForm from '@front/src/features/project-sales/features/tabs/contract/sections/cm/components/Form';
import contractHistoryQuery from '@front/src/features/project-sales/features/tabs/contract/sections/history/query/query';

export default function ContractCmModal(props: SectionComponentProps) {
  const { onClose, isOpen, clientId } = useContractCmModalStore(
    useShallow((state) => ({
      onClose: state.onClose,
      isOpen: state.isOpen,
      clientId: state.clientId,
    }))
  );

  if (!clientId) return <></>;

  return (
    <ModalUI
      title="계약CM"
      open={isOpen}
      onClose={onClose}
      disableEnforceFocus={true} // 모달에서 비고 사용시 form 요소 포커스 안되는 문제 처리
      sx={
        {
          // backfaceVisibility: 'hidden',
        }
      }
    >
      <Suspense fallback={<LoadingSpinnerUI />}>
        <Form
          {...props}
          defaultClientId={clientId}
        />
      </Suspense>
    </ModalUI>
  );
}

export interface ContractCmModalProps extends SectionComponentProps {
  defaultClientId: number;
}

const Form = (props: ContractCmModalProps) => {
  const { menuId, sectionId, dataId, defaultClientId } = props;
  const commonParams = useMemo(() => ({ menuId, sectionId, dataId }), [menuId, sectionId, dataId]);

  const dataObject = contractCmQuery.useGetObject(commonParams, defaultClientId);
  const historyList = contractHistoryQuery.useGetList(commonParams);

  if (
    !dataObject ||
    !historyList ||
    (Object.keys(dataObject).length === 0 && dataObject.constructor === Object)
  )
    return <></>;

  return <ContractCmModalForm {...props} />;
};
