import { AxiosError } from 'axios';
import type { AlertView } from '@front/src/features/dialog/types';
import type { VariableInformationView } from '@front/src/features/manageable-variable/types/view';
import type { OptionType } from '@front/src/components/select-with-hook-form/select-with-hook-form';

export const defaultApprovalListPath = '/approval/approval-list';

export const noOp = () => {};

export type CallbackOption = {
  onSuccess: (data: any, variables: any, context?: any) => void;
  onError: (error: any, variables: any) => void;
  onSettled: (data: any, error: any, variables: any) => void;
  onMutate?: (variables?: any) => any;
};

export function getNoOpCallback(): CallbackOption {
  return {
    onSuccess: noOp,
    onError: noOp,
    onSettled: noOp,
  };
}

export function getSetCallbackFunc(from: CallbackOption) {
  return (to: Partial<CallbackOption>) => {
    if (to?.onSuccess) {
      from.onSuccess = to.onSuccess;
    }
    if (to?.onError) {
      from.onError = to.onError;
    }
    if (to?.onSettled) {
      from.onSettled = to.onSettled;
    }
    if (to?.onMutate) {
      from.onMutate = to.onMutate;
    }
  };
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const YYYY_MM_DD = 'YYYY-MM-DD';
export const YY_MM_DD = 'YY-MM-DD';
export const YY_MM_DD_HH_MM = 'YY-MM-DD HH:mm';

export const TIME_FORMAT = 'HH:mm';
export const HH_MM = 'HH:mm';
export const HH_MM_SS = 'HH:mm:ss';

export const handleError = (error: unknown, alert?: (props: AlertView) => void, navigate?) => {
  try {
    if (error instanceof AxiosError) {
      if (
        error.response &&
        (error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403 ||
          error.response.status === 404 ||
          error.response.status === 801)
      ) {
        alert?.({
          children: error.response?.data?.message?.map((m) => ({
            value: m,
            sx: {
              display: 'flex',
            },
          })) ?? [{ value: '정확한 값을 입력해주세요.' }],
        });
        if (error.response.status === 801) {
          navigate?.(`/`);
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const convertToOptionList = (view: VariableInformationView[] | undefined) => {
  if (!view)
    return [
      {
        label: '',
        value: '',
      },
    ];
  return view?.map((item) => ({
    label: item.label,
    value: item.value,
  }));
};

export const YNOptionList: OptionType[] = [
  { label: 'Y', value: 'Y' },
  { label: 'N', value: 'N' },
];

export const adminPath = '/admin';
export const menuPath = '/api/admin/menu';

export const pageSizeOptionList = [
  {
    label: '10개씩 보기',
    value: '10',
  },
  {
    label: '20개씩 보기',
    value: '20',
  },
  {
    label: '50개씩 보기',
    value: '50',
  },
  {
    label: '100개씩 보기',
    value: '100',
  },
];

export const myPagePath = '/my-page';
export const WriteAuthErrorMsgArr = [
  {
    value: '개인 권한이 쓰기(W)권한이 아닙니다.',
    sx: {
      textAlign: 'center',
    },
  },
  {
    value: '관리자에게 문의하여 권한을 변경하여 주세요.',
    sx: {
      textAlign: 'center',
    },
  },
];

export const ReadAuthErrorMsgArr = [
  {
    value: '개인 권한이 읽기(R) 혹은 쓰기(W) 권한이 아닙니다.',
    sx: {
      textAlign: 'center',
    },
  },
  {
    value: '관리자에게 문의하여 권한을 변경하여 주세요.',
    sx: {
      textAlign: 'center',
    },
  },
];

export const proceedingsPath = '/proceedings';
export const ostPath = '/ost';
export const hRCardPath = '/hr-card-management';
export const departmentManagementPath = '/personnel/department-management';

export const userNotificationApiURL = '/api/user-notification';
export const noteApiURL = `/api/note`;
export const commentApiURL = `/api/comment`;
export const menuRequestUrl = '/api/admin/menu';
export const apiAminSectionRequestUrl = '/api/admin/section';

export const controlPanelTableCellWidth = 40;
export const userListUrl = '/api/user';

export const personnelOstLogPath = '/hr-card-management/:id/ost-log';
export const personnelDetailPath = '/hr-card-management/:id/detail';

export const departmentDetailPath = '/personnel/department-management/:id';
export const departmentTreePath = '/personnel/department-management-tree';

// temp path start
export const technologyPath = '/project/technology-department';
export const experimentPath = '/project/experiment-department';
export const design1Path = '/project/design1-department';
export const design2Path = '/project/design2-department';
export const manufacturePath = '/project/manufacture-department';
export const tubingPath = '/project/tubing-department';
export const editPath = '/project/edit-department';

export const generalizationPath = '/gantt/generalization';
export const pmPath = '/gantt/pm';
export const headDepartmentPath = '/gantt/head-of-the-department';
export const personnelGantPath = '/gantt/personnel';
export const calendarPath = '/gantt/calendar';

export const wbsPath = '/personnel/wbs';
export const rewardPath = '/personnel/reward-punishment';
export const absenteeismPath = '/personnel/absenteeism-tardiness-management';
export const personnelDesignPath = '/personnel/personnel-design';

export const accountingBudgetPath = '/accounting/budget';
export const executionPath = '/accounting/execution';
export const reportPath = '/accounting/report';

export const statisticsPath = '/project/sales/statistics';

// temp path end

export const IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'];
export function hasImageExtension(extension: string) {
  return IMAGE_FILE_EXTENSIONS.includes(extension?.trim().toLowerCase());
}

export const enum ArithmeticOperator {
  ADDITION = 'ADDITION',
  SUBTRACTION = 'SUBTRACTION',
}

export const enum AddPosition {
  DEFAULT = 'default',
  TOP = 'top',
  BOTTOM = 'bottom',
}
