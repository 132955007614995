import { useMutation, useQueryClient } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';

const collectionTaxInvoiceMutation = {
  useUpdate: (collectionId: number, commonParams: UIBuilderParameter) => {
    const queryClient = useQueryClient();
    const url = `/api/project/sales/sales-information/collection-history/collection/${collectionId}/tax-invoice`;
    const queryKey = getQueryKey(commonParams.dataId, collectionId);

    const { mutate } = useMutation<unknown, Error, FormData>({
      mutationFn: (params) => commonApi.update(url, params, commonParams),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
        await queryClient.invalidateQueries({
          queryKey: ['project', 'sales', commonParams.dataId, 'collection', 'history'],
        });
      },
    });

    return mutate;
  },
};

export default collectionTaxInvoiceMutation;

const getQueryKey = (dataId, collectionId) => [
  'project',
  'sales',
  dataId,
  'collection',
  collectionId,
  'tax-invoice',
];
