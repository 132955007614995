import React, { forwardRef } from 'react';
import type { TableBodyProps } from '@mui/material/TableBody';
import TableBody from '@mui/material/TableBody';
import { ColorPalette } from '@front/assets/theme';

// { sx = {}, children, ...rest }: Readonly<TableBodyProps>

const TableBodyUI = forwardRef<HTMLTableSectionElement, Readonly<TableBodyProps>>(
  ({ sx = {}, children, ...rest }, ref) => (
    <TableBody
      ref={ref}
      sx={{
        position: 'relative',
        background: ColorPalette.background.bg,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </TableBody>
  )
);
export default TableBodyUI;

TableBodyUI.displayName = 'UIBuilderTableBodyUI';
