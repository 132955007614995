import type { Dispatch, SetStateAction } from 'react';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { commonRepository } from '@front/src/api/repository';
import type { UIBuilderTableBodyProps } from '@front/src/components/ui-builder/table/TableBody';
import type { UIBuilderBundleOptions } from '@front/src/features/work/features/work/features/output/components/Table';
import TableContainerUI from '@front/src/components/components-with-design/compound/table/TableContainerUI';
import TableTableUI from '@front/src/components/components-with-design/compound/table/TableTableUI';
import TableBodyUI from '@front/src/components/components-with-design/compound/table/TableBodyUI';
import TableRowLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/TableRowLoadingSpinnerUI';
import UIBuilderHeaderSettingsHistoryModal from '@front/src/components/ui-builder/table/HeaderSettingsHistoryModal';
import TableBodyLoadingSpinnerUI from '@front/src/components/components-with-design/component/loading-spinner/TableBodyLoadingSpinnerUI';
import type { SectionComponentProps } from '@front/src/components/ui-builder/sectionComponent';
import FixedColumnSupport from '@front/src/components/ui-builder/table/FixedColumnSupport';
import type { UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import useUIBuilderTableForm from '@front/src/components/ui-builder/table/hooks/useForm';
import type { UIBuilderTableHeadProps } from '@front/src/components/ui-builder/table/Header';
import './ui-builder-table.css';
import UIBuilderFileAuthSettingsModal from '@front/src/components/ui-builder/table/FileAuthSettingsModal';
import useNoteStore from '@front/src/features/note/useState';
import { useShallow } from 'zustand/react/shallow';
import { noteAutoScrollingAnchorClassName } from '@front/src/features/note/utils';
import { scrollToElement } from '@front/src/utils';
import { useGlobalNavBarState } from '@front/src/features/global-nav-bar/useState';
import type { ControlPanelItem } from '@front/src/types/domain';
import type { SummaryRenderer } from '@front/src/components/ui-builder/table/HeaderSummaryRow';
import type { SxProps } from '@mui/system';
import TableHeadUI from '@front/src/components/components-with-design/compound/table/TableHeadUI';

export interface UiBuilderTableProps<T = any> extends SectionComponentProps {
  options?: UIBuilderTableOptions;
  list?: T[];
  bundleOptions?: UIBuilderBundleOptions;
  useNote?: boolean;
  useSummary?: boolean;
  summaryRenderer?: SummaryRenderer;
  groupAttr?: string;
  groupSumAttrs?: string[];
  useFormUpdate?: boolean;
  formUpdateFormContext?: UseFormReturn<any, any, undefined>;
}

interface Props extends UiBuilderTableProps {
  HeadComponent: (props: UIBuilderTableHeadProps) => JSX.Element;
  BodyComponent: (props: UIBuilderTableBodyProps) => JSX.Element;
  isLoading?: boolean;
  controlPanelHeaderItemList?: ControlPanelItem[];
  sx?: SxProps;
}

//TODO: props.options 속성 의미 확인 필요(기존 업무 feature 결재 연동 시 사용한 옵션으로 판단됨)
/**
 * 공통 테이블 컴포넌트
 * @param {object} props - 컴포넌트의 속성
 * @param {boolean} props.readOnly - 테이블이 읽기 전용인지 여부(true 시 동작버튼[:]을 제공하지 않음)
 * @param {boolean} [props.isLoading=false] - 로딩 상태 여부
 * @param {boolean} [props.useNote=true] - 비고 사용 여부
 * @param {boolean} [props.useSummary=true] - 합계 행 사용 여부
 * @param {SummaryRenderer} [props.summaryRenderer] - 합계 행 커스텀 셀 랜더러 옵션
 * @param {Array} props.list - 테이블에 표시할 데이터 리스트
 * @param {React.ComponentType} props.BodyComponent - 테이블의 본문에 사용할 컴포넌트
 * @param {React.ComponentType} props.HeadComponent - 테이블의 헤더에 사용할 컴포넌트
 * @param {string} props.dataId - 데이터 ID
 * @param {string} props.menuId - 테이블 부모 메뉴 ID
 * @param {string} props.sectionId - 테이블 부모 섹션의 ID
 * @param {object} props.options - 테이블 옵션 객체 (용도 확인중, 업무 feature 결재 연동용 속성으로 추정)
 * @param {string} [props.groupAttr] - 행 병합으로 그룹화 할 대상 속성명
 * @param {string[]} [props.groupSumAttrs] - 그룹 합산할 대상 속성명 배열(groupAttr과 동일한 그룹 내 속성만 허용)
 * @param {boolean} [props.useFormUpdate] - 폼 업데이트 형식(행 블러시 바로 업데이트가 아님) 사용 여부
 * @param {UseFormReturn} [props.formUpdateFormContext] - 폼 업데이트 형식(행 블러시 바로 업데이트가 아님) 훅폼 컨텍스트
 * @constructor
 */
export default function UiBuilderTable(props: Props) {
  const {
    readOnly,
    sectionId,
    BodyComponent,
    HeadComponent,
    isLoading = false,
    menuId,
    options,
    dataId,
    list,
    useNote = true,
    sx = {},
  } = props;
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement | null>(null);
  const { data: headerList } = commonRepository.useGetHeaderList(sectionId, menuId);

  const { methods, onCloseEditModal, resetValues } = useUIBuilderTableForm({ list, headerList });
  const colSpan = (headerList?.length ?? 0) + (readOnly ? 0 : 1) + 1;
  const newOptions = { ...options };
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { leftDrawerState } = useGlobalNavBarState();
  const { rowId, sectionId: noteSectionId } = useNoteStore(
    useShallow((state) => ({
      rowId: state.rowId,
      sectionId: state.sectionId,
    }))
  );

  const [tableReady, setTableReady] = useState<boolean>(false);
  useEffect(
    () => () => {
      setTableReady(false);
    },
    []
  );

  useEffect(() => {
    if (list && headerList) {
      resetValues();
      setTableReady(true);
    }
  }, [list, headerList]);

  useEffect(() => {
    if (tableBodyRef.current && noteSectionId && rowId && leftDrawerState === 'notification') {
      const elements = document.getElementsByClassName(
        `${noteAutoScrollingAnchorClassName}-${rowId}`
      );
      if (elements[0]) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          scrollToElement(elements[0] as HTMLElement, () => {}, 0.9);
          timeoutRef.current = null;
        }, 300);
      }
    }
  }, [timeoutRef.current, noteSectionId, rowId, leftDrawerState]);

  const [someOfRowIsEditMode, setSomeOfRowIsEditMode] = useState<boolean>(false);

  return (
    <FormProvider {...methods}>
      <FixedColumnSupport
        useNote={useNote}
        readOnly={readOnly}
        headerList={headerList}
        isDataReady={tableReady}
      >
        <TableContainerUI
          sx={{
            ...sx,
            position: 'relative',
            opacity: tableReady ? 1 : 0,
            transition: `opacity 0.2s`,
            overflow: 'visible !important',
            '& .MuiPickersPopper-root': {
              position: 'fixed',
              zIndex: 9999,
            },
          }}
        >
          <TableBodyLoadingSpinnerUI
            sx={{
              width: tableBodyRef.current ? tableBodyRef.current.clientWidth : '100%',
              height: tableBodyRef.current ? tableBodyRef.current.clientHeight : 0,
              top: tableHeadRef.current ? tableHeadRef.current.clientHeight : 36,
              zIndex: 11,
            }}
            open={isLoading}
          />
          <UIBuilderHeaderSettingsHistoryModal sectionId={sectionId} />
          <UIBuilderFileAuthSettingsModal
            menuId={menuId}
            dataId={dataId}
          />
          <TableTableUI
            className="ui-builder-table"
            sx={{
              '& .MuiTableRow-root > .MuiTableCell-root:nth-last-of-type(2)': {
                borderRight: 'none',
              },
              '& thead .MuiTableRow-root > .MuiTableCell-root:last-child': {
                padding: 0,
              },
            }}
          >
            <TableHeadUI ref={tableHeadRef}>
              <HeadComponent
                {...props}
                resetValues={resetValues}
                useNote={useNote}
                formContext={methods}
              />
            </TableHeadUI>
            <TableBodyUI ref={tableBodyRef}>
              <Suspense fallback={<TableRowLoadingSpinnerUI colSpan={colSpan} />}>
                <BodyComponent
                  {...props}
                  onCloseEditModal={onCloseEditModal}
                  options={newOptions}
                  useNote={useNote}
                  someOfRowIsEditMode={someOfRowIsEditMode}
                  setSomeOfRowIsEditMode={setSomeOfRowIsEditMode}
                />
              </Suspense>
            </TableBodyUI>
          </TableTableUI>
        </TableContainerUI>
      </FixedColumnSupport>
    </FormProvider>
  );
}

export interface UIBuilderTableOptions {
  setWorkId?: Dispatch<SetStateAction<number | undefined>>;
}
