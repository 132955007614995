import type {
  CellComponent,
  CellComponentProps,
} from '@front/src/components/ui-builder/cellComponent';
import type { EstimationComparisonView } from '@front/src/features/project-sales/features/tabs/estimation/sections/comparison/types/view';
import React, { useCallback } from 'react';
import UIBuilderTableCellDropDown from '@front/src/components/ui-builder/table/cell-renderer/DropDown';
import UIBuilderTableCellInputNumber from '@front/src/components/ui-builder/table/cell-renderer/InputNumber';
import UIBuilderTableCellAffiliatedCompanyPersonSelector from '@front/src/components/ui-builder/table/cell-renderer/AffiliatedCompanyPersonSelector';
import UIBuilderTableCellInputDate from '@front/src/components/ui-builder/table/cell-renderer/InputDate';
import { generateSpareAttrCellRenders } from '@front/src/components/ui-builder/table/utils/spare-attr-renderer-util';
import { createDropdownOptions, getRatio } from '@front/src/utils';

import UIBuilderTableCellManagedDropDown from '@front/src/components/ui-builder/table/cell-renderer/ManagedDropDown';
import { ManagedVariable } from '@front/src/components/ui-builder/managed-variable/utils/constant';

interface Props extends CellComponentProps<EstimationComparisonView> {}

/**
 * 계약지불단계
 * @param props
 * @constructor
 */
const CellRenderer1 = (props: Props) => {
  const { rowIndex, disabled, onAfterChange } = props;

  return (
    <UIBuilderTableCellDropDown
      {...props}
      name={`list.${rowIndex}.paymentStage`}
      disabled={disabled}
      options={createDropdownOptions(['계약금', '중도금', '잔금'])}
      onAfterChange={onAfterChange}
    />
  );
};

/**
 * 계약CM
 * @param props
 * @constructor
 */
const CellRenderer2 = (props: Props) => {
  const { rowIndex, formUpdateFormContext, onAfterChange, disabled } = props;
  const { setValue, getValues } = formUpdateFormContext!;

  const handleAfterChange = useCallback(() => {
    const contractCm = getValues(`list.${rowIndex}.contractCm`);
    const cmPostDeduction = getValues(`list.${rowIndex}.cmPostDeduction`);
    const totalContractPrice = getValues('totalContractPrice');

    setValue(
      `list.${rowIndex}.cmRatioAgainstContractPrice`,
      getRatio(contractCm, totalContractPrice)
    );

    setValue(
      `list.${rowIndex}.cmDeductionRatio`,
      calculateCmDeductionRatio(contractCm, cmPostDeduction)
    );

    onAfterChange?.();
  }, [getValues, setValue, onAfterChange]);

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      name={`list.${rowIndex}.contractCm`}
      disabled={disabled}
      onAfterChange={handleAfterChange}
    />
  );
};

/**
 * 계약액 대비 계약CM비율
 * @param props
 * @constructor
 */
const CellRenderer3 = (props: Props) => {
  const { rowIndex } = props;

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name={`list.${rowIndex}.cmRatioAgainstContractPrice`}
      type="percent"
    />
  );
};

/**
 * 공제후계약CM
 * @param props
 * @constructor
 */
const CellRenderer4 = (props: Props) => {
  const { rowIndex, onAfterChange, disabled } = props;

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      name={`list.${rowIndex}.cmPostDeduction`}
      disabled={disabled}
      onAfterChange={onAfterChange}
    />
  );
};

/**
 * 계약CM공제비율
 * @param props
 * @constructor
 */
const CellRenderer5 = (props: Props) => {
  const { rowIndex } = props;

  return (
    <UIBuilderTableCellInputNumber
      {...props}
      readOnly
      name={`list.${rowIndex}.cmDeductionRatio`}
      type="percent"
    />
  );
};

/**
 * 계약CM방식
 * @param props
 * @constructor
 */
const CellRenderer6 = (props: Props) => {
  const { rowIndex, onAfterChange, disabled } = props;

  return (
    <UIBuilderTableCellManagedDropDown
      {...props}
      name={`list.${rowIndex}.cmMethod`}
      disabled={disabled}
      code={ManagedVariable.ProjectSalesContractCmMethod}
      onAfterChange={onAfterChange}
    />
  );
};
/**
 * 계약CM지급대상
 * @param props
 * @constructor
 */
const CellRenderer7 = (props: Props) => {
  const { rowIndex, onAfterChange, disabled } = props;

  return (
    <UIBuilderTableCellAffiliatedCompanyPersonSelector
      {...props}
      companyName={`list.${rowIndex}.cmRecipientCompany`}
      personName={`list.${rowIndex}.cmRecipient`}
      disabled={disabled}
      onAfterChange={onAfterChange}
    />
  );
};

/**
 * 계약CM지급시기
 * @param props
 * @constructor
 */
const CellRenderer8 = (props: Props) => {
  const { rowIndex, disabled, onAfterChange } = props;

  return (
    <UIBuilderTableCellManagedDropDown
      {...props}
      name={`list.${rowIndex}.cmPaymentTiming`}
      disabled={disabled}
      code={ManagedVariable.ProjectSalesContractCmPaymentTiming}
      onAfterChange={onAfterChange}
    />
  );
};

/**
 * 계약CM지급예정일
 * @param props
 * @constructor
 */
const CellRenderer9 = (props: Props) => {
  const { rowIndex, onAfterChange, disabled } = props;

  return (
    <UIBuilderTableCellInputDate
      {...props}
      name={`list.${rowIndex}.cmExpectedPaymentDate`}
      disabled={disabled}
      onAfterChange={onAfterChange}
    />
  );
};

export const contractCmTableCells: CellComponent = {
  576: CellRenderer1, // 계약지불단계
  577: CellRenderer2, // 계약CM
  578: CellRenderer3, // 계약액 대비 계약CM비율
  579: CellRenderer4, // 공제후계약CM
  580: CellRenderer5, // 계약CM공제비율
  581: CellRenderer6, // 계약CM방식
  582: CellRenderer7, // 계약CM지급대상
  583: CellRenderer8, // 계약CM지급시기
  584: CellRenderer9, // 계약CM지급예정일
  ...generateSpareAttrCellRenders(594),
};

const calculateCmDeductionRatio = (contractCm: number | null, cmPostDeduction: number | null) => {
  if (!contractCm || contractCm === 0) return null;

  return (((contractCm ?? 0) - (cmPostDeduction ?? 0)) / contractCm) * 100;
};
