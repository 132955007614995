import type { UIBuilderParameter } from '@front/src/types/parameter';
import dayjs from 'dayjs';
import type { CollectionClaimDocumentFormValues } from '@front/src/features/project-sales/features/tabs/collection/sections/claim-document/hooks/useForm';
import { useSnackbar } from 'notistack';
import type { UseMutateFunction } from 'react-query';
import { useCallback } from 'react';

const useCollectionClaimDocumentFormSubmit = (
  onUpdate: UseMutateFunction<unknown, Error, FormData, unknown>,
  commonParams: UIBuilderParameter
) => {
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = useCallback(
    (data: CollectionClaimDocumentFormValues) => {
      const formData = new FormData();

      const result = data.list.concat(data.deleteList);

      if (result.length === 0) {
        enqueueSnackbar('변경사항이 없습니다.', {
          variant: 'warning',
        });
        return;
      }

      result.forEach((item, index) => {
        if (item.fileItem?.multipartFile) {
          formData.append(`upsertList[${index}].file`, item.fileItem.multipartFile);
        }

        formData.append(`upsertList[${index}].id`, item.rowId ? item.rowId.toString() : '');
        formData.append(
          `upsertList[${index}].writerId`,
          item.writer?.id ? item.writer?.id.toString() : ''
        );
        formData.append(
          `upsertList[${index}].claimDate`,
          item.claimDate ? dayjs(item.claimDate).format('YYYY-MM-DD') : ''
        );
        formData.append(
          `upsertList[${index}].fileItemId`,
          item.fileItem?.id ? item.fileItem?.id.toString() : ''
        );
        formData.append(`upsertList[${index}].isFileNew`, item.fileItem?.isNew ? 'true' : 'false');
        formData.append(
          `upsertList[${index}].menuId`,
          commonParams.menuId ? commonParams.menuId.toString() : ''
        );
        formData.append(
          `upsertList[${index}].dataId`,
          commonParams.dataId ? commonParams.dataId.toString() : ''
        );
        formData.append(
          `upsertList[${index}].sectionId`,
          commonParams.sectionId ? commonParams.sectionId.toString() : ''
        );
        formData.append(`upsertList[${index}].targetId`, '');

        formData.append(`upsertList[${index}].numberAttr1`, item.numberAttr1?.toString() ?? '');
        formData.append(`upsertList[${index}].numberAttr2`, item.numberAttr2?.toString() ?? '');
        formData.append(`upsertList[${index}].numberAttr3`, item.numberAttr3?.toString() ?? '');
        formData.append(`upsertList[${index}].textAttr1`, item.textAttr1 ?? '');
        formData.append(`upsertList[${index}].textAttr2`, item.textAttr2 ?? '');
        formData.append(`upsertList[${index}].textAttr3`, item.textAttr3 ?? '');
        formData.append(
          `upsertList[${index}].dateAttr1`,
          item.dateAttr1 ? dayjs(item.dateAttr1).format('YYYY-MM-DD') : ''
        );
        formData.append(
          `upsertList[${index}].dateAttr2`,
          item.dateAttr2 ? dayjs(item.dateAttr2).format('YYYY-MM-DD') : ''
        );
        formData.append(
          `upsertList[${index}].dateAttr3`,
          item.dateAttr3 ? dayjs(item.dateAttr3).format('YYYY-MM-DD') : ''
        );
        formData.append(
          `upsertList[${index}].isDeleted`,
          item.rowId && data.deleteList.map((m) => m.rowId).includes(item.rowId) ? 'true' : 'false'
        );
      });

      onUpdate(formData, {
        onSuccess: () => {
          enqueueSnackbar('저장했습니다.', {
            variant: 'success',
          });
        },
      });
    },
    [enqueueSnackbar, onUpdate, commonParams]
  );

  return onSubmit;
};

export default useCollectionClaimDocumentFormSubmit;
