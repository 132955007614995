import { useMutation, useQueryClient } from 'react-query';
import type { UIBuilderParameter } from '@front/src/types/parameter';
import { commonApi } from '@front/src/api/api';
import type { ContractCmUpdateParameter } from '@front/src/features/project-sales/features/tabs/contract/sections/cm/types/parameter';

const contractCmMutation = {
  useUpdate: (commonParams: UIBuilderParameter, clientId: number | null) => {
    const queryClient = useQueryClient();
    const queryKey = getQueryKey(commonParams.dataId, clientId);

    const url = `/api/project/sales/sales-information/contract/contact-client/contract-cm`;

    const { mutate } = useMutation<'', Error, ContractCmUpdateParameter>({
      mutationFn: (params) => commonApi.update(url, params, commonParams),
      mutationKey: [...queryKey, 'update'],
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey });
      },
    });

    return mutate;
  },
};

export default contractCmMutation;

const getQueryKey = (dataId, clientId) => [
  'project',
  'sales',
  dataId,
  'contract',
  'history',
  clientId,
  'cm',
];
